import { useLocation, useNavigate, } from 'react-router-dom';
import React, { useEffect, useState, useCallback,  Fragment  } from 'react';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { getFirestore, doc, getDoc, onSnapshot, Timestamp  } from 'firebase/firestore';
import { RiAccountPinCircleLine } from 'react-icons/ri';
import { FaUserMd, FaCalendar, FaUsers, FaFileMedicalAlt } from 'react-icons/fa';
import { TbLogout2,  } from "react-icons/tb";
import { IoSettingsOutline } from "react-icons/io5";
import { BsCalendar4Range} from "react-icons/bs";

import LanguageSwitcher from '../components/LanguageSwitcher';
import DarkModeToggle from "../components/DarkModeToggle.jsx";

import Spinner from './Spinner'; // Import your spinner component here
import { useLanguage } from '../components/LanguageContext'; 



export default function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [pageState, setPageState] = useState('');
  const [isLeftMenuOpen, setLeftMenuOpen] = useState(false);
  const [userType, setUserType] = useState(''); // Track user type
  const [isLoading, setLoading] = useState(true); // Track loading state
  const { language, translations } = useLanguage();
  const [open, setOpen] = useState(false);

  const auth = getAuth();
  const db = getFirestore();


  const fetchUserType = useCallback(async (uid) => {
    try {
      const userDocRef = doc(db, 'users', uid);
      const doctorDocRef = doc(db, 'doctors', uid);

      // Try to fetch userType from the users collection first
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        setUserType(userData.userType);
        setPageState(  'profil'  );
      } else {
        // If not found in users collection, try the doctors collection
        const doctorDocSnap = await getDoc(doctorDocRef);
        if (doctorDocSnap.exists()) {
          const doctorData = doctorDocSnap.data();
          setUserType(doctorData.userType);
          setPageState(  'profile'  );        } else {
          setPageState('Mon Compte');
        }
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des données de l\'utilisateur :', error);
      setPageState(  'profile'  );    } finally {
      // Data fetching completed
      setLoading(false);
    }
  }, [db]);

  
  function pathMatchRoute(route) {
    if (route === location.pathname) {
      return true;
    }
    return false;
  }

  
  useEffect(() => {
    // Set an initial value of "Mon Compte" while loading
    setPageState("Mon Compte");

    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is authenticated, fetch user type from Firestore
        fetchUserType(user.uid);
      } else {
        // User is not authenticated, loading is complete
        setLoading(false);
      }
    });
  }, [auth, fetchUserType ]);


  const handleLeftMenuToggle = () => {
    setLeftMenuOpen(!isLeftMenuOpen);
  };
  
  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  const onLogout = () => {
    signOut(auth)
      .then(() => {
        navigate("/"); // Redirect to the homepage or your login page
        window.location.reload(); // Reload the page to apply changes
      })
      .catch((error) => {
        console.log(error);
      });
  };
  


  const onNavigateToDossierMedical = () => {
    navigate("/dossier-medical");
  };

  const onNavigateToFavoris = () => {
    navigate("/favoris");
  };

  const onNavigateToMonCompte = () => {
    navigate("/mon-compte");
  };
  const onNavigateToMesRendezVous = () => {
    navigate("/mes-rendez-vous");
  };



  if (isLoading) {
    return <Spinner />; 
  }








  // JSX for the Left Menu
  const renderLeftMenu = () => {
    return (
      <div className="sm:hidden bg-blue-100 dark:bg-blue-200 mt-1 py-1 shadow rounded">
          
        <ul>
          {menuItems.map((item) => (
            <li
              key={item.path}
              className="px-3 py-1 hover:bg-blue-200 cursor-pointer"
              onClick={() => {
                navigate(item.path);
                setLeftMenuOpen(false);
              }}
            >
              {item.text}
            </li>
          ))}
        </ul>
      </div>
    );
  };


  let menuItems = [];
  let renderMenuItems;
  let renderProfileMenu;


  
  if (userType === 'doctor') {
    // Define menu items for doctors
    menuItems = [
      { text: translations.Dashboard , path: "/doctor-dashboard", icon: FaUserMd },
  
];
    // Define renderMenuItems function for doctors
    renderMenuItems = () => {
      return menuItems.map((item) => (
        <li
        key={item.path}
        className={`flex items-center p-3 rounded-lg cursor-pointer hover:bg-blue-100 transition duration-300 ${
          location.pathname === item.path ? 'bg-blue-200 text-blue-800' : ''
        }`}
        onClick={() => {
          navigate(item.path);
          setMenuOpen(false);
        }}
      >
        {item.icon({ size: 20, className: 'mr-2' })}
        <span className="text-lg">{item.text}</span>
      </li>

      ));
    };
  


    // Define renderProfileMenu function for doctors
    renderProfileMenu = () => {
      return (
        <div className="absolute  right-5 mt-4">
      <div className=" bg-blue-300 dark:bg-sky-500  py-2 px-4 shadow-lg rounded-lg w-36">
        <ul className="space-y-2 text-center ">
                  <li
              className="px-2 py-2 bg-blue-100 hover:bg-blue-200 cursor-pointer"
              onClick={() => {
                navigate('/mes-rdv');
                setMenuOpen(false);
              }}              
            >
                      < BsCalendar4Range  size={20} className="left-0 top-0  font-semibold transition-all duration-300 ease-in-out transform hover:rotate-12" />
                          {translations.myappointements}
        
            </li>
            <li
              className="px-4 py-2 bg-blue-100 hover:bg-blue-300 cursor-pointer"
 onClick={() => {
  navigate('/profile-doctor');
                setMenuOpen(false);
              }}                          >
                                       < IoSettingsOutline  size={20} className="left-0 top-0  font-semibold transition-all duration-300 ease-in-out transform hover:rotate-12" />
     {translations.Profilesettings}
            </li>
        
            <li
            className="text-red-600 hover:text-red-800 bg-red-100 hover:bg-red-300 cursor-pointer px-2 py-1 rounded-md transition duration-300"
            onClick={onLogout}
            >
    < TbLogout2  size={20} className=" text-red-600 font-semibold transition-all duration-300 ease-in-out transform hover:rotate-12" />
                            {translations.Déconnexion}

            </li>
          </ul>
        </div>
        </div>

      );
    };




  } else {

    // Define menu items for other user types
    menuItems = [
      { text: translations.bookappointementheader , path: "/doctors-display-page" }, // Customize for non-doctors

      { text:  translations.BecomingPartner , path: "/compte-Pro" },
    ];
  
    // Define renderMenuItems function for other user types
    renderMenuItems = () => {
      return menuItems.map((item) => (
        <li
          key={item.path}
          className={`ml-3 hover:text-blue-300 cursor-pointer font-semibold ${
            location.pathname === item.path ? 'text-blue-800' : ''
          }`}
          onClick={() => {
            navigate(item.path);
            setMenuOpen(false);
          }}
        >
          {item.text}
        </li>
      ));
    };
  
    // Define renderProfileMenu function for other user types
    renderProfileMenu = () => {
      return (
        <div className=" absolute  bg-blue-300 dark:bg-sky-500    mt-2 py-1 shadow rounded w-36 right-5">
        <ul className="space-y-2 text-center ">
            <li
              className="px-2 py-2 bg-blue-100 hover:bg-blue-300  cursor-pointer"
              onClick={() => {
                navigate('/mes-rdv');
                setMenuOpen(false);
              }}              
            >
                      < BsCalendar4Range  size={20} className="left-0 top-0  font-semibold transition-all duration-300 ease-in-out transform hover:rotate-12" />
                          {translations.myappointements}
        
            </li>
            <li
              className="px-4 py-2 bg-blue-100 hover:bg-blue-300 cursor-pointer"
 onClick={() => {
  navigate('/profile');
                setMenuOpen(false);
              }}                          >
                                       < IoSettingsOutline  size={20} className=" left-0 top-0 font-semibold transition-all duration-300 ease-in-out transform hover:rotate-12" />
     {translations.Profilesettings}
            </li>
        
   
            <li
            className="text-red-600  hover:text-red-800 bg-red-100 hover:bg-red-300 cursor-pointer px-2 py-1 rounded-md transition duration-300"
            onClick={onLogout}
            >
                           <  TbLogout2   size={20} className="left-0 top-0  font-semibold transition-all duration-300 ease-in-out transform hover:rotate-12" />
                  {translations.Déconnexion}
        
            </li>
          </ul>
        </div>
      );
    };
  }





  // Conditionally render the appropriate header based on userType
  if (userType === 'doctor') {
    return (
      // JSX for the header for doctor user type
      <div className="bg-gray-100 dark:bg-gray-200  border-b shadow-sm sticky top-0 z-40">
        
        <header className="flex justify-between items-center px-3 max-w-6XL mx-auto">
          <div className="flex items-center">
            <img
              src={process.env.PUBLIC_URL + '/logo.png'}
              alt="Logo"
              className="w-16 sm:w-24 h-auto cursor-pointer"
              onClick={() => navigate('/')}
            />
            <div className="sm:hidden">
              <button
                className="ml-3 text-blue-800 hover:text-blue-300 focus:outline-none"
                onClick={handleLeftMenuToggle}
              >
                <svg
                  className="w-6 h-6"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="fill-current"
                    d="M3 6h18v2H3zm0 5h18v2H3zm0 5h18v2H3z"
                  />
                </svg>
              </button>
            </div>
            
            <ul className="hidden sm:flex">{renderMenuItems()}</ul>
          </div>
          <div>


            <ul className="flex">



                      <div className=" sm:flex mx-1 items-center">
            {/* Language switcher always under "Home" link */}
            <li
            >
                   <DarkModeToggle />

            </li>
            <LanguageSwitcher />
          </div>

              {pageState === 'Mon Compte' ? (
                <li
                  className={`ml-3 sm:ml-4 ${
                    location.pathname === '/mon-compte' ? 'text-blue-800' : 'hover:text-blue-300'
                  } cursor-pointer font-semibold`}
                  onClick={onNavigateToMonCompte}
                >
               {translations.MyAccount}  
                </li>
              ) : (
                <li
                  className={`ml-3 sm:ml-4 ${
                    pathMatchRoute('/sign-in') || pathMatchRoute('/profile')
                      ? 'text-black border-b-red-500 '
                      : 'hover:text-blue-300'
                  } cursor-pointer font-semibold flex flex-col items-center relative`}
                  onClick={handleMenuToggle}
                >
                  <RiAccountPinCircleLine style={{ fontSize: '25px' }} />
                  <span style={{ fontSize: '14px', marginTop: '0.25rem' }}>{translations.MyProfile}</span>
                </li>
              )}
            </ul>
          </div>
        </header>

        {/* Profile Menu */}
        {pageState !== 'Mon Compte' && isMenuOpen && renderProfileMenu()}

        {/* Left Menu */}
        {isLeftMenuOpen && renderLeftMenu()}
      </div>
    );
  } else {
    return (
      // JSX for the header for all other user types
      <div className="bg-gray-100 dark:bg-gray-200 border-b shadow-sm sticky top-0 z-40">
               <header className="flex justify-between items-center px-3 max-w-6xl mx-auto">
      
      
          <div className="flex items-center">
            <img
             src={process.env.PUBLIC_URL + '/logo.png'}

              alt="Logo"
              className="w-16 sm:w-24 h-auto cursor-pointer"
              onClick={() => navigate('/')}
            />
            <div className="sm:hidden">
              <button
                className="ml-3 text-blue-800 hover:text-blue-300 focus:outline-none"
                onClick={handleLeftMenuToggle}
              >
                <svg
                  className="w-6 h-6"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="fill-current"
                    d="M3 6h18v2H3zm0 5h18v2H3zm0 5h18v2H3z"
                  />
                </svg>
              </button>
            </div>
    
            <ul className="hidden sm:flex">{renderMenuItems()}</ul>
          </div>
          <div>
         

            <ul className="flex">
            <div className=" sm:flex mx-1 items-center">
            {/* Language switcher always under "Home" link */}
            <li
            >
      <DarkModeToggle />
            </li>
            <LanguageSwitcher />
          </div>
              {pageState === 'Mon Compte' ? (
                <li
                  className={`ml-3 sm:ml-4 ${
                    location.pathname === '/mon-compte' ? 'text-blue-800' : 'hover:text-blue-300'
                  } cursor-pointer font-semibold`}
                  onClick={onNavigateToMonCompte}
                >
  {translations.MyAccount}  
                </li>
              ) : (
                <li
                  className={`ml-3 sm:ml-4 ${
                    pathMatchRoute('/sign-in') || pathMatchRoute('/profile')
                      ? 'text-black border-b-red-500 '
                      : 'hover:text-blue-300'
                  } cursor-pointer font-semibold flex flex-col items-center relative`}
                  onClick={handleMenuToggle}
                >
                  <RiAccountPinCircleLine style={{ fontSize: '25px' }} />
                  <span style={{ fontSize: '14px', marginTop: '0.25rem' }}>{translations.MyProfile}</span>
                </li>
              )}
            </ul>
          </div>
        </header>

        {/* Profile Menu */}
        {pageState !== 'Mon Compte' && isMenuOpen && renderProfileMenu()}

        {/* Left Menu */}
        {isLeftMenuOpen && renderLeftMenu()}
      </div>
    );
  }
}
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Firebase configuration details
const firebaseConfig = {
  apiKey: "AIzaSyBB0v22FA9EOUFj-eJqki0S8Kn5d56vgKI",
  authDomain: "doktori-71100.firebaseapp.com",
  projectId: "doktori-71100",
  storageBucket: "doktori-71100.appspot.com",
  messagingSenderId: "736872437584",
  appId: "1:736872437584:web:5dd8ec1381a3af4721fe55",
  measurementId: "G-91PN0YB6ZM",
};

// Initialize Firebase and Firebase Cloud Messaging (FCM)
initializeApp(firebaseConfig);
const messaging = getMessaging();

// Function to request permission for push notifications
export const requestPermission = () => {
  // Check if Notification is supported in the current environment
  if (typeof Notification !== "undefined") {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        // Get the Firebase Cloud Messaging token
        getToken(messaging, { vapidKey: "BPERKMNyCr1RUkg9fio0yPlXui2AofTswtTDtjr8j9rgMXTqaJBswtBCT0sqt6GDV1MSo5fh-XO_5CMCWCP6MHc" })
          .then((token) => {
            if (token) {
            } else {
              console.warn("Unable to retrieve token.");
            }
          })
          .catch((error) => {
            console.error("Error fetching token:", error);
          });
      } else {
        console.log("Notification permission denied.");
      }
    });
  } else {
    console.warn("Notifications are not supported in this environment.");
  }
};

// Function to listen for messages
export const onMessageListener = () =>
  new Promise((resolve, reject) => {
    if (typeof onMessage !== "undefined") {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
    } else {
      reject(new Error("Messaging not supported."));
    }
  });

export const db = getFirestore(); // Initialize Firestore
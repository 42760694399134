import React, { useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import { useLocation } from 'react-router-dom';

function Layout({ children }) {
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  
  useEffect(() => {
    if (location.pathname !== '/doctors-display-page') {
      window.scrollTo(0, 0); // Scroll to the top of the page on each location change
    } // Scroll to the top of the page on each location change
  }, [location]);
  
  return (
    <div className="flex flex-col bg-blue-100 dark:bg-gray-700  min-h-screen">
      <Header />
      <main className="flex-grow">{children}</main>
      {isHomePage && <Footer />}
    </div>
    
  );
}

export default Layout;

import React, { createContext, useContext, useEffect, useState } from 'react';

const DarkModeContext = createContext();

export const useDarkMode = () => useContext(DarkModeContext);

export const DarkModeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const storedMode = localStorage.getItem('isDarkMode');
    return storedMode ? storedMode === 'true' : false; // Default to light mode
  });

  useEffect(() => {
    localStorage.setItem('isDarkMode', isDarkMode.toString()); // Store in localStorage
    if (isDarkMode) {
      document.documentElement.classList.add('dark'); // Apply dark mode
    } else {
      document.documentElement.classList.remove('dark'); // Apply light mode
    }
  }, [isDarkMode]); // Run effect when isDarkMode changes

  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => !prevMode); // Toggle the mode
  };

  return (
    <DarkModeContext.Provider value={{ isDarkMode, toggleDarkMode }}>
      {children}
    </DarkModeContext.Provider>
  );
};

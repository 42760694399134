import { useState, useEffect, useRef, Fragment } from "react";
import Moment from 'react-moment';
import moment from 'moment';
import 'moment/locale/fr';
import Select from 'react-select';
import React from 'react';
import EditDayPopup from '../components/docdashboard/EditDayPopup'; 
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ImCalendar } from 'react-icons/im';
import { BsArrowLeftCircle, BsArrowRightCircle } from 'react-icons/bs';
import { CgToday } from 'react-icons/cg';
import { AiOutlineFieldTime } from 'react-icons/ai';
import { TbReport } from 'react-icons/tb';
import { TbCalendarCancel, TbCheckupList } from 'react-icons/tb';
import { IoClose } from 'react-icons/io5';
import { IoMdTime } from 'react-icons/io';
import { LuCalendarClock } from "react-icons/lu";
import { MdOutlineBloodtype } from "react-icons/md";
import { BsGenderFemale, BsGenderMale, BsGenderAmbiguous } from 'react-icons/bs';
import { GiSandsOfTime } from "react-icons/gi";
import { LiaAllergiesSolid } from "react-icons/lia";
import { MdOutlineSick } from "react-icons/md";
import { MdPhone } from "react-icons/md";
import { FaNotesMedical } from "react-icons/fa6";
import { HiOutlineClipboardCheck } from "react-icons/hi";
import { FaTimes, FaSearch, FaUsers } from 'react-icons/fa';
import { CgArrowLongDownC } from "react-icons/cg";


import { getAuth } from "firebase/auth";
import { db } from "../firebase";
import Spinner from "../components/Spinner"; // Adjust the import based on your project structure
import { Dialog, Transition } from '@headlessui/react';

import DatePicker from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import { Bar } from 'react-chartjs-2';

import { useLanguage } from '../components/LanguageContext'; 






import { collection, addDoc, doc, updateDoc, getDoc, setDoc, onSnapshot, Timestamp } from 'firebase/firestore';
import { MapContainer } from "react-leaflet";




const TimeBlock = ({ content, isSelected, onClick, onRemove, time, blockDate, isHighlightedDay, dayName, dayOfMonth,   view,  isDisabled,
statusCategory, 
}) => {


 
  const baseClasses = 'h-16 border border-gray-300 cursor-pointer';
  const dayHighlightClass = isHighlightedDay ? 'bg-blue-200' : '';

  const horizontalClass = view === 'day' ? 'w-16' : ''; // Apply width for day view
  const fullDateTime = `${dayName}, ${dayOfMonth} ${time}`;

  const uniqueId = `${dayName} ${dayOfMonth} ${time}`;

  const selectedClass =
  isSelected && statusCategory.status === 'reserved' ? 'bg-blue-300 ' :
  isSelected && statusCategory.status === 'reported' ? 'bg-orange-300 ' :
  isSelected && statusCategory.status === 'canceled' ? 'bg-red-300' :

  isSelected ? 'bg-green-300 ' :
  '';

  const [isDeleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);

  const handleDeleteClick = (e) => {
    e.stopPropagation(); // Prevent the time block click event from triggering

    if (!isSelected) {
      // Show the delete confirmation box
      setDeleteConfirmationVisible(true);
    } else {
      // Show a toast message for selected timeslot
      toast.error("The timeslot is selected. Please unselect it before deleting.");
    }
  };

  const handleDeleteConfirm = () => {
    // Delete the timeslot
    onRemove();
    setDeleteConfirmationVisible(false);
  };

  // Add an event handler to stop propagation for the confirmation dialog
  const handleConfirmationClick = (e) => {
    e.stopPropagation();
  };
 
// Calculate a dynamic z-index value based on the number of timeboxes rendered
   const zIndex = isDeleteConfirmationVisible ? 1000 : 0;

   

   return (
    <div
      className={`${baseClasses} ${dayHighlightClass} ${selectedClass}   transition-transform transform hover:scale-105 ${
        isDisabled ? 'opacity-50' : '' // Apply opacity-50 if the time slot is disabled
      }`}
      onClick={() => onClick(uniqueId)}
      
      style={{ zIndex: zIndex }}
    >

      <div className="flex flex-col justify-center  h-full p-2">
        <div className="text-lg font-semibold dark:text-gray-800  text-gray-800">{time}</div>
        <div className="text-xs max-w-full dark:text-gray-800 overflow-hidden whitespace-nowrap overflow-ellipsis font-semibold text-gray-500">
          {statusCategory && statusCategory.patientName}
        </div>       
         {onRemove && (
          <button
            className="absolute top-0 right-0 p-2 text-red-500 hover:text-red-700 focus:outline-none"
            onClick={handleDeleteClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-4 h-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        )}
      </div>
      {isDeleteConfirmationVisible && (
  <div
    className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50"
    onClick={handleConfirmationClick}
  >
    <div className="bg-white p-6 rounded-lg w-80 text-center shadow-lg" style={{ zIndex: zIndex + 1 }}>
            <p className="text-gray-800 text-lg mb-4">
              Êtes-vous sûr de vouloir supprimer cette plage horaire ?
            </p>
            <p className="text-gray-600 text-sm mb-6">{fullDateTime}</p>
      <div className="flex justify-end">

        <button  className="text-red-500 hover:text-red-700 px-4 py-2 rounded-md border border-red-500 hover:bg-red-100 focus:outline-none" onClick={handleDeleteConfirm}>
          Oui
        </button>
        <button
                className="text-gray-500 hover:text-gray-700 px-4 py-2 ml-4 rounded-md border border-gray-500 hover:bg-gray-100 focus:outline-none"
                onClick={() => setDeleteConfirmationVisible(false)}
        >
          Non
        </button>
      </div>
    </div>
  </div>
)}

    </div>
  );
};









// Define Popup component
function Popup({ content, onClose, }) {
  const [isLoading, setIsLoading] = useState(false);
  const [reportFormData, setReportFormData] = useState({
    newDateday: '',
    newDateId: '',
  });
  const [showReportForm, setShowReportForm] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date()); // Initialize with the current date
  const [newDateday, setNewDateday] = useState('');
  const [newDateId, setNewDateId] = useState('');
  const [selectedHour, setSelectedHour] = useState('09');
  const [selectedMinute, setSelectedMinute] = useState('30');
  const { language, translations } = useLanguage();

  const [isCancelConfirmationVisible, setCancelConfirmationVisible] = useState(false);
  const [isReportConfirmationVisible, setReportConfirmationVisible] = useState(false);
// Set the locale for French directly
const fr = {
  locale: 'fr-FR',
  weekStartsOn: 1,
};
// Hardcoded time options
const TimeOptions = () => {
  const hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
const minutes = [
  '00', '01', '02', '03', '04', '05', '06', '07', '08', '09',
  '10', '11', '12', '13', '14', '15', '16', '17', '18', '19',
  '20', '21', '22', '23', '24', '25', '26', '27', '28', '29',
  '30', '31', '32', '33', '34', '35', '36', '37', '38', '39',
  '40', '41', '42', '43', '44', '45', '46', '47', '48', '49',
  '50', '51', '52', '53', '54', '55', '56', '57', '58', '59'
];

  return { hours, minutes };
};

// Use the TimeOptions component to get the hardcoded values
const timeOptions = TimeOptions();

const handleHourChange = (selectedOption) => {
  setSelectedHour(selectedOption.value);
  handleDateChange(selectedDate);
};

const handleMinuteChange = (selectedOption) => {
  setSelectedMinute(selectedOption.value);
  handleDateChange(selectedDate);
};

// Create options for hours and minutes
const hourOptions = timeOptions.hours.map((hour) => ({ value: hour, label: hour }));
const minuteOptions = timeOptions.minutes.map((minute) => ({ value: minute, label: minute }));


  const handleDateChange = (date) => {
    // Check if the selected date is in the future
    if (date >= new Date()) {
      setSelectedDate(date);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      const newDateTime = `${selectedHour}:${selectedMinute}`;

      setNewDateday(`${year}-${month}-${day}`);
      setNewDateId(`${getDayOfWeek(date)} ${day} ${newDateTime}`);
      setReportFormData({ ...reportFormData, newDateTime });
    }
  };

  // ...

  const getDayOfWeek = (date) => {
    const daysOfWeek = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'];
    return daysOfWeek[date.getDay()];
  };

// Helper function to check if the appointment is in the future
const isAppointmentInFuture = (appointmentDay) => {
  const currentDate = new Date().toISOString().split('T')[0];
  return appointmentDay >= currentDate;
};


  const handleRefuseAppointment = async () => {
    const serverTimestamp = Timestamp.fromDate(new Date());
    const auth = getAuth();
    setIsLoading(true); // Set loading state to true

    try {
      const user = auth.currentUser;
      const doctorUID = user.uid;
      const userID = content.userId;
  
      if (!content) {
        console.error("Error: content is undefined");
        return;
      }
  
      const userRdvRef = doc(db, "RDVofuser", userID);
  
      if (content) {
        const appointmentPath = `${content.day}.${content.uniqueId}`;
        const updatedUserDocData = {
          [appointmentPath]: {
            status: "canceled",
            patientName: content.patientName || "",
            symptoms: content.symptoms || "",
            sex: content.sex || "",
            typeOfConsultation: content.typeOfConsultation || "",
            firstTimeConsulting: content.firstTimeConsulting || "",
            Age: content.Age || "",
            bloodtype: content.bloodtype || "",
            Alergies: content.Alergies || "",
            phoneNumber: content.phoneNumber || "",
            newDateday: content.newDateday || "",
            newDateid: content.newDateid || "",  

            userId: userID,
            doctorUID: doctorUID || "",
            serverTimestamp,
          },
        };
  
        // Update the user's document with the new status
        await updateDoc(userRdvRef, updatedUserDocData);
  
        const rdvRef = doc(db, "RDV", doctorUID);
        const rdvDoc = await getDoc(rdvRef);
  
        if (rdvDoc.exists()) {
          const rdvData = rdvDoc.data();
  
          const currentDate = new Date();
          currentDate.setHours(0, 0, 0, 0);
  
          const filteredTimeSlots = rdvData.selectedTimeSlots.reduce((filteredSlots, dayData) => {
            const dayDate = new Date(dayData.day);
  
            if (dayDate >= currentDate) {
              const filteredDaySlots = dayData.slots.filter((timeSlot) => {
                return timeSlot.status === "selected";
              });
  
              if (filteredDaySlots.length > 0) {
                filteredSlots.push({
                  day: dayData.day,
                  slots: filteredDaySlots,
                });
              }
            }
  
            return filteredSlots;
          }, []);
  
          const dayToUpdate = content.day || "";
          const slotIdToUpdate = content.uniqueId || "";
  
          // Create a copy of rdvData to modify
          const updatedRdvData = [...rdvData.selectedTimeSlots];
  
          // Find the dayData corresponding to selectedTimeSlot's day
          let dayDataToUpdate = updatedRdvData.find(dayData => dayData.day === dayToUpdate);
  
          // If the dayData doesn't exist, create a new one
          if (!dayDataToUpdate) {
            dayDataToUpdate = {
              day: dayToUpdate,
              slots: [],
            };
            updatedRdvData.push(dayDataToUpdate);
          }
  
          // Find the selected time slot within dayDataToUpdate
          // Find the index of the selected time slot within dayDataToUpdate
          const slotIndexToUpdate = dayDataToUpdate.slots.findIndex(slot => slot.id === slotIdToUpdate);
  
          // If the slot doesn't exist, create a new one; otherwise, update it
          if (slotIndexToUpdate === -1) {
            const slotToUpdate = {
              id: slotIdToUpdate,
              status: "canceled",
              patientName: content.patientName || "",
              symptoms: content.symptoms || "",
              sex: content.sex || "",
              typeOfConsultation: content.typeOfConsultation || "",
              firstTimeConsulting: content.firstTimeConsulting || "",
              Age: content.Age || "",
              bloodtype: content.bloodtype || "",
              Alergies: content.Alergies || "",
              phoneNumber: content.phoneNumber || "",
              newDateday: content.newDateday || "",
              newDateid: content.newDateid || "",  

              userId: userID,
              doctorUID: doctorUID || "",
              serverTimestamp,
            };
            dayDataToUpdate.slots.push(slotToUpdate);
          } else {
            // Update the existing slot
            dayDataToUpdate.slots[slotIndexToUpdate] = {
              id: slotIdToUpdate,
              status: "canceled",
              patientName: content.patientName || "",
              symptoms: content.symptoms || "",
              sex: content.sex || "",
              typeOfConsultation: content.typeOfConsultation || "",
              firstTimeConsulting: content.firstTimeConsulting || "",
              Age: content.Age || "",
              bloodtype: content.bloodtype || "",
              Alergies: content.Alergies || "",
              phoneNumber: content.phoneNumber || "",
              newDateday: content.newDateday || "",
              newDateid: content.newDateid || "",  

              userId: userID,
              doctorUID: doctorUID || "",
              serverTimestamp,
            };
          }
  
          try {
            // Update the document with the modified data
            await updateDoc(rdvRef, { selectedTimeSlots: updatedRdvData });
            onClose();

          } catch (error) {
            console.error("Error updating RDV document:", error);
            setIsLoading(false); 
            setCancelConfirmationVisible(false);
          }
        } else {
          console.error("Selected appointment is undefined");
          setIsLoading(false); 
          setCancelConfirmationVisible(false);
        }
      }
    } catch (error) {
      console.error("Error refusing appointment: ", error);
      setIsLoading(false); 
      setCancelConfirmationVisible(false);
    }
  };

  
  

  
  const handleReportAppointment = () => {
    setShowReportForm(true);
  };

    const handleConfirmReport  = async () => {
    const serverTimestamp = Timestamp.fromDate(new Date());
    const auth = getAuth();
    setIsLoading(true); // Set loading state to true
 // Check if the new date and time are provided
 if (!newDateday || !reportFormData.newDateTime) {
  toast.error("Please select a date and time before confirming the report.");
  setIsLoading(false);
  return;
}
    try {
      const user = auth.currentUser;
      const doctorUID = user.uid;
      const userID = content.userId;
  
      if (!content) {
        console.error("Error: content is undefined");
        return;
      }
  
      const userRdvRef = doc(db, "RDVofuser", userID);
  
      if (content) {
        const appointmentPath = `${content.day}.${content.uniqueId}`;
        const updatedUserDocData = {
          [appointmentPath]: {
            status: "reported",
            patientName: content.patientName || "",
            symptoms: content.symptoms || "",
            sex: content.sex || "",
            typeOfConsultation: content.typeOfConsultation || "",
            firstTimeConsulting: content.firstTimeConsulting || "",
            Age: content.Age || "",
            bloodtype: content.bloodtype || "",
            Alergies: content.Alergies || "",
            phoneNumber: content.phoneNumber || "",

               newDateday: newDateday || "", 
              newDateid: newDateId || "",
            userId: userID,
            doctorID: doctorUID || "",
            serverTimestamp,
          },
        };
  
        // Update the user's document with the new status
        await updateDoc(userRdvRef, updatedUserDocData);
  
        const rdvRef = doc(db, "RDV", doctorUID);
        const rdvDoc = await getDoc(rdvRef);
  
        if (rdvDoc.exists()) {
          const rdvData = rdvDoc.data();
  
          const currentDate = new Date();
          currentDate.setHours(0, 0, 0, 0);
  
          const filteredTimeSlots = rdvData.selectedTimeSlots.reduce((filteredSlots, dayData) => {
            const dayDate = new Date(dayData.day);
  
            if (dayDate >= currentDate) {
              const filteredDaySlots = dayData.slots.filter((timeSlot) => {
                return timeSlot.status === "reserved";
              });
  
              if (filteredDaySlots.length > 0) {
                filteredSlots.push({
                  day: dayData.day,
                  slots: filteredDaySlots,
                });
              }
            }
  
            return filteredSlots;
          }, []);
  
          const dayToUpdate = content.day || "";
          const slotIdToUpdate = content.uniqueId || "";
  
          // Create a copy of rdvData to modify
          const updatedRdvData = [...rdvData.selectedTimeSlots];
  
          // Find the dayData corresponding to selectedTimeSlot's day
          let dayDataToUpdate = updatedRdvData.find(dayData => dayData.day === dayToUpdate);
  
          // If the dayData doesn't exist, create a new one
          if (!dayDataToUpdate) {
            dayDataToUpdate = {
              day: dayToUpdate,
              slots: [],
            };
            updatedRdvData.push(dayDataToUpdate);
          }
  
          // Find the selected time slot within dayDataToUpdate
          // Find the index of the selected time slot within dayDataToUpdate
          const slotIndexToUpdate = dayDataToUpdate.slots.findIndex(slot => slot.id === slotIdToUpdate);
  
          // If the slot doesn't exist, create a new one; otherwise, update it
          if (slotIndexToUpdate === -1) {
            const slotToUpdate = {
              id: slotIdToUpdate,
              status: "reported",
              patientName: content.patientName || "",
              symptoms: content.symptoms || "",
              sex: content.sex || "",
              typeOfConsultation: content.typeOfConsultation || "",
              firstTimeConsulting: content.firstTimeConsulting || "",
              Age: content.Age || "",
              bloodtype: content.bloodtype || "",
              Alergies: content.Alergies || "",
              phoneNumber: content.phoneNumber || "",

              newDateday: newDateday || "",
              newDateid: newDateId || "",
              userId: userID,
              doctorID: doctorUID || "",
              serverTimestamp,
            };
            dayDataToUpdate.slots.push(slotToUpdate);
          } else {
            // Update the existing slot
            dayDataToUpdate.slots[slotIndexToUpdate] = {
              id: slotIdToUpdate,
            status: "reported",
              patientName: content.patientName || "",
              symptoms: content.symptoms || "",
              sex: content.sex || "",
              typeOfConsultation: content.typeOfConsultation || "",
              firstTimeConsulting: content.firstTimeConsulting || "",
              Age: content.Age || "",
              bloodtype: content.bloodtype || "",
              Alergies: content.Alergies || "",
              phoneNumber: content.phoneNumber || "",

              newDateday: newDateday || "", 
              newDateid: newDateId || "",
              userId: userID,
              doctorID: doctorUID || "",
              serverTimestamp,
            };
          }
  
          try {
            // Update the document with the modified data
            await updateDoc(rdvRef, { selectedTimeSlots: updatedRdvData });
            setIsLoading(false);
            onClose();
            setShowReportForm(false); // Close the report form
          } catch (error) {
            console.error("Error updating RDV document:", error);
            setIsLoading(false);
          }
        } else {
          console.error("Selected appointment is undefined");
        }
      }
    } catch (error) {
      console.error("Error refusing appointment: ", error);
      setIsLoading(false);
    }
  };
  


   // Helper function to get the background color based on status
   const getStatusColor = (status) => {
    switch (status) {
      case 'reserved':
        return 'bg-blue-500';
      case 'reported':
        return 'bg-orange-500';
      case 'canceled':
        return 'bg-red-500';
      default:
        return 'bg-gray-500';
    }
  };
  const headerBackgroundColor = getStatusColor(content.statusCategory);

 
  const getStatusicon = (status) => {
    switch (status) {
      case 'reserved':
        return  <HiOutlineClipboardCheck size={40} className="text-white mr-2" />;
      case 'reported':
        return  <TbReport size={40} className="text-white mr-2" />;
      case 'canceled':
        return  <TbCalendarCancel size={40} className="text-white mr-2" />;
      default:
        return  <TbCalendarCancel size={40} className="text-white mr-2" />;
    }
  };
 // Get the background color for the header based on status
 const headericon = getStatusicon(content.statusCategory);


  
 const getStatustranslation = (status) => {
  switch (status) {
    case 'reserved':
      return <>{translations.booked}</>;
    case 'reported':
      return <>{translations.reported}</>;
    case 'canceled':
      return <>{translations.canceled}</>;
    default:
      return <TbCalendarCancel size={40} className="text-white mr-2" />;
  }
};

const getsextranslation = (sex) => {
  switch (sex) {
    case 'Masculin':
      return <>
      <p className="text-gray-600 mb-0 mr-4 flex items-center">
      <  BsGenderMale
      size={20} className="ml-3 mr-1 text-gray-600 transition-all duration-300 ease-in-out transform hover:rotate-12 " />
      <span className="font-semibold"></span>  {translations.Masculin}
    </p>
     </>;

case 'Feminin':  
return <>
  <p className="text-gray-600 mb-0 mr-4 flex items-center">
                    < BsGenderFemale  size={20} className="ml-3 mr-1 text-gray-600 transition-all duration-300 ease-in-out transform hover:rotate-12 " />
                    <span className="font-semibold"></span>  {translations.Feminin}
                  </p>
</>;


      case 'autre':
      return <>
       <p className="text-gray-600 mb-0 mr-4 flex items-center">
                    <BsGenderAmbiguous  size={20} className="ml-3 mr-1 text-gray-600 transition-all duration-300 ease-in-out transform hover:rotate-12 " />
                    <span className="font-semibold"></span> {translations.autre}
                  </p>
  </>;
   
    default:
      return <></>;
  }
};



// Get the background color for the header based on status
const headerstatus = getStatustranslation(content.statusCategory);

  
  const isDateTimeInPast = (dateTime) => {
    const selectedDateTime = new Date(dateTime);
    const currentDateTime = new Date();
    return selectedDateTime < currentDateTime;
  };
  return (
    <main className="fixed inset-0 flex flex-col items-center justify-center min-h-screen py-10 sm:py-20 lg:py-40 overscroll-contain  z-10 overflow-auto">
    <div className="flex-shrink-0 w-full max-w-3xl mx-auto p-4 sm:p-8 dark:bg-gray-700 bg-gray-300 rounded shadow-md mt-4 sm:mt-8   ">
    <div className="flex-shrink-0 w-full max-w-3xl mx-auto p-4 sm:p-8  bg-gray-200 rounded shadow-md mt-4 sm:mt-8   ">
      {isLoading ? (
            /* Display a loading spinner while the action is in progress */
            <Spinner />
          ) : (
          /* Display either the report form or appointment details based on showReportForm */
          <div>
            {showReportForm ? (
              /* Display report form when showReportForm is true */
              <div>
           {/* Header */}
           <div className={`flex-shrink-0 text-gray-100 rounded-t-lg py-6 text-center bg-orange-500 sticky  flex items-center justify-center`}>
  <TbReport size={40} className="text-white mr-2 transition-all duration-300 ease-in-out transform hover:rotate-12 " />
  <div className="text-left">
    <p className="text-2xl text-center font-semibold mb-1">{translations.report}</p>
  </div>
</div>



 <div className="  flex flex-col items-center"> {/* Corrected class placement */}
 <label htmlFor="newTime" className="flex items-center text-sm font-semibold text-gray-700 mb-2">
<IoMdTime size={30} className="mr-2 text-xl  transition-all duration-300 ease-in-out transform hover:rotate-12 " />
{translations.time}
</label>
   <div className="border-2 border-gray-400 flex items-center ">
     <Select
       id="newHour"
       value={{ value: selectedHour, label: selectedHour }}
       onChange={handleHourChange}
       options={hourOptions}
       isSearchable={false}
       menuPlacement="auto"
       className="w-12" // Set a specific width for hours
     />
     <span className="mx-2">:</span>
     <Select
       id="newMinute"
       value={{ value: selectedMinute, label: selectedMinute }}
       onChange={handleMinuteChange}
       options={minuteOptions}
       isSearchable={false}
       menuPlacement="auto"
       className="w-12" // Set a specific width for minutes
     />
   </div>
 </div>
 <div className="text-2xl border border-orange-400 text-gray-700 mt-1 text-center font-semibold "> 

{translations.chooseatimefirst}
</div>
    <div className="flex flex-col md:flex-row items-center justify-center md:justify-between mt-2">
  
    <div className=" flex items-center justify-center  space-y-4 md:space-y-0 md:space-x-4">
 {/* Date Picker */}
 <div className="mb-2 space-y-2">
  <label htmlFor="datepicker" className="flex items-center text-sm font-semibold text-gray-700 mt-2">
  <LuCalendarClock size={30} className="mr-2 text-xl  transition-all duration-300 ease-in-out transform hover:rotate-12 " />
 {translations.SelectDate}

      </label>

      <DatePicker
        onChange={handleDateChange}
        value={selectedDate}
        minDate={new Date()}
        locale="fr"

        className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400 text-gray-700"
      />
    </div>
 

</div>
         {/* Confirm and Close Buttons */}
         <div className="flex flex-col md:flex-row items-center justify-center md:justify-between mt-6  ">
  <div className="flex items-center justify-center space-y-2 md:space-y-0 md:space-x-4">
  
   <div className="flex justify-between items-center">
  
   <button
 onClick={() => setReportConfirmationVisible(true)}
 className="w-full md:w-auto bg-blue-500 text-white rounded-lg px-4 py-2 font-semibold hover:bg-blue-700 cursor-pointer transition duration-300 mr-4"
    >
    {translations.Confirm}
    </button>

   <button
      onClick={onClose}
      className="text-white px-4 py-2 rounded-full bg-gray-500 hover:bg-gray-600 flex items-center space-x-2 mr-4 cursor-pointer transition duration-300"
    >
      <IoClose size={20} className="text-white" />
      <span>{translations.close}</span>
    </button>
   

  
  </div>
</div>
    </div> 
</div>

    </div>





            ) : (
              
              <div>
           {/* Header */}
      <div className={`text-gray-100 rounded-t-lg py-6 text-center ${headerBackgroundColor} sticky flex items-center justify-center`}>
      {headericon} 
  <div className="text-left">
    <p className="text-2xl text-center font-semibold mb-1">{headerstatus}</p>
  </div>


     

</div>


        <div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
  <p className="text-1xl  font-bold mb-2 text-gray-800"><span className="font-semibold"></span> {content.day} </p>
  
  <p className="text-2xl font-bold mb-1 ml-4 text-gray-800"><span className="font-semibold"></span> {content.uniqueId}</p>
</div>    
  {content.statusCategory === 'reported' && (
      <div>
  <CgArrowLongDownC size={30} className=" text-gray-700 text-center font-semibold transition-all duration-300 ease-in-out transform hover:rotate-12" />

      <div className="flex flex-col  bg-orange-300  items-center mt-1">
      <div className="text-center text-gray-700 bg-orange-300  space-x-2 " >
        <strong className="text-gray-600 text-md "> {content.newDateday} </strong> 
        <br />
        <strong className="text-gray-600 text-md "> {content.newDateid}</strong>
        <div>  

</div>
      </div>
      </div>
      </div>

    )}
       
              <h2 className="text-2xl text-center align-middle font-extrabold text-gray-800">{content.patientName}</h2>
              </div>

  <div className="border-t border-gray-300"></div>

  <div >

  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
  
  <p className="text-gray-600 mb-0 mr-2 flex items-center ">
  <GiSandsOfTime size={20} className="ml-1 text-gray-600 transition-all duration-300 ease-in-out transform hover:rotate-12  " /><span className="font-semibold ">Age:</span> </p>
  <p className="text-gray-600 mb-0 items-center  ">
{content.Age}</p>

  {getsextranslation(content.sex)}

    
  <p className="text-gray-600 mb-0 mr-4 flex items-center">
  <MdOutlineBloodtype size={20} className="ml-2 text-red-600 transition-all duration-300 ease-in-out transform hover:rotate-12 " />
    <span className="font-semibold"></span> {content.bloodtype}
  </p>

</div>

  <div className="border-t border-gray-300"></div>

  <p className="text-gray-600 py-1 content-center overflow-wrap break-words flex items-center">
  <MdPhone  size={20} className="ml-2 text-gray-600 transition-all duration-300 ease-in-out transform hover:rotate-12 " /><span className="font-semibold"></span>{content.phoneNumber}</p>
 
  <div className="border-t border-gray-300"></div>

  <div className="mb-4">
  <p className="text-gray-600 overflow-wrap pt-1 mr-4 break-words flex items-center">
  <LiaAllergiesSolid size={20} className="ml-4 text-gray-600 transition-all duration-300 ease-in-out transform hover:rotate-12 " />

    <span className="font-semibold">{translations.Allergies}</span>   
  </p>
  <div className="text-gray-600 max-h-16 overflow-wrap overscroll-none overflow-y-scroll break-words items-center">
  {content.Alergies}   
    </div>

    </div>

  <div className="border-t border-gray-300"></div>

  <div className="mb-6 text-base mg:text-lg">
   
  <p className="text-gray-600 overflow-wrap overscroll-none pt-1 mr-2 break-words flex items-center">
  <MdOutlineSick  size={20} className="ml-4 text-gray-600 transition-all duration-300 ease-in-out transform hover:rotate-12 " />
  <span className="font-semibold">{translations.Symptômes}:</span></p>

  <div className="text-gray-600 max-h-16 overflow-wrap overscroll-none overflow-y-scroll break-words items-center">
  {content.symptoms}
  </div>


  </div>

<div className="border-t border-gray-300"></div>


  </div>

  <div className="border-t border-gray-300"></div>
  <div className="border-t border-gray-300"></div>

  <div className="flex flex-col md:flex-row items-center justify-center md:justify-between mt-6">
  <div className="flex items-center justify-center space-y-2 md:space-y-0 md:space-x-4">

    <button
      onClick={onClose}
      className={`text-white px-4 py-2 rounded-full ${headerBackgroundColor} transition duration-300 ease-in-out hover:bg-gray-600 flex items-center space-x-2`}
    >
      <IoClose size={20} className="mr-2 text-white transition-all duration-300 ease-in-out transform hover:rotate-12 " />
      <span className="overflow-wrap">{translations.close}</span>
    </button>

    {isAppointmentInFuture(content.day) && (content.statusCategory === 'reserved' || content.statusCategory === 'reported') && !isLoading && (
      <>
        <button
 onClick={() => setCancelConfirmationVisible(true)}
 className="w-full md:w-auto bg-red-500 text-white rounded-lg px-4 py-2 font-semibold hover:bg-red-600 cursor-pointer transition duration-300 ease-in-out flex items-center space-x-2"
        >
          <TbCalendarCancel size={20} className="text-white transition-all duration-300 ease-in-out transform hover:rotate-12  " />
          <span className="overflow-wrap">{translations.cancel} </span>
        </button>
        <button
          onClick={() => handleReportAppointment(content.dayIndex, content.uniqueId)}
          className="w-full md:w-auto bg-orange-500 text-white rounded-lg px-4 py-2 font-semibold hover:bg-orange-600 cursor-pointer transition duration-300 ease-in-out flex items-center space-x-2"
        >
          <TbReport size={20} className="text-white transition-all duration-300 ease-in-out transform hover:rotate-12 " />
          <span className="overflow-wrap">{translations.report} </span>
        </button>
      </>
    )}
  </div>
</div>

</div>
            )}
          </div>

        )}
      </div>


      {isCancelConfirmationVisible && (
  <div
    className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50" >
    <div className="bg-white p-6 rounded-lg w-80 text-center shadow-lg" >
            <p className="text-gray-800 text-lg mb-4">
              {translations.Annullezplagehoraire}
            </p>
            <p className="text-gray-600 text-sm mb-6">{content.day}</p>
            <p className="text-gray-600 text-sm mb-6">{content.uniqueId}</p>

      <div className="flex justify-end">

        <button  className="text-red-500 hover:text-red-700 px-4 py-2 rounded-md border border-red-500 hover:bg-red-100 focus:outline-none" 
         onClick={() => handleRefuseAppointment(content.dayIndex, content.uniqueId)}
>
{translations.oui}
        </button>
        <button
                className="text-gray-500 hover:text-gray-700 px-4 py-2 ml-4 rounded-md border border-gray-500 hover:bg-gray-100 focus:outline-none"
                onClick={() => setCancelConfirmationVisible(false)}
        >
              {translations.non}

        </button>
      </div>
    </div>
  </div>
)}

{isReportConfirmationVisible && (
  <div
    className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50" >
    <div className="bg-white p-6 rounded-lg w-80 text-center shadow-lg" >
            <p className="text-gray-800 text-lg mb-4">
          
            {translations.Reporteplagehoraire}

            </p>
            <p className="text-gray-600 text-sm mb-6">{ newDateday }</p>
            <p className="text-gray-600 text-sm mb-6">{ newDateId }</p>

      <div className="flex justify-end">

        <button  className="text-red-500 hover:text-red-700 px-4 py-2 rounded-md border border-red-500 hover:bg-red-100 focus:outline-none" 
         onClick={() => handleConfirmReport(content.dayIndex, content.uniqueId)}
>
{translations.oui}
        </button>
        <button
                className="text-gray-500 hover:text-gray-700 px-4 py-2 ml-4 rounded-md border border-gray-500 hover:bg-gray-100 focus:outline-none"
                onClick={() => setReportConfirmationVisible(false)}
        >
            {translations.non}
        </button>
      </div>
    </div>
  </div>
)}


      </div>
      </main>

);
  
}


















const TimeBlocksGrid = () => {
  const [isLoading, setIsLoading] = useState(false);

  const auth = getAuth();
  const handleSubmit = async () => {
    try {
      setIsLoading(true); // Show loading screen
  
      // Retrieve the selected time slots' uniqueIds from the `selectedTimeSlotsByDay` object
      const selectedSlotsForSubmission = selectedTimeSlotsByDay;
  
      // Get the user's UID from Firebase Authentication
      const userRef = auth.currentUser.uid;
  
      // Construct the data to be submitted to Firebase with sorted days and time slots
      const rdvData = {
        
        selectedTimeSlots: Object.keys(selectedSlotsForSubmission)
          .filter((dayKey) => Array.isArray(selectedSlotsForSubmission[dayKey]) && selectedSlotsForSubmission[dayKey].length > 0)
          .map((dayKey) => {
            let slots = selectedSlotsForSubmission[dayKey];
  
            if (!Array.isArray(slots)) {
              slots = [];
            }
  
            slots.sort((a, b) => {
              const timeA = a.split(' ')[2];
              const timeB = b.split(' ')[2];
              return timeA.localeCompare(timeB);
            });
  
            return {
              day: dayKey,
              slots: slots.map((uniqueId) => {
                const { status, patientName,
                  symptoms,
                  sex,
                  typeOfConsultation,
                  firstTimeConsulting,
                  Age,
                  bloodtype,
                  Alergies,
                  userId, 
                  phoneNumber,
                  newDateday,
                  newDateid,       
                } = getStatusAndPatientName(dayKey, uniqueId);
                return {
                  id: uniqueId,
                  status: status ?? 'selected',
                  patientName: patientName ?? 'nom du patient',
                  symptoms: symptoms ?? "",
                  sex: sex ?? "",
                  typeOfConsultation: typeOfConsultation ?? "",
                  firstTimeConsulting: firstTimeConsulting ?? "",
                  Age: Age ?? "",
                  bloodtype: bloodtype ?? "",
                  Alergies: Alergies ?? "",
                  userId: userId ?? "",
                  phoneNumber: phoneNumber ?? "",
                  newDateday: newDateday ?? "",
                  newDateid: newDateid ?? "",
                };
              }),
            };
          })
          .sort((a, b) => {
            return a.day.localeCompare(b.day);
          }),
      };
  
      // Convert the data into an array of time slots
      const timeSlotsArray = rdvData.selectedTimeSlots.map((dayData) => dayData.slots).flat();
  
      const userDocRef = doc(db, "RDV", userRef); // Use the user's UID as the document ID
  
      // Check if the user's document already exists in Firebase
      const userDocSnap = await getDoc(userDocRef);
  
      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        const { numberOfSubDocuments = 0 } = userData.metadata || {};
        const currentDate = moment().format('YYYY-MM-DD');
  
        // Check the size of the document
        const jsonSize = JSON.stringify(userData).length;
        const MAX_SIZE = 879 * 1024; // 900 KB in bytes
  
        // Identify past time slots
        const pastTimeSlots = userData.selectedTimeSlots.filter(dayData => {
          const dayDate = moment(dayData.day);
          return dayDate.isBefore(currentDate, 'day');
        });
  
        // Check if the document needs to be split and if there are past time slots
        if (jsonSize >= MAX_SIZE && pastTimeSlots.length > 0) {
          // Create a new sub-document
          const newSubDocName = `${userRef}_${numberOfSubDocuments + 1}`;
          const newSubDocRef = doc(userDocRef, 'sub_documents', newSubDocName);
  
          const newDataForMainDoc = {
            ...rdvData,
            selectedTimeSlots: userData.selectedTimeSlots.filter(dayData => {
              const dayDate = moment(dayData.day);
              return dayDate.isSameOrAfter(currentDate, 'day');
            }),
          };
  
          await updateDoc(userDocRef, {
            ...newDataForMainDoc,
            metadata: { numberOfSubDocuments: numberOfSubDocuments + 1 },
          });
  
          await setDoc(newSubDocRef, {
            selectedTimeSlots: pastTimeSlots,
          });
  
        } else {
          // Update the main document
          await updateDoc(userDocRef, rdvData);
        }
      } else {
        // If the document doesn't exist, create it with the new data
        await setDoc(userDocRef, {
          ...rdvData,
          metadata: { numberOfSubDocuments: 0 },
        });
      }
  
      // Submission completed, hide loading screen and confirmation dialog
      setIsLoading(false);
      setUploadConfirmationVisible(false);
  
    } catch (error) {
      console.error("Error updating/creating document: ", error);
      setIsLoading(false);
    }
  };
  
  

  function getStatusAndPatientName(dayKey, uniqueId) {
    if (statusCategories[dayKey] && statusCategories[dayKey][uniqueId]) {
      const { status, patientName, symptoms,
        sex,
        typeOfConsultation,
        firstTimeConsulting,
        Age,
        bloodtype,
        Alergies,
        userId,
        phoneNumber,
        newDateday,
        newDateid,      
      } = statusCategories[dayKey][uniqueId];


      return { status, patientName, symptoms,
        sex,
        typeOfConsultation,
        firstTimeConsulting,
        Age,
        bloodtype,
        Alergies,
        userId,
        phoneNumber,
        newDateday,
        newDateid,
       };
    } else {
      // If the combination is not found in statusCategories, return the default values
      return {
        status: 'selected',
        patientName: 'nom du patient',
        symptoms: "",
        sex: "",
        typeOfConsultation: "",
        firstTimeConsulting: "",
        Age:"", 
        bloodtype: "",

        Alergies: "",
        userId: "",
        phoneNumber: "",
        newDateday: "",
        newDateid: "",
      };
    }
  }
  
  











  const [view, setView] = useState('week');
  
  const [currentDate, setCurrentDate] = useState(new Date());
  const [isStartTimeSelected, setIsStartTimeSelected] = useState(false);

  const [modalData, setModalData] = useState({
    startTime: 9, // Initialize with 0, or a default start time index
    endTime: 17,   // Initialize with 1, or a default end time index
  });
  const [selectedTimeSlotsByDay, setSelectedTimeSlotsByDay] = useState({});
  const [selectedAddedTimeSlots, setSelectedAddedTimeSlots] = useState({});

  const [maxPatients, setMaxPatients] = useState(10);


  const [organizedByStatus, setOrganizedByStatus] = useState({});

  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [popupContent, setPopupContent] = useState(null);

  const [open, setOpen] = useState(false);

  const [isUploadConfirmationVisible, setUploadConfirmationVisible] = useState(false);




  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (auth.currentUser) {
          const userRef = doc(db, "RDV", auth.currentUser.uid);
  
          // Subscribe to real-time updates with onSnapshot
          const unsubscribe = onSnapshot(userRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
              const userData = docSnapshot.data();
  
              // Call organizeTimeSlotsData to organize the data
              const organizedData = organizeTimeSlotsData(userData.selectedTimeSlots);
              setSelectedTimeSlotsByDay(organizedData);
  
              // Call the function to organize the data by status
              const organizedByStatusData = organizeDataByStatus(userData.selectedTimeSlots);
              setOrganizedByStatus(organizedByStatusData);

              setStatusCategories(organizedByStatusData); // Update statusCategories here

  
            } else {
              console.log("User document does not exist.");
            }
          });
  
          // Cleanup the subscription when the component unmounts
          return () => unsubscribe();
        }
      } catch (error) {
        console.error("Error fetching user data: ", error);
      }
    };
  
    fetchUserData(); // Call the function inside the useEffect callback
  }, [auth.currentUser]);

function organizeTimeSlotsData(selectedTimeSlots) {
  const organizedData = {};

  selectedTimeSlots.forEach((dayData) => {
    const { day, slots } = dayData;

    // Check if the slots property is defined and is an array
    if (Array.isArray(slots)) {
      // Extract the uniqueIds from the slots and store them in an array
      const uniqueIds = slots.map((slot) => slot.id);

      // Store the array of uniqueIds under the day as the key
      organizedData[day] = uniqueIds;
    } else {
      // Handle the case where slots is not an array or is undefined
      console.warn(`Slots for day ${day} are not in the expected format.`);
    }
  });

  return organizedData;
}

// Define a function to organize the data by status
function organizeDataByStatus(selectedTimeSlots) {
  const organizedByStatus = {};

  selectedTimeSlots.forEach((dayData) => {
    const { day, slots } = dayData;

    if (Array.isArray(slots)) {
      if (!organizedByStatus[day]) {
        organizedByStatus[day] = {};
      }

      slots.forEach((slot) => {
        const { id, status, patientName, symptoms,
          sex,
          typeOfConsultation,
          firstTimeConsulting,
          Age,
          bloodtype,
          Alergies, userId, 
          phoneNumber,
         
 newDateday,
          newDateid, } = slot;

        if (!organizedByStatus[day][id]) {
          organizedByStatus[day][id] = { status, patientName, symptoms,
            sex,
            typeOfConsultation,
            firstTimeConsulting,
            Age,
            bloodtype,
            Alergies, userId, 
            phoneNumber,
            newDateday,
            newDateid, };
        } else {
          console.warn(`Duplicate slot id: ${id}`);
        }
      });
    } else {
      console.warn(`Slots for day ${day} are not in the expected format.`);
    }
  });

  return organizedByStatus;
}

const [statusCategories, setStatusCategories] = useState({});




const getStatusCategory = (dayKey, uniqueId) => {
  return statusCategories[dayKey]?.[uniqueId] || { status: 'default', patientName: '', userId: '',  };
};

const updateTimeSlotStatus = (uniqueId, newStatus) => {
  setStatusCategories((prevStatusCategories) => ({
    ...prevStatusCategories,
    [uniqueId]: newStatus,
  }));
};
  









  //BLOCK selection
 const toggleBlockSelection = (dayIndex, uniqueId, statusCategory) => {
  if (statusCategory.status !== 'default') {
    // Open popup here based on the statusCategory and uniqueId
    openPopup(dayIndex, uniqueId, statusCategory);
  } else {
      // Extract the time portion from the uniqueId (assuming "dayName dayOfMonth time" format)
      const timeFromUniqueId = uniqueId.split(' ')[2];
  

    // Get the dayKey for the selected day
    const dayKey = moment(renderDays[dayIndex].dayDate).format('YYYY-MM-DD');



      // Get the current time
      const currentDateTime = moment();
  
      // Construct a moment object for the time slot's time
      const blockTime = moment(timeFromUniqueId, 'HH:mm');
  
      // Set the date to match the date of the selected day
      const blockDateTime = moment(renderDays[dayIndex].dayDate).set({
        hour: blockTime.hour(),
        minute: blockTime.minute(),
      });
  
      // Check if the blockDateTime is before the currentDateTime
      if (blockDateTime.isBefore(currentDateTime, 'minute')) {
        // Disable selecting time slots in the past
        return;
      }
  
      setSelectedTimeSlotsByDay((prevSelectedTimeSlotsByDay) => {
        const dayKey = moment(renderDays[dayIndex].dayDate).format('YYYY-MM-DD');
        const selectedTimeSlots = prevSelectedTimeSlotsByDay[dayKey] || [];
  
        if (selectedTimeSlots.includes(uniqueId)) {
          // Remove the block from the selected time slots for the day
          const updatedTimeSlots = selectedTimeSlots.filter((id) => id !== uniqueId);
  
          if (updatedTimeSlots.length === 0) {
            // If no time slots are left, remove the day from the selection
            const updatedSelectedTimeSlotsByDay = { ...prevSelectedTimeSlotsByDay };
            delete updatedSelectedTimeSlotsByDay[dayKey];
            return updatedSelectedTimeSlotsByDay;
          }
  
          return {
            ...prevSelectedTimeSlotsByDay,
            [dayKey]: updatedTimeSlots,
          };
        } else {
          // Add the block to the selected time slots for the day
          return {
            ...prevSelectedTimeSlotsByDay,
            [dayKey]: [...selectedTimeSlots, uniqueId],
          };
        }
      });
  
      // Also, update the selected time slots state
      setSelectedTimeSlots((prevSelectedTimeSlots) => {
        if (prevSelectedTimeSlots[uniqueId]) {
          const updatedSelectedTimeSlots = { ...prevSelectedTimeSlots };
          delete updatedSelectedTimeSlots[uniqueId];
          return updatedSelectedTimeSlots;
        } else {
          return { ...prevSelectedTimeSlots, [uniqueId]: true };
        }
      });
    }
  };
  





  const openPopup = (dayIndex, uniqueId, statusCategory) => {
    if (!isPopupVisible) {
    // Implement your logic to open the popup based on the statusCategory, uniqueId, and dayIndex
    switch (statusCategory.status) {
      case 'reserved':
        // Handle the reserved status
        setPopupContent({
          statusCategory: statusCategory.status,
          day: moment(renderDays[dayIndex].dayDate).format('YYYY-MM-DD'),
          uniqueId: uniqueId,
          patientName: statusCategory.patientName,
          userId: statusCategory.userId,
          symptoms: statusCategory.symptoms,
          sex: statusCategory.sex,
          typeOfConsultation: statusCategory.typeOfConsultation,
          firstTimeConsulting: statusCategory.firstTimeConsulting,
          Age: statusCategory.Age,
          bloodtype: statusCategory.bloodtype,
          Alergies: statusCategory.Alergies,
          phoneNumber: statusCategory.phoneNumber,
          newDateday: statusCategory.newDateday ,
          newDateid:  statusCategory.newDateid,
          // ...other necessary data for the popup
        });
        setIsPopupVisible(true);
        break;
  
      case 'reported':
        // Handle the reported status
        setPopupContent({  
          statusCategory: statusCategory.status,
          day: moment(renderDays[dayIndex].dayDate).format('YYYY-MM-DD'),
          uniqueId: uniqueId,
          patientName: statusCategory.patientName,
          userId: statusCategory.userId,
          symptoms: statusCategory.symptoms,
          sex: statusCategory.sex,
          typeOfConsultation: statusCategory.typeOfConsultation,
          firstTimeConsulting: statusCategory.firstTimeConsulting,
          Age: statusCategory.Age,
          bloodtype: statusCategory.bloodtype,
          Alergies: statusCategory.Alergies,
          phoneNumber: statusCategory.phoneNumber,
          newDateday: statusCategory.newDateday ,
          newDateid:  statusCategory.newDateid,

          // ...other necessary data for the popup
        });
        setIsPopupVisible(true);
        break;
  
      case 'canceled':
        // Handle the canceled status
        setPopupContent({
          statusCategory: statusCategory.status,
          day: moment(renderDays[dayIndex].dayDate).format('YYYY-MM-DD'),
          uniqueId: uniqueId,
          patientName: statusCategory.patientName,
          userId: statusCategory.userId,
          symptoms: statusCategory.symptoms,
          sex: statusCategory.sex,
          typeOfConsultation: statusCategory.typeOfConsultation,
          firstTimeConsulting: statusCategory.firstTimeConsulting,
          Age: statusCategory.Age,
          bloodtype: statusCategory.bloodtype,
          Alergies: statusCategory.Alergies,
                    phoneNumber: statusCategory.phoneNumber,
                    newDateday: statusCategory.newDateday ,
                    newDateid:  statusCategory.newDateid,

          // ...other necessary data for the popup
        });
        setIsPopupVisible(true);
        break;
  
      // Add more cases for other status categories as needed
      // ...
  
      default:
        // Handle the default case if needed
        break;
    }
  }
  };
  
  








  const { language, translations } = useLanguage();

  const days = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];
  const hours = Array.from({ length: 24 }).map((_, hour) => hour);

  const momentDate = moment(currentDate);

  const renderDays = view === 'week'
  ? days.map(day => {
    const dayIndex = days.indexOf(day); // Start from Monday
    const dayMoment = momentDate.clone().day(dayIndex); 
      return {
        dayName: moment().day(dayIndex).format('dddd'),
        dayOfMonth: dayMoment.date(), 
        dayDate: dayMoment.toDate(), 
        isCurrentBlock: momentDate.isSame(moment(), 'day') && dayIndex === momentDate.day()
      };
    })
  : [{
      dayName: momentDate.format('dddd'),
      dayOfMonth: momentDate.date(),
      dayDate: momentDate.toDate(), 
      isCurrentBlock: momentDate.isSame(moment(), 'day')
    }];
  const handlePrev = () => {
    setCurrentDate(prevDate => {
      if (view === 'week') {
        return moment(prevDate).subtract(7, 'days').toDate();
      } else {
        return moment(prevDate).subtract(1, 'day').toDate();
      }
    });
  };

  const handleNext = () => {
    setCurrentDate(prevDate => {
      if (view === 'week') {
        return moment(prevDate).add(7, 'days').toDate();
      } else {
        return moment(prevDate).add(1, 'day').toDate();
      }
    });
  };

  const goToCurrentDate = () => {
    setCurrentDate(new Date());
  };

  
  const timeOptions = hours.map(hour => ({ value: hour, label: `${hour}:00` }));
  const startTime = modalData.startTime;
// Update the endTimeOptions generation logic
const endTimeOptions = timeOptions.slice(startTime).concat({ value: 24, label: '24:00' });

 


const [averageDurationForNewEvents, setAverageDurationForNewEvents] = useState(15);
  const [inputValue, setInputValue] = useState(''); // Manage the input value separately
  // Handle changes in average duration
  const handleAverageDurationChange = (value) => {
    setInputValue(value);

    const parsedValue = parseInt(value, 10);

    if (value === '' || isNaN(parsedValue) || parsedValue <= 0) {
      setAverageDurationForNewEvents(10);
    } else {
      setAverageDurationForNewEvents(parsedValue);
    }
  };


  // Load saved settings from local storage on component mount
  useEffect(() => {
    const savedSettings = JSON.parse(localStorage.getItem('appointmentSettings')) || {};
    setModalData({
      startTime: savedSettings.startTime || 9,
      endTime: savedSettings.endTime || 17,
    });

    const savedAverageDuration = savedSettings.averageDuration || 15;
    setAverageDurationForNewEvents(savedAverageDuration);
    setInputValue(savedAverageDuration.toString()); // Initialize inputValue with the saved average duration
  }, []);

  

  // Save settings to local storage
  const saveSettingsToLocal = () => {
    const settingsToSave = {
      startTime: modalData.startTime,
      endTime: modalData.endTime,
      averageDuration: averageDurationForNewEvents,
    };
    localStorage.setItem('appointmentSettings', JSON.stringify(settingsToSave));
    toast.success('Time settings saved successfully!');
  };
  
  // Handle maximum patients input change
  const handleMaxPatientsChange = (value) => {
    const parsedValue = parseInt(value, 10);

    if (!isNaN(parsedValue) && parsedValue >= 0) {
      setMaxPatients(parsedValue);
    }
  };


const totalWorkingMinutes = (modalData.endTime - modalData.startTime) * 60;
const numberOfAppointments = Math.floor(totalWorkingMinutes / averageDurationForNewEvents);
const [selectedTimeSlots, setSelectedTimeSlots] = useState({});
// Add a state variable to store the default time slots
const [defaultTimeSlots, setDefaultTimeSlots] = useState([]);


// Update the default time slots whenever the start time or end time changes
useEffect(() => {
  const defaultSlots = [];
  const startTime = moment(modalData.startTime, 'HH:mm');
  const endTime = moment(modalData.endTime, 'HH:mm');
  const averageDuration = moment.duration(averageDurationForNewEvents, 'minutes');

  while (startTime.isBefore(endTime)) {
    defaultSlots.push(startTime.format('HH:mm'));
    startTime.add(averageDuration);
  }

  setDefaultTimeSlots(defaultSlots);
}, [modalData.startTime, modalData.endTime, averageDurationForNewEvents]);



const addTimeSlot = (dayIndex, dayDate) => {
const dayKey = moment(dayDate).format('YYYY-MM-DD');
const currentSelectedTimeSlots = { ...selectedTimeSlots };

  if (!currentSelectedTimeSlots[dayKey]) {
    currentSelectedTimeSlots[dayKey] = [];
  }

  // Use the last time slot from the default time slots as the starting point
const lastDefaultTimeSlot = defaultTimeSlots[defaultTimeSlots.length - 1];

  // Check if there are existing time slots for the day
const existingTimeSlots = currentSelectedTimeSlots[dayKey];

  if (existingTimeSlots.length > 0) {
    const lastTimeSlot = moment(existingTimeSlots[existingTimeSlots.length - 1], 'HH:mm');
    const nextTimeSlot = lastTimeSlot.clone().add(averageDurationForNewEvents, 'minutes');

    // Check if the next time slot exceeds 24:00
    if (nextTimeSlot.isBefore(moment('24:00', 'HH:mm'))) {
      currentSelectedTimeSlots[dayKey].push(nextTimeSlot.format('HH:mm'));
    } else {
      // If it exceeds 24:00, start from the beginning of the next day
      const nextDay = moment(dayDate).add(1, 'day');
      currentSelectedTimeSlots[nextDay.format('YYYY-MM-DD')] = [lastDefaultTimeSlot];
    }
  } else {
    // If there are no existing time slots, start from the last default time slot
    currentSelectedTimeSlots[dayKey].push(lastDefaultTimeSlot);
  }

  setSelectedTimeSlots(currentSelectedTimeSlots);
};
const removeTimeSlot = (dayIndex, timeSlot, dayName, dayOfMonth) => {
  const dayKey = moment(renderDays[dayIndex].dayDate).format('YYYY-MM-DD');
  const currentSelectedTimeSlots = { ...selectedTimeSlots };

  if (currentSelectedTimeSlots[dayKey]) {
    currentSelectedTimeSlots[dayKey] = currentSelectedTimeSlots[dayKey].filter((slot) => slot !== timeSlot);

    setSelectedTimeSlots(currentSelectedTimeSlots);

    // Remove the unique ID from selectedAddedTimeSlots
    const updatedSelectedAddedTimeSlots = { ...selectedAddedTimeSlots };
    delete updatedSelectedAddedTimeSlots[`${dayName} ${dayOfMonth} ${timeSlot}`];
    setSelectedAddedTimeSlots(updatedSelectedAddedTimeSlots);
  }
};

const [editingDay, setEditingDay] = useState(null); // Track the day being edited
const [daySettings, setDaySettings] = useState({}); // Store day-specific settings

const [isDaySettingsPopupOpen, setIsDaySettingsPopupOpen] = useState(false);
const [editingDayIndex, setEditingDayIndex] = useState(null);
const [popupInitialValues, setPopupInitialValues] = useState({});

  // Function to open the popup for day settings editing
  const openDaySettingsPopup = (dayIndex) => {
    // Use a unique identifier for each day, for example, the dayDate
    const dayKey = moment(renderDays[dayIndex].dayDate).format('YYYY-MM-DD');
  
    setEditingDay(dayKey);
    setIsDaySettingsPopupOpen(true);
    setEditingDayIndex(dayIndex);
  
    // Get the day-specific settings if they exist, otherwise, use default values
    const initialSettings = daySettings[dayKey] || {
      startTime: modalData.startTime,
      endTime: modalData.endTime,
      averageDuration: averageDurationForNewEvents,
      maxPatients: maxPatients,
    };
  
    setPopupInitialValues(initialSettings);
  };
  
   // Function to save day settings
   const saveDaySettings = (editedSettings) => {
    setDaySettings((prevSettings) => ({
      ...prevSettings,
      [editingDay]: editedSettings,
    }));

    // Update the selected time slots for the edited day
    const updatedSelectedTimeSlots = { ...selectedTimeSlots };
    const editedDayKey = moment(renderDays[editingDayIndex].dayDate).format('YYYY-MM-DD');

    // Calculate and update the selected time slots based on the new settings
    const startTime = moment(editedSettings.startTime, 'HH:mm');
    const endTime = moment(editedSettings.endTime, 'HH:mm');
    const averageDuration = moment.duration(editedSettings.averageDuration, 'minutes');
    const newSelectedTimeSlots = [];

    while (startTime.isBefore(endTime)) {
      newSelectedTimeSlots.push(startTime.format('HH:mm'));
      startTime.add(averageDuration);
    }

    updatedSelectedTimeSlots[editedDayKey] = newSelectedTimeSlots;

    setSelectedTimeSlots(updatedSelectedTimeSlots);
    setEditingDay(null); // Close the popup

    // Switch to the "day view" for the edited day
    setView('day');
  };


  const cancelDaySettings = () => {
    setEditingDay(null); // Close the popup without saving changes
  }; 




  

  




// Assume you have a Popups component
const Popups = ({ contents, onClose }) => (
  <main className="fixed inset-0 flex flex-col items-center justify-center min-h-screen bg-gray-800 bg-opacity-75">
    <div className="flex-shrink-0 w-full max-w-3xl mx-auto p-4 sm:p-8 bg-white rounded shadow-md mt-4 sm:mt-8 ">
      <div className="flex-shrink-0 w-full max-w-3xl mx-auto p-4 sm:p-8 bg-white rounded shadow-md mt-4 sm:mt-8 ">
        {contents}
      </div>
    </div>
  </main>
);

const TimeSlotsPage = ({ organizedByStatus }) => {
  const allPatients = Object.entries(organizedByStatus).reduce((patients, [dayKey, day]) => {
    Object.entries(day).forEach(([slotId, slot]) => {
      const userId = slot.userId;
      if (userId) {
        if (!patients[userId]) {
          patients[userId] = {
            userId,
            patientName: slot.patientName,
            appointments: [],
          };
        }
        patients[userId].appointments.push({ ...slot, id: slotId, day: dayKey });
      }
    });
    return patients;
  }, {});

  const [searchTerm, setSearchTerm] = useState('');
  const [appointmentsToShow, setAppointmentsToShow] = useState(2);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const { language, translations } = useLanguage();

  const handleLoadMore = () => {
    setAppointmentsToShow(appointmentsToShow + 2);
  };

  const handleAppointmentClick = (appointment) => {
    setSelectedAppointment(appointment);
  };

  const handleClosePopup = () => {
    setSelectedAppointment(null);
  };

  const filteredPatientsArray = Object.values(allPatients).filter((patient) =>
    patient.patientName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const calculateStatusCounts = () => {
    // Initialize counts
    let reservedCount = 0;
    let canceledCount = 0;
    let reportedCount = 0;

    // Iterate through all patients
    Object.values(allPatients).forEach((patient) => {
      // Iterate through appointments of each patient
      patient.appointments.forEach((appointment) => {
        // Check the status and increment the corresponding count
        switch (appointment.status) {
          case 'reserved':
            reservedCount++;
            break;
          case 'canceled':
            canceledCount++;
            break;
          case 'reported':
            reportedCount++;
            break;
          // Add more cases for additional status types if needed
          default:
            break;
        }
      });
    });

    return {
      reserved: reservedCount,
      canceled: canceledCount,
      reported: reportedCount,
    };
  };

  const statusCounts = calculateStatusCounts();

  const getStatustranslation = (status) => {
    switch (status) {
      case 'reserved':
        return <>{translations.booked}</>;
      case 'reported':
        return <>{translations.reported}</>;
      case 'canceled':
        return <>{translations.canceled}</>;
      default:
        return <TbCalendarCancel size={40} className="text-white mr-2" />;
    }
  };

  const getsextranslation = (sex) => {
    switch (sex) {
      case 'Masculin':
        return <>
        <p className="text-gray-600 mb-0 mr-4 flex items-center">
        <  BsGenderMale
        size={20} className="ml-3 mr-1 text-gray-600 transition-all duration-300 ease-in-out transform hover:rotate-12 " />
        <span className="font-semibold"></span>  {translations.Masculin}
      </p>
       </>;

case 'Feminin':  
  return <>
    <p className="text-gray-600 mb-0 mr-4 flex items-center">
                      < BsGenderFemale  size={20} className="ml-3 mr-1 text-gray-600 transition-all duration-300 ease-in-out transform hover:rotate-12 " />
                      <span className="font-semibold"></span>  {translations.Feminin}
                    </p>
 </>;


        case 'autre':
        return <>
         <p className="text-gray-600 mb-0 mr-4 flex items-center">
                      <BsGenderAmbiguous  size={20} className="ml-3 mr-1 text-gray-600 transition-all duration-300 ease-in-out transform hover:rotate-12 " />
                      <span className="font-semibold"></span> {translations.autre}
                    </p>
    </>;
     
      default:
        return <></>;
    }
  };

  return (
    <div>
      <div className="flex justify-center items-end space-x-4 y-1 text-center mb-4">
        <div className="flex flex-col items-center">
          <div className="w-3 h-3 bg-blue-300 rounded-full mb-1"></div>
          <p className="text-xs dark:text-gray-100 text-gray-600">    {translations.booked}: {statusCounts.reserved}</p>
        </div>
        <div className="flex flex-col items-center">
          <div className="w-3 h-3 bg-orange-300 rounded-full mb-1"></div>
          <p className="text-xs dark:text-gray-100 text-gray-600">{translations.reported}: {statusCounts.reported}</p>
        </div>
        <div className="flex flex-col items-center">
          <div className="w-3 h-3 bg-red-300 rounded-full mb-1"></div>
          <p className="text-xs dark:text-gray-100 text-gray-600">{translations.canceled}: {statusCounts.canceled}</p>
        </div>
      </div>

      <div className="container mx-auto p-8">
        <form className="flex flex-col md:flex-row gap-3">
          <div className="flex">
            <input
              type="text"
              placeholder="Search patient"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full md:w-80 px-3 h-10 rounded-l border-2 border-sky-500 focus:outline-none focus:border-sky-500"
            />
            <div className=" h-5 w-5 absolute top-3.5 right-3 fill-current">
              <FaSearch className=" h-5 w-5 absolute top-3.5 right-3 fill-current" /> {/* Search icon */}
            </div>
          </div>
        </form>

        {filteredPatientsArray.map((patient) => (
          <div key={patient.userId} className="mb-8">
            <h2 className="text-2xl p-2 text-center md:text-3xl pl-2 my-2 border-l-4 font-sans font-bold border-blue-400  dark:text-gray-200">
              {patient.patientName}
            </h2>
            <div className="flex flex-wrap -mx-4">
              {patient.appointments.slice(0, appointmentsToShow).map((appointment) => (
                <div
                  key={appointment.id}
                  className="bg-blue-200 p-4 rounded-lg shadow-md w-full md:w-1/2 lg:w-1/2 xl:w-1/2 mb-4 mx-4"
                  onClick={() => handleAppointmentClick(appointment)}
                  style={{ cursor: 'pointer' }}
                >
                  <div>
                    <div className="text-md font-semibold text-gray-800">{appointment.day}</div>
                    <div className="text-sm max-w-full overflow-hidden whitespace-nowrap overflow-ellipsis font-semibold text-gray-500">
                      {appointment.id}
                    </div>
                  </div>
                  <p className="text-gray-700 mb-2">Status: {getStatustranslation(appointment.status)}</p>
                </div>
              ))}
            </div>
            {appointmentsToShow < patient.appointments.length && (
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded-lg mt-4"
                onClick={handleLoadMore}
              >
                {translations.LoadMore}
              </button>
            )}
          </div>
        ))}
        {selectedAppointment && (
          <Popups
            contents={
              <div>
                {/* Body */}
                <div>
                  <div>
                    <div className="text-md font-semibold text-gray-800">{selectedAppointment.day}</div>
                    <div className="text-sm max-w-full overflow-hidden whitespace-nowrap overflow-ellipsis font-semibold text-gray-500">
                      {selectedAppointment.id}
                    </div>
                  </div>
                  {selectedAppointment.statusCategory === 'reported' && (
      <div>
  <CgArrowLongDownC size={30} className=" text-gray-700 text-center font-semibold transition-all duration-300 ease-in-out transform hover:rotate-12" />

      <div className="flex flex-col  bg-orange-300  items-center mt-1">
      <div className="text-center text-gray-700 bg-orange-300  space-x-2 " >
        <strong className="text-gray-600 text-md "> {selectedAppointment.newDateday} </strong> 
        <br />
        <strong className="text-gray-600 text-md "> {selectedAppointment.newDateid}</strong>
        <div>  

</div>
      </div>
      </div>
      </div>

    )}
                  <h2 className="text-2xl text-center align-middle font-extrabold text-gray-800">{selectedAppointment.patientName}</h2>
                </div>

                <div className="border-t border-gray-300"></div>

                
  <div>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <p className="text-gray-600 mb-0 mr-2 flex items-center ">
                      <GiSandsOfTime size={20} className="ml-1 text-gray-600 transition-all duration-300 ease-in-out transform hover:rotate-12  " />
                      <span className="font-semibold ">{translations.Age}</span>
                    </p>
                    <p className=" text-xs text-gray-600 mb-0 items-center  ">
                      {selectedAppointment.Age}
                    </p>

                    {getsextranslation(selectedAppointment.sex)}

                    <p className="text-gray-600 mb-0 mr-4 flex items-center">
                      <MdOutlineBloodtype size={20} className="ml-2 text-red-600 transition-all duration-300 ease-in-out transform hover:rotate-12 " />
                      <span className="font-semibold"></span> {selectedAppointment.bloodtype}
                    </p>
                  </div>

                  <div className="border-t border-gray-300"></div>

                  <p className="text-gray-600 py-1 content-center overflow-wrap break-words flex items-center">
                    <MdPhone size={20} className="ml-2 text-gray-600 transition-all duration-300 ease-in-out transform hover:rotate-12 " />
                    <span className="font-semibold"></span>{selectedAppointment.phoneNumber}
                  </p>

                  <div className="border-t border-gray-300"></div>

                  <div className="mb-4">
                    <p className="text-gray-600 overflow-wrap pt-1 mr-4 break-words flex items-center">
                      <LiaAllergiesSolid size={20} className="ml-4 text-gray-600 transition-all duration-300 ease-in-out transform hover:rotate-12 " />
                      <span className="font-semibold">{translations.Allergies}</span>
                    </p>
                    <div className="text-gray-600 max-h-16 overflow-wrap overscroll-none overflow-y-scroll break-words items-center">
                      {selectedAppointment.Alergies}
                    </div>
                  </div>

                  <div className="border-t border-gray-300"></div>

                  <div className="mb-6 text-base mg:text-lg">
                    <p className="text-gray-600 overflow-wrap overscroll-none pt-1 mr-2 break-words flex items-center">
                      <MdOutlineSick size={20} className="ml-4 text-gray-600 transition-all duration-300 ease-in-out transform hover:rotate-12 " />
                      <span className="font-semibold">{translations.Symptômes}</span>
                    </p>
                    <div className="text-gray-600 max-h-16 overflow-wrap overscroll-none overflow-y-scroll break-words items-center">
                      {selectedAppointment.symptoms}
                    </div>
                  </div>

                  <div className="border-t border-gray-300"></div>
                  <div className="border-t border-gray-300"></div>

                </div>

                {/* Footer */}
                <div className="border-t border-gray-300"></div>
                <div className="border-t border-gray-300"></div>

                <div className="flex flex-col md:flex-row items-center justify-center md:justify-between mt-6">
                  <div className="flex items-center justify-center space-y-2 md:space-y-0 md:space-x-4">
                    <button
                      onClick={handleClosePopup}
                      className={`text-white px-4 py-2 rounded-full bg-gray-400 transition duration-300 ease-in-out hover:bg-gray-600 flex items-center space-x-2`}
                    >
                      <IoClose size={20} className="mr-2 text-white transition-all duration-300 ease-in-out transform hover:rotate-12" />
                      <span className="overflow-wrap">{translations.close}</span>
                    </button>
                  </div>
                </div>
              </div>
            }
            onClose={handleClosePopup}
          />
        )}
      </div>
    </div>
  );
};


    




  
 

  return (

    <div className="bg-sky-500 min-h-screen p-6 dark:text-gray-700  dark:bg-gray-700 ">
    
    {isLoading ? (
            /* Display a loading spinner while the action is in progress */
            <Spinner />
          ) : (
  
            <div className="max-w-5xl mx-auto bg-blue-100 dark:bg-gray-500 shadow-lg p-8 rounded-lg">
            <h2 className="text-3xl dark:text-blue-100 font-semibold mb-4">{translations.GestionRDV}</h2>
            
            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
              <div className="border border-gray-800 dark:border-gray-100 rounded-lg p-6 w-full sm:w-2/3 lg:w-1/2">
                <h2 className="text-xl font-semibold dark:text-blue-200 text-gray-800 flex items-center mb-4">
                  <LuCalendarClock size={30} className="mr-2 text-blue-500 dark:text-blue-200" />
                  {translations.TimeSettings}
                </h2>
                
                <div className="space-y-4">
                  <div className="flex flex-col md:flex-row md:space-x-4">
                    <div className="w-full md:w-1/2">
                      <label className="block text-sm font-semibold text-center dark:text-blue-200 text-gray-800 mb-2">
                        {translations.tempsDebut}:
                      </label>
                      <Select
                        options={timeOptions}
                        isSearchable={false}
                        value={timeOptions.find((option) => option.value === modalData.startTime)}
                        onChange={(selectedOption) => {
                          const selectedStartTime = selectedOption.value;
                          const nextAvailableEndTimeOption = timeOptions.find((option) => option.value > selectedStartTime);
                          const newEndTime = nextAvailableEndTimeOption ? nextAvailableEndTimeOption.value : 24;
          
                          setModalData((prevModalData) => ({
                            ...prevModalData,
                            startTime: selectedStartTime,
                            endTime: newEndTime,
                          }));
          
                          setIsStartTimeSelected(true);
                        }}
                        className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:border-indigo-500"
                      />
                    </div>
                    
                    <div className="w-full md:w-1/2">
                      <label className="block text-sm font-semibold text-center dark:text-blue-200 text-gray-800 mb-2">
                        {translations.tempsFin}:
                      </label>
                      <Select
                        options={endTimeOptions.filter((option) => option.value >= modalData.startTime)}
                        value={{ value: modalData.endTime, label: `${modalData.endTime}:00` }}
                        onChange={(selectedOption) => {
                          setModalData((prevModalData) => ({
                            ...prevModalData,
                            endTime: selectedOption.value,
                          }));
                        }}
                        isSearchable={false}
                        isDisabled={!isStartTimeSelected}
                        className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:border-indigo-500"
                      />
                    </div>
                  </div>
          
                  <div className="w-full">
                    <label className="block text-sm font-semibold dark:text-blue-200 text-gray-800 mb-2">
                      {translations.Dureemoyenne} {translations.minutes}:
                    </label>
                    <input
                      min="0" // Enforces positive numbers only

  type="number"
  value={inputValue || ''}
  onChange={(e) => handleAverageDurationChange(e.target.value)}
  className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:border-indigo-500 w-full"
/>


                  </div>
                  
                  <button
                    className="w-full bg-blue-400 hover:bg-blue-600 text-white font-medium py-2 rounded-lg shadow-md transition-all duration-300"
                    onClick={saveSettingsToLocal}
                  >
                    {translations.Apply}
                  </button>
                </div>
              </div>
          
              <div className="flex flex-col space-y-4 w-full sm:w-1/3 lg:w-1/2">
  <button
    className="bg-gradient-to-r from-blue-400 to-blue-500 hover:from-sky-400 hover:to-blue-500 text-white font-semibold py-2 px-4 rounded-lg focus:outline-none focus:shadow-outline flex items-center transition-all duration-300 transform hover:scale-105"
    onClick={() => setOpen(true)}
  >
    <FaUsers size={30} className="mr-2 transform transition-all duration-300 hover:rotate-12" />
    {translations.Patients}
  </button>
  
  <button
    className="bg-gradient-to-r from-blue-400 to-blue-500 hover:from-sky-400 hover:to-blue-500 text-white font-semibold py-4 px-4 rounded-lg focus:outline-none focus:shadow-outline flex items-center transition-all duration-300 transform hover:scale-105 shadow-md"
    onClick={() => setUploadConfirmationVisible(true)}
  >
    <TbCheckupList size={30} className="transform transition-all duration-300 hover:rotate-12" />
    <span className="ml-2">{translations.Submit}</span>
  </button>
</div>

            </div>
          



      {/* Time Blocks */}
      <div className="container mx-auto p-4">
      <div className="mb-4">
      <div className="flex flex-row space-x-4">
  <button
    className={`relative inline-flex items-center p-2 text-sm font-medium text-white bg-blue-400 hover:bg-blue-500 rounded-lg shadow-md ${
      view === 'week' ? 'bg-indigo-500 hover:bg-indigo-600' : ''
    }`}
    onClick={() => setView('week')}
  >
    <ImCalendar className="text-xl  transition-all duration-300 ease-in-out transform hover:rotate-12  " />
    <span className="ml-2">    {translations.week}</span>
  </button>
  <button
    className={`relative inline-flex items-center p-2 text-sm font-medium text-white bg-blue-400 hover:bg-blue-500 rounded-lg shadow-md ${
      view === 'day' ? 'bg-indigo-500 hover:bg-indigo-600' : ''
    }`}
    onClick={() => setView('day')}
  >
    <CgToday className="text-xl  transition-all duration-300 ease-in-out transform hover:rotate-12  " />
    <span className="ml-2"> {translations.day}</span>
  </button>

  <button
  className={`relative inline-flex items-center p-2 text-sm font-medium text-white bg-blue-400 hover:bg-blue-600 rounded-lg shadow-md ${
    view === 'today' ? 'bg-indigo-500 hover:bg-indigo-600' : ''
  }`}
  onClick={goToCurrentDate}
>
  <AiOutlineFieldTime className=" text-xl  transition-all duration-300 ease-in-out transform hover:rotate-12 " />
</button>
</div>

</div>



<div className="mb-4 flex flex-col md:flex-row md:items-center md:space-x-4">
<div className="flex justify-center md:justify-start items-center md:mb-0 space-y-4 md:space-y-0 md:space-x-4">
  {/* Previous Button */}
  <div className="flex max-w-sm rounded-xl bg-gradient-to-tr from-gray-300 to-blue-300 p-0.5 shadow-lg">
    <button
      className={`relative inline-flex items-center p-2 text-sm font-medium text-white bg-white hover:bg-gray-200 rounded-lg shadow-md ${
        view === 'prev' ? 'bg-indigo-500 hover:bg-indigo-600' : ''
      }`}
      onClick={handlePrev}
    >
      <BsArrowLeftCircle className="  transition-all duration-300 ease-in-out transform hover:rotate-12  text-lg text-black" />
      <span className="hidden md:inline ml-2">Précédent</span>
    </button>
  </div>
</div>

{/* Date Display */}
<div className="flex max-w-sm rounded-xl bg-gradient-to-tr from-gray-300 to-blue-300 p-0.5 shadow-lg">
  <span className="bg-white text-indigo-800 rounded-lg px-3 py-1 shadow-md">
    <Moment locale="fr" format="dddd, D MMMM YYYY">
      {currentDate}
    </Moment>
  </span>
</div>

<div className="flex justify-center md:justify-start items-center space-y-4">
  {/* Next Button */}
  <div className="flex max-w-sm rounded-xl bg-gradient-to-tr from-gray-300 to-blue-300 p-0.5 shadow-lg">
    <button
      className={`relative inline-flex items-center p-2 text-sm font-medium text-white bg-white hover:bg-gray-200 rounded-lg shadow-md ${
        view === 'next' ? 'bg-indigo-500 hover:bg-indigo-600' : ''
      }`}
      onClick={handleNext}
    >
      <BsArrowRightCircle className="text-lg text-black" />
      <span className="hidden md:inline ml-2">Suivant</span>
    </button>
  </div>
  
</div>

</div>


<div className="flex justify-center items-end space-x-4 y-1 text-center mb-4">
        <div className="flex flex-col items-center">
          <div className="w-3 h-3 bg-blue-500 rounded-full mb-1"></div>
          <p className="text-xs dark:text-gray-100 text-gray-600">{translations.booked}</p>
        </div>
        <div className="flex flex-col items-center">
          <div className="w-3 h-3 bg-orange-500 rounded-full mb-1"></div>
          <p className="text-xs dark:text-gray-100 text-gray-600">{translations.reported} </p>
        </div>
        <div className="flex flex-col items-center">
          <div className="w-3 h-3 bg-red-500 rounded-full mb-1"></div>
          <p className="text-xs dark:text-gray-200 text-gray-600">{translations.canceled}  </p>
        </div>
      </div>


        <div className="grid grid-cols-1 md:grid-cols-7 gap-4 ">
     {/* Render Time Blocks */}
  {renderDays.map((day, dayIndex) => (
    <div key={dayIndex} className="flex flex-col">
      <div
        className={`h-16 border-2 border-blue-300 text-center flex items-center justify-center ${
          (view === 'day' && day.dayName === momentDate.format('dddd')) || day.isCurrentBlock
            ? 'bg-blue-200 dark:bg-gray-200 text-blue-800'
            : 'bg-slate-100 dark:bg-gray-200 text-gray-800'
        }`}
        onClick={() => openDaySettingsPopup(dayIndex)}
        style={{ marginBottom: '10px' }}
      >
        <div className="font-semibold">
          {day.dayName} {day.dayOfMonth}
        </div>
      </div>

      <div className="day-time-blocks dark:bg-gray-400">
        {[
          ...Array.from({ length: numberOfAppointments }).map((_, index) => {
            const appointmentStartTime = moment(modalData.startTime, 'HH:mm').add(
              index * averageDurationForNewEvents,
              'minutes'
            );
            const uniqueId = `${day.dayName} ${day.dayOfMonth} ${appointmentStartTime.format('HH:mm')}`;
            const dayKey = moment(renderDays[dayIndex].dayDate).format('YYYY-MM-DD');

            const isSelectedBlock =
              selectedTimeSlotsByDay[moment(renderDays[dayIndex].dayDate).format('YYYY-MM-DD')]?.includes(uniqueId) ||
              false;

            // If the uniqueId is selected, skip rendering in the first time block
            if (isSelectedBlock) {
              return null;
            }

            const timeFromUniqueId = uniqueId.split(' ')[2];
            const currentDateTime = moment();
            const blockTime = moment(timeFromUniqueId, 'HH:mm');
            const blockDateTime = moment(renderDays[dayIndex].dayDate).set({
              hour: blockTime.hour(),
              minute: blockTime.minute(),
            });

            const isTimeSlotDisabled = blockDateTime.isBefore(currentDateTime, 'minute') && !isSelectedBlock;

            if (isTimeSlotDisabled) {
              return null; // Skip rendering past time slots
            }

            const statusCategory = getStatusCategory(dayKey, uniqueId);
            const isHighlightedDay = day.isCurrentBlock || isSelectedBlock;

            return {
              key: `generated_${index}`, // Ensure a unique key for generated time blocks
              isSelected: isSelectedBlock,
              onClick: () => toggleBlockSelection(dayIndex, uniqueId, statusCategory),
              time: appointmentStartTime.format('HH:mm'),
              blockDate: appointmentStartTime.toDate(),
              isHighlightedDay,
              dayName: day.dayName,
              dayOfMonth: day.dayOfMonth,
              view: view,
              isDisabled: isTimeSlotDisabled,
              statusCategory: statusCategory,
            };
          }),
          ...(selectedTimeSlotsByDay[moment(renderDays[dayIndex].dayDate).format('YYYY-MM-DD')] || []).map((uniqueId, index) => {
            const dayKey = moment(renderDays[dayIndex].dayDate).format('YYYY-MM-DD');
            const isSelectedBlock = selectedTimeSlotsByDay[dayKey]?.includes(uniqueId) || false;
            const timeFromUniqueId = uniqueId.split(' ')[2];
            const currentDateTime = moment();
            const blockTime = moment(timeFromUniqueId, 'HH:mm');
            const blockDateTime = moment(renderDays[dayIndex].dayDate).set({
              hour: blockTime.hour(),
              minute: blockTime.minute(),
            });
            const isTimeSlotDisabled = blockDateTime.isBefore(currentDateTime, 'minute') && !isSelectedBlock;

            if (isTimeSlotDisabled) {
              return null;
            }

            const statusCategory = getStatusCategory(dayKey, uniqueId);
            const isHighlightedDay = day.isCurrentBlock || isSelectedBlock;

            return {
              key: `selected_${index}`, // Ensure a unique key for selected time slots
              isSelected: isSelectedBlock,
              onClick: () => toggleBlockSelection(dayIndex, uniqueId, statusCategory),
              time: blockDateTime.format('HH:mm'),
              blockDate: blockDateTime.toDate(),
              isHighlightedDay,
              dayName: day.dayName,
              dayOfMonth: day.dayOfMonth,
              view: view,
              isDisabled: isTimeSlotDisabled,
              statusCategory: statusCategory,
            };
          }),
        ]
          .filter((block) => block !== null) // Filter out null values from skipped renderings
          .reduce((uniqueBlocks, block) => {
            // Use a reducer to ensure uniqueness based on the key
            const isUnique = !uniqueBlocks.some((uniqueBlock) => uniqueBlock.key === block.key);
            return isUnique ? [...uniqueBlocks, block] : uniqueBlocks;
          }, [])
          .sort((a, b) => moment(a.time, 'HH:mm').diff(moment(b.time, 'HH:mm')))
          .map(({ key, ...block }) => (
            <TimeBlock key={key} {...block} />
          ))}
      </div>

 




                {/* Render the additional time slots within the grid box */}
                {selectedTimeSlots[moment(renderDays[dayIndex].dayDate).format('YYYY-MM-DD')]?.map(
                  (timeSlot, slotIndex) => {
                    const uniqueId = `${day.dayName} ${day.dayOfMonth} ${timeSlot}`;
                    const dayKey = moment(renderDays[dayIndex].dayDate).format('YYYY-MM-DD'); // Add this line

                    const isSelectedBlock =
                      selectedTimeSlotsByDay[moment(renderDays[dayIndex].dayDate).format('YYYY-MM-DD')]?.includes(
                        uniqueId
                      ) || false;

                   
  const statusCategory = getStatusCategory(dayKey, uniqueId);   

                    const isHighlightedDay = day.isCurrentBlock || isSelectedBlock;
  
                    return (
                      <TimeBlock
                        key={slotIndex}
                        isSelected={isSelectedBlock}
                        onClick={() => toggleBlockSelection(dayIndex, uniqueId, statusCategory)}
                        onRemove={() =>
                          removeTimeSlot(dayIndex, timeSlot, day.dayName, day.dayOfMonth)
                        } // Pass dayName and dayOfMonth
                        time={timeSlot}
                        blockDate={moment(renderDays[dayIndex].dayDate).set('HH:mm', timeSlot).toDate()}
                        isHighlightedDay={isHighlightedDay}
                        dayName={day.dayName}
                        dayOfMonth={day.dayOfMonth}
                        statusCategory={statusCategory} 
                      />
                    );
                  }
                )}
              </div>
            ))}
          </div>
        </div>
  
        {/* Render day settings popup */}
        {editingDay !== null && (
          <EditDayPopup
            isOpen={isDaySettingsPopupOpen} // Make sure to pass the correct prop name
            initialValues={popupInitialValues}
            daySettings={daySettings[editingDay] || modalData}
            onSave={saveDaySettings}
            onCancel={cancelDaySettings}
            timeOptions={timeOptions}
            endTimeOptions={endTimeOptions}
            view={view}
          />
        )}
      </div>

)}
    

      <div className=" overscroll-contain ">

       {/* Render the popup if it's visible */}
    {isPopupVisible && (
        <Popup
          content={popupContent}
          onClose={() => setIsPopupVisible(false)} // Close the popup when needed
          className=" overscroll-none "
          />
      )}
      </div>
    

      <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 z-60">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md z-70">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4 z-80">
                      <button
                        type="button"
                        className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => setOpen(false)}
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <FaTimes className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex h-full flex-col overflow-y-scroll bg-blue-100  dark:bg-gray-500 py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <Dialog.Title className="text-base font-semibold leading-6 dark:text-sky-500 text-sky-600">
                       DoKtori
                      </Dialog.Title>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                    <TimeSlotsPage organizedByStatus={organizedByStatus} />


                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>



    {isUploadConfirmationVisible && (

      <div className="fixed inset-0 flex flex-col items-center justify-center min-h-screen py-10 sm:py-20 lg:py-40 overscroll-contain  z-10 overflow-auto">
       
  <div
    className="absolute inset-0 flex  items-center justify-center bg-gray-800 bg-opacity-50" >
    <div className="bg-blue-100 dark:bg-gray-400 p-6 rounded-lg w-80 text-center items-center shadow-lg" >
            <p className="text-gray-800 dark:text-gray-100 text-lg mb-4">
        {translations.confirmezplageshoraires}  
            </p>
          
      <div className="flex justify-end">

        <button  className="text-sky-600 hover:text-sky-700 px-4 py-2 rounded-md border border-sky-500 hover:bg-blue-100 dark:bg-blue-100 focus:outline-none" 
         onClick={handleSubmit}
>
{translations.oui}  
       
        </button>
        <button
                className="text-gray-500 hover:text-gray-700 px-4 py-2 ml-4 rounded-md border border-gray-500 hover:bg-gray-100 dark:bg-gray-100 focus:outline-none"
                onClick={() => setUploadConfirmationVisible(false)}
        >
         {translations.non}  
        </button>
      </div>
    </div>
  </div>
 
  </div>  
)}

    </div>
    
  );
  
};

export default TimeBlocksGrid; 
import { useState, useEffect } from "react";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { toast } from "react-toastify";
import OAuth from "../components/OAuth";
import Spinner from "../components/Spinner";
import patientsignin from "../Assets/images/patientsignin.gif";
import { useLanguage } from '../components/LanguageContext'; 
import TermsAndConditions from '../components/TermsAndConditions'; 

export default function SignIn() {
  const [loading, setLoading] = useState(false); // State to track loading
  const [showPassword, setShowPassword] = useState(false);
  const { language, translations } = useLanguage();
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [istermserviceVisible, settermserviceVisible] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const { email, password } = formData;
  const navigate = useNavigate();
  useEffect(() => {
    const resetCounts = () => {
      // Reset failed login attempts after 20 minutes
      const failedLoginAttempts = JSON.parse(
        localStorage.getItem("failedLoginAttempts") || "{}"
      );
      Object.keys(failedLoginAttempts).forEach((emailKey) => {
        failedLoginAttempts[emailKey] = 0; // Reset attempts to 0 for each email
      });
      localStorage.setItem(
        "failedLoginAttempts",
        JSON.stringify(failedLoginAttempts)
      );
  
      // Update the last reset time
      localStorage.setItem("lastResetTime", new Date().getTime());
    };
  
    // Check if the last reset time is available
    const lastResetTime = parseInt(localStorage.getItem("lastResetTime"));

    let timeout; // Define timeout variable

    if (lastResetTime) {
      // Calculate the elapsed time since the last reset
      const currentTime = new Date().getTime();
      const elapsedTime = currentTime - lastResetTime;
  
      // If elapsed time exceeds 20 minutes, reset the counts
      if (elapsedTime >= 20 * 60 * 1000) {
        resetCounts();
      } else {
        // Otherwise, set a timeout to reset counts after remaining time
        const remainingTime = 20 * 60 * 1000 - elapsedTime;
        timeout = setTimeout(resetCounts, remainingTime); // Assign timeout here
      }
    } else {
      // If it's the first time or last reset time is not available, reset counts
      resetCounts();
    }
  
    // Cleanup function to clear the timeout
    return () => clearTimeout(timeout);
  }, []);

  function onChange(e) {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  }

  async function onSubmit(e) {
    e.preventDefault();
    
    // Check if the user has exceeded the maximum number of login attempts
    const failedLoginAttempts = JSON.parse(
      localStorage.getItem("failedLoginAttempts") || "{}"
    );
    const emailKey = email.toLowerCase();
    const attempts = failedLoginAttempts[emailKey] || 0;
    if (attempts >= 5) {
      toast.error("Vous avez dépassé le nombre maximum de tentatives de connexion. Veuillez réessayer plus tard.");
      return;
    }

    try {
      setLoading(true); // Set loading to true during sign-in

      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      if (userCredential.user) {
        navigate("/");
      }
    } catch (error) {
      // Handle failed login attempt
      handleFailedLogin(emailKey);
      toast.error("Email ou mot de passe invalide");
    } finally {
      setLoading(false); // Set loading to false after the sign-in process
    }
  }

  function handleFailedLogin(emailKey) {
    // Increment failed login attempts for the current user
    let failedLoginAttempts = JSON.parse(
      localStorage.getItem("failedLoginAttempts") || "{}"
    );
    failedLoginAttempts[emailKey] = (failedLoginAttempts[emailKey] || 0) + 1;

    // Update localStorage with the new failed login attempts count
    localStorage.setItem(
      "failedLoginAttempts",
      JSON.stringify(failedLoginAttempts)
    );
  }
  function toggleAcceptedTerms() {
    setAcceptedTerms((prevAccepted) => !prevAccepted);
  }

  return (
    <section className="bg-blue-200 dark:bg-gray-700 min-h-screen flex items-center justify-center">
      <div className="max-w-md mx-auto p-8 dark:bg-gray-500 bg-blue-100 rounded-lg shadow-lg">
        <h1 className="text-3xl text-center dark:text-blue-100 font-bold mb-4">{translations.SignIn}</h1>
        <OAuth acceptedTerms={acceptedTerms} />
        <span className="ml-2 dark:text-blue-100">
              {translations.Conditionsutilisationgoogle} {" "}
              <button
                className="text-blue-500 dark:text-blue-300 hover:underline"
                onClick={() => settermserviceVisible(true)}
              >
                {translations.Conditionsutilisation2}
              </button>
            </span>
       
        <div className="items-center flex my-4 before:border-t before:flex-1 before:border-gray-300 justify-center after:border-t after:flex-1 after:border-gray-300">
          <p className="text-gray-500 dark:text-blue-100 font-semibold mx-4">{translations.Ou}</p>
        </div>
        {istermserviceVisible && (
          <main className="fixed inset-0 flex flex-col items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-700 mb-10 z-50">
    <div  className="w-full max-w-screen-lg p-4 bg-gray-100 dark:bg-gray-700 rounded-lg shadow-lg overflow-auto z-50 ">
          <TermsAndConditions />
      
        
        
        </div>
        <div className="flex justify-end bg-blue-100 dark:bg-gray-700 mb-10">
        
        <button  className="text-sky-600 hover:text-sky-700 px-4 py-2 rounded-md border border-sky-500 hover:bg-blue-100 dark:bg-blue-100 focus:outline-none" 
        onClick={() => {
          toggleAcceptedTerms();
          settermserviceVisible(false);
        }}      >
      {translations.iaccept}  
       
        </button>
        <button
                className="text-gray-500 hover:text-gray-700 px-4 py-2 ml-4 rounded-md border border-gray-500 hover:bg-gray-100 dark:bg-gray-100 focus:outline-none"
                onClick={() => settermserviceVisible(false)}
        >
         {translations.close}  
        </button>
      </div>
 
        </main>  
        )}  

      </div>
    </section>
  );
}

function TermsAndConditions() {
  return (
    <div className="container mx-auto bg-blue-200 py-8 px-4 overflow-scroll ">
      <h1 className="text-3xl font-bold mb-4">Conditions d'Utilisation de Doktori</h1>
      <p className="mb-6">En acceptant les présentes conditions d'utilisation, les utilisateurs reconnaissent avoir pris connaissance et compris l'ensemble des termes qui les régissent.</p>
      
      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Définition :</h2>
        <p className="mb-2">a. Pour les besoins des présentes conditions d'utilisation, le terme "utilisateurs" fait référence à toutes les personnes utilisant la plateforme Doktori, y compris mais sans s'y limiter les patients et les professionnels de la santé.</p>
        <p className="mb-2">b. Le terme "professionnels de la santé" fait référence spécifiquement aux utilisateurs qui possèdent des comptes professionnels sur la plateforme Doktori, tels que les médecins, les infirmières, les thérapeutes, et autres praticiens de la santé autorisés.</p>
        <p className="mb-2">c. Dans les présentes conditions d'utilisation, le terme "Doktori" fait référence à la plateforme en ligne, comprenant le site web, l'application mobile et toute entité ou organisation associée exploitant la plateforme.</p>
        <p>d. Toutes les références aux droits, obligations, responsabilités ou autres dispositions applicables aux utilisateurs ou aux professionnels de la santé dans les présentes conditions d'utilisation seront interprétées en tenant compte de ces définitions.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Utilisation de la plateforme :</h2>
        <p className="mb-2">a. En accédant à l'application ou au site web Doktori, vous, en tant qu'utilisateur, acceptez pleinement et sans réserve les présentes conditions d'utilisation.</p>
        <p className="mb-2">b. Les utilisateurs doivent s'inscrire sur la plateforme en fournissant des informations exactes et à jour. Tout usage frauduleux ou non autorisé peut entraîner la résiliation du compte ou des poursuites judiciaires.</p>
        <p className="mb-2">c. L'utilisation de Doktori est destinée à faciliter la prise de rendez-vous médicaux entre les patients et les médecins. Toute autre utilisation est interdite.</p>
        <p className="mb-2">d. Les utilisateurs sont responsables de maintenir la confidentialité de leurs identifiants de connexion et sont responsables de toutes les activités se déroulant sous leur compte.</p>
        <p className="mb-2">e. Les utilisateurs de Doktori doivent être légalement admissibles à utiliser les services de santé dans leur pays de résidence. Les mineurs doivent obtenir le consentement parental pour utiliser la plateforme.</p>
        <p className="mb-2">f. Doktori n'est pas responsable des informations fournies par les utilisateurs ni des interactions entre les professionnels de la santé et les utilisateurs.</p>
        <p className="mb-2">g. Doktori n'est pas destiné aux situations médicales d'urgence. Si vous rencontrez une urgence médicale, veuillez contacter immédiatement les services d'urgence.</p>
        <p>h. La disponibilité des professionnels de la santé sur Doktori est sujette à modification sans préavis. Nous ne pouvons garantir la disponibilité d'un médecin spécifique à tout moment donné.</p>
      </section>

      <section>
        <h2 className="text-2xl font-bold mb-4">Interdictions :</h2>
        <p className="mb-2">a. Il est strictement interdit d'utiliser Doktori à des fins illégales, frauduleuses ou nuisibles, y compris mais sans s'y limiter à la diffusion de logiciels malveillants, à l'usurpation d'identité, ou à la perturbation des services.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Responsabilités des utilisateurs :</h2>
        <p className="mb-2">a. Les utilisateurs s'engagent à respecter les rendez-vous pris avec les professionnels de la Santé.</p>
        <p className="mb-2">b. Les utilisateurs reconnaissent l'importance de respecter la confidentialité médicale et s'engagent à ne pas divulguer les informations médicales personnelles d'autrui sans leur consentement préalable.</p>
        <p className="mb-2">c. Les utilisateurs conviennent que les informations qu'ils partagent ou partageront lors de la prise de rendez-vous peuvent être consultées par d'autres personnes travaillant pour ou avec le professionnels de la santé, en plus du professionnels de la santé lui-même.</p>
        <p className="mb-2">d. En partageant des informations lors de la prise de rendez-vous, les utilisateurs acceptent que ces informations puissent être accessibles aux membres du personnel administratif, aux assistants ou à d'autres professionnels de la santé travaillant  pour et ou en collaboration avec le professionnels de la santé concerné. dans la mesure nécessaire pour faciliter la prestation des services de santé.</p>
        <p className="mb-2">e. Les utilisateurs sont encouragés à ne partager que les informations qu'ils sont à l'aise de voir consultées par d'autres utilisateurs travaillant pour le ou les professionnels de la santé concerné, en tenant compte de la nature confidentielle des informations médicales et personnelles partagées.</p>
        <p className="mb-2">f. Les utilisateurs reconnaissent et acceptent que Doktori puisse également avoir accès aux informations partagées lors de la prise de rendez-vous sur la plateforme.</p>
        <p className="mb-2">g. Doktori peut avoir besoin d'accéder à ces informations dans le cadre de ses activités y compris mais sans s'y limiter administratives, de gestion de la plateforme, de soutien technique ou pour répondre aux demandes des utilisateurs.</p>
      <p className="mb-2">h. Les parents ou tuteurs légaux des patients mineurs sont responsables de la prise de rendez-vous et de la supervision des consultations médicales, et doivent donner leur consentement pour tout traitement médical prodigué aux mineurs.</p>
      <p className="mb-2">i. Les utilisateurs s'engagent à ne pas publier de contenus inappropriés, tels que des images ou des vidéos à caractère sexuel, violent ou choquant, sur la plateforme Doktori.</p>
    
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Engagements des Professionnels de la Santé : </h2>
        <p className="mb-2">a. Les professionnels de la Santé inscrits sur Doktori doivent détenir les certifications et licences médicales appropriées dans leur pays de pratique, et s'engagent à maintenir ces qualifications en règle.</p>
        <p className="mb-2">b. Les professionnels de la Santé s'engagent à ne pas discriminer les patients sur la base de leur origine ethnique, de leur religion ou de tout autre critère protégé par la loi.</p>
        <p className="mb-2">c. Les professionnels de la Santé doivent éviter les conflits d'intérêts et agir dans l'intérêt supérieur du patient. Ils doivent divulguer toute relation financière ou professionnelle qui pourrait influencer leur jugement ou leur objectivité.</p>
        <p className="mb-2">d. Les professionnels de la Santé utilisant Doktori sont tenus de fournir des informations claires et précises sur les services qu'ils proposent, y compris leurs domaines de spécialisation.</p>
        <p className="mb-2">e. En cas de suspicion de comportement non éthique ou de pratique médicale inappropriée d'un autre professionnel de la Santé utilisant Doktori, les professionnels de la Santé sont encouragés à nous signaler ces incidents et à les reporter aux autorités compétentes pour enquête et action appropriée.</p>
        <p className="mb-2">f. Les professionnels de la Santé s'engagent à respecter les rendez-vous fixés avec les patients via Doktori et à être disponibles aux heures convenues, sauf en cas de circonstances imprévues ou d'urgence médicale.</p>
        <p className="mb-2">g. En cas de litige ou de différend avec un patient, les professionnels de la Santé doivent faire preuve de diligence et de professionnalisme dans la résolution de la situation, en privilégiant toujours le bien-être et la satisfaction du patient.</p>
        <p className="mb-2">h. Les professionnels de la Santé reconnaissent qu'ils demeurent entièrement responsables des services qu'ils fournissent, y compris en cas de consultations à distance ou de téléconsultations.</p>
        <p className="mb-2">i. Les professionnels de la santé utilisant Doktori s'engagent à fournir des services médicaux conformes aux normes professionnelles en vigueur et à respecter les règles de déontologie médicale.</p>
        <p className="mb-2">j. Les professionnels de la santé se réservent le droit d'annuler ou de reporter des rendez-vous sans préavis aux patients. Doktori décline toute responsabilité pour de telles actions.</p>
        <p>k. Doktori peut facturer des frais pour certains services. En utilisant ces services, les utilisateurs acceptent de payer les frais applicables tels que définis par Doktori.</p>
      </section>
    
    <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Non-Paiement des Frais  :</h2>
        <p className="mb-2">a. En cas de refus de paiement des frais applicables dus à Doktori après avoir été acceptés préalablement, ou de violation des conditions d'utilisation par un professionnel de la santé, Doktori se réserve expressément le droit de suspendre ou de résilier leur compte sur la plateforme, sans préavis.</p>
        <p className="mb-2">b. Cette suspension ou résiliation peut entraîner la perte d'accès aux fonctionnalités de la plateforme.</p>
        <p className="mb-2">c. Doktori se réserve également le droit d'entreprendre des actions supplémentaires, telles que l'engagement de procédures de recouvrement de créances ou le signalement de la violation aux autorités compétentes, si nécessaire.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Protection des données personnelles :</h2>
        <p className="mb-2">a. Doktori collecte et traite les données personnelles des utilisateurs conformément à sa politique de confidentialité disponible sur la plateforme.</p>
        <p className="mb-2">b. Les utilisateurs reconnaissent et acceptent que Doktori puisse utiliser leurs données personnelles à des fins de marketing ou pour améliorer ses services.</p>
        <p>c. Les utilisateurs consentent au traitement de leurs données personnelles conformément à la politique de confidentialité de Doktori, et acceptent que leurs données puissent être transférées et stockées dans des serveurs situés en dehors de leur pays de résidence.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Sécurité des Données :</h2>
        <p className="mb-2">a. Doktori met en place des mesures de sécurité raisonnables pour protéger les données des utilisateurs. Cependant, les utilisateurs reconnaissent que la transmission de données sur Internet comporte des risques et que Doktori ne peut garantir une sécurité absolue.</p>
        <p>b. Doktori ne pourra être tenu responsable en cas de retard, d'interruption ou de dysfonctionnement de la plateforme résultant de circonstances échappant à son contrôle raisonnable, telles que des événements de force majeure, des pannes techniques ou des actions de tiers.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Intégration de Tiers Services :</h2>
        <p className="mb-2">a. Doktori peut proposer l'intégration de services tiers sur sa plateforme, y compris mais sans s'y limiter des services de téléconsultation, de traitement des paiements, ou d'autres fonctionnalités complémentaires.</p>
        <p className="mb-2">b. Les utilisateurs reconnaissent que l'utilisation de services tiers est soumise aux conditions d'utilisation et aux politiques de confidentialité propres à ces services tiers, et qu'ils doivent les consulter et les accepter séparément avant de les utiliser via Doktori.</p>
        <p>c. Doktori décline toute responsabilité quant à la disponibilité, à la qualité ou à la fiabilité des services tiers intégrés à sa plateforme, et recommande aux utilisateurs de lire attentivement les conditions et politiques de confidentialité des dits services tiers avant de les utiliser.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Évolution des Services :</h2>
        <p className="mb-2">a. Doktori se réserve le droit d'ajouter, de modifier ou de supprimer des services à tout moment, en fonction de l'évolution des besoins des utilisateurs, des avancées technologiques ou des changements dans le secteur de la santé.</p>
        <p className="mb-2">c. Doktori se réserve le droit de modifier ou de mettre fin à tout nouveau service supplémentaire à sa seule discrétion, sans préavis ni responsabilité envers les utilisateurs.</p>
        <p>d. Les utilisateurs reconnaissent que l'ajout de nouveaux services peut entraîner des changements dans les fonctionnalités, les tarifs ou les conditions d'utilisation de la plateforme, et acceptent ces modifications.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Publicité et Promotion :</h2>
        <p className="mb-2">a. Doktori se réserve le droit d'afficher des publicités et des promotions sur sa plateforme, y compris mais sans s'y limiter sous forme de bannières publicitaires, de messages sponsorisés ou d'autres formats publicitaires.</p>
        <p className="mb-2">b. Les utilisateurs reconnaissent et acceptent que Doktori puisse afficher des publicités ciblées en fonction des informations fournies par les utilisateurs ou de leur comportement sur la plateforme.</p>
        <p className="mb-2">c. Doktori s'engage à faire des efforts raisonnables pour assurer que les publicités et promotions affichées sur sa plateforme soient conformes aux normes éthiques et légales en vigueur, et ne portent pas atteinte à l'expérience utilisateur.</p>
        <p className="mb-2">d. Les utilisateurs reconnaissent que leur interaction avec des publicités ou promotions affichées sur la plateforme Doktori est entièrement facultative et qu'ils peuvent choisir de ne pas y participer.</p>
        <p>e. Les utilisateurs acceptent que Doktori puisse leur envoyer des communications promotionnelles.</p>
      </section>

          {/* Suspension et Résiliation des Comptes */}
          <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Suspension et Résiliation des Comptes :</h2>
        <p className="mb-2">a. Doktori se réserve le droit de suspendre ou de résilier les comptes des utilisateurs en cas de violation des présentes conditions d'utilisation ou de comportement inapproprié sur la plateforme, sans préavis ni responsabilité envers les utilisateurs.</p>
      </section>

      {/* Respect des Droits de Propriété */}
      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Respect des Droits de Propriété :</h2>
        <p className="mb-2">a. Les utilisateurs s'engagent à respecter les droits de propriété intellectuelle de Doktori et des tiers y compris mais sans s'y limiter, les droits de propriété sur le contenu, les marques de commerce, les logos, les designs, les fonctionnalités et tout autre élément de propriété intellectuelle associé à la plateforme. Toute reproduction, modification, distribution ou utilisation non autorisée du contenu de la plateforme est strictement interdite.</p>
        <p className="mb-2">b. Aucun utilisateur n'est autorisé à copier, reproduire, modifier, distribuer, afficher ou exploiter de quelque manière que ce soit tout contenu protégé par des droits de propriété intellectuelle appartenant à Doktori sans autorisation expresse écrite.</p>
        <p className="mb-2">c. Les utilisateurs reconnaissent que toute utilisation non autorisée du contenu protégé par des droits de propriété intellectuelle de Doktori peut constituer une violation des lois sur les droits d'auteur et des autres lois applicables, et peut entraîner des poursuites judiciaires.</p>
        <p>d. Les utilisateurs s'engagent à signaler toute violation présumée des droits de propriété intellectuelle de Doktori dès qu'ils en ont connaissance, afin que des mesures correctives puissent être prises rapidement.</p>
      </section>

      {/* Modification du contenu de la Plateforme */}
      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Modification du contenu de la Plateforme :</h2>
        <p className="mb-2">a. Doktori se réserve le droit de changer le contenu de la platforme y compris mais sans s'y limiter le nom de la plateforme à sa discrétion. Un tel changement du contenu n'entraînera pas l'annulation ou la modification des autres clauses des présentes conditions d'utilisation.</p>
        <p className="mb-2">b. Toutes les références au contenu précédent de la plateforme dans les présentes conditions d'utilisation seront réputées se référer au nouveau contenu de la plateforme après tout changement de contenu effectué par Doktori.</p>
        <p className="mb-2">c. Les utilisateurs reconnaissent et acceptent que le changement de nom de la plateforme n'affectera pas leurs droits ou obligations en vertu des présentes conditions d'utilisation, et que celles-ci resteront pleinement en vigueur et applicables.</p>
      </section>

      {/* Limitation de Responsabilité */}
      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Limitation de Responsabilité :</h2>
        <p className="mb-2">a. Dans toute la mesure permise par la loi applicable, Doktori et ses affiliés, ainsi que leurs dirigeants, administrateurs, employés et agents, ne seront en aucun cas responsables envers les utilisateurs pour tout dommage indirect, accessoire, spécial, exemplaire ou consécutif découlant de ou lié à l'utilisation de la plateforme Doktori, y compris mais sans s'y limiter, les pertes de profits, les pertes de données, les pertes d'exploitation ou les dommages résultant de l'interruption de l'activité, même si Doktori a été informé de la possibilité de tels dommages.</p>
        <p className="mb-2">b. En aucun cas, la responsabilité totale de Doktori envers un utilisateur pour tous les dommages, pertes et causes d'action, qu'elles soient contractuelles, délictuelles (y compris la négligence) ou autres, n'excédera le montant total payé par l'utilisateur à Doktori au cours des douze (06) mois précédant immédiatement l'événement donnant lieu à la responsabilité.</p>
        <p>c. Doktori s'efforce de fournir des services de qualité, mais ne garantit pas l'exactitude, l'exhaustivité ou la fiabilité des informations fournies par les utilisateurs ou des services rendus par les professionnelles de la Santé. En aucun cas, Doktori ne pourra être tenu responsable des dommages directs, indirects, accessoires, spéciaux ou consécutifs découlant de l'utilisation de la plateforme.</p>
      </section>

      {/* Règlement à l'amiable des Différends */}
      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Règlement à l'amiable des Différends :</h2>
        <p className="mb-2">a. Avant d'entamer une action en justice ou d'engager toute autre procédure de résolution de conflit, les utilisateurs conviennent de faire des efforts raisonnables pour résoudre tout différend, réclamation ou litige découlant des présentes conditions d'utilisation de manière amicale et de bonne foi.</p>
        <p className="mb-2">b. Si le différend ne peut être résolu de manière informelle, les utilisateurs conviennent de recourir à des mécanismes de médiation ou d'arbitrage indépendants et neutres, plutôt que d'engager des poursuites judiciaires immédiates.</p>
        <p className="mb-2">c. Si les utilisateurs ne parviennent pas à résoudre le différend de manière amicale dans un délai raisonnable, ils peuvent alors envisager d'autres recours, y compris le recours à des mécanismes de règlement des litiges formels conformes à la législation applicable.</p>
        <p className="mb-2">d. Les utilisateurs s'engagent à notifier à l'autre partie tout différend ou réclamation et à tenter de résoudre le problème par le biais de négociations directes et constructives, en mettant en œuvre tous les moyens raisonnables pour parvenir à un règlement mutuellement acceptable.</p>     
        <p className="mb-2">e. Les utilisateurs s'engagent à coopérer de bonne foi avec tout processus de résolution des litiges, et à respecter les décisions et les accords qui en résultent.</p>
        <p className="mb-2">f. En cas de litige porté devant un tribunal compétent, les utilisateurs conviennent que le tribunal compétent sera celui désigné dans les clauses de juridiction et de loi applicable des présentes conditions d'utilisation, sous réserve des lois en vigueur dans le pays concerné.</p>   </section>

<section className="mb-8">

<h2 className="text-2xl font-bold mb-4">Juridiction et Loi Applicable :</h2>
<p className="mb-2">a. Les utilisateurs reconnaissent et acceptent que tout litige découlant des présentes conditions d'utilisation ou de l'utilisation de la plateforme Doktori sera régi par les lois de l'Algérie, à l'exclusion de tout conflit de lois.</p>
<p className="mb-2">b. Tout litige ou réclamation découlant des présentes conditions d'utilisation sera soumis à la compétence exclusive des tribunaux de l'Algérie, sauf si Doktori en décide autrement par écrit.</p>
<p className="mb-2">c. Les utilisateurs renoncent à tout droit qu'ils pourraient avoir à contester la juridiction ou le lieu des tribunaux de l'Algérie pour toute action découlant des présentes conditions d'utilisation ou de l'utilisation de la plateforme Doktori.</p>
</section>

  {/* Attribution de Droits */}
  <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Attribution de Droits :</h2>
        <p className="mb-2">a. Les utilisateurs reconnaissent et acceptent que Doktori puisse céder ses droits et obligations en vertu des présentes conditions d'utilisation à toute filiale ou société affiliée, ou dans le cadre d'une fusion, acquisition ou autre transaction similaire.</p>
      </section>

      <section className="mb-8">

      <h2 className="text-2xl font-bold mb-4">Mention Légale :</h2>
      <p className="mb-2">a. Les présentes conditions d'utilisation constituent un accord juridiquement contraignant entre les utilisateurs et Doktori. En accédant ou en utilisant la plateforme Doktori, les utilisateurs acceptent d'être liés par ces conditions.</p>
      <p className="mb-2">b. Doktori se réserve le droit de modifier, de mettre à jour ou de réviser les présentes conditions d'utilisation à tout moment, sans préavis. Il incombe aux utilisateurs de consulter régulièrement les conditions d'utilisation pour prendre connaissance de toute modification.</p>
      <p className="mb-2">c. Si une disposition des présentes conditions d'utilisation est jugée invalide, illégale ou inapplicable par un tribunal compétent, cette disposition sera modifiée dans la mesure du possible pour refléter l'intention des parties, et les autres dispositions demeureront en vigueur.</p>
      <p className="mb-2">d. Le fait que Doktori n'exerce pas un droit ou ne prenne pas de mesure en cas de violation des présentes conditions d'utilisation ne constitue pas une renonciation à ce droit ou à cette mesure, et n'affectera pas la capacité de Doktori à exercer ce droit ou à prendre cette mesure ultérieurement.</p>
      </section>


      {/* Langue des conditions d'utilisation */}
      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Langue des conditions d'utilisation :</h2>
        <p className="mb-2">a. Les présentes conditions d'utilisation sont rédigées en français. En cas de traduction dans d'autres langues, la version française prévaudra en cas de divergence ou d'incohérence.</p>
        <p>b. Les utilisateurs reconnaissent avoir lu et compris la langue dans laquelle les présentes conditions d'utilisation sont rédigées. En cas de difficulté de compréhension, les utilisateurs sont invités à solliciter des clarifications auprès de Doktori avant d'accepter les conditions d'utilisation.</p>
      </section>
      
      <footer className="text-center mt-8 text-sm text-gray-500">
        <p>Pour toute question, préoccupation ou réclamation concernant Doktori ou ces conditions d'utilisation, les utilisateurs peuvent contacter l'équipe de support à l'adresse fournie sur la plateforme.
        support@doktori.site
        </p>
      </footer>
    </div>
  );
}

export default TermsAndConditions;
import { getAuth, updateProfile } from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { db } from "../firebase";
import { useAuthStatus } from "../hooks/useAuthStatus";
import { useLanguage } from '../components/LanguageContext'; 

import { FaMale, FaFemale, FaEdit, FaMapMarkerAlt  } from 'react-icons/fa';




export default function Profile() {
  const authStatus = useAuthStatus();
  const auth = authStatus ? getAuth() : null;
    const navigate = useNavigate();
  const [changeDetail, setChangeDetail] = useState(false);
  const [loading, setLoading] = useState(true); // Add the loading state
  const [formData, setFormData] = useState({
    name: auth.currentUser.displayName,
    email: auth.currentUser.email,
    id: auth.currentUser.uid,
 });
  const { name, email, id} = formData;

  const { language, translations } = useLanguage();


  function onChange(e) {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  }

  async function onSubmit() {
    try {
      if (auth.currentUser.displayName !== name) {
        setLoading(true); // Start loading

        // Update display name in Firebase Auth
        await updateProfile(auth.currentUser, {
          displayName: name,
        });

        // Update name in Firestore
        const docRef = doc(db, "users", auth.currentUser.uid);
        await updateDoc(docRef, {
          name,
        });

        setLoading(false); // Stop loading
      }
      toast.success("Profile details updated");
    } catch (error) {
      setLoading(false); // Stop loading
      toast.error("Could not update the profile details");
    }
  }

  return (
    <>
      <section className="max-w-6xl mx-auto bg-blue-100 dark:bg-gray-700  flex justify-center items-center flex-col">

      <h1 className="text-3xl text-center mt-6 font-bold">{translations.Myprofile}          
</h1>
        <div className="w-full md:w-[50%] mt-6 px-3">
          <form>
     
          <h1 className="text-1xl mt-2 font-bold word-break dark:text-blue-100">
 ID: </h1>

            {/* Email Input */}
            <input
              type="text"
              id="id"
              value={id}
              disabled
              required

              className="mb-6 w-full px-4 py-2 text-xl word-break text-gray-700 bg-white border border-gray-600 rounded transition ease-in-out"
            />

          <h1 className="text-1xl mt-2 dark:text-blue-100  font-bold">   {translations.nomprenom}   :</h1>
            <input
              type="text"
              id="name"
              value={name}
              disabled={!changeDetail}
              onChange={onChange}
              className={`mb-6 w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-600 rounded transition ease-in-out ${
                changeDetail && "bg-blue-200 focus:bg-blue-200"
              }`}
            />
  <h1 className="text-1xl mt-2 font-bold word-break dark:text-blue-100 ">
{translations.email} </h1>
            <input
              type="email"
              id="email"
              value={email}
              disabled
              className="mb-6 w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-600 rounded transition ease-in-out"
            />

            <div className="flex justify-between whitespace-nowrap text-sm sm:text-lg mb-6">
              <p className="flex items-center">
              <button
  type="button"
  onClick={() => {
    changeDetail && onSubmit(); // Call onSubmit() instead of updateData()
    setChangeDetail((prevState) => !prevState);
  }}
  className="flex items-center bg-sky-600 text-gray-100 rounded-lg px-4 py-2 hover:bg-blue-600 transition duration-200 ml-1 cursor-pointer"
>
  <FaEdit className="mr-2" />
  {changeDetail ? translations.applychanges : translations.EditProfile}
</button>
              </p>
            
            </div>
          </form>
        </div>
      </section>
    </>
  );
}

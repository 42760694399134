import { useState, useEffect } from "react";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { toast } from "react-toastify";
import Spinner from "../components/Spinner";

import signupDoctorImage1 from "../Assets/images/signupdoctor.png";
import signupDoctorImage2 from "../Assets/images/signupdoctor02.png";
import { useLanguage } from '../components/LanguageContext'; 

export default function DoctorSignIn() {
  const [showPassword, setShowPassword] = useState(false);
  const { language, translations } = useLanguage();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const { email, password } = formData;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false); // State to track loading

  const [currentImage, setCurrentImage] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage === 1 ? 2 : 1));
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const resetCounts = () => {
      // Reset failed login attempts after 20 minutes
      const failedLoginAttempts = JSON.parse(
        localStorage.getItem("failedLoginAttempts") || "{}"
      );
      Object.keys(failedLoginAttempts).forEach((emailKey) => {
        failedLoginAttempts[emailKey] = 0; // Reset attempts to 0 for each email
      });
      localStorage.setItem(
        "failedLoginAttempts",
        JSON.stringify(failedLoginAttempts)
      );
  
      // Update the last reset time
      localStorage.setItem("lastResetTime", new Date().getTime());
    };
  
    // Check if the last reset time is available
    const lastResetTime = parseInt(localStorage.getItem("lastResetTime"));

    let timeout; // Define timeout variable

    if (lastResetTime) {
      // Calculate the elapsed time since the last reset
      const currentTime = new Date().getTime();
      const elapsedTime = currentTime - lastResetTime;
  
      // If elapsed time exceeds 20 minutes, reset the counts
      if (elapsedTime >= 20 * 60 * 1000) {
        resetCounts();
      } else {
        // Otherwise, set a timeout to reset counts after remaining time
        const remainingTime = 20 * 60 * 1000 - elapsedTime;
        timeout = setTimeout(resetCounts, remainingTime); // Assign timeout here
      }
    } else {
      // If it's the first time or last reset time is not available, reset counts
      resetCounts();
    }
  
    // Cleanup function to clear the timeout
    return () => clearTimeout(timeout);
  }, []);

  function onChange(e) {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  }

  async function onSubmit(e) {
    e.preventDefault();
    
    // Check if the user has exceeded the maximum number of login attempts
    const failedLoginAttempts = JSON.parse(
      localStorage.getItem("failedLoginAttempts") || "{}"
    );
    const emailKey = email.toLowerCase();
    const attempts = failedLoginAttempts[emailKey] || 0;
    if (attempts >= 5) {
      toast.error("Vous avez dépassé le nombre maximum de tentatives de connexion. Veuillez réessayer plus tard.");
      return;
    }

    try {
      setLoading(true); // Set loading to true during sign-in

      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      if (userCredential.user) {
        navigate("/");
      }
    } catch (error) {
      // Handle failed login attempt
      handleFailedLogin(emailKey);
      toast.error("Email ou mot de passe invalide");
    } finally {
      setLoading(false); // Set loading to false after the sign-in process
    }
  }

  function handleFailedLogin(emailKey) {
    // Increment failed login attempts for the current user
    let failedLoginAttempts = JSON.parse(
      localStorage.getItem("failedLoginAttempts") || "{}"
    );
    failedLoginAttempts[emailKey] = (failedLoginAttempts[emailKey] || 0) + 1;

    // Update localStorage with the new failed login attempts count
    localStorage.setItem(
      "failedLoginAttempts",
      JSON.stringify(failedLoginAttempts)
    );
  }

  return (
    <section className="bg-blue-200 dark:bg-gray-700 min-h-screen flex items-center justify-center">
  
    <div className="max-w-md mx-auto p-8 dark:bg-gray-500 bg-blue-100 rounded-lg shadow-lg">
      <h1 className="text-3xl text-center font-bold mb-4  dark:text-blue-300 text-blue-500">
      {translations.ProfessionalAccount}
      </h1>


    
        {currentImage === 1 ? (
        <div className="md:w-[50%] px-4 sm:w-[50%] xs:w-[50%] lg:w-[50%] mb-17 md:my-3">

          <img
            src={signupDoctorImage1}
            alt="doctor1"
            className="w-full rounded-2xl   "
          />
        </div>
      ) : (
        <div className="md:w-[20%] px-4 sm:w-[20%] xs:w-[20%] lg:w-[50%] mb-17 md:my-3">
        <img
            src={signupDoctorImage2}
            alt="doctor2"
            className="w-full rounded-2xl   "
          />
        </div>
      )}
   
   
<form onSubmit={onSubmit}>
          {/* Email input */}
          <div className="mb-6">
            <label htmlFor="email" className="text-sm dark:text-blue-100 font-semibold">
{translations.ProfessionalAccount}  :
            </label>
            <input
              type="email"
              id="email"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-blue-500"
              placeholder="Enter your email address"
              value={email}
              onChange={onChange}
            />
          </div>
          {/* Password input */}
          <div className="mb-6">
            <label htmlFor="password" className="text-sm dark:text-blue-100 font-semibold">
            {translations.password}    :
            </label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-blue-500"
                placeholder="Enter your password"
                value={password}
                onChange={onChange}
              />
              {showPassword ? (
                <AiFillEye
                  className="absolute right-3 top-3 text-xl cursor-pointer"
                  onClick={() => setShowPassword((prevState) => !prevState)}
                />
              ) : (
                <AiFillEyeInvisible
                  className="absolute right-3 top-3 text-xl cursor-pointer"
                  onClick={() => setShowPassword((prevState) => !prevState)}
                />
              )}
            </div>
          </div>

          {/* Create account link */}
          <div className="mb-3 flex items-center justify-between">
            <span className="text-gray-500 dark:text-blue-100">{translations.pasdecompte}?</span>
            <Link to="/compte-Pro" className="mx-1 text-blue-500 dark:text-blue-300 hover:underline">
              {translations.CreateAccount}
            </Link>
          </div>
          {/* Submit button */}
  {loading ? (
            <Spinner />
          ) : (
            <button
              type="submit"
              className="w-full py-2 px-4 bg-blue-500 hover:bg-blue-600 text-white rounded focus:outline-none focus:ring-blue-500 active:bg-blue-800"
              disabled={loading}
            >
              {translations.SignIn}
            </button>
          )}
        </form>
      </div>
    </section>
  );
}

import React, { useState, useEffect, Fragment  } from 'react';
import { Link } from 'react-router-dom';
import home from "../Assets/images/home.gif";
import { FaRegCheckCircle } from "react-icons/fa";
import Footer from '../components/Footer';
import { useLanguage } from '../components/LanguageContext'; 
import { IoMdArrowDropright } from "react-icons/io";


function Home() {

  const { language, translations } = useLanguage();

  return (
      <div className="bg-blue-100 py-16 dark:bg-gray-700">
      <div className="container mx-auto text-center">
        <div className="text-3xl dark:text-white md:text-4xl font-semibold mb-2">
          {translations.homeDoktori}
        </div>
        <p className="text-gray-600 dark:text-white md:text-lg mb-6">
          {translations.homeDoktorintro}
        </p>
        <Link to="/doctors-display-page">
          <button className="bg-gradient-to-r from-blue-400 to-blue-500 hover:from-sky-400 hover:to-blue-500  font-semibold  focus:outline-none focus:shadow-outline   ease-in-out transform hover:scale-102  text-white py-3 px-6 rounded-full shadow-md transition duration-300">
            {translations.homeReserveButton}
          </button>
        </Link>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="md:w-[60%] py-2 px-4 sm:w-[60%] xs:w-[60%] lg:w-[60%] mb-17 md:my-3">
            <img
              src={home}
              alt="home"
              className="w-full dark:bg-blue-100  rounded-2xl"
            />
          </div>
          {/* Benefits for Patients */}
          <div className="mt-8 overflow-x-auto">
            <table className="w-full table-auto border-collapse dark:bg-gray-300  border border-gray-200 shadow-lg bg-gray-100">
              <thead>
                <tr className="bg-gray-100 black:bg-gray-400">
                  <th className="py-2 px-4 dark:bg-gray-600  bg-blue-200 border border-gray-200"></th>
                  <th className="py-2 px-4 dark:text-white dark:bg-gray-600 bg-blue-200 border border-gray-200">
                    {translations.homewithdoktori}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="py-4 px-6 border border-gray-200">
                    <div className="flex items-center mb-2">
                      <span className="bg-blue-500 text-white rounded-full p-2 mr-3"><i className="fas fa-calendar-alt"></i></span>
                      <span className="font-semibold text-lg">{translations.homeadventagetitle1}</span>
                    </div>
                    <p className="text-gray-700">{translations.homeadventage1}</p>
                  </td>
                  <td className="py-4 px-6 border items-center border-gray-300"><FaRegCheckCircle size={30} className="transition-all items-center text-center text-blue-500 duration-300 ease-in-out transform hover:rotate-12" /></td>
                </tr>
                <tr>
                  <td className="py-4 px-6 border border-gray-300">
                    <div className="flex items-center mb-4">
                      <span className="bg-blue-500 text-white rounded-full p-2 mr-3"><i className="fas fa-calendar-alt"></i></span>
                      <span className="font-semibold">{translations.homeadventagetitle2}</span>
                    </div>
                    <p className="mt-2">{translations.homeadventage2}</p>
                  </td>
                  <td className="py-4 px-6 border items-center border-gray-300"><FaRegCheckCircle size={30} className="transition-all items-center text-center text-blue-500 duration-300 ease-in-out transform hover:rotate-12" /></td>
                </tr>
                <tr>
                  <td className="py-4 px-6 border border-gray-300">
                    <div className="flex items-center mb-4">
                      <span className="bg-blue-500 text-white rounded-full p-2 mr-3"><i className="fas fa-calendar-alt"></i></span>
                      <span className="font-semibold">{translations.homeadventagetitle3}</span>
                    </div>
                    <p className="mt-2">{translations.homeadventage3}</p>
                  </td>
                  <td className="py-4 px-6 border items-center border-gray-300"><FaRegCheckCircle size={30} className="transition-all items-center text-center text-blue-500 duration-300 ease-in-out transform hover:rotate-12" /></td>
                </tr>
                <tr>
                  <td className="py-4 px-6 border border-gray-300">
                    <div className="flex items-center mb-4">
                      <span className="bg-blue-500 text-white rounded-full p-2 mr-3"><i className="fas fa-calendar-alt"></i></span>
                      <span className="font-semibold">{translations.homeadventagetitle4}</span>
                    </div>
                    <p className="mt-2">{translations.homeadventage4}</p>
                  </td>
                  <td className="py-4 px-6 border items-center border-gray-300"><FaRegCheckCircle size={30} className="transition-all items-center text-center text-blue-500 duration-300 ease-in-out transform hover:rotate-12" /></td>
                </tr>
                <tr>
                  <td className="py-4 px-6 border border-gray-300">
                    <div className="flex items-center mb-4">
                      <span className="bg-blue-500 text-white rounded-full p-2 mr-3"><i className="fas fa-calendar-alt"></i></span>
                      <span className="font-semibold">{translations.homeadventagetitle5}</span>
                    </div>
                    <p className="mt-2">{translations.homeadventage5}</p>
                  </td>
                  <td className="py-4 px-6 border items-center border-gray-300"><FaRegCheckCircle size={30} className="transition-all items-center text-center text-blue-500 duration-300 ease-in-out transform hover:rotate-12" /></td>
                </tr>
                <tr>
                  <td className="py-4 px-6 border border-gray-300">
                    <div className="flex items-center mb-4">
                      <span className="bg-blue-500 text-white rounded-full p-2 mr-3"><i className="fas fa-calendar-alt"></i></span>
                      <span className="font-semibold">{translations.homeadventagetitle6}</span>
                    </div>
                    <p className="mt-2">{translations.homeadventage6}</p>
                  </td>
                  <td className="py-4 px-6 border items-center border-gray-300"><FaRegCheckCircle size={30} className="transition-all items-center text-center text-blue-500 duration-300 ease-in-out transform hover:rotate-12" /></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>


  );
}


export default Home;



import { useState, useEffect, useMemo, useRef } from "react";

import { collection, getDocs, doc, updateDoc, getDoc, setDoc, Timestamp } from "firebase/firestore";
import { db } from "../firebase";
import {
  getAuth
} from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../components/Spinner";
import { useAuthStatus } from "../hooks/useAuthStatus";
import MonCompte from "../pages/MonCompte";
import SigninPopup from './SigninPopup';
import CreateAnAccountPopup from '../pages/CreateAnAccountPopup';
import Select from 'react-select';
import Fuse from 'fuse.js';

import moment from 'moment';
import 'moment-timezone';

import { MdLocationOn } from "react-icons/md";
import { GiStethoscope } from "react-icons/gi";
import { MdLocationCity } from "react-icons/md";
import { BiRadioCircleMarked, BiShow  } from "react-icons/bi";
import { FiShare2 } from "react-icons/fi";
import { FaSignInAlt, FaUserPlus, FaRegArrowAltCircleLeft, FaRegArrowAltCircleRight, FaRegHospital, FaSearch, FaLaptopMedical } from "react-icons/fa";
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { BsFillCalendar2CheckFill } from 'react-icons/bs';
import { TbCalendarPlus } from'react-icons/tb'
import { FaMale, FaFemale, FaExclamationCircle, FaArrowLeft , FaRegCalendarTimes } from 'react-icons/fa';
import { TfiMore } from "react-icons/tfi";
import { BiSolidArrowFromTop } from "react-icons/bi";
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { IoMdInformationCircleOutline } from 'react-icons/io';

import { LuActivity } from "react-icons/lu";

import { useLocation } from 'react-router-dom';



import { EmailShareButton, FacebookShareButton, TwitterShareButton, WhatsappShareButton, ViberShareButton, FacebookMessengerShareButton, PinterestShareButton, RedditShareButton, TumblrShareButton, VKShareButton, WorkplaceShareButton, LineShareButton, WeiboShareButton, PocketShareButton, TelegramShareButton } from 'react-share';
import { EmailIcon, FacebookIcon, TwitterIcon, WhatsappIcon, ViberIcon, FacebookMessengerIcon, PinterestIcon, RedditIcon, TumblrIcon, VKIcon, WorkplaceIcon, LineIcon, WeiboIcon, PocketIcon, TelegramIcon } from 'react-share';
import { useLanguage } from '../components/LanguageContext'; 

const DoctorListingItem = ({ doctor }) => {
  const [rdvData, setRdvData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { language, translations } = useLanguage();
  const [shareOptionsVisible, setShareOptionsVisible] = useState(false);
  const [rdvButtonCount, setRdvButtonCount] = useState(() => {
    const storedCount = localStorage.getItem(`rdvButtonCount_${doctor.id}`);
    const lastResetTime = localStorage.getItem(`lastResetTime_${doctor.id}`);
    if (!lastResetTime || Date.now() - parseInt(lastResetTime) >= 600000) {
      return 0;
    } else {
      return parseInt(storedCount) || 0;
    }
  });
  const [doctorLinkCount, setDoctorLinkCount] = useState(() => {
    const storedCount = localStorage.getItem(`doctorLinkCount_${doctor.id}`);
    const lastResetTime = localStorage.getItem(`lastResetTime_${doctor.id}`);
    if (!lastResetTime || Date.now() - parseInt(lastResetTime) >= 600000) {
      return 0;
    } else {
      return parseInt(storedCount) || 0;
    }
  });

  useEffect(() => {
    const interval = setInterval(() => {
      localStorage.setItem(`lastResetTime_${doctor.id}`, Date.now().toString());
      setRdvButtonCount(0);
      setDoctorLinkCount(0);
    }, 300000); // Reset counts every 5 minutes (300,000 milliseconds)
    return () => clearInterval(interval);
  }, [doctor.id]);

  useEffect(() => {
    localStorage.setItem(`rdvButtonCount_${doctor.id}`, rdvButtonCount);
  }, [rdvButtonCount, doctor.id]);

  useEffect(() => {
    localStorage.setItem(`doctorLinkCount_${doctor.id}`, doctorLinkCount);
  }, [doctorLinkCount, doctor.id]);

  const fetchRdvData = async (doctorId) => {
    try {
      setLoading(true);
      const rdvRef = doc(db, "RDV", doctorId);
      const rdvDoc = await getDoc(rdvRef);
      if (rdvDoc.exists()) {
        const rdvData = rdvDoc.data();
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        // Filter data and only store filtered results
        const filteredTimeSlots = rdvData.selectedTimeSlots.reduce((filteredSlots, dayData) => {
          const dayDate = new Date(dayData.day);
          if (dayDate >= currentDate) {
            const filteredDaySlots = dayData.slots.filter((timeSlot) => timeSlot.status === "selected");
            if (filteredDaySlots.length > 0) {
              filteredSlots.push({
                day: dayData.day,
                slots: filteredDaySlots,
              });
            }
          }
          return filteredSlots;
        }, []);

        // Store only filtered data
        setRdvData(filteredTimeSlots);
        setIsPopupOpen(true);
      }
    } catch (error) {
      console.error("Error fetching RDV data:", error);
      if (error.code === "permission-denied") {
        toast.error("Vous devez d'abord ouvrir un compte pour réserver un rendez-vous.");
      }
    } finally {
      setIsPopupOpen(true); // Open the popup regardless of data availability
      setLoading(false);
    }

  };

  const generateShareableLink = (doctorId) => {
    return `${window.location.origin}/doctors/${doctorId}`;
  };

  const shareableLink = generateShareableLink(doctor.id);

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
  };

  const handleRdvButtonClick = async () => {
    if (rdvButtonCount >= 5) {
      toast.error("Réessayer plus-tard!");
      return;
    }
    setRdvButtonCount(rdvButtonCount + 1);
    fetchRdvData(doctor.id);
  };

  const handleDoctorLinkClick = () => {
    if (doctorLinkCount >= 5) {
      toast.error("Vous avez atteint la limite de clics sur ce lien pour cette période.");
      return;
    }
    setDoctorLinkCount(doctorLinkCount + 1);
  };

  return (
    <div className="max-w-screen round-md mx-auto p-4 sm:p-6 d:p-14">
      <main className="bg-white border border-sky-400 dark:bg-gray-300 p-4 md:p-8 shadow-md rounded-lg flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-8 duration-300 hover:border-blue-500 transition-transform transform-gpu hover:scale-19 z-0">
        <div className="md:flex-shrink-0">
          <div className="w-32 h-32 object-cover rounded-md overflow-hidden">
            <img
              src={doctor.imageUrl || "https://cdn.pixabay.com/photo/2020/08/03/09/39/medical-5459630_1280.png"}
              alt="Doctor's Profile"
              className="w-full h-full object-cover rounded-md"
              onError={(e) => {
                e.target.src = "https://cdn.pixabay.com/photo/2020/08/03/09/39/medical-5459630_1280.png";
              }}
            />
          </div>
          <button
            onClick={handleRdvButtonClick}
            className="mt-4 bg-gradient-to-r from-blue-400 to-blue-500 hover:from-sky-400 hover:to-blue-500 text-white text-sm font-bold py-2 px-5 rounded-lg focus:outline-none focus:shadow-outline transition-transform transform hover:scale-105"
          >
            {translations.bookappointement}
          </button>
        </div>
        <div className="flex-1 max-w-48 z-0">
          <Link to={`/doctors/${doctor.id}`} onClick={handleDoctorLinkClick}>
            <h2 className="text-2xl md:text-3xl font-semibold text-primary mb-2">
              {truncateText(doctor.doctorname || "N/A", 33)}
            </h2>
            <div className="flex flex-wrap gap-2 mb-4">
              {doctor.specialties
                ? doctor.specialties.map((specialty, index) => (
                    <span
                      key={index}
                      className="bg-blue-100 text-blue-700 px-3 py-1 rounded-full text-sm font-medium"
                    >
                      {specialty}
                    </span>
                  ))
                : "N/A"}
            </div>
            <div className="flex items-center space-x-2 mb-2">
              <FaRegHospital className="h-6 w-6 text-blue-500" />
              <div className="text-sm md:text-base break-words text-gray-600">
                <strong className="text-gray-600 text-sm break-words font-semibold">
                  {translations.cabinetcliniquehospitalname}
                </strong>
              </div>
            </div>
            <div className="text-sm text-center items-center md:text-base mt-1 bg-blue-500 border border-blue-300 break-words text-white">
              {truncateText(doctor.nomCabinet || "N/A", 33)}
            </div>
            <div className="flex items-center space-x-2">
              <MdLocationOn className="h-6 w-6 text-green-600" />
              <p className="text-sm md:text-base text-gray-600 truncate">
                {truncateText(doctor.address || "N/A", 33)}
              </p>
            </div>
           
          
            <div className="flex items-center space-x-2 mt-2 truncate">
              <MdLocationCity className="h-6 w-6 text-blue-500" />
              <div className="text-sm md:text-base text-gray-600 truncate">
                {translations.Wilaya}: {truncateText(doctor.selectedWilaya, 10)}
              </div>
              <BiRadioCircleMarked className="h-6 w-6 text-blue-500" />
              <div className="text-sm md:text-base text-gray-600 truncate">
                {translations.Daira}: {truncateText(doctor.selectedDaira, 10)}
              </div>
            </div>
          </Link>
        </div>
        <div className="md:ml-auto relative">
          <FiShare2
            size={30}
            className="text-primary hover:text-blue-500 mt-2 cursor-pointer"
            onClick={() => setShareOptionsVisible(!shareOptionsVisible)}
          />
          <div className="text-sm font-semibold text-gray-600">
            {translations.Share}
          </div>
          {shareOptionsVisible && (
            <div className="bg-white p-2 rounded-lg shadow-md flex space-x-3 space-y-2">
              <EmailShareButton url={shareableLink}>
                <EmailIcon size={23} round />
              </EmailShareButton>
              <FacebookShareButton url={shareableLink}>
                <FacebookIcon size={23} round />
              </FacebookShareButton>
              <FacebookMessengerShareButton url={shareableLink}>
                <FacebookMessengerIcon size={23} round />
              </FacebookMessengerShareButton>
              <WhatsappShareButton url={shareableLink}>
                <WhatsappIcon size={23} round />
              </WhatsappShareButton>
              <ViberShareButton url={shareableLink}>
                <ViberIcon size={23} round />
              </ViberShareButton>
              <TelegramShareButton url={shareableLink}>
                <TelegramIcon size={23} round />
              </TelegramShareButton>
              <TwitterShareButton url={shareableLink}>
                <TwitterIcon size={23} round />
              </TwitterShareButton>
            </div>
          )}
        </div>
      </main>
      {isPopupOpen && (
        <Popup
          rdvData={rdvData}
          onClose={() => setIsPopupOpen(false)}
          doctorId={doctor.id}
          doctor={doctor}
        />
      )}
    </div>
  );
};




const Popup = ({ rdvData, onClose, doctorId, doctor }) => {
 
  const { loggedIn, loading: authLoading } = useAuthStatus();
  const [currentDayIndex, setCurrentDayIndex] = useState(0);
  const [visibleTimeSlots, setVisibleTimeSlots] = useState(10);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [showPopup, setShowPopup] = useState(null); 
  const navigate = useNavigate();
  const { language, translations } = useLanguage();
  const [showErrorPopup, setShowErrorPopup] = useState(false);

  const [formData, setFormData] = useState({
    patientName: "",
    symptoms: "",
    sex: "",
typeOfConsultation: "",
    firstTimeConsulting: "",
    Age:"", 
    bloodtype:"",
    Alergies:"",
   userId:"",
   phoneNumber:"",
  });

  const [loading, setLoading] = useState(false);

  const [isAppointmentSuccessful, setAppointmentSuccess] = useState(false);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);

  const scrollableContainerRef = useRef(null);

  const handleTimeSlotClick = (day, id) => {

    if (authLoading) {
      // Display a loading indicator while checking authentication status
      setLoading(true);
    } else {
      setLoading(false);
      if (loggedIn) {

    // Find the selected time slot when it's clicked
    const selectedDayData = rdvData.find((data) => data.day === day);
    if (selectedDayData) {
      const selectedSlot = selectedDayData.slots.find((slot) => slot.id === id);
      if (selectedSlot && selectedSlot.status === "selected") {
      setSelectedTimeSlot({
        ...selectedSlot,
        day: day, // Set the day here
      });
      scrollableContainerRef.current.scrollTop = 0;

      }
    }
        } else {
    // User is not logged in, display the MonCompte component
    return <MonCompte />;
  }
}
};

  const handleInputChange = (e) => {
    // Update the form data when users enter values
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  
  const handleReserveAppointment = async () => {

      if (!formData.patientName) {
        toast.error("Veuillez saisir le nom du patient.");
        return;
      }
  
      if (!formData.Age) {
        toast.error("Veuillez saisir l'âge du patient.");
        return;
      }
  

      if (!formData.sex) {
        toast.error("Veuillez sélectionner votre Genre .");
        

        return;
      }

      if (!formData.phoneNumber) {
        toast.error("Veuillez saisir le numéro de téléphone.");
        return;
      }
  
 

    if (selectedTimeSlot && !["reserved", "reported"].includes(selectedTimeSlot.status)) {
      setLoading(true);
  
      const auth = getAuth();
      const serverTimestamp = Timestamp.fromDate(new Date());

      // Now, store the reservation data in RDVofuser collection with the user's UID as the document title
      const user = auth.currentUser; 
      const userUID = user.uid;

      const rdvRef = doc(db, "RDV", doctorId);
      const rdvDoc = await getDoc(rdvRef);
  
  
      if (rdvDoc.exists()) {
        const rdvData = rdvDoc.data();
  
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
  
        const filteredTimeSlots = rdvData.selectedTimeSlots.reduce((filteredSlots, dayData) => {
          const dayDate = new Date(dayData.day);
  
          if (dayDate >= currentDate) {
            const filteredDaySlots = dayData.slots.filter((timeSlot) => {
              return timeSlot.status === "selected";
            });
  
            if (filteredDaySlots.length > 0) {
              filteredSlots.push({
                day: dayData.day,
                slots: filteredDaySlots,
              });
            }
          }
  
          return filteredSlots;
        }, []);
  
  
        const isSlotIncluded = filteredTimeSlots.some(dayData => {
         return dayData.day === selectedTimeSlot.day && dayData.slots.some(slot => slot.id === selectedTimeSlot.id);
        });
        
        if (isSlotIncluded) {
          // Create a copy of rdvData to modify
          const updatedRdvData = [...rdvData.selectedTimeSlots];
          // Find the dayData corresponding to selectedTimeSlot's day
          const dayDataToUpdate = updatedRdvData.find(dayData => dayData.day === selectedTimeSlot.day);
          // Find the selected time slot within dayDataToUpdate
          const slotToUpdate = dayDataToUpdate.slots.find(slot => slot.id === selectedTimeSlot.id);
  

        if (isSlotIncluded) {
          slotToUpdate.status = "reserved";
          slotToUpdate.patientName = formData.patientName;
          slotToUpdate.symptoms = formData.symptoms;
          slotToUpdate.sex = formData.sex;
          slotToUpdate.typeOfConsultation = formData.typeOfConsultation;
          slotToUpdate.firstTimeConsulting = formData.firstTimeConsulting;
          slotToUpdate.Age = formData.Age;
          slotToUpdate.bloodtype = formData.bloodtype;
          slotToUpdate.Alergies = formData.Alergies;
          slotToUpdate.userId = user.uid;
          slotToUpdate.phoneNumber = formData.phoneNumber;
        

          try {
            const rdvRef = doc(db, "RDV", doctorId);
            await updateDoc(rdvRef, { selectedTimeSlots: updatedRdvData });
           
            const userRdvRef = doc(db, "RDVofuser", userUID);
          
            // Create a data object for the selected time slot in the user's document
            const userDocData = {
              [selectedTimeSlot.day]: {
                [selectedTimeSlot.id]: {
                  status: "reserved",
                  patientName: formData.patientName,
                  symptoms: formData.symptoms,
                  sex: formData.sex,
                  typeOfConsultation: formData.typeOfConsultation,
                  firstTimeConsulting: formData.firstTimeConsulting,
                  Age: formData.Age,
                  bloodtype: formData.bloodtype,
                  Alergies: formData.Alergies,
                 
                  phoneNumber: formData.phoneNumber,
                 
                  userId: userUID,
                  serverTimestamp,
                },
              },
            };
          
            // Update the user's document with the selected time slot data
            await setDoc(userRdvRef, userDocData, { merge: true });
          

            setAppointmentSuccess(true); 
            setConfirmationOpen(false); 



            setFormData({
              patientName: "",
              symptoms: "",
              sex: "",
              typeOfConsultation: "",
              firstTimeConsulting: "",
              Age: "",
              bloodtype: "",
              Alergies: "",
              userId: "",
              phoneNumber: "",
            });
            



          } catch (error) {
            console.error("Error updating time slot:", error);
            toast.error("Une erreur s'est produite lors de la mise à jour du créneau horaire.");
            setLoading(false);

          }
        } else {
          console.error("SUne erreur s'est produite lors de la mise à jour du créneau horaire.");
          setLoading(false);

        }
      } else {
        console.error("The selected time slot is not included. Please check the values.");
        toast.error("Le créneau horaire sélectionné n'est plus disponible. Veuille choisir un autre créneau.");
        setLoading(false);
        setShowErrorPopup(true);
        setConfirmationOpen(false);
      }
    } else {
      toast.error("Une erreur s'est produite lors de la mise à jour du créneau horaire.");
      setLoading(false);

    }

    setLoading(false);
  } else {
    toast.error("Le créneau horaire sélectionné n'est plus disponible. Veuille choisir un autre créneau.");
    setLoading(false);
        setShowErrorPopup(true);
        setConfirmationOpen(false);
      }
};

  
  

  const handlePreviousDay = () => {
    setCurrentDayIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    setVisibleTimeSlots(10); // Reset visible time slots when changing the day
  };

  const handleNextDay = () => {
    if (currentDayIndex < rdvData.length - 1) {
      setCurrentDayIndex((prevIndex) => prevIndex + 1);
      setVisibleTimeSlots(10); // Reset visible time slots when changing the day
    } else {
      toast.error("Le docteur n'a pas prévu de rendez-vous ultérieurs à cette date");
    }
  };

       
  const handleGoBack = () => {
    // Clear the selected time slot and return to the time slot view
    setSelectedTimeSlot(null);
  };

  const consultationOptions = [
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
    // Add more options as needed
  ];

  const sexOptions = [
    { value: 'Masculin', icon: <FaMale className="ml-2 text-blue-400" />, label: translations.Masculin },
    { value: 'Feminin', icon: <FaFemale className="ml-2 text-pink-400" />, label: translations.Feminin },
    { value: 'autre', icon: null, label: translations.autre }, // You can replace null with the icon for 'autre'
  ];
  

  const handleSelectChange = (fieldName, selectedOption) => {
    const updatedFormData = { ...formData, [fieldName]: selectedOption.value };
    setFormData(updatedFormData);
  };

  const handleCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
    const updatedFormData = {
      ...formData,
      [name]: checked ? value : '', // Use empty string when unchecked
    };
    setFormData(updatedFormData);
  };

  
  const bloodTypes = ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'];
const noTimeSlotsAvailable = !rdvData[currentDayIndex]?.slots || rdvData[currentDayIndex]?.slots.length === 0;



// Assuming rdvData is an array of objects containing day and slots
// Set the timezone to Algeria

const [delayedContentVisible, setDelayedContentVisible] = useState(false);

useEffect(() => {
  const delayTimeout = setTimeout(() => {
    setDelayedContentVisible(true);
  }, 1200);

  return () => clearTimeout(delayTimeout); // Clear the timeout if the component unmounts or if it re-renders
}, []);


moment.tz.setDefault('Africa/Algiers');
const currentDate = moment().tz('Africa/Algiers');
const currentHour = currentDate.format('HH:mm');
const formattedDate = () => {
  const date = moment(filteredRdvData[currentDayIndex]?.day);
  return date.format('dddd, D MMMM YYYY');
};

// Filter out days with no available time slots.
const filteredRdvData = rdvData.filter((dayData) => {
  const dayDate = moment(dayData.day);
  if (dayDate.isAfter(currentDate, 'day')) {
    return true; // Include future days
  } else if (dayDate.isSame(currentDate, 'day')) {
    const futureTimeSlots = dayData.slots.filter((timeSlot) => moment(`${dayData.day} ${timeSlot.id}`,'dddd D HH:mm') > currentDate);
    return futureTimeSlots.length > 0 || dayData.slots.length === 0; // Include the day if there are future time slots or if there are no time slots at all
  }
  return false; // Exclude past days
});






const daySelectorRef = useRef(null);

// Scroll to the current day whenever currentDayIndex changes
useEffect(() => {
  scrollToCurrentDay();
}, [currentDayIndex]);

const scrollToCurrentDay = () => {
  if (daySelectorRef.current) {
    const selectedButton = daySelectorRef.current.children[currentDayIndex];
    if (selectedButton) {
      selectedButton.scrollIntoView({ behavior: 'smooth', inline: 'center' });
    }
  }
};

const handleNextMonth = () => {
  const currentDay = moment(filteredRdvData[currentDayIndex].day);
  const currentMonth = currentDay.month();

  const nextMonthIndex = filteredRdvData.findIndex((dayData) => {
    const dayMoment = moment(dayData.day);
    return dayMoment.month() > currentMonth || dayMoment.year() > currentDay.year();
  });

  if (nextMonthIndex !== -1) {
    setCurrentDayIndex(nextMonthIndex);
  } else {
    toast.info('Aucun créneau disponible pour d\'autres mois .');
  }
};

const handlePreviousMonth = () => {
  const currentDay = moment(filteredRdvData[currentDayIndex].day);
  const currentMonth = currentDay.month();

  const previousMonthIndex =
    [...filteredRdvData]
      .reverse()
      .findIndex((dayData) => {
        const dayMoment = moment(dayData.day);
        return (
          dayMoment.month() < currentMonth || dayMoment.year() < currentDay.year()
        );
      });

  if (previousMonthIndex !== -1) {
    const adjustedIndex = filteredRdvData.length - 1 - previousMonthIndex;
    setCurrentDayIndex(adjustedIndex);
  } else {
    toast.info('');
  }
};




  return (
    <div className="fixed inset-0 flex items-center justify-center py-70 bg-black bg-opacity-50 z-10">
    <div  className="w-full max-w-screen-lg p-4 bg-gray-100 dark:bg-gray-700 rounded-lg shadow-lg overflow-hidden z-10 ">
      <div ref={scrollableContainerRef}  className=" flex-shrink-0 w-full max-w-3xl mx-auto py-8 sm:p-4  bg-gray-100 dark:bg-gray-600 rounded-lg P-10 shadow-lg  max-h-96  overflow-y-scroll mt-10 z-11 " > 
      
        {loading ? (
          <Spinner />
        ) : loggedIn ? ( isAppointmentSuccessful ? (
          <div className="flex flex-col items-center justify-center text-center p-8 bg-white rounded-lg shadow-md ">
  <div className="text-lg text-gray-800">
  {translations.booksuccess1}{' '}
  <span className="text-blue-500 font-semibold">
    {moment(selectedTimeSlot.day).format('dddd, DD MMMM YYYY')} à {moment(selectedTimeSlot.id, 'dddd DD HH:mm').format('HH:mm')}
  </span>{' '}
  {translations.booksuccess2}{' '}
  <span className="text-blue-500 font-semibold">{doctor.doctorname}</span>{translations.booksuccess3}
</div>
{translations.booksuccess4}

        </div>

          ) : selectedTimeSlot ? (
       
  
               
    
      
      <div>
      <button
        onClick={handleGoBack}
        className="text-blue-500 dark:text-blue-300 hover:text-blue-700 font-semibold mb-2 mt-6 flex items-center sticky "
      >
        <AiOutlineArrowLeft className="mr-2" />          {translations.retourbutton}

      </button>

                    <div className="text-md font-semibold   dark:text-gray-100  align-middle text-gray-800"> {selectedTimeSlot.day}  </div>
                    <div className="text-md max-w-full  dark:text-gray-100 text-center align-middle overflow-hidden whitespace-nowrap overflow-ellipsis font-semibold text-gray-500">
                    {selectedTimeSlot.id} 
               </div>
            


      
    
      
               <div id="formContainer" className="flex-shrink-0 w-full max-w-3xl mx-auto p-2 sm:p-4 bg-blue-100 dark:bg-gray-600 rounded shadow-md sm:mt-8  ">
   

      <div className="grid grid-cols-1  dark:bg-gray-700  md:grid-cols-2 lg:grid-cols-3 gap-4 z-5">
        
        <div>
          <label htmlFor="patientName" className="text-sm dark:text-gray-100 font-semibold">
        *{translations.NometPrenomduPatient}
          </label>
          <input
            type="text"
            name="patientName"
            id="patientName"
            placeholder="Saisissez le nom du patient"
            value={formData.patientName}
            onChange={handleInputChange}
            required
            className="w-full rounded-lg px-4 py-2 border border-sky-500 focus:outline-none"
          />

<label htmlFor="sex" className="text-sm dark:text-gray-100 font-semibold">
 *{translations.checkbox}

</label>
<div className="flex flex-wrap mt-2 border bg- border-sky-500 justify-center ">
  {sexOptions.map((option) => (
    <label key={option.value} className="flex dark:text-gray-100 items-center mx-2 mb-2">
      <input
        type="checkbox"
        name="sex"
        value={option.value}
        checked={formData.sex === option.value}
        required
        onChange={handleCheckboxChange}
      />
      {option.icon} {option.label}
    </label>
  ))}
</div>

        </div>
        <div>
        
          <label htmlFor="Age" className="text-sm dark:text-gray-100 font-semibold">
         * {translations.Âgedupatient}
          </label>
          <input
            type="text"
            name="Age"
            id="Age"
            placeholder="Saisissez l'âge"
            value={formData.Age}
            onChange={handleInputChange}
            min="0"
            maxLength="50"
            required
            className="w-full rounded-lg px-4 py-2 border  border-sky-500  focus:outline-none"
          />

<label htmlFor="phoneNumber" className="text-sm dark:text-gray-100 font-semibold">
*{translations.NumerodeTelephone}
          </label>
          <input
            type="number"
            name="phoneNumber"
            id="phoneNumber"
            min="0"
            maxLength="30"

            required
            placeholder="Indiquez le numero de telephone "
            value={formData.phoneNumber}
            onChange={handleInputChange}
            className="w-full rounded-lg px-4 py-2 border border-sky-500 focus:outline-none"
          />


        </div>
      

       
      </div>
      <div className="flex flex-wrap mt-2 font-semibold text-1xl text-center dark:text-blue-100 text-gray-600 border bg- border-sky-500 justify-center ">
      *{translations.Informationscomplémentaires }

<BiSolidArrowFromTop  size={30} className="mr-2 text-xl dark:text-blue-100 transition-all duration-300 ease-in-out transform hover:rotate-12 " />

</div>

        <div>
<label htmlFor="bloodtype" className="text-sm dark:text-gray-100 font-semibold">
{translations.GroupeSanguin}

</label>
<div className="flex flex-wrap justify-center">
{bloodTypes.map((type) => (
<label key={type} className="flex dark:text-gray-100 items-center mx-2 mb-2">
<input
type="checkbox"
required
name="bloodtype"
value={type}
checked={formData.bloodtype === type}
onChange={handleCheckboxChange}
/>
{type}
</label>
))}

</div>

</div>

<div>

  <label htmlFor="symptoms" className="text-sm dark:text-gray-100 font-semibold">
   {translations.Symptômes}

  </label>
  <textarea
    name="symptoms"
    id="symptoms"
    placeholder="Décrivez ce que vous resentez actuellement "
    value={formData.symptoms}
    maxLength="1000"

    onChange={handleInputChange}
    className="w-full rounded-lg px-4 py-2 h-32 overflow-y-scroll border  border-sky-500  focus:outline-none"
  />
</div>
<div>

  <label htmlFor="Alergies" className="text-sm dark:text-gray-100 font-semibold">
    {translations.Allergies}
  </label>
  <input
    type="text"
    name="Alergies"
    id="Alergies"
    maxLength="300" 

    placeholder="Indiquez les allergies (le cas échéant)"
    value={formData.Alergies}
    onChange={handleInputChange}
    className="w-full rounded-lg px-4 py-2 border border-sky-500  focus:outline-none"
  />
      </div>

      </div>

    </div> ) : (
    <>
    <div
      className="p-6 text-gray-800 dark:text-gray-200 rounded-xl shadow-lg space-y-4 border border-blue-300"
    >
    {/* Month Navigation Buttons */}
<div className="flex justify-center space-x-2">
  <button
    onClick={handlePreviousMonth}
    className={`bg-gradient-to-r from-blue-400 to-blue-500 hover:from-sky-400 hover:to-blue-500 text-white font-semibold py-1 px-3 rounded-md focus:outline-none focus:shadow-outline flex items-center transition-all duration-300 transform hover:scale-105 text-sm ${
      currentDayIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''
    }`}
    disabled={currentDayIndex === 0}
  >
          {translations.PreviousMonth }

  </button>

  <button
    onClick={handleNextMonth}
    className={`bg-gradient-to-r from-blue-400 to-blue-500 hover:from-sky-400 hover:to-blue-500 text-white font-semibold py-1 px-3 rounded-md focus:outline-none focus:shadow-outline flex items-center transition-all duration-300 transform hover:scale-105 text-sm ${
      currentDayIndex >= filteredRdvData.length - 1
        ? 'opacity-50 cursor-not-allowed'
        : ''
    }`}
    disabled={currentDayIndex >= filteredRdvData.length - 1}
  >
        {translations.NextMonth}

  </button>
</div>

  
      {/* Header with Previous and Next Day Buttons */}
      <div className="flex  dark:text-gray-100  dark:bg-gray-500 bg-blue-100 justify-between items-center mb-4">
      <button
          onClick={() => {
            handlePreviousDay();
            scrollToCurrentDay();
          }}
       
          disabled={currentDayIndex === 0}
        >
          <FaRegArrowAltCircleLeft size={30} className="ml-1 text-sky-600 hover:text-sky-700 dark:text-blue-100 font-semibold transition-all duration-300 ease-in-out transform hover:rotate-12" />
          
          </button>
  
        <div className="text-xl font-semibold text-center text-gray-900 dark:text-gray-100">
          {formattedDate()}
        </div>
  
        <button
          onClick={() => {
            handleNextDay();
            scrollToCurrentDay();
          }}
         
          disabled={currentDayIndex === filteredRdvData.length - 1}
        >
          <FaRegArrowAltCircleRight size={30} className="ml-1 text-sky-600 hover:text-sky-700 dark:text-blue-100 font-semibold transition-all duration-300 ease-in-out transform hover:rotate-12" />
          
        </button>
      </div>
  
      {/* Horizontal Scrollable Day Selector */}
      <div
        ref={daySelectorRef}
        className="flex overflow-x-auto space-x-2 border-b border-gray-300 pb-2 mt-2"
      >
        {filteredRdvData.map((dayData, index) => (
          <button
            key={dayData.day}
            onClick={() => {
              setCurrentDayIndex(index);
              scrollToCurrentDay();
            }}
            className={`whitespace-nowrap px-3 py-2 text-sm rounded-md ${
              index === currentDayIndex
                ? 'bg-blue-500 text-white'
                : 'bg-blue-200 text-gray-600 hover:bg-blue-300'
            } transition-all duration-200 ease-in-out transform hover:scale-105`}
          >
            {`${moment(dayData.day).format('ddd.DD MMM')}`}
          </button>
        ))}
      </div>
  
      {/* Time Slots Grid */}
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
        {filteredRdvData[currentDayIndex]?.slots.length > 0 ? (
          filteredRdvData[currentDayIndex].slots
            .map((timeSlot) => {
              const [_, __, time] = timeSlot.id.split(' '); // Extract time
              const timeSlotDateTime = moment(
                `${filteredRdvData[currentDayIndex].day} ${time}`,
                'YYYY-MM-DD HH:mm'
              ).tz('Africa/Algiers');
              const isAfterCurrentTime = timeSlotDateTime > currentDate;
              return { ...timeSlot, isAfterCurrentTime };
            })
            .filter((timeSlot) => timeSlot.isAfterCurrentTime)
            .map((timeSlot) => (
              <button
                key={timeSlot.uniqueid}
                className={`bg-blue-100 text-gray-800  rounded-lg cursor-pointer p-3 text-center hover:bg-blue-200 transition-all`}
                onClick={() =>
                  timeSlot.status === 'selected' &&
                  handleTimeSlotClick(
                    filteredRdvData[currentDayIndex].day,
                    timeSlot.id
                  )
                }
              >
                {timeSlot.id.split(' ')[2]}
              </button>
            ))
        ) : (
          <div className="col-span-full text-center dark:text-gray-200 text-gray-900">
            {translations.notimeslot}
          </div>
        )}
      </div>
    </div>
  </>
          )
        ) :   delayedContentVisible &&   (
        <div className="mt-4 text-center word-break">
          <h1 className="text-1xl font-semibold text-gray-800 mb-4"> 
          {translations.securityraisons}
  </h1>
      
      

          <div className="flex flex-col items-center space-y-2">
            {showPopup ? (
              <button
                onClick={() => setShowPopup(null)}
                className="bg-blue-500 hover:bg-blue-700 text-white rounded-full px-6 py-3 font-semibold cursor-pointer transition-all duration-300 flex items-center"
              >
                <FaArrowLeft className="mr-2 " />  {translations.retourbutton}
              </button>
            ) : delayedContentVisible &&  (
               (
                <div className="space-y-4">
                  <button
                    onClick={() => setShowPopup('signIn')}
                    className="bg-blue-500 hover:bg-blue-700 text-white rounded-full px-6 py-3 font-semibold cursor-pointer transition-all duration-300 flex items-center"
                  >
                    <FaSignInAlt className="mr-2" />  {translations.SignIn}
                  </button>
                  <button
                    onClick={() => setShowPopup('createAccount')}
                    className="bg-green-500 hover:bg-green-700 text-white rounded-full px-6 py-3 font-semibold cursor-pointer transition-all duration-300 flex items-center"
                  >
                    <FaUserPlus className="mr-2" />  {translations.CreateAccount}
                  </button>
                </div>
              )
            )}  
            </div>
                
          {showPopup === 'signIn' && (
        <SigninPopup onClose={() => setShowPopup(null)} />
      )}

      {showPopup === 'createAccount' && (
        <CreateAnAccountPopup onClose={() => setShowPopup(null)} />
      )}
        </div>
      )}


    </div>



      {selectedTimeSlot && !isAppointmentSuccessful && (
  <button
    onClick={() => setConfirmationOpen(true)} // Open the confirmation modal
    className="w-full bg-blue-500 text-white rounded-lg px-4 py-2 mt-7 font-semibold hover-bg-blue-700 cursor-pointer flex items-center justify-center"
  >
    <span className="mr-2">
      <TbCalendarPlus className="text-xl" />
    </span>

    {translations.Réserver}
  </button>
)}



<div > 


{showErrorPopup && (
  <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
    <div className="bg-white p-4 rounded-lg w-80 text-center shadow-lg relative">
    
      <div className="pt-8 pb-4">
      <div className="absolute top-6 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-red-600 text-3xl">
        <AiOutlineCloseCircle size={40} />
      </div>
        <div className="text-gray-800 font-semibold text-lg mb-4">
        
          <p>
          {translations.errorappointementbooking}            <br />
          {translations.errorappointementbooking2}          </p>
        </div>
        <button
          onClick={() => {
            handleGoBack();
            setShowErrorPopup(false);
            setConfirmationOpen(false);
          }}
          className="text-blue-500 dark:text-blue-300 hover:text-blue-700 font-semibold mb-2 mt-6 flex items-center"
        >
          <AiOutlineArrowLeft className="mr-2" /> {translations.retourbutton}
        </button>
      </div>
    </div>
  </div>
)}




{isConfirmationOpen && (
 <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 "> {/* Increase z-index for the confirmation popup backdrop */}
 <div className="bg-white p-4 rounded-lg w-80 text-center shadow-lg "> {/* Increase z-index for the confirmation popup */}

         <div className="text-gray-800 font-semibold text-lg mb-4 ">
         {translations.confirmeRéserver}
     <div className=" bg-blue-400 h-18 py-2 text-center rounded-lg text-white text-lg mb-4">{doctor.doctorname} </div>       

         </div>   
         <div className="text-center border border-sky-400 space-x-2 " >
  
         <p className="text-gray-700 text-md ">{selectedTimeSlot.day}
         </p>
         <p className="text-gray-600 text-md ">{selectedTimeSlot.id}
         </p>
         </div>

   <div className="flex iteme-center justify-end p-2">

     <button  className="text-blue-500 hover:text-blue-700 px-4 py-2 rounded-md border border-blue-500 hover:bg-red-100 focus:outline-none"
          onClick={handleReserveAppointment}
          >
   {translations.oui}     
     </button>
     <button
             className="text-gray-500 hover:text-gray-700 px-4 py-2 ml-4 rounded-md border border-gray-500 hover:bg-gray-100 focus:outline-none"
             onClick={() => setConfirmationOpen(false)}
     >
       {translations.close}     
   
     </button>
   </div>
 </div>
</div>
)}



</div>



      {isAppointmentSuccessful && (
         <button
         onClick={() => navigate('/mes-rdv')}
         className="w-full bg-blue-500 text-white rounded-full  px-6 py-3 mt-8 pb-4 font-semibold hover:bg-blue-700 hover:shadow-md cursor-pointer transition-all duration-300 focus:outline-none focus:ring focus:border-blue-300"
       > 
               {translations.appointementunderstood}

      
       </button>
        )}    
{!isAppointmentSuccessful && (
          // Render the "Close" button when isAppointmentSuccessful is falsy
          <button
            onClick={onClose}
            className="mt-7 w-full md:w-auto bg-gray-800  text-blue-100 dark:bg-blue-200 dark:text-gray-700 py-1 px-2  rounded hover:bg-gray-600 transition duration-300 text-sm"
          >
                      {translations.close}
       
          </button>
        )}


    </div>
    </div>
  );
};










const DoctorsDisplayPage = () => {
  const [doctors, setDoctors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');

  const [selectedWilayas, setSelectedWilayas] = useState([]); // Use an array for multiple selections
  const [selectedDairas, setSelectedDairas] = useState([]); // Use an array for multiple selections
  const [selectedSpecialties, setSelectedSpecialties] = useState([]);
  const [filteredDoctors, setFilteredDoctors] = useState([]);

  const { language, translations } = useLanguage();

  // Define a Fuse instance for flexible searching
  const fuse = useMemo(() => {
    return new Fuse(doctors, {
      keys: [
        'data.address',
        'data.doctorname',
        'data.nomCabinet',
        'data.zipCode',
        'data.selectedWilaya',
        'data.selectedDaira',
        'data.specialties',
      ],
      includeScore: true,
      threshold: 0.8,
    });
  }, [doctors]);
  

  useEffect(() => {
    const fetchDoctors = async () => {
      try {
        const alldoctorsRef = doc(db, 'alldoctors', 'all-doctors-document');
        const alldoctorsSnapshot = await getDoc(alldoctorsRef);
  
        if (alldoctorsSnapshot.exists()) {
          const allDoctorsData = alldoctorsSnapshot.data();
  
          // Extract an array of doctor data objects with active=true
          const activeDoctorsData = Object.keys(allDoctorsData)
            .filter(userId => allDoctorsData[userId].active === true)
            .map((userId) => ({
              id: userId,
              data: allDoctorsData[userId],
            }));
  
          setDoctors(activeDoctorsData);
        }
  
        setLoading(false);
      } catch (error) {
        console.error('Error fetching doctors:', error);
      }
    };
  
    fetchDoctors();
  }, []);
  
  useEffect(() => {
    // Filter doctors based on selected options and search query
    let updatedDoctors = doctors;

    if (searchQuery) {
      const searchResults = fuse.search(searchQuery);
      updatedDoctors = searchResults.map((result) => result.item);
    }

    if (selectedWilayas.length > 0) {
      updatedDoctors = updatedDoctors.filter((doctor) =>
        selectedWilayas.some((wilaya) => doctor.data.selectedWilaya === wilaya.value)
      );
    }

    if (selectedDairas.length > 0) {
      updatedDoctors = updatedDoctors.filter((doctor) =>
        selectedDairas.some((daira) => doctor.data.selectedDaira === daira.value)
      );
    }

    if (selectedSpecialties.length > 0) {
      updatedDoctors = updatedDoctors.filter((doctor) =>
        selectedSpecialties.every((spec) => doctor.data.specialties.includes(spec.value))
      );
    }

    setFilteredDoctors(updatedDoctors);
  }, [doctors, selectedWilayas, selectedDairas, selectedSpecialties, searchQuery, fuse ]);

  // Define options for wilayas, dairas, and specialties
  const wilayaOptions = [
    { label: 'Tizi Ouzou', value: 'Tizi Ouzou' },
        { label: 'Alger', value: 'Alger' },
        { label: 'Oran', value: 'Oran' },
        { label: 'Constantine', value: 'Constantine' },
        { label: 'Tipaza', value: 'Tipaza' },
        { label: 'Sétif', value: 'Sétif' },
        { label: 'Boumerdès', value: 'Boumerdès' },

        { label: 'Médéa', value: 'Médéa' },
        { label: 'Mostaganem', value: 'Mostaganem' },
          { label: 'Adrar', value: 'Adrar' },
          { label: 'Chlef', value: 'Chlef' },
          { label: 'Laghouat', value: 'Laghouat' },
          { label: 'Oum El Bouaghi', value: 'Oum El Bouaghi' },
          { label: 'Batna', value: 'Batna' },
          { label: 'Béjaïa', value: 'Béjaïa' },
        
        
        
          { label: 'Biskra', value: 'Biskra' },
          { label: 'Béchar', value: 'Béchar' },
          { label: 'Blida', value: 'Blida' },
          { label: 'Bouira', value: 'Bouira' },
          { label: 'Tamanrasset', value: 'Tamanrasset' },
          { label: 'Tebessa', value: 'Tebessa' },
          { label: 'Tlemcen', value: 'Tlemcen' },
          { label: 'Tiaret', value: 'Tiaret' },
         

          { label: 'Djelfa', value: 'Djelfa' },
          { label: 'Jijel', value: 'Jijel' },
          { label: 'Saida', value: 'Saida' },
          { label: 'Skikda', value: 'Skikda' },
          { label: 'Sidi-Bel-Abbès', value: 'Sidi-Bel-Abbès' },
          { label: 'Annaba', value: 'Annaba' },
          { label: 'Guelma', value: 'Guelma' },
     { label: 'Msila', value: 'Msila' },
          { label: 'Mascara', value: 'Mascara' },



          { label: 'El-Bayadh', value: 'El-Bayadh' },
          { label: 'Illizi', value: 'Illizi' },
          { label: 'Bordj-Bou-Arreridj', value: 'Bordj-Bou-Arreridj' },
          { label: 'El-Tarf', value: 'El-Tarf' },
          { label: 'Tindouf', value: 'Tindouf' },
          { label: 'Tissemsilt', value: 'Tissemsilt' },
          { label: 'El-Oued', value: 'El-Oued' },
          { label: 'Khenchela', value: 'Khenchela' },
          { label: 'Souk-Ahras', value: 'Souk-Ahras' },
          { label: 'Mila', value: 'Mila' },
          { label: 'Aïn-Defla', value: 'Aïn-Defla' },
          { label: 'Naâma', value: 'Naâma' },
          { label: 'Aïn-Témouchent', value: 'Aïn-Témouchent' },
          { label: 'Ghardaia', value: 'Ghardaia' },
         
         
          { label: 'Relizane', value: 'Relizane' },
          { label: 'Timimoun', value: 'Timimoun' },
          { label: 'Bordj Badji Mokhtar', value: 'Bordj Badji Mokhtar' },
          { label: 'Ouled Djellal', value: 'Ouled Djellal' },
          { label: 'Béni Abbès', value: 'Béni Abbès' },
          { label: 'In Salah', value: 'In Salah' },
          { label: 'In Guezzam', value: 'In Guezzam' },
          { label: 'Touggourt', value: 'Touggourt' },
          { label: 'Djanet', value: 'Djanet' },
          { label: 'El Mghair', value: 'El Mghair' },
          { label: 'El Meniaa', value: 'El Meniaa' }
          // Add more wilayas as needed
  ];

  const defaultDairaOption = [
    { label: 'Boghni', value: 'Boghni' },
    { label: 'Ouadhias / Iwadhiyen', value: 'Ouadhias / Iwadhiyen' },
    { label: 'Tigzirt', value: 'Tigzirt' },
    { label: 'Tizi Ouzou', value: 'Tizi Ouzou' },
    { label: 'Draa El Mizan', value: 'Draa El Mizan' },

    { label: 'Ain El Hammam', value: 'Ain El Hammam' },
    { label: 'Azazga', value: 'Azazga' },
    { label: 'Azeffoun', value: 'Azeffoun' },
    { label: 'Beni Douala', value: 'Beni Douala' },
    { label: 'Ath Dwala', value: 'Ath Dwala' },
    { label: 'Beni-yenni', value: 'Beni-yenni' },
    { label: 'Ath Yenni', value: 'Ath Yenni' },
    { label: 'Bouzeguene', value: 'Bouzeguene' },
    { label: 'Draa Ben Khedda', value: 'Draa Ben Khedda' },
    { label: 'Iferhounène', value: 'Iferhounène' },
    { label: 'Larbaa Nath Irathen', value: 'Larbaa Nath Irathen' },
    { label: 'Maatkas', value: 'Maatkas' },
    { label: 'Makouda', value: 'Makouda' },
    { label: 'Mekla', value: 'Mekla' },
    { label: 'Ouacifs', value: 'Ouacifs' },
    { label: 'Ouaguenoun', value: 'Ouaguenoun' },
    { label: 'Tizi Ghenif', value: 'Tizi Ghenif' },
    { label: 'Tizi-Rached', value: 'Tizi-Rached' },
    { label: 'Zéralda', value: 'Zéralda' },
    { label: 'Chéraga', value: 'Chéraga' },
    { label: 'Draria', value: 'Draria' },
    { label: 'Bir Mourad Raïs', value: 'Bir Mourad Raïs' },
    { label: 'Birtouta', value: 'Birtouta' },
    { label: 'Bouzareah', value: 'Bouzareah' },
    { label: 'Bab El Oued', value: 'Bab El Oued' },
    { label: 'Sidi Mhamed', value: 'Sidi Mhamed' },
    { label: 'Hussein Dey', value: 'Hussein Dey' },
    { label: 'El Harrach', value: 'El Harrach' },
    { label: 'Baraki', value: 'Baraki' },
    { label: 'Dar El Beïda', value: 'Dar El Beïda' },
    { label: 'Rouïba', value: 'Rouïba' },
    { label: 'Oran', value: 'Oran' },

    { label: 'Aïn El Turk', value: 'Aïn El Turk' },
    { label: 'Arzew', value: 'Arzew' },
    { label: 'Bethioua', value: 'Bethioua' },
    { label: 'Es Sénia', value: 'Es Sénia' },
    { label: 'Bir El Djir', value: 'Bir El Djir' },
    { label: 'Boutlélis', value: 'Boutlélis' },
    { label: 'Oued Tlelat', value: 'Oued Tlelat' },
    { label: 'Gdyel', value: 'Gdyel' },
    { label: 'Constantine', value: 'Constantine' },

    { label: 'El Khroub', value: 'El Khroub' },
    { label: 'Aïn Abid', value: 'Aïn Abid' },
    { label: 'Zighoud Youcef', value: 'Zighoud Youcef' },
    { label: 'Hamma Bouziane', value: 'Hamma Bouziane' },
    { label: 'Ibn Ziad', value: 'Ibn Ziad' },
    { label: 'Ahmar El Ain', value: 'Ahmar El Ain' },
    { label: 'Bou Ismail', value: 'Bou Ismail' },
    { label: 'Cherchell', value: 'Cherchell' },
    { label: 'Damous', value: 'Damous' },
    { label: 'Fouka', value: 'Fouka' },
    { label: 'Gouraya', value: 'Gouraya' },
    { label: 'Hadjout', value: 'Hadjout' },
    { label: 'Koléa', value: 'Koléa' },
    { label: 'Sidi Amar', value: 'Sidi Amar' },
    { label: 'Tipaza', value: 'Tipaza' },
    { label: 'Sétif', value: 'Sétif' },

    { label: 'Aïn Arnat', value: 'Aïn Arnat' },
      { label: 'Aïn Azel', value: 'Aïn Azel' },
      { label: 'Aïn El Kebira', value: 'Aïn El Kebira' },
      { label: 'Aïn Oulmene', value: 'Aïn Oulmene' },
      { label: 'Amoucha', value: 'Amoucha' },
      { label: 'Babor', value: 'Babor' },
      { label: 'Beni Aziz', value: 'Beni Aziz' },
      { label: 'Beni Ourtilane', value: 'Beni Ourtilane' },
      { label: 'Bir El Arch', value: 'Bir El Arch' },
      { label: 'Bouandas', value: 'Bouandas' },
      { label: 'Bougaa', value: 'Bougaa' },
      { label: 'Djemila', value: 'Djemila' },
      { label: 'El Eulma', value: 'El Eulma' },
      { label: 'Guidjel', value: 'Guidjel' },
      { label: 'Guenzet', value: 'Guenzet' },
      { label: 'Hammam Guergour', value: 'Hammam Guergour' },
      { label: 'Hammam Soukhna', value: 'Hammam Soukhna' },
      { label: 'Maoklane', value: 'Maoklane' },
      { label: 'Salah Bey', value: 'Salah Bey' },


   { label: 'Boumerdès', value: 'Boumerdès' },

    { label: 'Baghlia', value: 'Baghlia' },
    { label: 'Boudouaou', value: 'Boudouaou' },
    { label: 'Bordj Ménaïel', value: 'Bordj Ménaïel' },
    { label: 'Boumerdès', value: 'Boumerdès' },
    { label: 'Dellys', value: 'Dellys' },
    { label: 'Khemis El Kechna', value: 'Khemis El Kechna' },
    { label: 'Isser', value: 'Isser' },
    { label: 'Naciria', value: 'Naciria' },
    { label: 'Thenia', value: 'Thenia' },

    { label: 'Aïn Boucif', value: 'Aïn Boucif' },
    { label: 'Aziz', value: 'Aziz' },
    { label: 'Beni Slimane', value: 'Beni Slimane' },
    { label: 'Berrouaghia', value: 'Berrouaghia' },
    { label: 'Chahbounia', value: 'Chahbounia' },
    { label: 'Chellalet El Adhaoura', value: 'Chellalet El Adhaoura' },
    { label: 'El Azizia', value: 'El Azizia' },
    { label: 'El Guelb El Kebir', value: 'El Guelb El Kebir' },
    { label: 'El Omaria', value: 'El Omaria' },
    { label: 'Ksar Boukhari', value: 'Ksar Boukhari' },
    { label: 'Médéa', value: 'Médéa' },
    { label: 'Ouamri', value: 'Ouamri' },
    { label: 'Ouled Antar', value: 'Ouled Antar' },
    { label: 'Ouzera', value: 'Ouzera' },
    { label: 'Seghouane', value: 'Seghouane' },
    { label: 'Sidi Naâmane', value: 'Sidi Naâmane' },
    { label: 'Si Mahdjoub', value: 'Si Mahdjoub' },
    { label: 'Souagui', value: 'Souagui' },
    { label: 'Tablat', value: 'Tablat' },

    { label: 'Achaacha', value: 'Achaacha' },
    { label: 'Ain Nouissi', value: 'Ain Nouissi' },
    { label: 'Ain Tadles', value: 'Ain Tadles' },
    { label: 'Bouguirat', value: 'Bouguirat' },
    { label: 'Hassi Mameche', value: 'Hassi Mameche' },
    { label: 'Khiredine', value: 'Khiredine' },
    { label: 'Mesra', value: 'Mesra' },
    { label: 'Mostaganem', value: 'Mostaganem' },
    { label: 'Sidi Ali', value: 'Sidi Ali' },
    { label: 'Sidi Lakhdar', value: 'Sidi Lakhdar' },

    { label: 'Adrar', value: 'Adrar' },
    { label: 'Aoulef', value: 'Aoulef' },
    { label: 'Aougrout', value: 'Aougrout' },
    { label: 'Bordj Badji Mokhtar', value: 'Bordj Badji Mokhtar' },
    { label: 'Charouine', value: 'Charouine' },
    { label: 'Fenoughil', value: 'Fenoughil' },
    { label: 'Reggane', value: 'Reggane' },
    { label: 'Timimoun', value: 'Timimoun' },
    { label: 'Tinerkouk', value: 'Tinerkouk' },
    { label: 'Tsabit', value: 'Tsabit' },
    { label: 'Zaouiet Kounta', value: 'Zaouiet Kounta' },

    { label: 'Abou El Hassan', value: 'Abou El Hassan' },
    { label: 'Aïn Merane', value: 'Aïn Merane' },
    { label: 'Beni Haoua', value: 'Beni Haoua' },
    { label: 'Boukadir', value: 'Boukadir' },
    { label: 'Chlef', value: 'Chlef' },
    { label: 'El Karimia', value: 'El Karimia' },
    { label: 'El Marsa', value: 'El Marsa' },
    { label: 'Oued Fodda', value: 'Oued Fodda' },
    { label: 'Ouled Ben Abdelkader', value: 'Ouled Ben Abdelkader' },
    { label: 'Ouled Fares', value: 'Ouled Fares' },
    { label: 'Taougrit', value: 'Taougrit' },
    { label: 'Ténès', value: 'Ténès' },
    { label: 'Zeboudja', value: 'Zeboudja' },

    { label: 'Aflou', value: 'Aflou' },
    { label: 'Aïn Mahdi', value: 'Aïn Mahdi' },
    { label: 'Brida', value: 'Brida' },
    { label: 'El Ghicha', value: 'El Ghicha' },
    { label: 'Gueltet Sidi Saâd', value: 'Gueltet Sidi Saâd' },
    { label: 'Hassi Rmel', value: 'Hassi Rmel' },
    { label: 'Ksar El Hirane', value: 'Ksar El Hirane' },
    { label: 'Laghouat', value: 'Laghouat' },
    { label: 'Oued Morra', value: 'Oued Morra' },
    { label: 'Sidi Makhlouf', value: 'Sidi Makhlouf' },

    { label: 'Aïn Babouche', value: 'Aïn Babouche' },
    { label: 'Ksar Sbahi', value: 'Ksar Sbahi' },
    { label: 'Aïn Beïda', value: 'Aïn Beïda' },
    { label: 'Fkirina', value: 'Fkirina' },
    { label: 'Aïn Mlila', value: 'Aïn Mlila' },
    { label: 'Souk Naamane', value: 'Souk Naamane' },
    { label: 'Aïn Fakroun', value: 'Aïn Fakroun' },
    { label: 'Sigus', value: 'Sigus' },
    { label: 'Aïn Kercha', value: 'Aïn Kercha' },
    { label: 'Meskiana', value: 'Meskiana' },
    { label: 'Dhalaa', value: 'Dhalaa' },
    { label: 'Oum El Bouaghi', value: 'Oum El Bouaghi' },

    { label: 'Aïn Djasser', value: 'Aïn Djasser' },
    { label: 'Aïn Touta', value: 'Aïn Touta' },
    { label: 'Arris', value: 'Arris' },
    { label: 'Barika', value: 'Barika' },
    { label: 'Batna', value: 'Batna' },
    { label: 'Bouzina', value: 'Bouzina' },
    { label: 'Chemora', value: 'Chemora' },
    { label: 'Djezzar', value: 'Djezzar' },
    { label: 'El Madher', value: 'El Madher' },
    { label: 'Ichmoul', value: 'Ichmoul' },
    { label: 'Menaa', value: 'Menaa' },
    { label: 'Merouana', value: 'Merouana' },
    { label: 'N\'Gaous', value: 'N\'Gaous' },
    { label: 'Ouled Si Slimane', value: 'Ouled Si Slimane' },
    { label: 'Ras El Aioun', value: 'Ras El Aioun' },
    { label: 'Seggana', value: 'Seggana' },
    { label: 'Seriana', value: 'Seriana' },
    { label: 'Tazoult', value: 'Tazoult' },
    { label: 'Teniet El Abed', value: 'Teniet El Abed' },
    { label: 'Timgad', value: 'Timgad' },

    { label: 'Adekar', value: 'Adekar' },
    { label: 'Akbou', value: 'Akbou' },
    { label: 'Amizour', value: 'Amizour' },
    { label: 'Aokas', value: 'Aokas' },
    { label: 'Barbacha', value: 'Barbacha' },
    { label: 'Béjaïa', value: 'Béjaïa' },
    { label: 'Beni Maouche', value: 'Beni Maouche' },
    { label: 'Chemini', value: 'Chemini' },
    { label: 'Darguina', value: 'Darguina' },
    { label: 'El Kseur', value: 'El Kseur' },
    { label: 'Ighil Ali', value: 'Ighil Ali' },
    { label: 'Kherrata', value: 'Kherrata' },
    { label: 'Ouzellaguen', value: 'Ouzellaguen' },
    { label: 'Seddouk', value: 'Seddouk' },
    { label: 'Sidi-Aïch', value: 'Sidi-Aïch' },
    { label: 'Souk El Ténine', value: 'Souk El Ténine' },
    { label: 'Tazmalt', value: 'Tazmalt' },
    { label: 'Tichy', value: 'Tichy' },
    { label: 'Timezrit', value: 'Timezrit' },

    { label: 'Chekfa', value: 'Chekfa' },
    { label: 'Djimla', value: 'Djimla' },
    { label: 'El Ancer', value: 'El Ancer' },
    { label: 'El Aouana', value: 'El Aouana' },
    { label: 'El Milia', value: 'El Milia' },
    { label: 'Jijel', value: 'Jijel' },
    { label: 'Settara', value: 'Settara' },
    { label: 'Sidi Maarouf', value: 'Sidi Maarouf' },
    { label: 'Taher', value: 'Taher' },
    { label: 'Texenna', value: 'Texenna' },
    { label: 'Ziama Mansouriah', value: 'Ziama Mansouriah' },

    { label: 'Aïn El Ibel', value: 'Aïn El Ibel' },
    { label: 'Aïn Oussara', value: 'Aïn Oussara' },
    { label: 'Birine', value: 'Birine' },
    { label: 'Charef', value: 'Charef' },
    { label: 'Dar Chioukh', value: 'Dar Chioukh' },
    { label: 'Djelfa', value: 'Djelfa' },
    { label: 'El Idrissia', value: 'El Idrissia' },
    { label: 'Faidh El Botma', value: 'Faidh El Botma' },
    { label: 'Had-Sahary', value: 'Had-Sahary' },
    { label: 'Hassi Bahbah', value: 'Hassi Bahbah' },

    { label: 'Saida', value: 'Saida' },
    { label: 'Aïn El Hadjar', value: 'Aïn El Hadjar' },
    { label: 'Sidi Boubekeur', value: 'Sidi Boubekeur' },
    { label: 'El Hassasna', value: 'El Hassasna' },
    { label: 'Ouled Brahim', value: 'Ouled Brahim' },
    { label: 'Youb', value: 'Youb' },

    
{ label: 'Skikda', value: 'Skikda' },
{ label: 'Azzaba', value: 'Azzaba' },
  { label: 'Aïn Kechra', value: 'Aïn Kechra' },
  { label: 'Ben Azzouz', value: 'Ben Azzouz' },
  { label: 'Collo', value: 'Collo' },
  { label: 'El Hadaiek', value: 'El Hadaiek' },
  { label: 'El Harrouch', value: 'El Harrouch' },
  { label: 'Ouled Attia', value: 'Ouled Attia' },
  { label: 'Oum Toub', value: 'Oum Toub' },
  { label: 'Ramdane Djamel', value: 'Ramdane Djamel' },
  { label: 'Sidi Mezghiche', value: 'Sidi Mezghiche' },
  { label: 'Tamalous', value: 'Tamalous' },
  { label: 'Zitouna', value: 'Zitouna' },

  { label: 'Sidi Bel Abbès', value: 'Sidi Bel Abbès' },
  { label: 'Aïn el Berd', value: 'Aïn el Berd' },
  { label: 'Ben Badis', value: 'Ben Badis' },
  { label: 'Marhoum', value: 'Marhoum' },
  { label: 'Merine', value: 'Merine' },
  { label: 'Mostefa Ben Brahim', value: 'Mostefa Ben Brahim' },
  { label: 'Moulay Slissen', value: 'Moulay Slissen' },
  { label: 'Ras El Ma', value: 'Ras El Ma' },
  { label: 'Sfisef', value: 'Sfisef' },
  { label: 'Sidi Ali Benyoub', value: 'Sidi Ali Benyoub' },
  { label: 'Sidi Ali Boussidi', value: 'Sidi Ali Boussidi' },
  { label: 'Sidi Lahcene', value: 'Sidi Lahcene' },
  { label: 'Telagh', value: 'Telagh' },
  { label: 'Tenira', value: 'Tenira' },
  { label: 'Tessala', value: 'Tessala' },

  { label: 'Annaba', value: 'Annaba' },
  { label: 'Aïn Berda', value: 'Aïn Berda' },
    { label: 'El Hadjar', value: 'El Hadjar' },
    { label: 'Berrahal', value: 'Berrahal' },
    { label: 'Chetaïbi', value: 'Chetaïbi' },
    { label: 'El Bouni', value: 'El Bouni' },


    { label: 'Guelma', value: 'Guelma' },   
  { label: 'Khezarra', value: 'Khezarra' },
          { label: 'Guelaât Bou Sbaâ', value: 'Guelaât Bou Sbaâ' },
          { label: 'Héliopolis', value: 'Héliopolis' },
          { label: 'Oued Zenati', value: 'Oued Zenati' },
          { label: 'Ain Makhlouf', value: 'Ain Makhlouf' },
          { label: 'Hammam Debagh', value: 'Hammam Debagh' },
          { label: 'Bouchegouf', value: 'Bouchegouf' },
          { label: 'Hammam N’Bails', value: 'Hammam N’Bails' },
          { label: 'Houari Boumédiène', value: 'Houari Boumédiène' },

          { label: 'Msila', value: 'Msila' },
          { label: 'Hammam Dalaa', value: 'Hammam Dalaa' },
          { label: 'Ouled Derradj', value: 'Ouled Derradj' },
          { label: 'Sidi Aissa', value: 'Sidi Aissa' },
          { label: 'Aïn El Melh', value: 'Aïn El Melh' },
          { label: 'Ben Srour', value: 'Ben Srour' },
          { label: 'Bou Saada', value: 'Bou Saada' },
          { label: 'Ouled sidi Brahim', value: 'Ouled sidi Brahim' },
          { label: 'Sidi Ameur', value: 'Sidi Ameur' },
          { label: 'Magra', value: 'Magra' },
          { label: 'Chellal', value: 'Chellal' },
          { label: 'Khoubana', value: 'Khoubana' },
          { label: 'Medjedel', value: 'Medjedel' },
          { label: 'Aïn El Hadjel', value: 'Aïn El Hadjel' },
          { label: 'Djebel Messaad', value: 'Djebel Messaad' },


          { label: 'Aïn Fares', value: 'Aïn Fares' },
          { label: 'Aïn Fekan', value: 'Aïn Fekan' },
          { label: 'Aouf', value: 'Aouf' },
          { label: 'Bou Hanifia', value: 'Bou Hanifia' },
          { label: 'El Bordj', value: 'El Bordj' },
          { label: 'Ghriss', value: 'Ghriss' },
          { label: 'Hachem', value: 'Hachem' },
          { label: 'Mascara', value: 'Mascara' },
          { label: 'Mohammadia', value: 'Mohammadia' },
          { label: 'Oggaz', value: 'Oggaz' },
          { label: 'Oued El Abtal', value: 'Oued El Abtal' },
          { label: 'Oued Taria', value: 'Oued Taria' },
          { label: 'Sig', value: 'Sig' },
          { label: 'Tighennif', value: 'Tighennif' },
          { label: 'Tizi', value: 'Tizi' },
   { label: 'Zahana', value: 'Zahana' },
     

   { label: 'Biskra', value: 'Biskra' },
   { label: 'Djemorah', value: 'Djemorah' },
{ label: 'Djemorah', value: 'Djemorah' },
     { label: 'El Kantara', value: 'El Kantara' },
     { label: 'M\'Chouneche', value: 'M\'Chouneche' },
     { label: 'Sidi Okba', value: 'Sidi Okba' },
     { label: 'Zeribet El Oued', value: 'Zeribet El Oued' },
     { label: 'Ourlal', value: 'Ourlal' },
     { label: 'Tolga', value: 'Tolga' },
     { label: 'Ouled Djellal', value: 'Ouled Djellal' },
     { label: 'Sidi Khaled', value: 'Sidi Khaled' },
     { label: 'Foughala', value: 'Foughala' },
     { label: 'El Outaya', value: 'El Outaya' },

     { label: 'Béchar', value: 'Béchar' },
     { label: 'Beni Ounif', value: 'Beni Ounif' },
        { label: 'Lahmar', value: 'Lahmar' },
        { label: 'Kenadsa', value: 'Kenadsa' },
        { label: 'Taghit', value: 'Taghit' },
        { label: 'Abadla', value: 'Abadla' },
        { label: 'Tabelbala', value: 'Tabelbala' },
        { label: 'Igli', value: 'Igli' },
        { label: 'Beni Abbes', value: 'Beni Abbes' },
        { label: 'El Ouata', value: 'El Ouata' },
        { label: 'Kerzaz', value: 'Kerzaz' },
        { label: 'Ouled Khoudir', value: 'Ouled Khoudir' },

        { label: 'Blida', value: 'Blida' },
        { label: 'Boufarik', value: 'Boufarik' },
        { label: 'Bougara', value: 'Bougara' },
        { label: 'Bouinan', value: 'Bouinan' },
        { label: 'El Affroun', value: 'El Affroun' },
        { label: 'Larbaa', value: 'Larbaa' },
        { label: 'Meftah', value: 'Meftah' },
        { label: 'Mouzaïa', value: 'Mouzaïa' },
        { label: 'Oued Alleug', value: 'Oued Alleug' },
        { label: 'Ouled Yaich', value: 'Ouled Yaich' },


        { label: 'Bouira', value: 'Bouira' },
        { label: 'Haizer', value: 'Haizer' },
        { label: 'Bechloul', value: 'Bechloul' },
        { label: 'Mchedallah', value: 'Mchedallah' },
        { label: 'Kadiria', value: 'Kadiria' },
        { label: 'Lakhdaria', value: 'Lakhdaria' },
        { label: 'Bir Ghbalou', value: 'Bir Ghbalou' },
        { label: 'Ain Bessam', value: 'Ain Bessam' },
        { label: 'Souk El Khemis', value: 'Souk El Khemis' },
        { label: 'El Hachimia', value: 'El Hachimia' },
        { label: 'Sour El Ghozlane', value: 'Sour El Ghozlane' },
        { label: 'Bordj Okhriss', value: 'Bordj Okhriss' },

        { label: 'Tamanrasset', value: 'Tamanrasset' },
        { label: 'Abalessa', value: 'Abalessa' },
        { label: 'In Ghar', value: 'In Ghar' },
        { label: 'In Guezzam', value: 'In Guezzam' },
        { label: 'In Salah', value: 'In Salah' },
        { label: 'Tazrouk', value: 'Tazrouk' },
        { label: 'Tinzaouten', value: 'Tinzaouten' },


   { label: 'Tebessa', value: 'Tebessa' },
          { label: 'El Kouif', value: 'El Kouif' },
          { label: 'Morsott', value: 'Morsott' },
          { label: 'El Ma Labiodh', value: 'El Ma Labiodh' },
          { label: 'El Aouinet', value: 'El Aouinet' },
          { label: 'Ouenza', value: 'Ouenza' },
          { label: 'Bir Mokkadem', value: 'Bir Mokkadem' },
          { label: 'Bir El-Ater', value: 'Bir El-Ater' },
          { label: 'El Ogla', value: 'El Ogla' },
          { label: 'Oum Ali', value: 'Oum Ali' },
          { label: 'Negrine', value: 'Negrine' },
          { label: 'Cheria', value: 'Cheria' },

          { label: 'Tlemcen', value: 'Tlemcen' },
          { label: 'Aïn Tallout', value: 'Aïn Tallout' },
          { label: 'Bab El Assa', value: 'Bab El Assa' },
          { label: 'Beni Boussaid', value: 'Beni Boussaid' },
          { label: 'Beni Snous', value: 'Beni Snous' },
          { label: 'Bensekrane', value: 'Bensekrane' },
          { label: 'Chetouane', value: 'Chetouane' },
          { label: 'Fellaoucene', value: 'Fellaoucene' },
          { label: 'Ghazaouet', value: 'Ghazaouet' },
          { label: 'Hennaya', value: 'Hennaya' },
          { label: 'Honaïne', value: 'Honaïne' },
          { label: 'Maghnia', value: 'Maghnia' },
          { label: 'Mansourah', value: 'Mansourah' },
          { label: 'Marsa Ben M\'Hidi', value: 'Marsa Ben M\'Hidi' },
          { label: 'Nedroma', value: 'Nedroma' },
          { label: 'Ouled Mimoun', value: 'Ouled Mimoun' },
          { label: 'Remchi', value: 'Remchi' },
          { label: 'Sabra', value: 'Sabra' },
          { label: 'Sebdou', value: 'Sebdou' },
          { label: 'Sidi Djillal', value: 'Sidi Djillal' },

          { label: 'Tiaret', value: 'Tiaret' },
          { label: 'Sougueur', value: 'Sougueur' },
          { label: 'Aïn Deheb', value: 'Aïn Deheb' },
          { label: 'Aïn Kermes', value: 'Aïn Kermes' },
          { label: 'Frenda', value: 'Frenda' },
          { label: 'Dahmouni', value: 'Dahmouni' },
          { label: 'Mahdia', value: 'Mahdia' },
          { label: 'Hamadia', value: 'Hamadia' },
          { label: 'Ksar Chellala', value: 'Ksar Chellala' },
          { label: 'Medroussa', value: 'Medroussa' },
          { label: 'Mechraa Safa', value: 'Mechraa Safa' },
          { label: 'Rahouia', value: 'Rahouia' },
          { label: 'Oued Lilli', value: 'Oued Lilli' },
          { label: 'Meghila', value: 'Meghila' },

          { label: 'El Bayadh', value: 'El Bayadh' },
          { label: 'Rogassa', value: 'Rogassa' },
          { label: 'Brezina', value: 'Brezina' },
          { label: 'El Abiodh Sidi Cheikh', value: 'El Abiodh Sidi Cheikh' },
          { label: 'Bougtoub', value: 'Bougtoub' },
          { label: 'Chellala', value: 'Chellala' },
          { label: 'Boussemghoun', value: 'Boussemghoun' },
          { label: 'Boualem', value: 'Boualem' },
          { label: 'Illizi', value: 'Illizi' },
          { label: 'Bordj Omar Driss', value: 'Bordj Omar Driss' },
          { label: 'Debdeb', value: 'Debdeb' },
          { label: 'In Amenas', value: 'In Amenas' },

          { label: 'Bordj-Bou-Arreridj', value: 'Bordj-Bou-Arreridj' },
          { label: 'Aïn Taghrout', value: 'Aïn Taghrout' },
          { label: 'Ras El Oued', value: 'Ras El Oued' },
          { label: 'Bordj Ghedir', value: 'Bordj Ghedir' },
          { label: 'Bir Kasdali', value: 'Bir Kasdali' },
          { label: 'El Hamadia', value: 'El Hamadia' },
          { label: 'Mansoura', value: 'Mansoura' },
          { label: 'Medjana', value: 'Medjana' },
          { label: 'Bordj Zemoura', value: 'Bordj Zemoura' },
          { label: 'Djaafra', value: 'Djaafra' },

          { label: 'El-Tarf', value: 'El-Tarf' },
          { label: 'El Kala', value: 'El Kala' },
               { label: 'Ben Mehidi', value: 'Ben Mehidi' },
               { label: 'Besbes', value: 'Besbes' },
               { label: 'Dréan', value: 'Dréan' },
               { label: 'Bouhadjar', value: 'Bouhadjar' },
               { label: 'Bouteldja', value: 'Bouteldja' },
   
               { label: 'Tindouf', value: 'Tindouf' },
               { label: 'Tissemsilt', value: 'Tissemsilt' },
               { label: 'Ammari', value: 'Ammari' },
               { label: 'Bordj Bou Naama', value: 'Bordj Bou Naama' },
               { label: 'Bordj El Emir Abdelkader', value: 'Bordj El Emir Abdelkader' },
               { label: 'Khemisti', value: 'Khemisti' },
               { label: 'Lardjem', value: 'Lardjem' },
               { label: 'Lazharia', value: 'Lazharia' },
               { label: 'Theniet El Had', value: 'Theniet El Had' },


                 { label: 'El-Oued', value: 'El-Oued' },
          { label: 'Robbah', value: 'Robbah' },
          { label: 'Oued El Alenda', value: 'Oued El Alenda' },
          { label: 'Bayadha', value: 'Bayadha' },
          { label: 'Nakhla', value: 'Nakhla' },
          { label: 'Guemar', value: 'Guemar' },
          { label: 'Kouinine', value: 'Kouinine' },
          { label: 'Reguiba', value: 'Reguiba' },
          { label: 'Hamraia', value: 'Hamraia' },
          { label: 'Taghzout', value: 'Taghzout' },
          { label: 'Debila', value: 'Debila' },
          { label: 'Hassani Abdelkrim', value: 'Hassani Abdelkrim' },
          { label: 'Hassi Khalifa', value: 'Hassi Khalifa' },
          { label: 'Taleb Larbi', value: 'Taleb Larbi' },
          { label: 'Douar El Ma', value: 'Douar El Ma' },
          { label: 'Sidi Aoun', value: 'Sidi Aoun' },
          { label: 'Trifaoui', value: 'Trifaoui' },
          { label: 'Magrane', value: 'Magrane' },
          { label: 'Beni Guecha', value: 'Beni Guecha' },
          { label: 'Ourmas', value: 'Ourmas' },

          { label: 'Khenchela', value: 'Khenchela' },
          { label: 'Babar', value: 'Babar' },
          { label: 'Bouhmama', value: 'Bouhmama' },
          { label: 'Chechar', value: 'Chechar' },
          { label: 'El Hamma', value: 'El Hamma' },
          { label: 'Kais', value: 'Kais' },
          { label: 'Ouled Rechache', value: 'Ouled Rechache' },
          { label: 'Aïn Touila', value: 'Aïn Touila' },

          { label: 'Souk-Ahras', value: 'Souk-Ahras' },
          { label: 'Bir Bou Haouch', value: 'Bir Bou Haouch' },
          { label: 'Heddada', value: 'Heddada' },
          { label: "M'daourouch", value: "M'daourouch" },
          { label: 'Mechroha', value: 'Mechroha' },
          { label: 'Merahna', value: 'Merahna' },
          { label: 'Ouled Driss', value: 'Ouled Driss' },
          { label: 'Oum El Adhaim', value: 'Oum El Adhaim' },
          { label: 'Sedrata', value: 'Sedrata' },
          { label: 'Taoura', value: 'Taoura' },

          { label: 'Mila', value: 'Mila' },
          { label: 'Chelghoum Laid', value: 'Chelghoum Laid' },
          { label: 'Ferdjioua', value: 'Ferdjioua' },
          { label: 'Grarem Gouga', value: 'Grarem Gouga' },
          { label: 'Oued Endja', value: 'Oued Endja' },
          { label: 'Rouached', value: 'Rouached' },
          { label: 'Terrai Bainen', value: 'Terrai Bainen' },
          { label: 'Tassadane Haddada', value: 'Tassadane Haddada' },
          { label: 'Aïn Beida Harriche', value: 'Aïn Beida Harriche' },
          { label: 'Sidi Merouane', value: 'Sidi Merouane' },
          { label: 'Teleghma', value: 'Teleghma' },
          { label: 'Bouhatem', value: 'Bouhatem' },
          { label: 'Tadjenanet', value: 'Tadjenanet' },

          { label: 'Aïn-Defla', value: 'Aïn-Defla' },
          { label: 'Aïn Lechiekh', value: 'Aïn Lechiekh' },
          { label: 'Bathia', value: 'Bathia' },
          { label: 'Bordj Emir Khaled', value: 'Bordj Emir Khaled' },
          { label: 'Boumedfaa', value: 'Boumedfaa' },
          { label: 'Djelida', value: 'Djelida' },
          { label: 'Djendel', value: 'Djendel' },
          { label: 'El Abadia', value: 'El Abadia' },
          { label: 'El Amra', value: 'El Amra' },
          { label: 'El Attaf', value: 'El Attaf' },
          { label: 'Hammam Righa', value: 'Hammam Righa' },
          { label: 'Khemis Miliana', value: 'Khemis Miliana' },
          { label: 'Miliana', value: 'Miliana' },
          { label: 'Rouina', value: 'Rouina' },
  
          { label: 'Naâma', value: 'Naâma' },
          { label: 'Ain Sefra', value: 'Ain Sefra' },
          { label: 'Assela', value: 'Assela' },
          { label: 'Makman Ben Amer', value: 'Makman Ben Amer' },
          { label: 'Mecheria', value: 'Mecheria' },
          { label: 'Moghrar', value: 'Moghrar' },
          { label: 'Sfissifa', value: 'Sfissifa' },
  
          { label: 'Aïn-Témouchent', value: 'Aïn-Témouchent' },   
          { label: 'Aïn El Arbaa', value: 'Aïn El Arbaa' },
                  { label: 'Ain Kihal', value: 'Ain Kihal' },
                  { label: 'Beni Saf', value: 'Beni Saf' },
                  { label: 'El Amria', value: 'El Amria' },
                  { label: 'El Malah', value: 'El Malah' },
                  { label: 'Hammam Bou Hadjar', value: 'Hammam Bou Hadjar' },
                  { label: 'Oulhaça El Gheraba', value: 'Oulhaça El Gheraba' },

                  { label: 'Ghardaia', value: 'Ghardaia' },
                  { label: 'El Meniaa', value: 'El Meniaa' },
                  { label: 'Metlili', value: 'Metlili' },
                  { label: 'Berriane', value: 'Berriane' },
                  { label: 'Daïa Ben Dahoua', value: 'Daïa Ben Dahoua' },
                  { label: 'Mansoura', value: 'Mansoura' },
                  { label: 'Zelfana', value: 'Zelfana' },
                  { label: 'Guerrara', value: 'Guerrara' },
                  { label: 'Bounoura', value: 'Bounoura' },

                  { label: 'Relizane', value: 'Relizane' },
                  { label: 'Aïn Tarek', value: 'Aïn Tarek' },
                  { label: 'Ammi Moussa', value: 'Ammi Moussa' },
                  { label: 'Djidioua', value: 'Djidioua' },
                  { label: 'El Hamadna', value: 'El Hamadna' },
                  { label: 'El Matmar', value: 'El Matmar' },
                  { label: 'Mazouna', value: 'Mazouna' },
                  { label: 'Mendes', value: 'Mendes' },
                  { label: 'Oued Rhiou', value: 'Oued Rhiou' },
                  { label: 'Ramka', value: 'Ramka' },
                  { label: 'Sidi M\'Hamed Ben Ali', value: 'Sidi M\'Hamed Ben Ali' },
                  { label: 'Yellel', value: 'Yellel' },
                  { label: 'Zemmora', value: 'Zemmora' },

                  { label: 'Timimoun', value: 'Timimoun' },
                  { label: 'Aougrout', value: 'Aougrout' },
                  { label: 'Tinerkouk', value: 'Tinerkouk' },
                  { label: 'Charouine', value: 'Charouine' },

                  { label: 'Bordj Badji Mokhtar', value: 'Bordj Badji Mokhtar' },
         
                  { label: 'Ouled Djellal', value: 'Ouled Djellal' },
                  { label: 'Sidi Khaled', value: 'Sidi Khaled' },
                  
                  { label: 'Béni Abbès', value: 'Béni Abbès' },
                  { label: 'Tamtert', value: 'Tamtert' },

                  { label: 'In Salah', value: 'In Salah' },
                  { label: 'In Ghar', value: 'In Ghar' },
                  
                  { label: 'In Guezzam', value: 'In Guezzam' },
                  { label: 'Tin Zaouatine', value: 'Tin Zaouatine' },

                  { label: 'Touggourt', value: 'Touggourt' }, 
                  { label: 'Djanet', value: 'Djanet' },
                  { label: 'Djamaa', value: 'Djamaa' },
                  { label: 'El Mghair', value: 'El Mghair' },
        
                  { label: 'El Meniaa', value: 'El Meniaa' },
               
                ];

// Define the mapping of wilayas to corresponding dairas
const wilayaToDairaMap =useMemo(
  () => ({
  'Tizi Ouzou': [
    { label: 'Boghni', value: 'Boghni' },
    { label: 'Ouadhias', value: 'Ouadhias' },
    { label: 'Tigzirt', value: 'Tigzirt' },
    { label: 'Tizi Ouzou', value: 'Tizi Ouzou' },
    { label: 'Draa El Mizan', value: 'Draa El Mizan' },

    { label: 'Ain El Hammam', value: 'Ain El Hammam' },
    { label: 'Azazga', value: 'Azazga' },
    { label: 'Azeffoun', value: 'Azeffoun' },
    { label: 'Beni Douala', value: 'Beni Douala' },
    { label: 'Ath Dwala', value: 'Ath Dwala' },
    { label: 'Beni-yenni', value: 'Beni-yenni' },
    { label: 'Ath Yenni', value: 'Ath Yenni' },
    { label: 'Bouzeguene', value: 'Bouzeguene' },
    { label: 'Draa Ben Khedda', value: 'Draa Ben Khedda' },
    { label: 'Iferhounène', value: 'Iferhounène' },
    { label: 'Larbaa Nath Irathen', value: 'Larbaa Nath Irathen' },
    { label: 'Maatkas', value: 'Maatkas' },
    { label: 'Makouda', value: 'Makouda' },
    { label: 'Mekla', value: 'Mekla' },
    { label: 'Ouacifs', value: 'Ouacifs' },
    { label: 'Ouaguenoun', value: 'Ouaguenoun' },
    { label: 'Tizi Ghenif', value: 'Tizi Ghenif' },
    { label: 'Tizi-Rached', value: 'Tizi-Rached' }
],

'Alger': [
  { label: 'Zéralda', value: 'Zéralda' },
  { label: 'Chéraga', value: 'Chéraga' },
  { label: 'Draria', value: 'Draria' },
  { label: 'Bir Mourad Raïs', value: 'Bir Mourad Raïs' },
  { label: 'Birtouta', value: 'Birtouta' },
  { label: 'Bouzareah', value: 'Bouzareah' },
  { label: 'Bab El Oued', value: 'Bab El Oued' },
  { label: 'Sidi Mhamed', value: 'Sidi Mhamed' },
  { label: 'Hussein Dey', value: 'Hussein Dey' },
  { label: 'El Harrach', value: 'El Harrach' },
  { label: 'Baraki', value: 'Baraki' },
  { label: 'Dar El Beïda', value: 'Dar El Beïda' },
  { label: 'Rouïba', value: 'Rouïba' }
],

'Oran': [
{ label: 'Oran', value: 'Oran' },

{ label: 'Aïn El Turk', value: 'Aïn El Turk' },
{ label: 'Arzew', value: 'Arzew' },
{ label: 'Bethioua', value: 'Bethioua' },
{ label: 'Es Sénia', value: 'Es Sénia' },
{ label: 'Bir El Djir', value: 'Bir El Djir' },
{ label: 'Boutlélis', value: 'Boutlélis' },
{ label: 'Oued Tlelat', value: 'Oued Tlelat' },
{ label: 'Gdyel', value: 'Gdyel' }
],

'Constantine': [
{ label: 'Constantine', value: 'Constantine' },

{ label: 'El Khroub', value: 'El Khroub' },
{ label: 'Aïn Abid', value: 'Aïn Abid' },
{ label: 'Zighoud Youcef', value: 'Zighoud Youcef' },
{ label: 'Hamma Bouziane', value: 'Hamma Bouziane' },
{ label: 'Ibn Ziad', value: 'Ibn Ziad' }
],


'Tipaza': [
{ label: 'Ahmar El Ain', value: 'Ahmar El Ain' },
{ label: 'Bou Ismail', value: 'Bou Ismail' },
{ label: 'Cherchell', value: 'Cherchell' },
{ label: 'Damous', value: 'Damous' },
{ label: 'Fouka', value: 'Fouka' },
{ label: 'Gouraya', value: 'Gouraya' },
{ label: 'Hadjout', value: 'Hadjout' },
{ label: 'Koléa', value: 'Koléa' },
{ label: 'Sidi Amar', value: 'Sidi Amar' },
{ label: 'Tipaza', value: 'Tipaza' }
],

'Sétif': [
{ label: 'Sétif', value: 'Sétif' },

{ label: 'Aïn Arnat', value: 'Aïn Arnat' },
{ label: 'Aïn Azel', value: 'Aïn Azel' },
{ label: 'Aïn El Kebira', value: 'Aïn El Kebira' },
{ label: 'Aïn Oulmene', value: 'Aïn Oulmene' },
{ label: 'Amoucha', value: 'Amoucha' },
{ label: 'Babor', value: 'Babor' },
{ label: 'Beni Aziz', value: 'Beni Aziz' },
{ label: 'Beni Ourtilane', value: 'Beni Ourtilane' },
{ label: 'Bir El Arch', value: 'Bir El Arch' },
{ label: 'Bouandas', value: 'Bouandas' },
{ label: 'Bougaa', value: 'Bougaa' },
{ label: 'Djemila', value: 'Djemila' },
{ label: 'El Eulma', value: 'El Eulma' },
{ label: 'Guidjel', value: 'Guidjel' },
{ label: 'Guenzet', value: 'Guenzet' },
{ label: 'Hammam Guergour', value: 'Hammam Guergour' },
{ label: 'Hammam Soukhna', value: 'Hammam Soukhna' },
{ label: 'Maoklane', value: 'Maoklane' },
{ label: 'Salah Bey', value: 'Salah Bey' }
],
'Boumerdès': [
{ label: 'Boumerdès', value: 'Boumerdès' },

{ label: 'Baghlia', value: 'Baghlia' },
{ label: 'Boudouaou', value: 'Boudouaou' },
{ label: 'Bordj Ménaïel', value: 'Bordj Ménaïel' },
{ label: 'Boumerdès', value: 'Boumerdès' },
{ label: 'Dellys', value: 'Dellys' },
{ label: 'Khemis El Kechna', value: 'Khemis El Kechna' },
{ label: 'Isser', value: 'Isser' },
{ label: 'Naciria', value: 'Naciria' },
{ label: 'Thenia', value: 'Thenia' }
],

'Médéa': [
{ label: 'Aïn Boucif', value: 'Aïn Boucif' },
{ label: 'Aziz', value: 'Aziz' },
{ label: 'Beni Slimane', value: 'Beni Slimane' },
{ label: 'Berrouaghia', value: 'Berrouaghia' },
{ label: 'Chahbounia', value: 'Chahbounia' },
{ label: 'Chellalet El Adhaoura', value: 'Chellalet El Adhaoura' },
{ label: 'El Azizia', value: 'El Azizia' },
{ label: 'El Guelb El Kebir', value: 'El Guelb El Kebir' },
{ label: 'El Omaria', value: 'El Omaria' },
{ label: 'Ksar Boukhari', value: 'Ksar Boukhari' },
{ label: 'Médéa', value: 'Médéa' },
{ label: 'Ouamri', value: 'Ouamri' },
{ label: 'Ouled Antar', value: 'Ouled Antar' },
{ label: 'Ouzera', value: 'Ouzera' },
{ label: 'Seghouane', value: 'Seghouane' },
{ label: 'Sidi Naâmane', value: 'Sidi Naâmane' },
{ label: 'Si Mahdjoub', value: 'Si Mahdjoub' },
{ label: 'Souagui', value: 'Souagui' },
{ label: 'Tablat', value: 'Tablat' }
],

'Mostaganem': [
{ label: 'Achaacha', value: 'Achaacha' },
{ label: 'Ain Nouissi', value: 'Ain Nouissi' },
{ label: 'Ain Tadles', value: 'Ain Tadles' },
{ label: 'Bouguirat', value: 'Bouguirat' },
{ label: 'Hassi Mameche', value: 'Hassi Mameche' },
{ label: 'Khiredine', value: 'Khiredine' },
{ label: 'Mesra', value: 'Mesra' },
{ label: 'Mostaganem', value: 'Mostaganem' },
{ label: 'Sidi Ali', value: 'Sidi Ali' },
{ label: 'Sidi Lakhdar', value: 'Sidi Lakhdar' }
],

'Adrar': [
{ label: 'Adrar', value: 'Adrar' },
{ label: 'Aoulef', value: 'Aoulef' },
{ label: 'Aougrout', value: 'Aougrout' },
{ label: 'Bordj Badji Mokhtar', value: 'Bordj Badji Mokhtar' },
{ label: 'Charouine', value: 'Charouine' },
{ label: 'Fenoughil', value: 'Fenoughil' },
{ label: 'Reggane', value: 'Reggane' },
{ label: 'Timimoun', value: 'Timimoun' },
{ label: 'Tinerkouk', value: 'Tinerkouk' },
{ label: 'Tsabit', value: 'Tsabit' },
{ label: 'Zaouiet Kounta', value: 'Zaouiet Kounta' }
],

'Chlef': [
{ label: 'Abou El Hassan', value: 'Abou El Hassan' },
{ label: 'Aïn Merane', value: 'Aïn Merane' },
{ label: 'Beni Haoua', value: 'Beni Haoua' },
{ label: 'Boukadir', value: 'Boukadir' },
{ label: 'Chlef', value: 'Chlef' },
{ label: 'El Karimia', value: 'El Karimia' },
{ label: 'El Marsa', value: 'El Marsa' },
{ label: 'Oued Fodda', value: 'Oued Fodda' },
{ label: 'Ouled Ben Abdelkader', value: 'Ouled Ben Abdelkader' },
{ label: 'Ouled Fares', value: 'Ouled Fares' },
{ label: 'Taougrit', value: 'Taougrit' },
{ label: 'Ténès', value: 'Ténès' },
{ label: 'Zeboudja', value: 'Zeboudja' }
],

'Laghouat': [
{ label: 'Aflou', value: 'Aflou' },
{ label: 'Aïn Mahdi', value: 'Aïn Mahdi' },
{ label: 'Brida', value: 'Brida' },
{ label: 'El Ghicha', value: 'El Ghicha' },
{ label: 'Gueltet Sidi Saâd', value: 'Gueltet Sidi Saâd' },
{ label: 'Hassi Rmel', value: 'Hassi Rmel' },
{ label: 'Ksar El Hirane', value: 'Ksar El Hirane' },
{ label: 'Laghouat', value: 'Laghouat' },
{ label: 'Oued Morra', value: 'Oued Morra' },
{ label: 'Sidi Makhlouf', value: 'Sidi Makhlouf' }
],


'Oum El Bouaghi': [
{ label: 'Aïn Babouche', value: 'Aïn Babouche' },
{ label: 'Ksar Sbahi', value: 'Ksar Sbahi' },
{ label: 'Aïn Beïda', value: 'Aïn Beïda' },
{ label: 'Fkirina', value: 'Fkirina' },
{ label: 'Aïn Mlila', value: 'Aïn Mlila' },
{ label: 'Souk Naamane', value: 'Souk Naamane' },
{ label: 'Aïn Fakroun', value: 'Aïn Fakroun' },
{ label: 'Sigus', value: 'Sigus' },
{ label: 'Aïn Kercha', value: 'Aïn Kercha' },
{ label: 'Meskiana', value: 'Meskiana' },
{ label: 'Dhalaa', value: 'Dhalaa' },
{ label: 'Oum El Bouaghi', value: 'Oum El Bouaghi' }
],

'Batna': [
{ label: 'Aïn Djasser', value: 'Aïn Djasser' },
{ label: 'Aïn Touta', value: 'Aïn Touta' },
{ label: 'Arris', value: 'Arris' },
{ label: 'Barika', value: 'Barika' },
{ label: 'Batna', value: 'Batna' },
{ label: 'Bouzina', value: 'Bouzina' },
{ label: 'Chemora', value: 'Chemora' },
{ label: 'Djezzar', value: 'Djezzar' },
{ label: 'El Madher', value: 'El Madher' },
{ label: 'Ichmoul', value: 'Ichmoul' },
{ label: 'Menaa', value: 'Menaa' },
{ label: 'Merouana', value: 'Merouana' },
{ label: 'N\'Gaous', value: 'N\'Gaous' },
{ label: 'Ouled Si Slimane', value: 'Ouled Si Slimane' },
{ label: 'Ras El Aioun', value: 'Ras El Aioun' },
{ label: 'Seggana', value: 'Seggana' },
{ label: 'Seriana', value: 'Seriana' },
{ label: 'Tazoult', value: 'Tazoult' },
{ label: 'Teniet El Abed', value: 'Teniet El Abed' },
{ label: 'Timgad', value: 'Timgad' }
],

'Béjaïa': [
{ label: 'Adekar', value: 'Adekar' },
{ label: 'Akbou', value: 'Akbou' },
{ label: 'Amizour', value: 'Amizour' },
{ label: 'Aokas', value: 'Aokas' },
{ label: 'Barbacha', value: 'Barbacha' },
{ label: 'Béjaïa', value: 'Béjaïa' },
{ label: 'Beni Maouche', value: 'Beni Maouche' },
{ label: 'Chemini', value: 'Chemini' },
{ label: 'Darguina', value: 'Darguina' },
{ label: 'El Kseur', value: 'El Kseur' },
{ label: 'Ighil Ali', value: 'Ighil Ali' },
{ label: 'Kherrata', value: 'Kherrata' },
{ label: 'Ouzellaguen', value: 'Ouzellaguen' },
{ label: 'Seddouk', value: 'Seddouk' },
{ label: 'Sidi-Aïch', value: 'Sidi-Aïch' },
{ label: 'Souk El Ténine', value: 'Souk El Ténine' },
{ label: 'Tazmalt', value: 'Tazmalt' },
{ label: 'Tichy', value: 'Tichy' },
{ label: 'Timezrit', value: 'Timezrit' }
],

'Jijel': [
{ label: 'Chekfa', value: 'Chekfa' },
{ label: 'Djimla', value: 'Djimla' },
{ label: 'El Ancer', value: 'El Ancer' },
{ label: 'El Aouana', value: 'El Aouana' },
{ label: 'El Milia', value: 'El Milia' },
{ label: 'Jijel', value: 'Jijel' },
{ label: 'Settara', value: 'Settara' },
{ label: 'Sidi Maarouf', value: 'Sidi Maarouf' },
{ label: 'Taher', value: 'Taher' },
{ label: 'Texenna', value: 'Texenna' },
{ label: 'Ziama Mansouriah', value: 'Ziama Mansouriah' }
],

'Djelfa': [
{ label: 'Aïn El Ibel', value: 'Aïn El Ibel' },
{ label: 'Aïn Oussara', value: 'Aïn Oussara' },
{ label: 'Birine', value: 'Birine' },
{ label: 'Charef', value: 'Charef' },
{ label: 'Dar Chioukh', value: 'Dar Chioukh' },
{ label: 'Djelfa', value: 'Djelfa' },
{ label: 'El Idrissia', value: 'El Idrissia' },
{ label: 'Faidh El Botma', value: 'Faidh El Botma' },
{ label: 'Had-Sahary', value: 'Had-Sahary' },
{ label: 'Hassi Bahbah', value: 'Hassi Bahbah' }
],

'Saida': [
{ label: 'Saida', value: 'Saida' },
{ label: 'Aïn El Hadjar', value: 'Aïn El Hadjar' },
{ label: 'Sidi Boubekeur', value: 'Sidi Boubekeur' },
{ label: 'El Hassasna', value: 'El Hassasna' },
{ label: 'Ouled Brahim', value: 'Ouled Brahim' },
{ label: 'Youb', value: 'Youb' }
],

'Skikda': [
{ label: 'Skikda', value: 'Skikda' },
{ label: 'Azzaba', value: 'Azzaba' },
{ label: 'Aïn Kechra', value: 'Aïn Kechra' },
{ label: 'Ben Azzouz', value: 'Ben Azzouz' },
{ label: 'Collo', value: 'Collo' },
{ label: 'El Hadaiek', value: 'El Hadaiek' },
{ label: 'El Harrouch', value: 'El Harrouch' },
{ label: 'Ouled Attia', value: 'Ouled Attia' },
{ label: 'Oum Toub', value: 'Oum Toub' },
{ label: 'Ramdane Djamel', value: 'Ramdane Djamel' },
{ label: 'Sidi Mezghiche', value: 'Sidi Mezghiche' },
{ label: 'Tamalous', value: 'Tamalous' },
{ label: 'Zitouna', value: 'Zitouna' }
],
'Sidi Bel Abbès': [
{ label: 'Sidi Bel Abbès', value: 'Sidi Bel Abbès' },
{ label: 'Aïn el Berd', value: 'Aïn el Berd' },
{ label: 'Ben Badis', value: 'Ben Badis' },
{ label: 'Marhoum', value: 'Marhoum' },
{ label: 'Merine', value: 'Merine' },
{ label: 'Mostefa Ben Brahim', value: 'Mostefa Ben Brahim' },
{ label: 'Moulay Slissen', value: 'Moulay Slissen' },
{ label: 'Ras El Ma', value: 'Ras El Ma' },
{ label: 'Sfisef', value: 'Sfisef' },
{ label: 'Sidi Ali Benyoub', value: 'Sidi Ali Benyoub' },
{ label: 'Sidi Ali Boussidi', value: 'Sidi Ali Boussidi' },
{ label: 'Sidi Lahcene', value: 'Sidi Lahcene' },
{ label: 'Telagh', value: 'Telagh' },
{ label: 'Tenira', value: 'Tenira' },
{ label: 'Tessala', value: 'Tessala' }
],

'Annaba': [
{ label: 'Annaba', value: 'Annaba' },
{ label: 'Aïn Berda', value: 'Aïn Berda' },
{ label: 'El Hadjar', value: 'El Hadjar' },
{ label: 'Berrahal', value: 'Berrahal' },
{ label: 'Chetaïbi', value: 'Chetaïbi' },
{ label: 'El Bouni', value: 'El Bouni' }
],
'Guelma': [       
{ label: 'Guelma', value: 'Guelma' },   
{ label: 'Khezarra', value: 'Khezarra' },
    { label: 'Guelaât Bou Sbaâ', value: 'Guelaât Bou Sbaâ' },
    { label: 'Héliopolis', value: 'Héliopolis' },
    { label: 'Oued Zenati', value: 'Oued Zenati' },
    { label: 'Ain Makhlouf', value: 'Ain Makhlouf' },
    { label: 'Hammam Debagh', value: 'Hammam Debagh' },
    { label: 'Bouchegouf', value: 'Bouchegouf' },
    { label: 'Hammam N’Bails', value: 'Hammam N’Bails' },
    { label: 'Houari Boumédiène', value: 'Houari Boumédiène' }
],

'Msila': [
{ label: 'Msila', value: 'Msila' },
    { label: 'Hammam Dalaa', value: 'Hammam Dalaa' },
    { label: 'Ouled Derradj', value: 'Ouled Derradj' },
    { label: 'Sidi Aissa', value: 'Sidi Aissa' },
    { label: 'Aïn El Melh', value: 'Aïn El Melh' },
    { label: 'Ben Srour', value: 'Ben Srour' },
    { label: 'Bou Saada', value: 'Bou Saada' },
    { label: 'Ouled sidi Brahim', value: 'Ouled sidi Brahim' },
    { label: 'Sidi Ameur', value: 'Sidi Ameur' },
    { label: 'Magra', value: 'Magra' },
    { label: 'Chellal', value: 'Chellal' },
    { label: 'Khoubana', value: 'Khoubana' },
    { label: 'Medjedel', value: 'Medjedel' },
    { label: 'Aïn El Hadjel', value: 'Aïn El Hadjel' },
    { label: 'Djebel Messaad', value: 'Djebel Messaad' }
],


'Mascara': [
    { label: 'Aïn Fares', value: 'Aïn Fares' },
    { label: 'Aïn Fekan', value: 'Aïn Fekan' },
    { label: 'Aouf', value: 'Aouf' },
    { label: 'Bou Hanifia', value: 'Bou Hanifia' },
    { label: 'El Bordj', value: 'El Bordj' },
    { label: 'Ghriss', value: 'Ghriss' },
    { label: 'Hachem', value: 'Hachem' },
    { label: 'Mascara', value: 'Mascara' },
    { label: 'Mohammadia', value: 'Mohammadia' },
    { label: 'Oggaz', value: 'Oggaz' },
    { label: 'Oued El Abtal', value: 'Oued El Abtal' },
    { label: 'Oued Taria', value: 'Oued Taria' },
    { label: 'Sig', value: 'Sig' },
    { label: 'Tighennif', value: 'Tighennif' },
    { label: 'Tizi', value: 'Tizi' },
{ label: 'Zahana', value: 'Zahana' }
],

'Biskra': [
{ label: 'Biskra', value: 'Biskra' },
  { label: 'Djemorah', value: 'Djemorah' },
{ label: 'Djemorah', value: 'Djemorah' },
    { label: 'El Kantara', value: 'El Kantara' },
    { label: 'M\'Chouneche', value: 'M\'Chouneche' },
    { label: 'Sidi Okba', value: 'Sidi Okba' },
    { label: 'Zeribet El Oued', value: 'Zeribet El Oued' },
    { label: 'Ourlal', value: 'Ourlal' },
    { label: 'Tolga', value: 'Tolga' },
    { label: 'Ouled Djellal', value: 'Ouled Djellal' },
    { label: 'Sidi Khaled', value: 'Sidi Khaled' },
    { label: 'Foughala', value: 'Foughala' },
    { label: 'El Outaya', value: 'El Outaya' }
],

'Béchar': [
{ label: 'Béchar', value: 'Béchar' },
 { label: 'Beni Ounif', value: 'Beni Ounif' },
    { label: 'Lahmar', value: 'Lahmar' },
    { label: 'Kenadsa', value: 'Kenadsa' },
    { label: 'Taghit', value: 'Taghit' },
    { label: 'Abadla', value: 'Abadla' },
    { label: 'Tabelbala', value: 'Tabelbala' },
    { label: 'Igli', value: 'Igli' },
    { label: 'Beni Abbes', value: 'Beni Abbes' },
    { label: 'El Ouata', value: 'El Ouata' },
    { label: 'Kerzaz', value: 'Kerzaz' },
    { label: 'Ouled Khoudir', value: 'Ouled Khoudir' }
],

'Blida': [
  { label: 'Blida', value: 'Blida' },
    { label: 'Boufarik', value: 'Boufarik' },
    { label: 'Bougara', value: 'Bougara' },
    { label: 'Bouinan', value: 'Bouinan' },
    { label: 'El Affroun', value: 'El Affroun' },
    { label: 'Larbaa', value: 'Larbaa' },
    { label: 'Meftah', value: 'Meftah' },
    { label: 'Mouzaïa', value: 'Mouzaïa' },
    { label: 'Oued Alleug', value: 'Oued Alleug' },
    { label: 'Ouled Yaich', value: 'Ouled Yaich' }
],

'Bouira': [     { label: 'Bouira', value: 'Bouira' },
    { label: 'Haizer', value: 'Haizer' },
    { label: 'Bechloul', value: 'Bechloul' },
    { label: 'Mchedallah', value: 'Mchedallah' },
    { label: 'Kadiria', value: 'Kadiria' },
    { label: 'Lakhdaria', value: 'Lakhdaria' },
    { label: 'Bir Ghbalou', value: 'Bir Ghbalou' },
    { label: 'Ain Bessam', value: 'Ain Bessam' },
    { label: 'Souk El Khemis', value: 'Souk El Khemis' },
    { label: 'El Hachimia', value: 'El Hachimia' },
    { label: 'Sour El Ghozlane', value: 'Sour El Ghozlane' },
    { label: 'Bordj Okhriss', value: 'Bordj Okhriss' }
],

'Tamanrasset': [
    { label: 'Tamanrasset', value: 'Tamanrasset' },
    { label: 'Abalessa', value: 'Abalessa' },
    { label: 'In Ghar', value: 'In Ghar' },
    { label: 'In Guezzam', value: 'In Guezzam' },
    { label: 'In Salah', value: 'In Salah' },
    { label: 'Tazrouk', value: 'Tazrouk' },
    { label: 'Tinzaouten', value: 'Tinzaouten' }
],

'Tebessa': [    { label: 'Tebessa', value: 'Tebessa' },
    { label: 'El Kouif', value: 'El Kouif' },
    { label: 'Morsott', value: 'Morsott' },
    { label: 'El Ma Labiodh', value: 'El Ma Labiodh' },
    { label: 'El Aouinet', value: 'El Aouinet' },
    { label: 'Ouenza', value: 'Ouenza' },
    { label: 'Bir Mokkadem', value: 'Bir Mokkadem' },
    { label: 'Bir El-Ater', value: 'Bir El-Ater' },
    { label: 'El Ogla', value: 'El Ogla' },
    { label: 'Oum Ali', value: 'Oum Ali' },
    { label: 'Negrine', value: 'Negrine' },
    { label: 'Cheria', value: 'Cheria' }
],

'Tlemcen': [ { label: 'Tlemcen', value: 'Tlemcen' },
    { label: 'Aïn Tallout', value: 'Aïn Tallout' },
    { label: 'Bab El Assa', value: 'Bab El Assa' },
    { label: 'Beni Boussaid', value: 'Beni Boussaid' },
    { label: 'Beni Snous', value: 'Beni Snous' },
    { label: 'Bensekrane', value: 'Bensekrane' },
    { label: 'Chetouane', value: 'Chetouane' },
    { label: 'Fellaoucene', value: 'Fellaoucene' },
    { label: 'Ghazaouet', value: 'Ghazaouet' },
    { label: 'Hennaya', value: 'Hennaya' },
    { label: 'Honaïne', value: 'Honaïne' },
    { label: 'Maghnia', value: 'Maghnia' },
    { label: 'Mansourah', value: 'Mansourah' },
    { label: 'Marsa Ben M\'Hidi', value: 'Marsa Ben M\'Hidi' },
    { label: 'Nedroma', value: 'Nedroma' },
    { label: 'Ouled Mimoun', value: 'Ouled Mimoun' },
    { label: 'Remchi', value: 'Remchi' },
    { label: 'Sabra', value: 'Sabra' },
    { label: 'Sebdou', value: 'Sebdou' },
    { label: 'Sidi Djillal', value: 'Sidi Djillal' }
],

'Tiaret': [
{ label: 'Tiaret', value: 'Tiaret' },
    { label: 'Sougueur', value: 'Sougueur' },
    { label: 'Aïn Deheb', value: 'Aïn Deheb' },
    { label: 'Aïn Kermes', value: 'Aïn Kermes' },
    { label: 'Frenda', value: 'Frenda' },
    { label: 'Dahmouni', value: 'Dahmouni' },
    { label: 'Mahdia', value: 'Mahdia' },
    { label: 'Hamadia', value: 'Hamadia' },
    { label: 'Ksar Chellala', value: 'Ksar Chellala' },
    { label: 'Medroussa', value: 'Medroussa' },
    { label: 'Mechraa Safa', value: 'Mechraa Safa' },
    { label: 'Rahouia', value: 'Rahouia' },
    { label: 'Oued Lilli', value: 'Oued Lilli' },
    { label: 'Meghila', value: 'Meghila' }
],

'El Bayadh': [
{ label: 'El Bayadh', value: 'El Bayadh' },
    { label: 'Rogassa', value: 'Rogassa' },
    { label: 'Brezina', value: 'Brezina' },
    { label: 'El Abiodh Sidi Cheikh', value: 'El Abiodh Sidi Cheikh' },
    { label: 'Bougtoub', value: 'Bougtoub' },
    { label: 'Chellala', value: 'Chellala' },
    { label: 'Boussemghoun', value: 'Boussemghoun' },
    { label: 'Boualem', value: 'Boualem' },
],

'Illizi': [  { label: 'Illizi', value: 'Illizi' },
    { label: 'Bordj Omar Driss', value: 'Bordj Omar Driss' },
    { label: 'Debdeb', value: 'Debdeb' },
    { label: 'In Amenas', value: 'In Amenas' }
],

'Bordj Bou Arreridj': [ 
{ label: 'Bordj-Bou-Arreridj', value: 'Bordj-Bou-Arreridj' },
    { label: 'Aïn Taghrout', value: 'Aïn Taghrout' },
    { label: 'Ras El Oued', value: 'Ras El Oued' },
    { label: 'Bordj Ghedir', value: 'Bordj Ghedir' },
    { label: 'Bir Kasdali', value: 'Bir Kasdali' },
    { label: 'El Hamadia', value: 'El Hamadia' },
    { label: 'Mansoura', value: 'Mansoura' },
    { label: 'Medjana', value: 'Medjana' },
    { label: 'Bordj Zemoura', value: 'Bordj Zemoura' },
    { label: 'Djaafra', value: 'Djaafra' }
],

'El Tarf': [
  { label: 'El-Tarf', value: 'El-Tarf' },
{ label: 'El Kala', value: 'El Kala' },
    { label: 'Ben Mehidi', value: 'Ben Mehidi' },
    { label: 'Besbes', value: 'Besbes' },
    { label: 'Dréan', value: 'Dréan' },
    { label: 'Bouhadjar', value: 'Bouhadjar' },
    { label: 'Bouteldja', value: 'Bouteldja' },
],

'Tindouf': [ { label: 'Tindouf', value: 'Tindouf' },

],

'Tissemsilt': [ 
{ label: 'Tissemsilt', value: 'Tissemsilt' },
    { label: 'Ammari', value: 'Ammari' },
    { label: 'Bordj Bou Naama', value: 'Bordj Bou Naama' },
    { label: 'Bordj El Emir Abdelkader', value: 'Bordj El Emir Abdelkader' },
    { label: 'Khemisti', value: 'Khemisti' },
    { label: 'Lardjem', value: 'Lardjem' },
    { label: 'Lazharia', value: 'Lazharia' },
    { label: 'Theniet El Had', value: 'Theniet El Had' }
],

'El Oued': [
{ label: 'El-Oued', value: 'El-Oued' },
    { label: 'Robbah', value: 'Robbah' },
    { label: 'Oued El Alenda', value: 'Oued El Alenda' },
    { label: 'Bayadha', value: 'Bayadha' },
    { label: 'Nakhla', value: 'Nakhla' },
    { label: 'Guemar', value: 'Guemar' },
    { label: 'Kouinine', value: 'Kouinine' },
    { label: 'Reguiba', value: 'Reguiba' },
    { label: 'Hamraia', value: 'Hamraia' },
    { label: 'Taghzout', value: 'Taghzout' },
    { label: 'Debila', value: 'Debila' },
    { label: 'Hassani Abdelkrim', value: 'Hassani Abdelkrim' },
    { label: 'Hassi Khalifa', value: 'Hassi Khalifa' },
    { label: 'Taleb Larbi', value: 'Taleb Larbi' },
    { label: 'Douar El Ma', value: 'Douar El Ma' },
    { label: 'Sidi Aoun', value: 'Sidi Aoun' },
    { label: 'Trifaoui', value: 'Trifaoui' },
    { label: 'Magrane', value: 'Magrane' },
    { label: 'Beni Guecha', value: 'Beni Guecha' },
    { label: 'Ourmas', value: 'Ourmas' }
],


'Khenchela': [          
{ label: 'Khenchela', value: 'Khenchela' },
    { label: 'Babar', value: 'Babar' },
    { label: 'Bouhmama', value: 'Bouhmama' },
    { label: 'Chechar', value: 'Chechar' },
    { label: 'El Hamma', value: 'El Hamma' },
    { label: 'Kais', value: 'Kais' },
    { label: 'Ouled Rechache', value: 'Ouled Rechache' },
    { label: 'Aïn Touila', value: 'Aïn Touila' }
],

'Souk Ahras': [        
{ label: 'Souk-Ahras', value: 'Souk-Ahras' },
    { label: 'Bir Bou Haouch', value: 'Bir Bou Haouch' },
    { label: 'Heddada', value: 'Heddada' },
    { label: "M'daourouch", value: "M'daourouch" },
    { label: 'Mechroha', value: 'Mechroha' },
    { label: 'Merahna', value: 'Merahna' },
    { label: 'Ouled Driss', value: 'Ouled Driss' },
    { label: 'Oum El Adhaim', value: 'Oum El Adhaim' },
    { label: 'Sedrata', value: 'Sedrata' },
    { label: 'Taoura', value: 'Taoura' }
],

'Mila': [
{ label: 'Mila', value: 'Mila' },
    { label: 'Chelghoum Laid', value: 'Chelghoum Laid' },
    { label: 'Ferdjioua', value: 'Ferdjioua' },
    { label: 'Grarem Gouga', value: 'Grarem Gouga' },
    { label: 'Oued Endja', value: 'Oued Endja' },
    { label: 'Rouached', value: 'Rouached' },
    { label: 'Terrai Bainen', value: 'Terrai Bainen' },
    { label: 'Tassadane Haddada', value: 'Tassadane Haddada' },
    { label: 'Aïn Beida Harriche', value: 'Aïn Beida Harriche' },
    { label: 'Sidi Merouane', value: 'Sidi Merouane' },
    { label: 'Teleghma', value: 'Teleghma' },
    { label: 'Bouhatem', value: 'Bouhatem' },
    { label: 'Tadjenanet', value: 'Tadjenanet' }
],

'Aïn Defla': [  
{ label: 'Aïn-Defla', value: 'Aïn-Defla' },
    { label: 'Aïn Lechiekh', value: 'Aïn Lechiekh' },
    { label: 'Bathia', value: 'Bathia' },
    { label: 'Bordj Emir Khaled', value: 'Bordj Emir Khaled' },
    { label: 'Boumedfaa', value: 'Boumedfaa' },
    { label: 'Djelida', value: 'Djelida' },
    { label: 'Djendel', value: 'Djendel' },
    { label: 'El Abadia', value: 'El Abadia' },
    { label: 'El Amra', value: 'El Amra' },
    { label: 'El Attaf', value: 'El Attaf' },
    { label: 'Hammam Righa', value: 'Hammam Righa' },
    { label: 'Khemis Miliana', value: 'Khemis Miliana' },
    { label: 'Miliana', value: 'Miliana' },
    { label: 'Rouina', value: 'Rouina' }
],
'Naama': [       
{ label: 'Naâma', value: 'Naâma' },
    { label: 'Ain Sefra', value: 'Ain Sefra' },
    { label: 'Assela', value: 'Assela' },
    { label: 'Makman Ben Amer', value: 'Makman Ben Amer' },
    { label: 'Mecheria', value: 'Mecheria' },
    { label: 'Moghrar', value: 'Moghrar' },
    { label: 'Sfissifa', value: 'Sfissifa' }
],

'Aïn-Témouchent': [
      { label: 'Aïn-Témouchent', value: 'Aïn-Témouchent' },   
{ label: 'Aïn El Arbaa', value: 'Aïn El Arbaa' },
    { label: 'Ain Kihal', value: 'Ain Kihal' },
    { label: 'Beni Saf', value: 'Beni Saf' },
    { label: 'El Amria', value: 'El Amria' },
    { label: 'El Malah', value: 'El Malah' },
    { label: 'Hammam Bou Hadjar', value: 'Hammam Bou Hadjar' },
    { label: 'Oulhaça El Gheraba', value: 'Oulhaça El Gheraba' }
],

'Ghardaïa': [ { label: 'Ghardaia', value: 'Ghardaia' },
    { label: 'El Meniaa', value: 'El Meniaa' },
    { label: 'Metlili', value: 'Metlili' },
    { label: 'Berriane', value: 'Berriane' },
    { label: 'Daïa Ben Dahoua', value: 'Daïa Ben Dahoua' },
    { label: 'Mansoura', value: 'Mansoura' },
    { label: 'Zelfana', value: 'Zelfana' },
    { label: 'Guerrara', value: 'Guerrara' },
    { label: 'Bounoura', value: 'Bounoura' }
],
'Relizane': [ { label: 'Relizane', value: 'Relizane' },
    { label: 'Aïn Tarek', value: 'Aïn Tarek' },
    { label: 'Ammi Moussa', value: 'Ammi Moussa' },
    { label: 'Djidioua', value: 'Djidioua' },
    { label: 'El Hamadna', value: 'El Hamadna' },
    { label: 'El Matmar', value: 'El Matmar' },
    { label: 'Mazouna', value: 'Mazouna' },
    { label: 'Mendes', value: 'Mendes' },
    { label: 'Oued Rhiou', value: 'Oued Rhiou' },
    { label: 'Ramka', value: 'Ramka' },
    { label: 'Sidi M\'Hamed Ben Ali', value: 'Sidi M\'Hamed Ben Ali' },
    { label: 'Yellel', value: 'Yellel' },
    { label: 'Zemmora', value: 'Zemmora' }
],
'Timimoun': [
    { label: 'Timimoun', value: 'Timimoun' },
    { label: 'Aougrout', value: 'Aougrout' },
    { label: 'Tinerkouk', value: 'Tinerkouk' },
    { label: 'Charouine', value: 'Charouine' }
],
'Bordj Badji Mokhtar': [
         { label: 'Bordj Badji Mokhtar', value: 'Bordj Badji Mokhtar' },

],

'Ouled Djellal': [
    { label: 'Ouled Djellal', value: 'Ouled Djellal' },
    { label: 'Sidi Khaled', value: 'Sidi Khaled' }
],

'Béni Abbès': [
    { label: 'Béni Abbès', value: 'Béni Abbès' },
    { label: 'Tamtert', value: 'Tamtert' }
],

'In Salah': [
    { label: 'In Salah', value: 'In Salah' },
    { label: 'In Ghar', value: 'In Ghar' }
],

'In Guezzam': [
    { label: 'In Guezzam', value: 'In Guezzam' },
    { label: 'Tin Zaouatine', value: 'Tin Zaouatine' }
],

'Touggourt': [
   { label: 'Touggourt', value: 'Touggourt' }, 
],
'Djanet': [
         { label: 'Djanet', value: 'Djanet' },

],

'El Mghair': [
    { label: 'Djamaa', value: 'Djamaa' },
    { label: 'El Mghair', value: 'El Mghair' }
],

'El Meniaa': [
                { label: 'El Meniaa', value: 'El Meniaa' }


],
  // Add more dairas for other wilayas

}),
[] // Empty dependency array to avoid re-creation on each render
);

 // Default daira options when no wilayas are selected
 const defaultDairaOptions = useMemo(
  () => Object.values(wilayaToDairaMap).flat(),
  [wilayaToDairaMap]
);

// Compute filtered daira options based on selected wilayas
const filteredDairaOptions = useMemo(() => {
  if (selectedWilayas.length === 0) {
    return defaultDairaOptions; // Return all dairas if no wilayas are selected
  }

  const selectedDairas = selectedWilayas.reduce((acc, wilaya) => {
    const dairasForWilaya = wilayaToDairaMap[wilaya.value] || [];
    return acc.concat(dairasForWilaya);
  }, []);

  return selectedDairas;
}, [selectedWilayas, defaultDairaOptions, wilayaToDairaMap]); // Include all dependencies






    const specialtyOptions = [
      { value: "Médecin généraliste", label: translations.Médecingénéraliste },

      { value: "Chirurgien-dentiste", label: translations.Chirurgiendentiste },
      { value: "Ophtalmologue", label: translations.Ophtalmologue },
      { value: "Dermatologue et vénérologue", label: translations.Dermatologueetvénérologue },
      { value: "Allergologue", label: translations.Allergologue },
      { value: "Chirurgien urologue", label: translations.Chirurgienurologue },
      { value: "Rhumatologue", label: translations.Rhumatologue },
      { value: "Stomatologue", label: translations.Stomatologue },
      { value: "Endocrinologue", label: translations.Endocrinologue },
      { value: "Diététicien", label: translations.Diététicien },
      { value: "Psychologue", label: translations.Psychologue },
      { value: "Neurologue", label: translations.Neurologue },
      { value: "Psychiatre", label: translations.Psychiatre },
      { value: "Radiologue", label: translations.Radiologue },
      { value: "Cardiologue", label: translations.Cardiologue },
      { value: "Gastro-entérologue et hépatologue", label: translations.Gastroentérologueethépatologue },
      { value: "Dermatopathologue", label: translations.Dermatopathologue },
      { value: "Échographiste", label: translations.Échographiste },
      { value: "Endocrinologue diabétologue", label: translations.Endocrinologuediabétologue },
      { value: "Épithésiste", label: translations.Épithésiste },
      { value: "Ergothérapeute", label: translations.Ergothérapeute },
      { value: "Généticien", label: translations.Généticien },
      { value: "Gériatre", label: translations.Gériatre },
      { value: "Pédiatre", label: translations.Pédiatre },
      { value: "ORL", label: translations.ORL },

      { value: "Gynécologue médical", label: translations.Gynécologuemédical },
      { value: "Gynécologue médical et obstétrique", label: translations.Gynécologuemédicaletobstétrique },
      { value: "Gynécologue obstétricien", label: translations.Gynécologueobstétricien },
      { value: "Hématologue", label: translations.Hématologue },
      { value: "Hématologue biologiste", label: translations.Hématologuebiologiste },
      { value: "Homéopathe", label: translations.Homéopathe },
      { value: "Immunologue", label: translations.Immunologue },
      { value: "Infectiologue", label: translations.Infectiologue },
      { value: "Cancérologue", label: translations.Cancérologue },
      { value: "Cancérologue biologiste", label: translations.Cancérologuebiologiste },
      { value: "Cancérologue médical", label: translations.Cancérologuemédical },
      { value: "Cancérologue radiothérapeute", label: translations.Cancérologueradiothérapeute },

      { value: "Anatomo-cyto-pathologiste", label: translations.Anatomocytopathologiste },
      { value: "Andrologue", label: translations.Andrologue },
      { value: "Anesthésiste", label: translations.Anesthésiste },
      { value: "Angiologue", label: translations.Angiologue },
      { value: "Masseur-kinésithérapeute", label: translations.Masseurkinésithérapeute },
      { value: "Pédicure-podologue", label: translations.Pédicurepodologue },

      { value: "Audioprothésiste", label: translations.Audioprothésiste },
      { value: "Biologiste des agents infectieux", label: translations.Biologistedesagentsinfectieux },{ value: "Chercheur médical", label: translations.Chercheurmédical },
      { value: "Chiropracteur", label: translations.Chiropracteur },
      { value: "Chirurgien cancérologue", label: translations.Chirurgiencancérologue },
      { value: "Chirurgien de la face et du cou", label: translations.Chirurgiendelafaceetducou },
      { value: "Chirurgien de la main", label: translations.Chirurgiendelamain },
      { value: "Chirurgien endocrinien", label: translations.Chirurgienendocrinien },
      { value: "Chirurgien général", label: translations.Chirurgiengénéral },
      { value: "Chirurgien infantile", label: translations.Chirurgieninfantile },
      { value: "Chirurgien orthopédiste et traumatologue", label: translations.Chirurgienorthopédisteettraumatologue },
      { value: "Chirurgien maxillo-facial", label: translations.Chirurgienmaxillofacial },
      { value: "Chirurgien maxillo-facial et stomatologue", label: translations.Chirurgienmaxillofacialetstomatologue },
      { value: "Chirurgien oral", label: translations.Chirurgienoral },
      { value: "Chirurgien thoracique et cardio-vasculaire", label: translations.Chirurgienthoraciqueetcardiovasculaire },
      { value: "Chirurgien vasculaire", label: translations.Chirurgienvasculaire },
      { value: "Chirurgien viscéral et digestif", label: translations.Chirurgienviscéraletdigestif },
      { value: "Conseiller médico-social", label: translations.Conseillermédicosocial },
      { value: "Manipulateur en électroradiologie médicale", label: translations.Manipulateurenélectroradiologiemédicale },
      { value: "Médecin biologiste", label: translations.Médecinbiologiste },
      { value: "Médecin bucco-dentaire", label: translations.Médecinbuccodentaire },
      { value: "Médecin de la douleur", label: translations.Médecindeladouleur },
      { value: "Médecin de la reproduction", label: translations.Médecindelareproduction },
      { value: "Médecin de la reproduction et gynécologue médical", label: translations.Médecindelareproductionetgynécologuemédical },
      { value: "Médecin de soins palliatifs", label: translations.Médecindesoinspalliatifs },
      { value: "Médecin du sport", label: translations.Médecindusport },
      { value: "Médecin du travail", label: translations.Médecindutravail },
      { value: "Médecin morphologue et anti-âge", label: translations.Médecinmorphologueetantiâge },
      { value: "Médecin nucléaire", label: translations.Médecinnucléaire },
      { value: "Médecin nutritionniste", label: translations.Médecinnutritionniste },
      { value: "Médecin réanimateur", label: translations.Médecinréanimateur },
      { value: "Médecin spécialiste de santé publique", label: translations.Médecinspécialistedesantépublique },
      { value: "Médecin urgentiste", label: translations.Médecinurgentiste },
      { value: "Médecin vasculaire", label: translations.Médecinvasculaire },
      { value: "Néphrologue", label: translations.Néphrologue },
      { value: "Neurochirurgien", label: translations.Neurochirurgien },
      { value: "Neuro-pathologiste", label: translations.Neuropathologiste },
      { value: "Neuro-psychiatre", label: translations.Neuropsychiatre },
      { value: "Oculariste", label: translations.Oculariste },
      { value: "Onco-hématologue", label: translations.Oncohématologue },
      { value: "Oncologue médical", label: translations.Oncologuemédical },
      { value: "Onco-radiothérapeute", label: translations.Oncoradiothérapeute },
      { value: "ORL - Chirurgien de la face et du cou", label: translations.ORLChirurgiendelafaceetducou },
      { value: "Orthodontiste", label: translations.Orthodontiste },
      { value: "Orthopédiste-orthésiste", label: translations.Orthopédisteorthésiste },
      { value: "Orthophoniste", label: translations.Orthophoniste },
      { value: "Orthoprothésiste", label: translations.Orthoprothésiste },
      { value: "Orthoptiste", label: translations.Orthoptiste },
      { value: "Pharmacie", label: translations.Pharmacien },
      { value: "Phlébologue", label: translations.Phlébologue },
      { value: "Phoniatre", label: translations.Phoniatre },
      { value: "Pneumologue", label: translations.Pneumologue },
      { value: "Podo-orthésiste", label: translations.Podoorthésiste },
      { value: "Psychiatre enfant/adolescent", label: translations.Psychiatreenfant },
      { value: "Psychomotricien", label: translations.Psychomotricien },
      { value: "Psychothérapeute", label: translations.Psychothérapeute },
      { value: "Radiothérapeute", label: translations.Radiothérapeute },
      { value: "Spécialiste chirurgie plastique reconstructrice et esthétique", label: translations.Spécialisteenchirurgieplastiquereconstructriceetesthétique },
      { value: "Spécialiste hémobiologie-transfusion", label: translations.Spécialisteenhémobiologietransfusion },
      { value: "Spécialiste médecine aérospatiale", label: translations.Spécialisteenmédecineaérospatiale },
      { value: "Spécialiste médecine de catastrophe", label: translations.Spécialisteenmédecinedecatastrophe },
      { value: "Spécialiste médecine générale", label: translations.Spécialisteenmédecinegénérale },
      { value: "Spécialiste médecine interne", label: translations.Spécialisteenmédecineinterne },
      { value: "Spécialiste médecine légale et expertises médicales", label: translations.Spécialisteenmédecinelégaleetexpertisesmédicales },
      { value: "Spécialiste médecine physique réadaptation", label: translations.Spécialisteenmédecinephysiqueetderéadaptation },
      { value: "Spécialiste en réanimation", label: translations.Spécialisteenréanimation },
      { value: "Assistant de service social", label: translations.Assistantdeservicesocial },
      { value: "Acupuncteur", label: translations.Acupuncteur },
      { value: "Addictologue", label: translations.Addictologue },
      { value: "Auxiliaire de puériculture", label: translations.Auxiliairedepuériculture },
      { value: "Adjoint chirurgien-dentiste", label: translations.Adjointchirurgienentiste },
      { value: "Adjoint médecin", label: translations.Adjointmédecin },

 
      { value: "Technicien de biologie médicale", label: translations.Techniciendebiologiemédicale },
   { value: "Toxicologie", label: translations.Toxicologie },
   { value: "laboratoire d'analyse", label: translations.laboratoireanalyse },
   { value: "Opticien-lunetier", label: translations.Opticienlunetier },
   { value: "Infirmier en pratique avancée", label: translations.Infirmierenpratiqueavancée },
   { value: "Infirmière puéricultrice", label: translations.Infirmièrepuéricultrice },
 
   { value: "Infirmier", label: translations.Infirmier },
   { value: "Sage-femme", label: translations.Sagefemme },
   { value: "Sage-femme échographiste", label: translations.Sagefemmeéchographiste },

   { value: "veterinaire", label: translations.veterinaire },


    // Add more specialties as needed
  ];

  const [loadedDoctorsCount, setLoadedDoctorsCount] = useState(3); // Initial number of doctors to load

  const handleLoadMore = () => {
    const increment = 3; // Number of additional doctors to load each time "Load More" is clicked
    setLoadedDoctorsCount(loadedDoctorsCount + increment);
  };

  // Filter the doctors based on the loadedDoctorsCount
  const displayedDoctors = filteredDoctors.slice(0, loadedDoctorsCount);
  const [delayedContentVisible, setDelayedContentVisible] = useState(false);

  useEffect(() => {
    const delayTimeout = setTimeout(() => {
      setDelayedContentVisible(true);
    }, 10000);

    return () => clearTimeout(delayTimeout); // Clear the timeout if the component unmounts or if it re-renders
  }, []);



  const [wilayaClicked, setWilayaClicked] = useState(false);
  const [dairaClicked, setDairaClicked] = useState(false);

  // Handler for first click logic
  const handleWilayaClick = (e) => {
    if (!wilayaClicked) {
      setWilayaClicked(true);
      e.stopPropagation(); // Prevent keyboard opening on first click
    }
  };

  const handleDairaClick = (e) => {
    if (!dairaClicked) {
      setDairaClicked(true);
      e.stopPropagation(); // Prevent keyboard opening on first click
    }
  };






  return (
    <div className="container mx-auto">
    <div className="text-3xl font-semibold text-primary text-center mt-8 mb-4"></div>
    <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4">
      <div className="relative w-full mb-4">
        <div className="flex items-center mb-4">
          <div className="relative w-64  sm:w-1/3 mr-4 rounded  border-blue-300"> {/* Add border class */}
            <Select
              options={wilayaOptions}
              isMulti
              value={selectedWilayas}
              onChange={(selectedOptions) =>
                setSelectedWilayas(selectedOptions)
              }
              placeholder={translations.SelectWilaya}
              className=" border text-gray-800 rounded  border-sky-400 "
            />
          </div>
          <div className="relative w-64 sm:w-1/3 rounded border-sky-400"> 
            <Select
             options={filteredDairaOptions} // Daira options based on selected wilayas
             isMulti
             value={selectedDairas}
             onChange={(selectedOptions) => setSelectedDairas(selectedOptions)}
             placeholder={translations.SelectDaira}  
               className=" border text-gray-800 rounded border-sky-400  "
            />
          </div>
        </div>
        <div className="relative flex-grow border-blue-300">
          <input
            type="text"
            placeholder={translations.searchdoctor}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full py-2 pl-8 pr-3  text-gray-800  rounded-full bg-white border border-blue-400 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
          />
          <div className="absolute inset-y-0  text-gray-800 left-0 pl-3 flex items-center pointer-events-none">
            <FaSearch className="h-5 w-5 text-blue-400 " /> {/* Search icon */}
          </div>
        </div>
      </div>
  
      <div className="rounded border-blue-200 relative w-full"> {/* Add border class */}
        <Select
          options={specialtyOptions}
          isMulti
          value={selectedSpecialties}
          onChange={(selectedOptions) =>
            setSelectedSpecialties(selectedOptions)
          }
          placeholder={translations.Selectfield}
          className="rounded border  text-gray-800 border-sky-400  "
        />
      </div>
    </div>
      {loading ? (
            <Spinner />
            ) : (
        <div className="doctor-list-items py-4 z-0">
        {displayedDoctors.length > 0 ? (
          displayedDoctors.map((doctor, index) => (
            <div key={doctor.id}>
              <DoctorListingItem doctor={doctor.data} />
              {index !== displayedDoctors.length - 1 && (
                <hr className="my-4 border-t border-gray-300" />
              )}
            </div>
          ))
        ) : (
          <div className="">
           {delayedContentVisible && (   <div>
            <FaLaptopMedical size={40} className=" dark:text-blue-100 text-sky-500  hover:text-blue-700 font-semibold transition-all duration-300 ease-in-out transform hover:rotate-12" />
            <div className="text-gray-700 dark:text-blue-100 text-center text-xl mb-6">
            
            {translations.nodoctor}

            </div>
          </div>
          )}
        </div>
        )}
      </div>
    )} {filteredDoctors.length > loadedDoctorsCount && (
        <button
          onClick={handleLoadMore}
          className="block mx-auto my-4 bg-blue-500 text-white border border-blue-500 hover:bg-blue-700 hover:border-blue-700 font-semibold rounded-full px-6 py-3 shadow-md sticky"
        >
          <span className=" text-center">
            <TfiMore   className="text-white font-semibold transition-all duration-300 ease-in-out transform hover:rotate-12  " />
          </span>
          {translations.seeMoreDoctors}
        </button>
      )}
      
    </div>
  );
};

export default DoctorsDisplayPage;
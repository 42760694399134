function TermsAndConditions() {
    return (
      <div className="container mx-auto bg-blue-200 py-8 px-4 overflow-scroll ">
        <h1 className="text-3xl font-bold mb-4">Politique de Confidentialité de Doktori</h1>
        <p className="mb-6">Doktori attache une grande importance à la protection de la vie privée de ses 
utilisateurs. Cette politique de confidentialité explique comment nous recueillons, 
utilisons, partageons et protégeons vos informations lorsque vous utilisez notre 
application et notre site web (collectivement désignés par "la Plateforme"). En utilisant 
la Plateforme, vous consentez à la collecte et à l'utilisation de vos informations 
conformément à cette politique</p>
        
        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Définition :</h2>
          <p className="mb-2">a. Pour les besoins des présentes conditions d'utilisation, le terme "utilisateurs" fait référence à toutes les personnes utilisant la plateforme Doktori, y compris mais sans s'y limiter les patients et les professionnels de la santé.</p>
          <p className="mb-2">b. Le terme "professionnels de la santé" fait référence spécifiquement aux utilisateurs qui possèdent des comptes professionnels sur la plateforme Doktori, y compris mais sans s'y limiter les médecins, les infirmières, les thérapeutes, et autres praticiens de la santé autorisés.</p>
          <p className="mb-2">c. Dans les présentes conditions d'utilisation, le terme "Doktori" fait référence à la plateforme en ligne, comprenant le site web, l'application mobile et toute entité ou organisation associée exploitant la plateforme.</p>
          <p>d. Toutes les références aux droits, obligations, responsabilités ou autres dispositions applicables aux utilisateurs ou aux professionnels de la santé dans les présentes conditions d'utilisation seront interprétées en tenant compte de ces définitions.</p>
        </section>
  
        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4">
Informations Collectées :</h2>
      <p>a. Informations Personnelles : Doktori peut collecter des informations personnelles y compris mais sans s'y limiter votre nom, votre adresse e-mail, votre numéro de téléphone, votre adresse postale, et d'autres informations similaires lorsque vous vous inscrivez sur la Plateforme ou que vous utilisez nos services.</p>
      <p>b. Informations Médicales : Lors de la prise de rendez-vous ou de la consultation médicale,  vous les utilisateurs, pouvez choisir de partager des informations médicales avec les professionnels de santé.</p>
      <p>c. Données d'Utilisation : Doktori recueilli automatiquement des informations sur la manière dont vous utilisez la Plateforme, telles que vos interactions avec les fonctionnalités de la Plateforme, votre adresse IP, vos données de géolocalisation, et d'autres données de diagnostic et de performance.</p>
     </section>
  
        <section>
          <h2 className="text-2xl font-bold mb-4">Utilisation des Informations :</h2>
          <p>a. Doktori utilise vos informations pour fournir, maintenir et améliorer ses services, y compris mais sans s'y limiter pour vous permettre de prendre des rendez-vous médicaux, de communiquer avec les professionnels de santé, et d'accéder à d'autres fonctionnalités de la Plateforme.</p>
      <p>b. Doktori peut également utiliser vos informations pour vous envoyer des communications marketing, des mises à jour sur nos services et d'autres informations susceptibles de vous intéresser.</p>
        </section>
  
        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Partage des Informations :</h2>
          <p>Doktori ne partage vos informations personnelles avec des tiers que dans les cas suivants :</p>
      <p>a. Aux prestataires de services tels que les processeurs de paiement, les institutions financières, les fournisseurs de technologies, des fournisseurs d'hébergement, d'analyses, de marketing, de publicité et les partenaires et conseillers professionnels tiers (c'est-à-dire les auditeurs, les cabinets d'avocats, etc.), à condition que les prestataires de services acceptent de garder les informations confidentielles.</p>
      <p>b. Avec les organismes gouvernementaux et les forces de l'ordre ou lorsque nous croyons que la divulgation est conforme à, ou requise par, toute loi applicable ou tout processus juridique, y compris les demandes légales des autorités publiques pour répondre aux exigences de sécurité nationale ou d'application de la loi ou pour protéger nos droits, notre propriété ou notre sécurité, ainsi que ceux de nos utilisateurs ou du public. Si la loi l'exige ou le permet, et si cela est jugé nécessaire ou justifié, nous pouvons informer les utilisateurs avant la divulgation.</p>

      
        </section>
  
        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Protection des Informations : </h2>
          <p>a. Doktori met en place des mesures de sécurité appropriées pour protéger vos informations contre tout accès non autorisé, toute divulgation, toute altération ou toute destruction.</p>
      <p>b. Malgré nos efforts pour sécuriser nos systèmes, aucune méthode de transmission ou de stockage de données n'est totalement sûre. Par conséquent, nous ne pouvons garantir la sécurité absolue de vos informations.</p>

     </section>
      
      <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Données d'Utilisation :</h2>
          <p>a. Doktori pout également recueillir des informations que votre navigateur nous envoie chaque fois que vous visitez notre Service ou lorsque vous y accédez via un appareil mobile ("Données d'Utilisation").</p>
      <p>b. Ces Données d'Utilisation peuvent inclure des informations telles que votre adresse de protocole Internet (par exemple, adresse IP...), le type de navigateur, la version du navigateur, les pages de notre Service que vous visitez, l'heure et la date de votre visite, le temps passé sur ces pages, des identifiants uniques de l'appareil, votre pays et d'autres données de diagnostic.</p>
      <p>c. Lorsque vous accédez au Service via un appareil mobile, ces Données d'Utilisation peuvent inclure des informations telles que votre type de navigateur, votre système d'exploitation, le type d'appareil, la page servie, votre adresse IP, la manière dont vous êtes arrivé sur notre site Web, l'heure et la durée de votre visite, vos préférences linguistiques, votre géolocalisation approximative et les sites Web que vous avez visités avant de visiter les Services. Lorsque vous téléchargez et utilisez l'une de nos applications, nous et nos fournisseurs de services pouvons suivre et collecter des données d'utilisation de l'application, telles que la date et l'heure auxquelles l'application sur votre appareil accède à nos serveurs et quelles informations et fichiers ont été téléchargés sur l'application en fonction de votre numéro de dispositif.</p>    </section>
  
        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Comment Doktori Utilise les Informations :</h2>
          <p>a. Fournir, maintenir et améliorer nos services, notamment en personnalisant votre expérience utilisateur et en répondant à vos besoins individuels.</p>
      <p>b. Analyser l'utilisation du service, surveiller l'activité et les tendances, et recueillir des informations démographiques sur nos utilisateurs dans l'ensemble.</p>
      <p>c. Diagnostiquer les problèmes techniques et résoudre les problèmes rencontrés sur notre plateforme.</p>
      <p>d. Envoyer des communications jugé importantes y compris mais sans s'y limiter des notifications de service, des alertes de sécurité, des mises à jour de politique ou d'autres informations administratives.</p>
      <p>e. Personnaliser le contenu et les annonces que vous voyez sur notre plateformes.</p>
      <p>f. Vous fournir un support client et une assistance technique, le cas échéant.</p>
      <p>g. Faciliter votre accès et votre utilisation du service, y compris mais sans s'y limiter en vous authentifiant lorsque vous vous connectez et en conservant certaines préférences utilisateur.</p>
      <p>h. Se conformer aux obligations légales et réglementaires, ainsi qu'aux demandes des autorités gouvernementales, le cas échéant.</p>
      <p>i. Protéger nos droits, nos biens ou notre sécurité, ainsi que ceux de nos utilisateurs ou du public, conformément à nos conditions d'utilisation et à d'autres politiques applicables.</p>
      <p>j. Vous envoyer des communications marketing, y compris mais sans s'y limiter des newsletters, des offres spéciales, des promotions ou des annonces de nouveaux produits ou services, susceptibles de vous intéresser.</p>
      <p>k. Évaluer l'efficacité de nos campagnes marketing et de nos initiatives promotionnelles, en mesurant le taux de clics, le taux de conversion et d'autres métriques pertinentes.</p>
      <p>l. Vous inviter à participer à des enquêtes, des sondages ou des études de marché, afin de recueillir vos commentaires et vos opinions sur nos produits, services et initiatives marketing.</p>
  </section>
  
        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Accès et Contrôle de Vos Informations :</h2>
          <p>a. Vous les utilisateurs pouvez accéder, modifier vos informations personnelles à tout moment en vous connectant à votre compte sur la Plateforme et en modifiant vos paramètres de profil.</p>
      <p>b. Vous les utilisateurs pouvez également demander la suppression de vos données en nous contactant à l'adresse fournie en bas de cette politique. Nous répondrons à votre demande dans les meilleurs délais, sauf si nous sommes légalement tenus de conserver certaines informations.</p>
      </section>
  
        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Cookies et Technologies Similaires :</h2>
          <p>a. Doktori utilise des cookies et d'autres technologies de suivi pour collecter des informations sur votre utilisation de la Plateforme et pour personnaliser votre expérience. Vous pouvez modifier vos paramètres de cookies à tout moment en modifiant les paramètres de votre navigateur.</p>
  </section>
  
             <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Changements de la Politique de Confidentialité :</h2>
          <p>a. Doktori se réserve le droit de changer et/ou de mettre à jour cette politique de confidentialité de temps à autre pour refléter les changements dans nos pratiques de collecte et de gestion des informations. Il incombe à l'utilisateur de vérifier régulièrement cette politique de confidentialité pour prendre connaissance de toute modification. En continuant à utiliser la Plateforme après la publication des modifications, vous acceptez ces changements.</p>
        </section>
  





  
        <h1 className="text-3xl font-bold mb-4">Politique de Suppression de Compte</h1>
        <p className="mb-6">Doktori offre un processus clair et simple pour la suppression de compte.
</p>
        
        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Initiation de la Suppression de Compte :</h2>
          <p className="mb-2">a. Les utilisateurs qui souhaitent supprimer leur compte peuvent le faire en contactant notre équipe de support.
</p>
          <p className="mb-2">b. Les utilisateurs doivent envoyer un email depuis l'adresse qu'ils ont utilisée pour s'inscrire à [support@doktori.site].
</p>
          <p className="mb-2">c. L'email doit également inclure l'ID de compte de l'utilisateur, qui peut être trouvé dans la section "Réglages Profil" de l'application.</p>
        </section>
       
        <section className="mb-8">   
  <h2 className="text-2xl font-bold mb-4">Exigences de l'Email :</h2>
  <p className="mb-2">a. L'email doit avoir pour objet : "Demande de Suppression de Compte".</p>
  <p className="mb-2">b. Dans le corps de l'email, les utilisateurs doivent confirmer leur demande de suppression de compte, fournir leur ID de compte, et peuvent fournir des informations supplémentaires pour la vérification si nécessaire.</p>
</section>

<section className="mb-8">
  <h2 className="text-2xl font-bold mb-4">Processus de Vérification :</h2>
  <p className="mb-2">a. Dès réception de la demande de suppression de compte, notre équipe de support vérifiera la demande en vérifiant l'adresse email et l'ID de compte fournis.</p>
  <p className="mb-2">b. Nous pouvons contacter l'utilisateur pour une vérification supplémentaire si nécessaire afin de garantir la sécurité du compte.</p>
</section>

<section className="mb-8">
  <h2 className="text-2xl font-bold mb-4">Suppression des Données :</h2>
  <p className="mb-2">a. Une fois la demande de suppression de compte vérifiée, les données fournies par l'utilisateur lors de leur inscription/ouverture d’un compte seront définitivement supprimées de nos serveurs dans un délai de 90 jours.</p>
  <p className="mb-2">b. Données concernées : Les données fournies lors de l'inscription comprennent par exemple : « le nom, l'adresse email, le numéro de téléphone, l'adresse physique, et les informations de profil, Etc.»</p>
  <p className="mb-2">c. Veuillez noter que certaines données peuvent être conservées à des fins légales ou réglementaires, mais elles ne seront plus liées au compte supprimé.</p>
</section>

<section className="mb-8">
  <h2 className="text-2xl font-bold mb-4">Effet de la Suppression :</h2>
  <p className="mb-2">a. La suppression d'un compte est une action permanente et ne peut pas être annulée. Les utilisateurs perdront l'accès à toutes les données et services associés à leur compte.</p>
  <p className="mb-2">b. Les utilisateurs recevront un email de confirmation une fois le processus de suppression terminé.</p>
</section>



<h1 className="text-3xl font-bold mb-4">Politique de Suppression des Datas </h1>
        <p className="mb-6">Doktori offre un processus clair et simple pour la suppression de data.
</p>
        
        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Initiation de la Suppression de data:</h2>
          <p className="mb-2">a. Les utilisateurs qui souhaitent supprimer leur data peuvent le faire en contactant notre équipe de support.
</p>
          <p className="mb-2">b. Les utilisateurs doivent envoyer un email depuis l'adresse qu'ils ont utilisée pour s'inscrire à [support@doktori.site].
</p>
          <p className="mb-2">c. L'email doit également inclure l'ID de compte de l'utilisateur, qui peut être trouvé dans la section "Réglages Profil" de l'application.</p>
        </section>
       
        <section className="mb-8">   
  <h2 className="text-2xl font-bold mb-4">Exigences de l'Email :</h2>
  <p className="mb-2">a. L'email doit avoir pour objet : "Demande de Suppression de Data".</p>
  <p className="mb-2">b. Dans le corps de l'email, les utilisateurs doivent indiquer le type de data Qu'il souhaite supprimer Exmple: "je souhaite la suppression de mon historique de rendez-vous médicaux" dans leur demande de suppression de compte, fournir leur ID de compte, et peuvent fournir des informations supplémentaires pour la vérification si nécessaire.</p>
</section>

<section className="mb-8">
  <h2 className="text-2xl font-bold mb-4">Processus de Vérification :</h2>
  <p className="mb-2">a. Dès réception de la demande de suppression de data, notre équipe de support vérifiera la demande en vérifiant l'adresse email et l'ID de compte fournis.</p>
  <p className="mb-2">b. Nous pouvons contacter l'utilisateur pour une vérification supplémentaire si nécessaire afin de garantir la sécurité du compte.</p>
</section>


<section className="mb-8">
  <h2 className="text-2xl font-bold mb-4">Effet de la Suppression :</h2>
  <p className="mb-2">a. La suppression de data est une action permanente et ne peut pas être annulée. Les utilisateurs perdront l'accès à toutes les données et services associés à leur compte.</p>
  <p className="mb-2">b. Les utilisateurs recevront un email de confirmation une fois le processus de suppression terminé.</p>
</section>


  
        <footer className="text-center mt-8 text-sm text-gray-500">
          <p>Pour toute question, préoccupation ou réclamation concernant Doktori ou ces conditions d'utilisation, les utilisateurs peuvent contacter l'équipe de support à l'adresse fournie [ support@doktori.site ].
 sur la plateforme.</p>
        </footer>
      </div>
    );
  }
  
  export default TermsAndConditions;
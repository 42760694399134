import React, { useState } from 'react';
import Select from 'react-select';
import moment from 'moment';

 
const EditDayPopup = ({ isOpen, initialValues, onSave, onCancel, timeOptions, endTimeOptions }) => {
  const [startTime, setStartTime] = useState(initialValues.startTime);
  const [endTime, setEndTime] = useState(initialValues.endTime);
  const [averageDuration, setAverageDuration] = useState(initialValues.averageDuration);
  const [maxPatients, setMaxPatients] = useState(initialValues.maxPatients);
  const [isStartTimeSelected, setIsStartTimeSelected] = useState(false); // Define isStartTimeSelected

  const handleSave = () => {
    const editedSettings = {
      startTime,
      endTime,
      averageDuration,
      maxPatients,
    };
    const updatedSettings = {}; 
    
    onSave(editedSettings, updatedSettings);
  
  };
  

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-700 bg-opacity-75">
      <div className="bg-white p-4 rounded shadow-lg">
        <h2 className="text-xl font-semibold mb-2">Configurer les paramètres du jour</h2>

       <label className="block text-sm font-medium text-gray-700">Heure de début :</label>
<Select
  options={timeOptions}
  value={timeOptions.find((option) => option.value === startTime)}
  onChange={(selectedOption) => {
    const selectedStartTime = selectedOption.value;
    const availableEndTimeOptions = timeOptions.slice(selectedStartTime + 1);
    const newEndTime = availableEndTimeOptions[0].value;

    // Adjust end time if necessary
    const adjustedEndTime = moment(endTime, 'HH:mm').isAfter(moment(newEndTime, 'HH:mm'))
      ? endTime
      : newEndTime;

    setStartTime(selectedStartTime);
    setEndTime(adjustedEndTime);
    setIsStartTimeSelected(true);
  }}
/>

<label className="block text-sm font-medium text-gray-700">Heure de fin :</label>
<Select
  options={endTimeOptions.filter((option) => option.value >= startTime)}
  value={{ value: endTime, label: `${endTime}:00` }}
  onChange={(selectedOption) => {
    setEndTime(selectedOption.value);
  }}
  isDisabled={!isStartTimeSelected}
/>

<label className="block text-sm font-medium text-gray-700">
  Durée moyenne (minutes) :
</label>
<input
  type="number"
  value={averageDuration}
  onChange={(e) => setAverageDuration(parseInt(e.target.value))}
  className="mt-1 p-2 border rounded-md w-full"
/>

<label className="block text-sm font-medium text-gray-700">
  Maximum de patients :
</label>
<input
  type="number"
  value={maxPatients}
  onChange={(e) => setMaxPatients(parseInt(e.target.value))}
  className="mt-1 p-2 border rounded-md w-full"
/>
{maxPatients < 0 && (
  <p className="text-red-600">Le nombre maximum de patients doit être positif.</p>
)}

        <div className="mt-4 text-right">
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mr-2"
            onClick={handleSave}
          >
            Enregistrer
          </button>
          <button
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
            onClick={onCancel}
          >
            Annuler
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditDayPopup;

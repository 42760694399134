import React from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../components/LanguageContext'; 
import { FaFileContract, FaUserDoctor  } from "react-icons/fa6";
import { TbDeviceIpadMinus } from "react-icons/tb";
import { TbPoint } from "react-icons/tb";
import { FaFileShield } from "react-icons/fa6";

function Footer() {

  const { language, translations } = useLanguage();

  return (
    <footer className="bg-gray-100 dark:bg-gray-200 py-6">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {/* About Us */}
          <div>

        
       
         <div>
         <Link
                to="/compte-Pro"
           className="text-lg hover:text-blue-400 font-semibold mb-4">
                     <FaUserDoctor  size={30} className=" font-semibold transition-all duration-300 ease-in-out transform hover:rotate-12" />
   
            {translations.BecomingPartner}
  </Link>
  </div>

              <Link
                to="/compte-Pro"
                className="text-gray-600 hover:text-blue-400 transition duration-200 ease-in-out"
              >
                
                {translations.BecomingPartner2}
  </Link>
        
          </div>
          
          <div>
         <Link
                to="/security"
           className="text-lg hover:text-blue-400 font-semibold mb-4">
                     <FaFileShield   size={30} className=" font-semibold transition-all duration-300 ease-in-out transform hover:rotate-12" />
   
            {translations.certificatofsecurity}
  </Link>
  </div>
     
          {/* Contact Us */}
          <div>
            <h2 className="text-lg font-semibold mb-4">
            <TbDeviceIpadMinus  size={30} className=" font-semibold transition-all duration-300 ease-in-out transform hover:rotate-12" />
      {translations.Contact}</h2>
            
            <p className="text-gray-600">{translations.email}: support@doktori.site </p>
          </div>
      
          <div>
     <Link
           to="/term-service" className="text-lg hover:text-blue-400 font-semibold mb-4">
                   <FaFileContract  size={30} className="font-semibold transition-all duration-300 ease-in-out transform hover:rotate-12" />
     {translations.Conditionsutilisation2}
         </Link>
     
         <div>
         {translations.Conditionsutilisation3}

      </div>

      
      <div className="flex items-center mb-4">
      <span className="bg-gray-600 text-white rounded-full p-2 mr-3"><i className="fas fa-calendar-alt"></i></span>
   <Link
           to="/Politique-de-Confidentialité" className="text-lg hover:text-blue-400 font-semibold">

     {translations.PolitiquedeConfidentialité}
     
         </Link>
         </div>
               
     </div>
   
        </div>
      </div>
      
             {/* Bottom Footer */}
       <div className="container mx-auto mt-8 border-t border-gray-200 pt-8 flex justify-between items-center">
        <p className="text-gray-600">&copy; {new Date().getFullYear()} DoKtori App. All rights reserved.</p>
   
         </div>
    </footer>
  );
}

export default Footer;

import React from 'react';
import { useDarkMode } from './DarkModeContext';
import DarkModeToggle from 'react-dark-mode-toggle'; // Import the toggle component

const DarkModeTogglecomp = () => {
  const { isDarkMode, toggleDarkMode } = useDarkMode();
 return (
      <div > {/* Wrapper for alignment */}
        <DarkModeToggle
          onChange={toggleDarkMode} // Toggle dark mode
          checked={isDarkMode} // Checked state based on dark mode
          size={50} // Size of the toggle, adjust as needed
          speed={2} // Transition speed for switching
        />
      </div>
    );
  };

export default DarkModeTogglecomp;

function deleteaccount() {
    return (
      <div className="container mx-auto bg-blue-200 py-8 px-4 overflow-scroll ">
        <h1 className="text-3xl font-bold mb-4">Politique de Suppression de Compte</h1>
        <p className="mb-6">Doktori offre un processus clair et simple pour la suppression de compte.
</p>
        
        <section className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Initiation de la Suppression de Compte :</h2>
          <p className="mb-2">a. Les utilisateurs qui souhaitent supprimer leur compte peuvent le faire en contactant notre équipe de support.
</p>
          <p className="mb-2">b. Les utilisateurs doivent envoyer un email depuis l'adresse qu'ils ont utilisée pour s'inscrire à [support@doktori.site].
</p>
          <p className="mb-2">c. L'email doit également inclure l'ID de compte de l'utilisateur, qui peut être trouvé dans la section "Réglages Profil" de l'application.</p>
        </section>
       
        <section className="mb-8">   
  <h2 className="text-2xl font-bold mb-4">Exigences de l'Email :</h2>
  <p className="mb-2">a. L'email doit avoir pour objet : "Demande de Suppression de Compte".</p>
  <p className="mb-2">b. Dans le corps de l'email, les utilisateurs doivent confirmer leur demande de suppression de compte, fournir leur ID de compte, et peuvent fournir des informations supplémentaires pour la vérification si nécessaire.</p>
</section>

<section className="mb-8">
  <h2 className="text-2xl font-bold mb-4">Processus de Vérification :</h2>
  <p className="mb-2">a. Dès réception de la demande de suppression de compte, notre équipe de support vérifiera la demande en vérifiant l'adresse email et l'ID de compte fournis.</p>
  <p className="mb-2">b. Nous pouvons contacter l'utilisateur pour une vérification supplémentaire si nécessaire afin de garantir la sécurité du compte.</p>
</section>

<section className="mb-8">
  <h2 className="text-2xl font-bold mb-4">Suppression des Données :</h2>
  <p className="mb-2">a. Une fois la demande de suppression de compte vérifiée, les données fournies par l'utilisateur lors de leur inscription/ouverture d’un compte seront définitivement supprimées de nos serveurs dans un délai de 90 jours.</p>
  <p className="mb-2">b. Données concernées : Les données fournies lors de l'inscription comprennent par exemple : « le nom, l'adresse email, le numéro de téléphone, l'adresse physique, et les informations de profil, Etc.»</p>
  <p className="mb-2">c. Veuillez noter que certaines données peuvent être conservées à des fins légales ou réglementaires, mais elles ne seront plus liées au compte supprimé.</p>
</section>

<section className="mb-8">
  <h2 className="text-2xl font-bold mb-4">Effet de la Suppression :</h2>
  <p className="mb-2">a. La suppression d'un compte est une action permanente et ne peut pas être annulée. Les utilisateurs perdront l'accès à toutes les données et services associés à leur compte.</p>
  <p className="mb-2">b. Les utilisateurs recevront un email de confirmation une fois le processus de suppression terminé.</p>
</section>


        <footer className="text-center mt-8 text-sm text-gray-500">
          <p>Pour toute question, préoccupation ou réclamation concernant Doktori ou ces conditions d'utilisation, les utilisateurs peuvent contacter l'équipe de support à l'adresse fournie [ support@doktori.site ].
 sur la plateforme.</p>
        </footer>
      </div>
    );
  }
  
  export default deleteaccount;
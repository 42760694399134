import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");

  function onChange(e) {
    setEmail(e.target.value);
  }

  async function onSubmit(e) {
    e.preventDefault();
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
      toast.success("Email was sent");
    } catch (error) {
      toast.error("Could not send reset password");
    }
  }

  return (
    <section className="bg-gray-100 min-h-screen flex items-center justify-center">
      <div className="max-w-md mx-auto p-8 bg-white rounded-lg shadow-lg">
        <h1 className="text-3xl text-center font-bold mb-4 my-1">Réinitialisation du mot de passe</h1>
        <p className="text-gray-500 text-center mb-4">
          
          Veuillez saisir l'adresse e-mail associée à votre compte. vous recevrez un e-mail contenant les instructions pour mettre à jour votre mot de passe.
         
        </p>
        <form onSubmit={onSubmit}>
          <div className="mb-6">
            <label htmlFor="email" className="block mb-2">
              Adresse e-mail
            </label>
            <input
              type="email"
              id="email"
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:ring-blue-500"
              placeholder="Entrez votre adresse e-mail"
              value={email}
              onChange={onChange}
              />
          </div>
          <div className="mb-6">
            <button
              type="submit"
              className="w-full py-2 px-4 bg-blue-500 hover:bg-blue-600 text-white rounded focus:outline-none focus:ring-blue-500 active:bg-blue-800"
            >
              Réinitialiser le mot de passe
            </button>
          </div>
          <div className="text-center">
            <p className="text-gray-500">
              Vous connaissez votre mot de passe ?{' '}
              <Link to="/mon-compte" className="text-blue-500 hover:underline">
                Connectez-vous
              </Link>
            </p>
          </div>
        </form>
      </div>
    </section>
  );
}

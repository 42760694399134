import React from 'react';
import { FaCheckCircle, FaTimesCircle, FaLock, FaShieldAlt, FaCertificate } from 'react-icons/fa';
import { TbCertificate } from 'react-icons/tb';

// Liste des certifications de sécurité
const certifications = [
  {
    name: 'ISO/IEC 27001:2013',
    description: 'Norme internationale pour la gestion de la sécurité de l\'information.',
    certified: true
  },
  {
    name: 'HIPAA',
    description: 'Conformité pour le stockage et le traitement des informations de santé.',
    certified: true
  },
  {
    name: 'ISO/IEC 27017:2015',
    description: 'Norme sur la sécurité des informations pour les services de cloud computing.',
    certified: true
  },
  {
    name: 'ISO/IEC 27018:2019',
    description: 'Norme pour la protection des données personnelles dans le cloud.',
    certified: true
  },
  {
    name: 'SOC 1, SOC 2, et SOC 3',
    description: 'Rapports évaluant les contrôles internes relatifs à la sécurité, la disponibilité, et la confidentialité.',
    certified: true
  },
  {
    name: 'GDPR',
    description: 'Conformité au Règlement Général sur la Protection des Données pour les entreprises en Europe.',
    certified: true
  },
  {
    name: 'PCI-DSS',
    description: 'Conformité pour le traitement des informations de carte de paiement.',
    certified: true
  }
];

// Composant pour chaque carte de certification
const CertificationCard = ({ name, description, certified }) => (
  <div className="p-6 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-2xl">
    <div className="flex items-center mb-4">
      <TbCertificate size={50} className="text-blue-500 dark:text-blue-400 w-14 h-14" />
      <div className="ml-4">
        <h3 className="text-2xl font-semibold text-gray-900 dark:text-gray-100">{name}</h3>
        <p className="mt-1 text-gray-700 dark:text-gray-300">{description}</p>
      </div>
    </div>
    <div className="flex items-center mt-4">
      {certified ? (
        <FaCheckCircle size={30} className="text-green-500 dark:text-green-400" />
      ) : (
        <FaTimesCircle size={30} className="text-red-500 dark:text-red-400" />
      )}
      <span className={`ml-2 text-sm font-medium ${certified ? 'text-green-600 dark:text-green-300' : 'text-red-600 dark:text-red-300'}`}>
        {certified ? 'Certifié' : 'Non Certifié'}
      </span>
    </div>
  </div>
);

// Composant principal pour afficher les certifications, l'explication de Firestore, et le chiffrement HTTPS
const Security = () => (
  <div className="bg-gray-100 dark:bg-gray-900 min-h-screen py-12" style={{ background: 'linear-gradient(135deg, #e0e0e0, #f0f0f0)' }}>
    <div className="container mx-auto p-8 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
      <h1 className="text-4xl font-bold mb-8 text-center text-blue-800 dark:text-blue-400">
        <FaShieldAlt className="inline-block text-5xl mr-3" /> Sécurité de Doktori
      </h1>
      <p className="mb-6 text-lg text-gray-700 dark:text-gray-300 leading-relaxed">
        Doktori héberge ses données chez <strong>Cloud Firestore</strong>, un service de base de données entièrement géré et flexible
        proposé par <strong>Google</strong>. Ce service robustes, assure une haute disponibilité et une scalabilité automatique. Firestore garantit également la sécurité des données grâce aux certifications suivantes :
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {certifications.map(cert => (
          <CertificationCard
            key={cert.name}
            name={cert.name}
            description={cert.description}
            certified={cert.certified}
          />
        ))}
      </div>
      <h2 className="text-3xl font-bold mt-12 mb-4 text-blue-800 dark:text-blue-400">
        <FaLock className="inline-block text-4xl mr-3" /> Chiffrement des Données en Transit
      </h2>
      <p className="mb-6 text-lg text-gray-700 dark:text-gray-300 leading-relaxed">
        Doktori utilise le protocole <strong>TLS (Transport Layer Security)</strong> pour chiffrer les données en transit. TLS assure que toutes les communications entre nos utilisateurs et nos serveurs sont sécurisées et protégées contre les interceptions non autorisées. 
      </p>
      <p className="mb-6 text-lg text-gray-700 dark:text-gray-300 leading-relaxed">
        Doktori utilise le chiffrement HTTPS (HyperText Transfer Protocol Secure) pour assurer la confidentialité et l'intégrité des données échangées entre nos utilisateurs et nos serveurs. HTTPS protège les communications contre les attaques de type Man-in-the-Middle (MitM) et assure que les données transmises ne peuvent pas être interceptées ou altérées par des parties non autorisées.
      </p>
      <h2 className="text-3xl font-bold mt-12 mb-4 text-blue-800 dark:text-blue-400">
        <FaCertificate className="inline-block text-4xl mr-3" /> Mode SSL/TLS Complet (Strict)
      </h2>
      <p className="mb-6 text-lg text-gray-700 dark:text-gray-300 leading-relaxed">
        Doktori utilise le mode <strong>SSL/TLS Complet (strict)</strong> pour ses communications sécurisées. Cela signifie que le serveur présente un certificat SSL/TLS valide et que toutes les données échangées sont chiffrées, garantissant ainsi la confidentialité et l'intégrité des informations transmises. Ce mode offre une sécurité maximale en rejetant toute tentative de connexion non sécurisée.
      </p>
    </div>
  </div>
);

export default Security;

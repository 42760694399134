import React, { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { doc, getDoc, onSnapshot, updateDoc, Timestamp } from 'firebase/firestore';
import { db } from '../firebase';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { FaBookMedical } from "react-icons/fa6";
import { LuCalendarClock,  LuFolderArchive } from "react-icons/lu";
import { useLanguage } from '../components/LanguageContext'; 

import { ImCalendar } from 'react-icons/im';
import { BsGenderFemale, BsGenderMale, BsGenderAmbiguous } from 'react-icons/bs';
import { CgToday } from 'react-icons/cg';
import { AiOutlineFieldTime } from 'react-icons/ai';
import { TbReport } from 'react-icons/tb';
import { TbCalendarCancel, TbCheckupList } from 'react-icons/tb';
import { IoClose } from 'react-icons/io5';
import { IoMdTime } from 'react-icons/io';

import { MdOutlineBloodtype } from "react-icons/md";
import { GiSandsOfTime } from "react-icons/gi";
import { LiaAllergiesSolid } from "react-icons/lia";
import { MdOutlineSick } from "react-icons/md";
import { MdPhone } from "react-icons/md";
import { FaNotesMedical, FaArrowTurnDown } from "react-icons/fa6";
import { HiOutlineClipboardCheck } from "react-icons/hi";
import { FaTimes, FaSearch, FaUsers } from 'react-icons/fa';

export default function MesRdv() {
  const [userAppointments, setUserAppointments] = useState({ upcoming: [], previous: [] });
  const [displayedPreviousAppointments, setDisplayedPreviousAppointments] = useState(3);
  const [sortedPreviousAppointments, setSortedPreviousAppointments] = useState([]);

  const [showPreviousAppointments, setShowPreviousAppointments] = useState(true);
  const { language, translations } = useLanguage();
  const [isNewdateConfirmationVisible, setNewdateConfirmationVisible] = useState(false);
  const [isNewdateCancelVisible, setNewdateCancelVisible] = useState(false);

  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const auth = getAuth();

  const getCurrentDateTime = () => {
    const now = new Date();
    return now.getTime();
  };

  const formatDateAndTime = (day, id) => {
    const [dayName, dayNumber, time] = id.split(' ');
    const [year, month, date] = day.split('-');
    const [hour, minute] = time.split(':');
    return new Date(year, month - 1, date, hour, minute).getTime();
  };

  const openAppointmentPopup = (appointment) => {
    setSelectedAppointment(appointment);
  };

  const closeAppointmentPopup = () => {
    setSelectedAppointment(null);
  };

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const userUID = user.uid;
      const userRdvRef = doc(db, 'RDVofuser', userUID);

      const categorizeAppointments = (appointments) => {
        const currentDateTime = getCurrentDateTime();
        const upcoming = [];
        const previous = [];

      
  for (const day in appointments) {
    for (const timeSlot in appointments[day]) {
      const appointmentDateTime = formatDateAndTime(day, timeSlot);
      const appointmentData = appointments[day][timeSlot];

      if (appointmentDateTime >= currentDateTime) {
        upcoming.push({
          day,
          timeSlot,
          status: appointmentData.status,
          patientName: appointmentData.patientName,
          symptoms: appointmentData.symptoms,
          sex: appointmentData.sex,
          typeOfConsultation: appointmentData.typeOfConsultation,
          firstTimeConsulting: appointmentData.firstTimeConsulting,
          Age: appointmentData.Age,
          bloodtype: appointmentData.bloodtype,
          Alergies: appointmentData.Alergies,
          doctorID: appointmentData.doctorID,
          newDateday:  appointmentData.newDateday,
          newDateid:  appointmentData.newDateid,
          phoneNumber: appointmentData.phoneNumber,


        });
      } else {
        previous.unshift({
          day,
          timeSlot,
          status: appointmentData.status,
          patientName: appointmentData.patientName,
          symptoms: appointmentData.symptoms,
          sex: appointmentData.sex,
          typeOfConsultation: appointmentData.typeOfConsultation,
          firstTimeConsulting: appointmentData.firstTimeConsulting,
          Age: appointmentData.Age,
          bloodtype: appointmentData.bloodtype,
          Alergies: appointmentData.Alergies,
          doctorID: appointmentData.doctorID,
          newDateday:  appointmentData.newDateday,
          newDateid:  appointmentData.newDateid,
          phoneNumber: appointmentData.phoneNumber,

        });
      }
    }
  }

  return { upcoming, previous };
};

      // Fetch initial user's appointments data and categorize them
      const fetchUserAppointments = async () => {
        try {
          const userRdvDoc = await getDoc(userRdvRef);
          if (userRdvDoc.exists()) {
            const userRdvData = userRdvDoc.data();
            const { upcoming, previous } = categorizeAppointments(userRdvData);
            setUserAppointments({ upcoming, previous });
          } else {
            console.log('User document not found.');
          }
        } catch (error) {
          console.error('Error fetching user appointments:', error);
        }
      };

      // Listen for real-time changes in the user's appointments
      const unsubscribe = onSnapshot(userRdvRef, (doc) => {
        if (doc.exists()) {
          const userRdvData = doc.data();
          const { upcoming, previous } = categorizeAppointments(userRdvData);
          setUserAppointments({ upcoming, previous });

         
         // Display toast notification when appointment status changes to 'reported'
         const reportedAppointments = upcoming.filter((appointment) => appointment.status === 'reported');
         reportedAppointments.forEach((appointment) => {
           notify('warning', ` ${translations.Reportedtonewdate} `, () =>
             openAppointmentPopup(appointment)
           );
         });

// Display toast notification when appointment status changes to 'canceled'
const canceledAppointments = upcoming.filter((appointment) => appointment.status === 'canceled');
canceledAppointments.forEach((appointment) => {
notify('error', ` ${translations.appointementCanceled}`, () =>
openAppointmentPopup(appointment)
);
});

        } else {
          console.log('User document not found.');
        }
      });

      fetchUserAppointments();

      return () => {
        // Unsubscribe from the real-time listener when the component unmounts
        unsubscribe();
      };
    }
  }, [auth, translations.Reportedtonewdate, translations.appointementCanceled]);

  const getStatusColor = (status) => {
    switch (status) {
      case 'reserved':
        return 'bg-blue-300   ';
      case 'reported':
        return 'bg-orange-300 ';
      case 'canceled':
        return 'bg-red-300 ';
      default:
        return 'bg-gray-200  ';
    }
  };

  const getStatustranslation = (status) => {
    switch (status) {
      case 'reserved':
        return <>{translations.booked}</>;
        case 'reported':
          return <></>;
       
      case 'canceled':
        return <>{translations.canceled}</>;
      default:
        return <TbCalendarCancel size={40} className="text-white mr-2" />;
    }
  };

  const getsextranslation = (sex) => {
    switch (sex) {
      case 'Masculin':
        return <>
        <p className="text-gray-600 mb-0 mr-4 flex items-center">
        <  BsGenderMale
        size={20} className="ml-3 mr-1 text-gray-600 transition-all duration-300 ease-in-out transform hover:rotate-12 " />
        <span className="font-semibold"></span>  {translations.Masculin}
      </p>
       </>;

case 'Feminin':  
  return <>
    <p className="text-gray-600 mb-0 mr-4 flex items-center">
                      < BsGenderFemale  size={20} className="ml-3 mr-1 text-gray-600 transition-all duration-300 ease-in-out transform hover:rotate-12 " />
                      <span className="font-semibold"></span>  {translations.Feminin}
                    </p>
 </>;


        case 'autre':
        return <>
         <p className="text-gray-600 mb-0 mr-4 flex items-center">
                      <BsGenderAmbiguous  size={20} className="ml-3 mr-1 text-gray-600 transition-all duration-300 ease-in-out transform hover:rotate-12 " />
                      <span className="font-semibold"></span> {translations.autre}
                    </p>
    </>;
     
      default:
        return <></>;
    }
  };
// Sort all previous appointments when the component mounts
useEffect(() => {
  const sortedAppointments = [...userAppointments.previous]
    .sort((a, b) => formatDateAndTime(b.day, b.timeSlot) - formatDateAndTime(a.day, a.timeSlot));
  setSortedPreviousAppointments(sortedAppointments);
}, [userAppointments.previous]);
  



 

const notify = (type, message, onClick) => {
  toast[type](message, {
    autoClose: 10000,
    onClick: (e) => {
      if (onClick) {
        // Check if onClick callback is provided
        onClick();
      }
      // Prevent default behavior only if it's not a passive event listener
      if (e && e.preventDefault && !e.passive) {
        e.preventDefault();
      }
    },
  });
};

  const handleReportedAppointmentClick = (appointment) => {
    openAppointmentPopup(appointment);
  };



  const handleConfirmAppointment = async () => {
    const serverTimestamp = Timestamp.fromDate(new Date());
  
    try {
      const user = auth.currentUser;
      const userUID = user.uid;
      const userRdvRef = doc(db, "RDVofuser", userUID);
  
      if (selectedAppointment) {
        // Find the appointment data regardless of its location
        const appointmentData = userAppointments.upcoming.find(
          (appointment) =>
            appointment.day === selectedAppointment.day &&
            appointment.timeSlot === selectedAppointment.timeSlot &&
            appointment.Alergies === selectedAppointment.Alergies
        ) || userAppointments.previous.find(
          (appointment) =>
            appointment.day === selectedAppointment.day &&
            appointment.timeSlot === selectedAppointment.timeSlot &&
            appointment.Alergies === selectedAppointment.Alergies
        );
  
        const appointmentPath = `${selectedAppointment.day}.${selectedAppointment.timeSlot}`;
        const updatedUserDocData = {
          [appointmentPath]: {
            status: "reserved",
            patientName: appointmentData?.patientName || "",
            symptoms: appointmentData?.symptoms || "",
            sex: appointmentData?.sex || "",
            typeOfConsultation: appointmentData?.typeOfConsultation || "",
            firstTimeConsulting: appointmentData?.firstTimeConsulting || "",
            Age: appointmentData?.Age || "",
            bloodtype: appointmentData?.bloodtype || "",
            Alergies: appointmentData?.Alergies || "",
            doctorID: appointmentData?.doctorID || "",
            newDateday: appointmentData?.newDateday || "",
            newDateid: appointmentData?.newDateid || "",
            phoneNumber: appointmentData?.phoneNumber || "",

            userId: userUID,
            serverTimestamp,
          },
        };
  
        // Update the user's document with the new status
        await updateDoc(userRdvRef, updatedUserDocData);

 // Update the doctor's document with the selected time slot data
 const doctorId = appointmentData?.doctorID;
 const rdvRef = doc(db, "RDV", doctorId);
 const rdvDoc = await getDoc(rdvRef);


 if (rdvDoc.exists()) {
   const rdvData = rdvDoc.data();

   const currentDate = new Date();
   currentDate.setHours(0, 0, 0, 0);

   const filteredTimeSlots = rdvData.selectedTimeSlots.reduce((filteredSlots, dayData) => {
     const dayDate = new Date(dayData.day);

     if (dayDate >= currentDate) {
       const filteredDaySlots = dayData.slots.filter((timeSlot) => {
         return timeSlot.status === "selected";
       });

       if (filteredDaySlots.length > 0) {
         filteredSlots.push({
           day: dayData.day,
           slots: filteredDaySlots,
         });
       }
     }

     return filteredSlots;
   }, []);


   const dayToUpdate = appointmentData?.newDateday || "";
   const slotIdToUpdate = appointmentData?.newDateid || "";

   // Create a copy of rdvData to modify
   const updatedRdvData = [...rdvData.selectedTimeSlots];

   // Find the dayData corresponding to selectedTimeSlot's day
   let dayDataToUpdate = updatedRdvData.find(dayData => dayData.day === dayToUpdate);

   // If the dayData doesn't exist, create a new one
   if (!dayDataToUpdate) {
     dayDataToUpdate = {
       day: dayToUpdate,
       slots: [],
     };
     updatedRdvData.push(dayDataToUpdate);
   }

   // Find the selected time slot within dayDataToUpdate
    // Find the index of the selected time slot within dayDataToUpdate
    const slotIndexToUpdate = dayDataToUpdate.slots.findIndex(slot => slot.id === slotIdToUpdate);

    // If the slot doesn't exist, create a new one; otherwise, update it
    if (slotIndexToUpdate === -1) {
      const slotToUpdate = {
        id: appointmentData?.newDateid || "",
        status: "reserved",
       patientName: appointmentData?.patientName || "",
       symptoms: appointmentData?.symptoms || "",
       sex: appointmentData?.sex || "",
       typeOfConsultation: appointmentData?.typeOfConsultation || "",
       firstTimeConsulting: appointmentData?.firstTimeConsulting || "",
       Age: appointmentData?.Age || "",
       bloodtype: appointmentData?.bloodtype || "",
       Alergies: appointmentData?.Alergies || "",
       doctorID: appointmentData?.doctorID || "",
       newDateday: appointmentData?.newDateday || "",
       newDateid: appointmentData?.newDateid || "",
       phoneNumber: appointmentData?.phoneNumber || "",

       userId: userUID,
       serverTimestamp,
     };
     dayDataToUpdate.slots.push(slotToUpdate);
    } else {
      // Update the existing slot
      dayDataToUpdate.slots[slotIndexToUpdate] = {
        id: slotIdToUpdate,
        status: "reserved",
       patientName: appointmentData?.patientName || "",
       symptoms: appointmentData?.symptoms || "",
       sex: appointmentData?.sex || "",
       typeOfConsultation: appointmentData?.typeOfConsultation || "",
       firstTimeConsulting: appointmentData?.firstTimeConsulting || "",
       Age: appointmentData?.Age || "",
       bloodtype: appointmentData?.bloodtype || "",
       Alergies: appointmentData?.Alergies || "",
       doctorID: appointmentData?.doctorID || "",
       newDateday: appointmentData?.newDateday || "",
       newDateid: appointmentData?.newDateid || "",
       phoneNumber: appointmentData?.phoneNumber || "",

       userId: userUID,
       serverTimestamp,
      };
    }

    try {
      // Update the document with the modified data
      await updateDoc(rdvRef, { selectedTimeSlots: updatedRdvData });
     console.log("Appointment reserved");
     closeAppointmentPopup();
     setNewdateCancelVisible(false);
     setNewdateConfirmationVisible(false);
  
   } catch (error) {
     console.error("Error updating RDV document:", error);
   }
 } else {
   console.error("Selected appointment is undefined");
 }
}
} catch (error) {
console.error("Error reserving appointment:", error);
}
};


const handleRefuseAppointment = async () => {
  const serverTimestamp = Timestamp.fromDate(new Date());

    try {
      const user = auth.currentUser;
      const userUID = user.uid;
      const userRdvRef = doc(db, "RDVofuser", userUID);
  
      if (selectedAppointment) {
        // Find the appointment data regardless of its location
        const appointmentData = userAppointments.upcoming.find(
          (appointment) =>
            appointment.day === selectedAppointment.day &&
            appointment.timeSlot === selectedAppointment.timeSlot &&
            appointment.Alergies === selectedAppointment.Alergies
        ) || userAppointments.previous.find(
          (appointment) =>
            appointment.day === selectedAppointment.day &&
            appointment.timeSlot === selectedAppointment.timeSlot &&
            appointment.Alergies === selectedAppointment.Alergies
        );
        
  
        const appointmentPath = `${selectedAppointment.day}.${selectedAppointment.timeSlot}`;
        const updatedUserDocData = {
          [appointmentPath]: {
            status: "canceled",
            patientName: appointmentData?.patientName || "",
            symptoms: appointmentData?.symptoms || "",
            sex: appointmentData?.sex || "",
            typeOfConsultation: appointmentData?.typeOfConsultation || "",
            firstTimeConsulting: appointmentData?.firstTimeConsulting || "",
            Age: appointmentData?.Age || "",
            bloodtype: appointmentData?.bloodtype || "",
            Alergies: appointmentData?.Alergies || "",
            doctorID: appointmentData?.doctorID || "",
            phoneNumber: appointmentData?.phoneNumber || "",

            userId: userUID,
            serverTimestamp,
          },
        };
  
        // Update the user's document with the new status
        await updateDoc(userRdvRef, updatedUserDocData);

 // Update the doctor's document with the selected time slot data
 const doctorId = appointmentData?.doctorID;
 const rdvRef = doc(db, "RDV", doctorId);
 const rdvDoc = await getDoc(rdvRef);


 if (rdvDoc.exists()) {
   const rdvData = rdvDoc.data();

   const currentDate = new Date();
   currentDate.setHours(0, 0, 0, 0);

   const filteredTimeSlots = rdvData.selectedTimeSlots.reduce((filteredSlots, dayData) => {
     const dayDate = new Date(dayData.day);

     if (dayDate >= currentDate) {
       const filteredDaySlots = dayData.slots.filter((timeSlot) => {
         return timeSlot.status === "selected";
       });

       if (filteredDaySlots.length > 0) {
         filteredSlots.push({
           day: dayData.day,
           slots: filteredDaySlots,
         });
       }
     }

     return filteredSlots;
   }, []);


   const dayToUpdate = appointmentData?.day || "";
   const slotIdToUpdate = appointmentData?.timeSlot || "";

   // Create a copy of rdvData to modify
   const updatedRdvData = [...rdvData.selectedTimeSlots];

   // Find the dayData corresponding to selectedTimeSlot's day
   let dayDataToUpdate = updatedRdvData.find(dayData => dayData.day === dayToUpdate);

   // If the dayData doesn't exist, create a new one
   if (!dayDataToUpdate) {
     dayDataToUpdate = {
       day: dayToUpdate,
       slots: [],
     };
     updatedRdvData.push(dayDataToUpdate);
   }

   // Find the selected time slot within dayDataToUpdate
    // Find the index of the selected time slot within dayDataToUpdate
    const slotIndexToUpdate = dayDataToUpdate.slots.findIndex(slot => slot.id === slotIdToUpdate);

    // If the slot doesn't exist, create a new one; otherwise, update it
    if (slotIndexToUpdate === -1) {
      const slotToUpdate = {
        id: appointmentData?.timeSlot || "",
        status: "canceled",
       patientName: appointmentData?.patientName || "",
       symptoms: appointmentData?.symptoms || "",
       sex: appointmentData?.sex || "",
       typeOfConsultation: appointmentData?.typeOfConsultation || "",
       firstTimeConsulting: appointmentData?.firstTimeConsulting || "",
       Age: appointmentData?.Age || "",
       bloodtype: appointmentData?.bloodtype || "",
       Alergies: appointmentData?.Alergies || "",
       doctorID: appointmentData?.doctorID || "",
    
       phoneNumber: appointmentData?.phoneNumber || "",

       userId: userUID,
       serverTimestamp,
     };
     dayDataToUpdate.slots.push(slotToUpdate);
    } else {
      // Update the existing slot
      dayDataToUpdate.slots[slotIndexToUpdate] = {
        id: slotIdToUpdate,
        status: "canceled",
       patientName: appointmentData?.patientName || "",
       symptoms: appointmentData?.symptoms || "",
       sex: appointmentData?.sex || "",
       typeOfConsultation: appointmentData?.typeOfConsultation || "",
       firstTimeConsulting: appointmentData?.firstTimeConsulting || "",
       Age: appointmentData?.Age || "",
       bloodtype: appointmentData?.bloodtype || "",
       Alergies: appointmentData?.Alergies || "",
       doctorID: appointmentData?.doctorID || "",
    
       
       phoneNumber: appointmentData?.phoneNumber || "",

       userId: userUID,
       serverTimestamp,
      };
    }

    try {
      // Update the document with the modified data
      await updateDoc(rdvRef, { selectedTimeSlots: updatedRdvData });
     console.log("Appointment reserved");
     closeAppointmentPopup();
     setNewdateCancelVisible(false);
     setNewdateConfirmationVisible(false);
  
   } catch (error) {
     console.error("Error updating RDV document:", error);
   }
 } else {
   console.error("Selected appointment is undefined");
 }
}
} catch (error) {
console.error("Error reserving appointment:", error);
}
};


 const [isExtraSmallScreen, setIsExtraSmallScreen] = useState(false);

 
useEffect(() => {
  const checkScreenSize = () => {
    setIsExtraSmallScreen(window.innerWidth <= 320 && window.innerHeight <= 640);
  };

  // Check screen size on mount
  checkScreenSize();

  let timeoutId;

  const handleResize = () => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(checkScreenSize, 100); // Adjust the delay as needed
  };

  // Add event listener for resize with debouncing
  window.addEventListener('resize', handleResize);

  // Clean up the event listener on unmount
  return () => {
    clearTimeout(timeoutId);
    window.removeEventListener('resize', handleResize);
  };
}, []);




  return (
    <div className="  bg-blue-200 dark:bg-gray-700 p-4  rounded shadow-md">
 

      <div className="mt-6 w-70">
      <button>
      <p className="bg-gradient-to-r from-blue-400 to-blue-500 hover:from-sky-400 hover:to-blue-500 text-white font-semibold py-2 px-4 py-70  focus:outline-none focus:shadow-outline  rounded-lg shadow-md">
      <LuCalendarClock size={30} className="ml-2 text-white transition-all duration-300 ease-in-out transform hover:rotate-12 " /><span className="font-semibold"></span> 
      
       {translations.UpcomingAppointments}
</p>   

        </button>
    

<div className="flex justify-center items-end space-x-4 y-1 mt-2 text-center mb-4">
        <div className="flex flex-col  items-center">
          <div className="w-3 h-3 bg-blue-500 rounded-full mb-1"></div>
          <p className="text-xs dark:text-blue-100 text-gray-600">{translations.booked}</p>
        </div>
        <div className="flex flex-col items-center">
          <div className="w-3 h-3 bg-orange-500  rounded-full mb-1"></div>
          <p className="text-xs dark:text-blue-100 text-gray-600">{translations.reported} </p>
        </div>
        <div className="flex flex-col items-center">
          <div className="w-3 h-3 bg-red-500 rounded-full mb-1"></div>
          <p className="text-xs dark:text-blue-100 text-gray-600">{translations.canceled}  </p>
        </div>
      </div>

        <ul className="space-y-4 ">
          {userAppointments.upcoming.map((appointment, index) => (
            <li
              key={index}
              className={`p-4 rounded shadow-md cursor-pointer transform transition-transform ${getStatusColor(
                appointment.status
              )}`}
              onClick={() => openAppointmentPopup(appointment)}
            >              <div className="text-lg font-semibold mb-2">
            {(appointment.status === 'reserved' && appointment.newDateday && appointment.newDateid) ? (
              <>
                       <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>        
<p className=" mb-0 mr-2 flex items-center "> <>{appointment.day}</> </p>
<p className=" flex items-center ">  <>{appointment.timeSlot}</>  </p>
< FaArrowTurnDown  size={30} className=" text-gray-700 font-semibold transition-all duration-300 ease-in-out transform hover:rotate-12" />

</div>
             
            

                <br />
                <div className="text-gray-600 spa flex flex-col items-center">

                {translations.NewappointmentDate}

                <div className=" mt-1 font-bold text-gray-700">       
 {appointment.newDateday}</div>
              </div>
              </>

            ) : (
              appointment.day
            )}
          </div>
          <div className="text-md font-semibold">
            {(appointment.status === 'reserved' && appointment.newDateday && appointment.newDateid) ? (
              <>
                <br />
                <div className="text-gray-600  flex flex-col items-center">

                {translations.Newappointmenttime}

                <div className=" mt-1 font-bold text-gray-700">
                  
                   {appointment.newDateid}</div>
                   </div>
          
              </>
            ) : (
              appointment.timeSlot
            )}
          </div>  
          
                   <p className="text-gray-600 ">{translations.Status}: {getStatustranslation(appointment.status)}</p>
              {/* Add other appointment details here */}
           
            {appointment.status === 'reported' && (
      <div>
  
     

      <div className="flex flex-col border bg-orange-200 border-sky-600 items-center mt-4">
      <div className="text-center   bg-orange-200  space-x-2 " >
   <div> {translations.yourappointmentwasrescheduled}  </div> 

        <strong className="text-gray-600 text-md ">{appointment.newDateday} </strong> 
        <br />
        <strong className="text-gray-600 text-md "> {appointment.newDateid}</strong>
        <div>  

</div>
      </div>
      {translations.yourappointmentwasrescheduled2}
      </div>
      </div>

    )}
       
            </li>
    

          ))}

        </ul>
      </div>




      <div className="mt-6  w-70">
   
      <div>

      
<button
  className={`bg-gradient-to-r from-blue-400 to-blue-500 hover:from-sky-400 hover:to-blue-500 text-white font-semibold py-2 px-4  focus:outline-none focus:shadow-outline  transition-all duration-300 ease-in-out transform hover:scale-105 rounded-lg shadow-md''
  }`}
          onClick={() => setShowPreviousAppointments(!showPreviousAppointments)}
        >
     < LuFolderArchive  size={30} className=" text-white font-semibold transition-all duration-300 ease-in-out transform hover:rotate-12" />

          {showPreviousAppointments ?  translations.previousAppointements : translations.ShowPreviousAppointments }
        </button>
        </div>

        <ul
          className={`space-y-4 transition-opacity ${
            showPreviousAppointments ? 'opacity-100' : 'opacity-0 hidden'
          }`}
        >

{sortedPreviousAppointments.slice(0, displayedPreviousAppointments).map((appointment, index) => (
            <li
              key={index}
              className={`p-4 rounded shadow-md cursor-pointer transform transition-transform ${getStatusColor(
                appointment.status
              )}`
            }
            onClick={() => openAppointmentPopup(appointment)}
            >
              
              
              <div className="text-lg font-semibold mb-2">
            {(appointment.status === 'reserved' && appointment.newDateday && appointment.newDateid) ? (
              <>
                       <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>        
<p className=" mb-0 mr-2 flex items-center "> <>{appointment.day}</> </p>
<p className=" flex items-center ">  <>{appointment.timeSlot}</>  </p>
< FaArrowTurnDown  size={30} className=" text-gray-700 font-semibold transition-all duration-300 ease-in-out transform hover:rotate-12" />

</div>
             
            

                <br />
                <div className="text-gray-600 spa flex flex-col items-center">

                {translations.NewappointmentDate}

                <div className=" mt-1 font-bold text-gray-700">       
 {appointment.newDateday}</div>
              </div>
              </>

            ) : (
              appointment.day
            )}
          </div>
          <div className="text-md font-semibold">
            {(appointment.status === 'reserved' && appointment.newDateday && appointment.newDateid) ? (
              <>
                <br />
                <div className="text-gray-600  flex flex-col items-center">

                {translations.Newappointmenttime}

                <div className=" mt-1 font-bold text-gray-700">
                  
                   {appointment.newDateid}</div>
                   </div>
          
              </>
            ) : (
              appointment.timeSlot
            )}
          </div>   
              <p className="text-gray-600">{translations.Status}: {getStatustranslation(appointment.status)}</p>
           
    
              {appointment.status === 'reported' && (
      <div>
  
     

      <div className="flex flex-col border bg-orange-200 border-sky-600 items-center mt-4">
      <div className="text-center   bg-orange-200  space-x-2 " >
   <div> {translations.yourappointmentwasrescheduled}  </div> 

        <strong className="text-gray-600 text-md ">{appointment.newDateday} </strong> 
        <br />
        <strong className="text-gray-600 text-md "> {appointment.newDateid}</strong>
        <div>  

</div>
      </div>
      {translations.yourappointmentwasrescheduled2}
      </div>
      </div>

    )}
       
              {/* Add other appointment details here */}
            </li>
          ))}
        </ul>

        {userAppointments.previous.length > displayedPreviousAppointments && (
          <button
            className={`mt-4 bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-full transition-opacity ${
              showPreviousAppointments ? 'opacity-100' : 'opacity-0 hidden'
            }`}
            onClick={() => setDisplayedPreviousAppointments((prev) => prev + 3)}
          >
                      {translations.LoadMore}
          </button>
        )}
      </div>

      {selectedAppointment && (

<main className="fixed inset-0 flex flex-col items-center justify-center min-h-screen bg-gray-800 bg-opacity-75 overflow-auto ">
<div className="flex-shrink-0  w-full max-w-3xl mx-auto p-4 sm:p-8 bg-white dark:bg-gray-700  rounded shadow-md mt-4 sm:mt-8  ">        
  <div className={` flex-shrink-0 w-full max-w-3xl mx-auto p-4 sm:p-8 rounded shadow-md mt-4 sm:mt-8   ${getStatusColor( selectedAppointment.status
            )}`}
          >
            <div  className="items-center">
              
              <div>{translations.Appointmentday}</div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

            
              <p className=" mb-0 mr-2 flex items-center "> <strong>{selectedAppointment.day}</strong> </p>
              <p className=" flex items-center ">  <strong>{selectedAppointment.timeSlot}</strong>  </p>

             
              </div>
              
            
            </div>

            <p className="text-2xl">
           {getStatustranslation(selectedAppointment.status)}
            </p>

            { selectedAppointment.newDateday && selectedAppointment.newDateid && (
       <div className="flex flex-col border bg-orange-200 border-sky-600 items-center mt-4">
       <div className="text-center   bg-orange-200  space-x-2 " >
  

          <div> {translations.yourappointmentwasrescheduled}  </div> 

          <strong className="text-gray-600 text-md "> {selectedAppointment.newDateday} </strong> 
          <br />
          <strong className="text-gray-600 text-md "> {selectedAppointment.newDateid}</strong>
          </div>

  </div>
)}
                      <div className="flex flex-col md:flex-row items-center justify-center md:justify-between mt-1 space-y-2">

    {selectedAppointment.status === 'reported' && (
  <>
    <button
      className="w-full md:w-auto text-sm bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4  rounded-lg cursor-pointer transition duration-300 ease-in-out flex items-center space-x-2"
      onClick={() => setNewdateConfirmationVisible(true)}
          >
          <TbReport size={20} className="text-white transition-all duration-300 ease-in-out transform hover:rotate-12 " />
       
<span className="overflow-wrap">{translations.ConfirmthenewAppointment}   </span>
 
    </button>

      <button
        className="w-full md:w-auto text-sm bg-red-500 text-white rounded-lg px-4 py-2 font-semibold hover:bg-red-600 cursor-pointer transition duration-300 ease-in-out flex items-center "
        onClick={() => setNewdateCancelVisible(true)}      >
          <TbCalendarCancel size={20} className="text-white transition-all duration-300 ease-in-out transform hover:rotate-12  " />

      {translations.RefuseandcancelAppointment}  
      </button>
    </>

)}
</div>
                   
                      <div className="  overflow-wrap break-words  flex mt-2" >
                      <strong>{translations.NometPrenomduPatient}</strong>    
                
                  <p className=" ml-2 overflow-wrap break-words " >
         {selectedAppointment.patientName}
            </p>
            </div>


                  <div 
                  className="mt-2 "
                  
                  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <p className="text-gray-600 mb-0 mr-2 flex items-center ">
                      <GiSandsOfTime size={20} className="ml-1 text-gray-600 transition-all duration-300 ease-in-out transform hover:rotate-12  " />
                      <span className="font-semibold ">{translations.Age}</span>
                    </p>
                    <p className=" text-xs text-gray-600 mb-0 items-center  ">
                      {selectedAppointment.Age}
                    </p>

                  {getsextranslation( selectedAppointment.sex  )}
     

                    <p className="text-gray-600 mb-0 mr-4 flex items-center">
                      <MdOutlineBloodtype size={20} className="ml-2 text-red-600 transition-all duration-300 ease-in-out transform hover:rotate-12 " />
                      <span className="font-semibold"></span> {selectedAppointment.bloodtype}
                    </p>
                  </div>
         
                  {!isExtraSmallScreen && (
     
            <div className="mb-6 text-base mg:text-lg  ">
                    <p className="text-gray-600 overflow-wrap overscroll-none pt-1 mr-2 break-words flex ">
                      <MdOutlineSick size={20} className="ml-4 text-gray-600 transition-all duration-300 ease-in-out transform hover:rotate-12 " />
                      <span className="font-semibold">{translations.Symptômes}</span>
                    </p>
                    <div className="text-gray-600 rounded-lg px-4 py-2 h-16 overflow-y-scroll  break-words items-center">
                      {selectedAppointment.symptoms}
                    </div>
                  </div>
)}
                  <div className="border-t border-gray-300"></div>
                 
                  {!isExtraSmallScreen && (

<div className="mb-4 ">
  <p className="text-gray-600 overflow-wrap pt-1 mr-4 break-words flex items-center">
    <LiaAllergiesSolid size={20} className="ml-4 text-gray-600 transition-all duration-300 ease-in-out transform hover:rotate-12 " />
    <span className="font-semibold">{translations.Allergies}</span>
  </p>
  <div className="text-gray-600 max-h-16 overflow-wrap overscroll-none overflow-y-scroll break-words items-center">
    {selectedAppointment.Alergies}
  </div>
</div>
      )}

<div className="border-t border-gray-300    "></div>

 <p className="text-gray-600 py-1 content-center overflow-wrap break-words flex items-center">
                    <MdPhone size={20} className="ml-2 text-gray-600 transition-all duration-300 ease-in-out transform hover:rotate-12 " />
                    <span className="font-semibold"></span>{selectedAppointment.phoneNumber}
                  </p>

                  <div className="border-t border-gray-300"></div>

   

  
                  <div className="flex flex-col md:flex-row items-center justify-center md:justify-between mt-1">
  <div className="flex items-center justify-center space-y-2 md:space-y-0 md:space-x-4">

    <button
  onClick={closeAppointmentPopup}
  className={`text-white px-4 py-2 rounded-full bg-gray-500 transition duration-300 ease-in-out hover:bg-gray-600 flex items-center space-x-2`}
    >
      <IoClose size={20} className="mr-2 text-white transition-all duration-300 ease-in-out transform hover:rotate-12 " />
      <span className="overflow-wrap">{translations.close}</span>
    </button>


  </div>
</div>

          </div>



        </div>

  
        </main>
    
      
      )}

{isNewdateConfirmationVisible && (

<div className="fixed inset-0 flex flex-col items-center justify-center min-h-screen py-10 sm:py-20 lg:py-40 overscroll-contain  z-10 overflow-auto">
 
<div
className="absolute inset-0 flex  items-center justify-center bg-gray-800 bg-opacity-50" >
<div className="bg-blue-100 dark:bg-gray-500 p-6 rounded-lg w-80 text-center items-center shadow-lg" >
      <p className="text-gray-800 dark:text-gray-100 text-lg mb-4">
  {translations.ConfirmthenewAppointmentpopup}  
      </p>
    
<div className="flex justify-end">

  <button  className="text-sky-600 hover:text-sky-700 px-4 py-2 rounded-md border border-sky-500 hover:bg-blue-100 dark:bg-blue-100 focus:outline-none" 
      onClick={handleConfirmAppointment}
      >
{translations.oui}  
 
  </button>
  <button
          className="text-gray-500 hover:text-gray-700 px-4 py-2 ml-4 rounded-md border border-gray-500 hover:bg-gray-100 dark:bg-gray-100 focus:outline-none"
          onClick={() => setNewdateConfirmationVisible(false)}
  >
   {translations.non}  
  </button>
</div>
</div>
</div>

</div>  
)}


{isNewdateCancelVisible && (

<div className="fixed inset-0 flex flex-col items-center justify-center min-h-screen py-10 sm:py-20 lg:py-40 overscroll-contain  z-10 overflow-auto">
 
<div
className="absolute inset-0 flex  items-center justify-center bg-gray-800 bg-opacity-50" >
<div className="bg-blue-100 dark:bg-gray-500 p-6 rounded-lg w-80 text-center items-center shadow-lg" >
      <p className="text-gray-800 dark:text-gray-100 text-lg mb-4">
  {translations.cancelplageshoraires}  
      </p>
    
<div className="flex justify-end">

  <button  className="text-sky-600 hover:text-sky-700 px-4 py-2 rounded-md border border-sky-500 hover:bg-blue-100 dark:bg-blue-100 focus:outline-none" 
        onClick={handleRefuseAppointment}

>
{translations.oui}  
 
  </button>
  <button
          className="text-gray-500 hover:text-gray-700 px-4 py-2 ml-4 rounded-md border border-gray-500 hover:bg-gray-100 dark:bg-gray-100 focus:outline-none"
          onClick={() => setNewdateCancelVisible(false)}
  >
   {translations.non}  
  </button>
</div>
</div>
</div>

</div>  
)}


    </div>
  );
}

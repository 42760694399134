
import React, { useState, useEffect, Fragment  } from 'react';
import DoctorSignUp2 from "./DoctorSignUp2";
import DoctorSignIn from "./DoctorSignIn";


import { Link } from 'react-router-dom';

import signupDoctorImage1 from "../Assets/images/signupdoctor.png";
import dotorsubmit from "../Assets/images/dotorsubmit.gif";
import doctorlogin from "../Assets/images/doctorlogin.gif";

import doctorhome from "../Assets/images/doctorhome.gif";
import TimeEfficiency from "../Assets/images/TimeEfficiency.gif";
import availability from "../Assets/images/availability.gif";
import Administrative from "../Assets/images/Administrative.gif";
import manageappointments from "../Assets/images/manageappointments.gif";
import Accessibility from "../Assets/images/Accessibility.gif";
import PatientEngagement from "../Assets/images/PatientEngagement.gif";
import PreventiveCare from "../Assets/images/PreventiveCare.gif";

import { Dialog, Transition } from '@headlessui/react';
import { useLanguage } from '../components/LanguageContext'; 

import { FaTimes, FaSearch, FaUsers,FaCheckCircle   } from 'react-icons/fa';
import { FaRegCheckCircle } from "react-icons/fa";
import { RxCrossCircled } from "react-icons/rx";

import { IoIosAdd, IoIosRemove } from 'react-icons/io';




const AccordionSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="mb-6 border dark:border-gray-400 rounded-lg w-full md:w-70 lg:w-80 overflow-hidden bg-gray-200 shadow-md">
   <div
  className="flex items-center justify-between   bg-gradient-to-r from-sky-500 to-sky-500  cursor-pointer p-4 text-xl font-semibold transition duration-300 ease-in-out"
  onClick={toggleAccordion}
>

        <h2 className="flex items-center text-white">
          {title}
          {isOpen ? (
            <IoIosRemove size={24} className="ml-2" />
          ) : (
            <IoIosAdd size={24} className="ml-2" />
          )}
        </h2>
      </div>
      <div className={`p-1 transition-max-height ${isOpen ? 'max-h-screen' : 'max-h-0 overflow-hidden'} ease-in-out duration-300`}>
        {children}
      </div>
    </div>
  );
};




const ProfessionalAccount = () => {
  const [open, setOpen] = useState(false);
  const [openlogin, setOpenlogin] = useState(false);
  const [step, setStep] = useState(1);
  const { language, translations } = useLanguage();

  useEffect(() => {
    // Simulate the verification process by changing steps after a delay
    const timer = setTimeout(() => {
      setStep(2); // Move to step 2 after a delay
    }, 2000);

    return () => clearTimeout(timer);
  }, []);
 

  
  return (
    <div className="flex flex-col items-center bg bg-blue-100 dark:bg-gray-700 justify-center min-h-screen">
       <div className="md:w-[30%] px-4 sm:w-[30%] xs:w-[30%] lg:w-[30%] mb-17 md:my-3">

<img
  src={doctorhome}
  alt="doctor1"
  className="w-full rounded-3xl   "
/>
</div>

<div className=" flex flex-col sm:flex-row ">
<Link to="/doctor-sign-Up">

<button
        className="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm mt-4 px-5 py-2.5 text-center mb-8"
        >
{translations.ApplyforProfessionalAccount}

</button>
</Link>

<button className="border border-sky-600 bg-gray-100 m-2 hover:bg-sky-300 text-blue-500 py-3 px-6 rounded-full shadow-md transition duration-300"
onClick={() => setOpenlogin(true)}

>

<img
  src={doctorlogin}
  alt="Apply Icon"
  size={10}
    className=" w-11 h-11  font-semibold inline-block mr-2"
  />
{translations.logintomyAccount}
          </button>

          </div>




      <h1 className="text-2xl dark:text-gray-100 text-center font-bold mb-6 pt-2">{translations.BenefitsofOnlineBookingforMedicalProfessionals}
</h1>
  
      <div className="container space-1 flex flex-col sm:flex-row mp-4">

      <div className="w-full sm:w-1/2  mb-4 sm:mb-0 mr-0 sm:mr-4 ">
 
    <AccordionSection title= {translations.TimeEfficiency}>
  
    <ul >
    <div className="flex items-center mb-4">
            <span className="bg-blue-500  text-white rounded-full p-2 mr-3"><i className="fas fa-calendar-alt"></i></span>
            {translations.Timepoint1}
                      </div>
      
          <div className="flex items-center mb-4">
            <span className="bg-blue-500 text-white rounded-full p-2 mr-3"><i className="fas fa-calendar-alt"></i></span>
            {translations.Timepoint2}
          </div>

          <div className="flex items-center mb-4">
            <span className="bg-blue-500 text-white rounded-full p-2 mr-3"><i className="fas fa-calendar-alt"></i></span>
            {translations.Timepoint3}
          </div>
          <div className="flex items-center mb-4">
            <span className="bg-blue-500 text-white rounded-full p-2 mr-3"><i className="fas fa-calendar-alt"></i></span>
            {translations.Timepoint4}
          </div>
      
        
      </ul>
      <div className="md:w-[70%] px-4 sm:w-[70%] xs:w-[70%] lg:w-[70%] mb-17 md:my-3">

<img
  src={TimeEfficiency}
  alt="TimeEfficiency"
  className="w-full rounded-2xl   "
/>
</div>

    </AccordionSection>


    <AccordionSection title=    {translations.Availability}>
    <ul>
 
    <div className="flex items-center mb-4">
            <span className="bg-blue-500 text-white rounded-full p-2 mr-3"><i className="fas fa-calendar-alt"></i></span>
            {translations.Availabilitypoint1}
          </div>
          
    <div className="flex items-center mb-4">
            <span className="bg-blue-500 text-white rounded-full p-2 mr-3"><i className="fas fa-calendar-alt"></i></span>
            {translations.Availabilitypoint2}

          </div>
         
          <div className="flex items-center mb-4">
            <span className="bg-blue-500 text-white rounded-full p-2 mr-3"><i className="fas fa-calendar-alt"></i></span>
            {translations.Availabilitypoint3}

          </div>

 
     
      </ul>
      
<div className="md:w-[70%] px-4 sm:w-[70%] xs:w-[70%] lg:w-[70%] mb-17 md:my-3">

<img
  src={availability}
  alt="availability"
  className="w-full rounded-2xl   "
/>
</div>

    </AccordionSection>



    <AccordionSection title=    {translations.EffectivePatientManagement}
>
  
    <ul >
       
    
    <div className="flex items-center mb-4">
            <span className="bg-blue-500 text-white rounded-full p-2 mr-3"><i className="fas fa-calendar-alt"></i></span>
            {translations.PatientManagement1}

          </div>


        <div className="flex items-center mb-4">
            <span className="bg-blue-500 text-white rounded-full p-2 mr-3"><i className="fas fa-calendar-alt"></i></span>
            {translations.PatientManagement2}

          </div>
     
          <div className="flex items-center mb-4">
            <span className="bg-blue-500 text-white rounded-full p-2 mr-3"><i className="fas fa-calendar-alt"></i></span>
            {translations.PatientManagement3}

          </div>
    
          <div className="md:w-[70%] px-4 sm:w-[70%] xs:w-[70%] lg:w-[70%] mb-17 md:my-3">

<img
  src={manageappointments}
  alt="manageappointments"
  className="w-full rounded-2xl   "
/>
</div>
          

      </ul>
    </AccordionSection>


 
  </div>

  <div className="w-full sm:w-1/2 ">
  
    <AccordionSection title={translations.CostReductionandAdministrativeEfficiency}>
    <ul >
    <div className="flex items-center mb-4">
            <span className="bg-blue-500 text-white rounded-full p-2 mr-3"><i className="fas fa-calendar-alt"></i></span>
            {translations.costreduction1}

          </div>
          <div className="flex items-center mb-4">
            <span className="bg-blue-500 text-white rounded-full p-2 mr-3"><i className="fas fa-calendar-alt"></i></span>
            {translations.costreduction2}

          </div>

          <div className="flex items-center mb-4">
            <span className="bg-blue-500 text-white rounded-full p-2 mr-3"><i className="fas fa-calendar-alt"></i></span>
            {translations.costreduction3}

          </div>
          
        
       
          <div className="flex items-center mb-4">
            <span className="bg-blue-500 text-white rounded-full p-2 mr-3"><i className="fas fa-calendar-alt"></i></span>
            {translations.costreduction4}

          </div>

          <div className="md:w-[70%] px-4 sm:w-[70%] xs:w-[70%] lg:w-[70%] mb-17 md:my-3">

<img
  src={Administrative}
  alt="Administrative"
  className="w-full rounded-2xl   "
/>
</div>



      </ul>
    </AccordionSection>

  
    <AccordionSection title={translations.PromotionofPreventiveCare}>
    <ul >
    <div className="flex items-center mb-4">
            <span className="bg-blue-500 text-white rounded-full p-2 mr-3"><i className="fas fa-calendar-alt"></i></span>
            {translations.PreventiveCare}

          </div>

          <div className="flex items-center mb-4">
            <span className="bg-blue-500 text-white rounded-full p-2 mr-3"><i className="fas fa-calendar-alt"></i></span>
            {translations.PreventiveCare2}

          </div>

          <div className="md:w-[70%] px-4 sm:w-[70%] xs:w-[70%] lg:w-[70%] mb-17 md:my-3">

<img
  src={PreventiveCare}
  alt="PreventiveCare"
  className="w-full rounded-2xl   "
/>
</div>
      </ul>
    </AccordionSection>
  </div>

  <div className="w-full sm:w-1/2 ">

  <AccordionSection title={translations.ExpandedPatientBasewithMobileFriendlyServices}>
    <ul >
      
        <div className="flex items-center mb-4">
            <span className="bg-blue-500 text-white rounded-full p-2 mr-3"><i className="fas fa-calendar-alt"></i></span>
            {translations.ExpandedPatientBase}
          </div>
          <div className="flex items-center mb-4">
            <span className="bg-blue-500 text-white rounded-full p-2 mr-3"><i className="fas fa-calendar-alt"></i></span>
            {translations.ExpandedPatientBase2}

          </div>
          <div className="flex items-center mb-4">
            <span className="bg-blue-500 text-white rounded-full p-2 mr-3"><i className="fas fa-calendar-alt"></i></span>
            {translations.ExpandedPatientBase3}

          </div>
          <div className="md:w-[70%] px-4 sm:w-[70%] xs:w-[70%] lg:w-[70%] mb-17 md:my-3">

<img
  src={Accessibility}
  alt="Accessibility"
  className="w-full rounded-2xl   "
/>
</div>


      </ul>

    </AccordionSection>
    <AccordionSection title={translations.IncreasedPatientEngagement}>
   
   <ul >
   <div className="flex items-center mb-4">
           <span className="bg-blue-500 text-white rounded-full p-2 mr-3"><i className="fas fa-calendar-alt"></i></span>
   
           {translations.IncreasedEngagement}
         </div>    
         
         <div className="flex items-center mb-4">
           <span className="bg-blue-500 text-white rounded-full p-2 mr-3"><i className="fas fa-calendar-alt"></i></span>
           {translations.IncreasedEngagement2}

         </div>
         <div className="md:w-[70%] px-4 sm:w-[70%] xs:w-[70%] lg:w-[70%] mb-17 md:my-3">

<img
 src={PatientEngagement}
 alt="PatientEngagement"
 className="w-full rounded-2xl   "
/>
</div>
           </ul>
   </AccordionSection>


  </div>

    </div>
    <Link to="/doctor-sign-Up">

      <button
        className="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-8"
        >
{translations.ApplyforProfessionalAccount}
      </button>
      </Link>


    <div className="bg-sky-100 ">
    <div className="max-w-screen-md mx-auto px-4 sm:px-6 lg:px-8 flex flex-col justify-between">

        <div className="text-center">
         
            <h3 className="text-3xl sm:text-5xl p-2 leading-normal font-extrabold tracking-tight text-gray-900">
            {translations.ApplyingSteps}

            </h3>

        </div>

        <div className="mt-20">
            <ul className="">
                <li className=" bg-blue-200 p-5  border border-blue-400 pb-10 text-center mb-20">
                    <div className="flex flex-col items-center">
                        <div className="flex-shrink-0 relative top-0 -mt-16">
                            <div
                                className="flex items-center justify-center h-20 w-20 rounded-full bg-sky-600 text-white border-4 border-white text-xl font-semibold">
                                1
                            </div>
                        </div>
                        <div className="mt-4  ">
                            <h4 className="text-lg leading-6 font-semibold text-gray-900">  {translations.ApplyingStepsheader1}</h4>
                            <p className="mt-2 text-base  leading-6 text-gray-500">
                            {translations.ApplyingSteps1}
          </p>
                        </div>
                    </div>
                </li>
                <li className=" bg-blue-200 p-5  border border-blue-400  pb-10 text-center mb-20">
                    <div className="flex flex-col items-center">
                        <div className="flex-shrink-0 relative top-0 -mt-16">
                            <div
                                className="flex items-center justify-center h-20 w-20 rounded-full bg-sky-600 text-white border-4 border-white text-xl font-semibold">
                                2
                            </div>
                        </div>
                        <div className="mt-4">
                            <h4 className="text-lg leading-6 font-semibold text-gray-900"> {translations.ApplyingStepsheader2}</h4>
                            <p className="mt-2 text-base font-semibold leading-6 text-gray-500">
                            {translations.ApplyingSteps2}

          </p>
                        </div>
                    </div>
                </li>
                <li className=" bg-blue-200 p-5  border border-blue-400  pb-10 text-center mb-20">
                    <div className="flex flex-col items-center">
                        <div className="flex-shrink-0 relative top-0 -mt-16">
                            <div
                                className="flex items-center justify-center h-20 w-20 rounded-full bg-sky-600 text-white border-4 border-white text-xl font-semibold">
                                3
                            </div>
                        </div>
                        <div className="mt-4">
                            <h4 className="text-lg leading-6 font-semibold text-gray-900"> {translations.ApplyingStepsheader3}</h4>
                            <p className="mt-2 text-base leading-6 text-gray-500">
                            {translations.ApplyingSteps3}
  </p>
                        </div>
                    </div>
                </li>

            </ul>
        </div>
      
      
        <div  className=" items-center ">
        <Link to="/doctor-sign-Up">

<button
  className="text-white items-center bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-8"
  >
{translations.ApplyforProfessionalAccount}
</button>
</Link>  
        </div>

      
    </div>
   
   

</div>





      <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 z-60">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md z-70">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4 z-80">
                      <button
                        type="button"
                        className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => setOpen(false)}
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <FaTimes className="h-6 w-6" aria-hidden="true" />

                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                       DoKtori
                      </Dialog.Title>
                    </div>
      <div className="w-full max-w-xl">
                    <DoctorSignUp2 />


                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>



    <Transition.Root show={openlogin} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => setOpenlogin(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 z-60">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md z-70">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4 z-80">
                      <button
                        type="button"
                        className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        onClick={() => setOpenlogin(false)}
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <FaTimes className="h-6 w-6" aria-hidden="true" />

                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6">
                      <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                       DoKtori
                      </Dialog.Title>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">
                    <DoctorSignIn />


                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>




    </div>
  );
};

export default ProfessionalAccount;
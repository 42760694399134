import React, { useEffect, useState, useRef} from "react";
import { useParams } from "react-router-dom";
import { collection, getDocs, doc, updateDoc, getDoc, setDoc, Timestamp } from "firebase/firestore";
import { db } from "../firebase";
import "leaflet/dist/leaflet.css";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { icon } from 'leaflet';
import {  FaMapMarkerAlt, FaEdit } from "react-icons/fa";


import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../components/Spinner";
import { useAuthStatus } from "../hooks/useAuthStatus";


import MonCompte from "../pages/MonCompte";
import SigninPopup from './SigninPopup';
import CreateAnAccountPopup from '../pages/CreateAnAccountPopup';
import Select from 'react-select';
import Fuse from 'fuse.js';

import moment from 'moment';
import {
  getAuth
} from "firebase/auth";


import { MdLocationOn } from "react-icons/md";
import { GiStethoscope } from "react-icons/gi";
import { MdLocationCity } from "react-icons/md";
import { BiRadioCircleMarked, BiShow, BiIntersect   } from "react-icons/bi";
import { FiShare2 } from "react-icons/fi";
import { FaSignInAlt, FaUserPlus, FaRegArrowAltCircleLeft, FaRegArrowAltCircleRight, FaRegHospital, FaSearch, FaLaptopMedical } from "react-icons/fa";
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { BsFillCalendar2CheckFill } from 'react-icons/bs';
import { TbCalendarPlus } from'react-icons/tb'
import { FaMale, FaFemale, FaExclamationCircle, FaArrowLeft , FaRegCalendarTimes } from 'react-icons/fa';
import { TfiMore } from "react-icons/tfi";
import { LuActivity } from "react-icons/lu";

import { BiSolidArrowFromTop } from "react-icons/bi";

import { EmailShareButton, FacebookShareButton, TwitterShareButton, WhatsappShareButton, ViberShareButton, FacebookMessengerShareButton, PinterestShareButton, RedditShareButton, TumblrShareButton, VKShareButton, WorkplaceShareButton, LineShareButton, WeiboShareButton, PocketShareButton, TelegramShareButton } from 'react-share';
import { EmailIcon, FacebookIcon, TwitterIcon, WhatsappIcon, ViberIcon, FacebookMessengerIcon, PinterestIcon, RedditIcon, TumblrIcon, VKIcon, WorkplaceIcon, LineIcon, WeiboIcon, PocketIcon, TelegramIcon } from 'react-share';
import { useLanguage } from '../components/LanguageContext'; // Assuming you have the LanguageContext from the previous example



function MapPopup({ latitude, longitude, onClose }) {

  const markerIcon = icon({
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    shadowSize: [41, 41],
  });

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="w-full max-w-screen-lg p-10 bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="w-full h-[320px] md:h-[480px] rounded-lg overflow-hidden">
          <MapContainer center={[latitude, longitude]} zoom={14} style={{ width: "100%", height: "100%" }}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker
            icon={markerIcon}
             position={[latitude, longitude]}>
              <Popup>
                A marker indicating the property location.
              </Popup>
            </Marker>
          </MapContainer>
        </div>
        <button
          onClick={onClose}
          className="mt-7 w-full md:w-auto bg-gray-800 text-white py-1 px-2 rounded hover:bg-gray-600 transition duration-300 text-sm"
        >
          Close
        </button>
      </div>
    </div>
  );
}







const Popups = ({ rdvData, onClose, doctorId, doctor }) => {
 
  const { loggedIn, loading: authLoading } = useAuthStatus();
  const [currentDayIndex, setCurrentDayIndex] = useState(0);
  const [visibleTimeSlots, setVisibleTimeSlots] = useState(10);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [showPopup, setShowPopup] = useState(null); 
  const navigate = useNavigate();
  const { language, translations } = useLanguage();

  const [formData, setFormData] = useState({
    patientName: "",
    symptoms: "",
    sex: "",
typeOfConsultation: "",
    firstTimeConsulting: "",
    Age:"", 
    bloodtype:"",
    Alergies:"",
   userId:"",
   phoneNumber:"",
  });

  const [loading, setLoading] = useState(false);

  const [isAppointmentSuccessful, setAppointmentSuccess] = useState(false);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);

  const scrollableContainerRef = useRef(null);

  const handleTimeSlotClick = (day, id) => {

    if (authLoading) {
      // Display a loading indicator while checking authentication status
      setLoading(true);
    } else {
      setLoading(false);
      if (loggedIn) {

    // Find the selected time slot when it's clicked
    const selectedDayData = rdvData.find((data) => data.day === day);
    if (selectedDayData) {
      const selectedSlot = selectedDayData.slots.find((slot) => slot.id === id);
      if (selectedSlot && selectedSlot.status === "selected") {
      setSelectedTimeSlot({
        ...selectedSlot,
        day: day, // Set the day here
      });
      scrollableContainerRef.current.scrollTop = 0;

      }
    }
        } else {
    // User is not logged in, display the MonCompte component
    return <MonCompte />;
  }
}
};

  const handleInputChange = (e) => {
    // Update the form data when users enter values
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  
  const handleReserveAppointment = async () => {

      if (!formData.patientName) {
        toast.error("Veuillez saisir le nom du patient.");
        return;
      }
  
      if (!formData.Age) {
        toast.error("Veuillez saisir l'âge du patient.");
        return;
      }
  

      if (!formData.sex) {
        toast.error("Veuillez sélectionner votre Genre .");
        

        return;
      }

      if (!formData.phoneNumber) {
        toast.error("Veuillez saisir le numéro de téléphone.");
        return;
      }
  
 

    if (selectedTimeSlot && !["reserved", "reported"].includes(selectedTimeSlot.status)) {
      setLoading(true);
  
      const auth = getAuth();
      const serverTimestamp = Timestamp.fromDate(new Date());

      // Now, store the reservation data in RDVofuser collection with the user's UID as the document title
      const user = auth.currentUser; 
      const userUID = user.uid;

      const rdvRef = doc(db, "RDV", doctorId);
      const rdvDoc = await getDoc(rdvRef);
  
  
      if (rdvDoc.exists()) {
        const rdvData = rdvDoc.data();
  
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
  
        const filteredTimeSlots = rdvData.selectedTimeSlots.reduce((filteredSlots, dayData) => {
          const dayDate = new Date(dayData.day);
  
          if (dayDate >= currentDate) {
            const filteredDaySlots = dayData.slots.filter((timeSlot) => {
              return timeSlot.status === "selected";
            });
  
            if (filteredDaySlots.length > 0) {
              filteredSlots.push({
                day: dayData.day,
                slots: filteredDaySlots,
              });
            }
          }
  
          return filteredSlots;
        }, []);
  
  
        const isSlotIncluded = filteredTimeSlots.some(dayData => {
            return dayData.day === selectedTimeSlot.day && dayData.slots.some(slot => slot.id === selectedTimeSlot.id);
        });
        
        if (isSlotIncluded) {
          // Create a copy of rdvData to modify
          const updatedRdvData = [...rdvData.selectedTimeSlots];
          // Find the dayData corresponding to selectedTimeSlot's day
          const dayDataToUpdate = updatedRdvData.find(dayData => dayData.day === selectedTimeSlot.day);
          // Find the selected time slot within dayDataToUpdate
          const slotToUpdate = dayDataToUpdate.slots.find(slot => slot.id === selectedTimeSlot.id);
  

        if (isSlotIncluded) {
          slotToUpdate.status = "reserved";
          slotToUpdate.patientName = formData.patientName;
          slotToUpdate.symptoms = formData.symptoms;
          slotToUpdate.sex = formData.sex;
          slotToUpdate.typeOfConsultation = formData.typeOfConsultation;
          slotToUpdate.firstTimeConsulting = formData.firstTimeConsulting;
          slotToUpdate.Age = formData.Age;
          slotToUpdate.bloodtype = formData.bloodtype;
          slotToUpdate.Alergies = formData.Alergies;
          slotToUpdate.userId = user.uid;
          slotToUpdate.phoneNumber = formData.phoneNumber;
        

          try {
            const rdvRef = doc(db, "RDV", doctorId);
            await updateDoc(rdvRef, { selectedTimeSlots: updatedRdvData });
           
            const userRdvRef = doc(db, "RDVofuser", userUID);
          
            // Create a data object for the selected time slot in the user's document
            const userDocData = {
              [selectedTimeSlot.day]: {
                [selectedTimeSlot.id]: {
                  status: "reserved",
                  patientName: formData.patientName,
                  symptoms: formData.symptoms,
                  sex: formData.sex,
                  typeOfConsultation: formData.typeOfConsultation,
                  firstTimeConsulting: formData.firstTimeConsulting,
                  Age: formData.Age,
                  bloodtype: formData.bloodtype,
                  Alergies: formData.Alergies,
                 
                  phoneNumber: formData.phoneNumber,
                 
                  userId: userUID,
                  serverTimestamp,
                },
              },
            };
          
            // Update the user's document with the selected time slot data
            await setDoc(userRdvRef, userDocData, { merge: true });
          

            setAppointmentSuccess(true); 
            setConfirmationOpen(false); 



            setFormData({
              patientName: "",
              symptoms: "",
              sex: "",
              typeOfConsultation: "",
              firstTimeConsulting: "",
              Age: "",
              bloodtype: "",
              Alergies: "",
              userId: "",
              phoneNumber: "",
            });
            



          } catch (error) {
            console.error("Error updating time slot:", error);
            toast.error("Une erreur s'est produite lors de la mise à jour du créneau horaire.");
            setLoading(false);

          }
        } else {
          console.error("Selected time slot not found within the dayData.");
          setLoading(false);

        }
      } else {
        console.error("The selected time slot is not included. Please check the values.");
        toast.error("Le créneau horaire sélectionné n'est plus disponible. Veuillez rafraîchir la page et choisir un autre créneau.");
        setLoading(false);

      }
    } else {
      console.error("The RDV document doesn't exist.");
      toast.error("Le document RDV n'existe pas.");
      setLoading(false);

    }

    setLoading(false);
  } else {
    console.error("This time slot is already reserved or reported.");
    toast.error("Ce créneau horaire est déjà réservé .");
    setLoading(false);
 
  }
};

  
  

  const handlePreviousDay = () => {
    setCurrentDayIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    setVisibleTimeSlots(10); // Reset visible time slots when changing the day
  };

  const handleNextDay = () => {
    if (currentDayIndex < rdvData.length - 1) {
      setCurrentDayIndex((prevIndex) => prevIndex + 1);
      setVisibleTimeSlots(10); // Reset visible time slots when changing the day
    } else {
      toast.error("Le docteur n'a pas prévu de rendez-vous ultérieurs à cette date");
    }
  };

       
  const handleGoBack = () => {
    // Clear the selected time slot and return to the time slot view
    setSelectedTimeSlot(null);
  };

  const consultationOptions = [
    { value: 'option1', label: 'Option 1' },    
    { value: 'option2', label: 'Option 2' },
    // Add more options as needed
  ];

  const sexOptions = [
    { value: 'Masculin', icon: <FaMale className="ml-2 text-blue-400" />, label: translations.Masculin },
    { value: 'Feminin', icon: <FaFemale className="ml-2 text-pink-400" />, label: translations.Feminin },
    { value: 'autre', icon: null, label: translations.autre }, // You can replace null with the icon for 'autre'
  ];
  

  const handleSelectChange = (fieldName, selectedOption) => {
    const updatedFormData = { ...formData, [fieldName]: selectedOption.value };
    setFormData(updatedFormData);
  };

  const handleCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
    const updatedFormData = {
      ...formData,
      [name]: checked ? value : '', // Use empty string when unchecked
    };
    setFormData(updatedFormData);
  };

  
  const bloodTypes = ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'];
const noTimeSlotsAvailable = !rdvData[currentDayIndex]?.slots || rdvData[currentDayIndex]?.slots.length === 0;



// Assuming rdvData is an array of objects containing day and slots
// Set the timezone to Algeria

const [delayedContentVisible, setDelayedContentVisible] = useState(false);

useEffect(() => {
  const delayTimeout = setTimeout(() => {
    setDelayedContentVisible(true);
  }, 1200);

  return () => clearTimeout(delayTimeout); // Clear the timeout if the component unmounts or if it re-renders
}, []);


moment.tz.setDefault('Africa/Algiers');
const currentDate = moment().tz('Africa/Algiers');
const currentHour = currentDate.format('HH:mm');
const formattedDate = () => {
  const date = moment(filteredRdvData[currentDayIndex]?.day);
  return date.format('dddd, D MMMM YYYY');
};

// Filter out days with no available time slots.
const filteredRdvData = rdvData.filter((dayData) => {
  const dayDate = moment(dayData.day);
  if (dayDate.isAfter(currentDate, 'day')) {
    return true; // Include future days
  } else if (dayDate.isSame(currentDate, 'day')) {
    const futureTimeSlots = dayData.slots.filter((timeSlot) => moment(`${dayData.day} ${timeSlot.id}`,'dddd D HH:mm') > currentDate);
    return futureTimeSlots.length > 0 || dayData.slots.length === 0; // Include the day if there are future time slots or if there are no time slots at all
  }
  return false; // Exclude past days
});



  return (
    <div className="fixed inset-0 flex items-center justify-center py-70 bg-black bg-opacity-50 z-10">
    <div  className="w-full max-w-screen-lg p-4 bg-gray-100 dark:bg-gray-700 rounded-lg shadow-lg overflow-hidden z-10 ">
      <div ref={scrollableContainerRef}  className=" flex-shrink-0 w-full max-w-3xl mx-auto py-8 sm:p-4  bg-gray-100 dark:bg-gray-600 rounded-lg P-10 shadow-lg  max-h-96  overflow-y-scroll mt-10 z-11 " > 
      
        {loading ? (
          <Spinner />
        ) : loggedIn ? ( isAppointmentSuccessful ? (
          <div className="flex flex-col items-center justify-center text-center p-8 bg-white rounded-lg shadow-md ">
          <div className="text-5xl text-blue-500 mb-4">
            <BsFillCalendar2CheckFill /> {/* Blue Calendar with Checkmark Icon */}
          </div>
          <div className="text-lg  text-gray-800">
          {translations.booksuccess1}{' '}
            <span className="text-blue-500  font-semibold">{selectedTimeSlot.id}</span> {translations.booksuccess2}{' '}
            <span className="text-blue-500 font-semibold">{doctor.doctorname}</span>{translations.booksuccess3} 
          </div>
          {translations.booksuccess4}
       
        </div>

          ) : selectedTimeSlot ? (
       
  
               
    
      
      <div>
      <button
        onClick={handleGoBack}
        className="text-blue-500 dark:text-blue-300 hover:text-blue-700 font-semibold mb-2 mt-6 flex items-center sticky "
      >
        <AiOutlineArrowLeft className="mr-2" />          {translations.retourbutton}

      </button>

                    <div className="text-md font-semibold   dark:text-gray-100  align-middle text-gray-800"> {selectedTimeSlot.day}  </div>
                    <div className="text-md max-w-full  dark:text-gray-100 text-center align-middle overflow-hidden whitespace-nowrap overflow-ellipsis font-semibold text-gray-500">
                    {selectedTimeSlot.id} 
               </div>
            


      
    
      
               <div id="formContainer" className="flex-shrink-0 w-full max-w-3xl mx-auto p-2 sm:p-4 bg-blue-100 dark:bg-gray-600 rounded shadow-md sm:mt-8  ">
   

      <div className="grid grid-cols-1  dark:bg-gray-700  md:grid-cols-2 lg:grid-cols-3 gap-4 z-5">
        
        <div>
          <label htmlFor="patientName" className="text-sm dark:text-gray-100 font-semibold">
        *{translations.NometPrenomduPatient}
          </label>
          <input
            type="text"
            name="patientName"
            id="patientName"
            placeholder="Saisissez le nom du patient"
            value={formData.patientName}
            onChange={handleInputChange}
            required
            className="w-full rounded-lg px-4 py-2 border border-sky-500 focus:outline-none"
          />

<label htmlFor="sex" className="text-sm dark:text-gray-100 font-semibold">
 *{translations.checkbox}

</label>
<div className="flex flex-wrap mt-2 border bg- border-sky-500 justify-center ">
  {sexOptions.map((option) => (
    <label key={option.value} className="flex dark:text-gray-100 items-center mx-2 mb-2">
      <input
        type="checkbox"
        name="sex"
        value={option.value}
        checked={formData.sex === option.value}
        required
        onChange={handleCheckboxChange}
      />
      {option.icon} {option.label}
    </label>
  ))}
</div>

        </div>
        <div>
        
          <label htmlFor="Age" className="text-sm dark:text-gray-100 font-semibold">
         * {translations.Âgedupatient}
          </label>
          <input
            type="text"
            name="Age"
            id="Age"
            placeholder="Saisissez l'âge"
            value={formData.Age}
            onChange={handleInputChange}
            min="0"
            maxLength="50" 

            required
            className="w-full rounded-lg px-4 py-2 border  border-sky-500  focus:outline-none"
          />

<label htmlFor="phoneNumber" className="text-sm dark:text-gray-100 font-semibold">
*{translations.NumerodeTelephone}
          </label>
          <input
            type="number"
            name="phoneNumber"
            id="phoneNumber"
            min="0"
            maxLength="30" 
            required
            placeholder="Indiquez le numero de telephone "
            value={formData.phoneNumber}
            onChange={handleInputChange}
            className="w-full rounded-lg px-4 py-2 border border-sky-500 focus:outline-none"
          />


        </div>
      

       
      </div>
      <div className="flex flex-wrap mt-2 font-semibold text-1xl text-center dark:text-blue-100 text-gray-600 border bg- border-sky-500 justify-center ">
      *{translations.Informationscomplémentaires }

<BiSolidArrowFromTop  size={30} className="mr-2 text-xl dark:text-blue-100 transition-all duration-300 ease-in-out transform hover:rotate-12 " />

</div>

        <div>
<label htmlFor="bloodtype" className="text-sm dark:text-gray-100 font-semibold">
{translations.GroupeSanguin}

</label>
<div className="flex flex-wrap justify-center">
{bloodTypes.map((type) => (
<label key={type} className="flex dark:text-gray-100 items-center mx-2 mb-2">
<input
type="checkbox"
required
name="bloodtype"
value={type}
checked={formData.bloodtype === type}
onChange={handleCheckboxChange}
/>
{type}
</label>
))}

</div>

</div>

<div>

  <label htmlFor="symptoms" className="text-sm dark:text-gray-100 font-semibold">
   {translations.Symptômes}

  </label>
  <textarea
    name="symptoms"
    id="symptoms"
    maxLength="1000" 
    placeholder="Décrivez ce que vous resentez actuellement "
    value={formData.symptoms}
    onChange={handleInputChange}
    className="w-full rounded-lg px-4 py-2 h-32 overflow-y-scroll border  border-sky-500  focus:outline-none"
  />
</div>
<div>

  <label htmlFor="Alergies" className="text-sm dark:text-gray-100 font-semibold">
    {translations.Allergies}
  </label>
  <input
    type="text"
    name="Alergies"
    maxLength="300" 

    id="Alergies"
    placeholder="Indiquez les allergies (le cas échéant)"
    value={formData.Alergies}
    onChange={handleInputChange}
    className="w-full rounded-lg px-4 py-2 border border-sky-500  focus:outline-none"
  />
      </div>

      </div>

    </div> ) : (
      // Display previous and next day buttons along with time slots for the selected day
            <>
              <div className="flex  dark:text-gray-100  dark:bg-gray-500 bg-blue-100 justify-between items-center mb-4">
                <button
                  onClick={handlePreviousDay}
                  className="text-sky-500 hover-text-blue-700 font-semibold"
                  disabled={currentDayIndex === 0}
                >
                  <FaRegArrowAltCircleLeft size={40} className="ml-1 text-sky-600 hover:text-sky-700 dark:text-blue-100 font-semibold transition-all duration-300 ease-in-out transform hover:rotate-12" />
                </button>
                <div className="text-xl font-semibold text-center">
                  {formattedDate()}
                </div>
                <button
                  onClick={handleNextDay}
                  className="text-blue-500 hover:text-blue-700 font-semibold"
                  disabled={currentDayIndex === filteredRdvData.length - 1}
                >
                  <FaRegArrowAltCircleRight size={40} className="ml-1 text-sky-600 hover:text-sky-700 dark:text-blue-100  font-semibold transition-all duration-300 ease-in-out transform hover:rotate-12" />
                </button>
              </div>
              
{filteredRdvData[currentDayIndex]?.slots.length > 0 ? (
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
    {filteredRdvData[currentDayIndex]?.slots
      .map((timeSlot) => {
        const [dayName, dayNumber, time] = timeSlot.id.split(' '); // Splitting the time slot string into day name, day number, and time
        const timeSlotDateTime = moment(`${filteredRdvData[currentDayIndex].day} ${time}`, 'YYYY-MM-DD HH:mm').tz('Africa/Algiers'); // Constructing the date-time of the time slot
        const isAfterCurrentTime = timeSlotDateTime > currentDate;
        return { ...timeSlot, isAfterCurrentTime }; // Adding a property to the time slot indicating whether it's after the current time
      })
      .filter((timeSlot) => timeSlot.isAfterCurrentTime) // Filtering out time slots that are not after the current time
      .map((timeSlot) => (
        <div
          key={timeSlot.uniqueid}
          className="bg-blue-100 rounded-lg cursor-pointer p-3 text-center hover:bg-blue-200 transition-all"
          onClick={() => {
            if (timeSlot.status === 'selected') {
              handleTimeSlotClick(filteredRdvData[currentDayIndex].day, timeSlot.id);
            }
          }}
        >
          <span>{timeSlot.id}</span>
        </div>
      ))}
  </div>

              ) : (
                // Display message when no time slots are available for the selected day
                <div className="flex flex-col items-center justify-center pb-6">
                  <p className="text-gray-700 dark:text-blue-100 text-center mb-4">
                    {translations.notimeslot}
                  </p>
                  <div className="text-center">
                    <FaRegCalendarTimes size={50} className="text-sky-500 font-semibold transition-all duration-300 ease-in-out transform hover:rotate-12" />
                  </div>
                </div>
              )}
          
            </>
          )
        ) :   delayedContentVisible &&   (
        <div className="mt-4 text-center word-break">
          <h1 className="text-1xl font-semibold text-gray-800 mb-4"> 
          {translations.securityraisons}
  </h1>
          <div className="flex flex-col items-center space-y-2">
            {showPopup ? (
              <button
                onClick={() => setShowPopup(null)}
                className="bg-blue-500 hover:bg-blue-700 text-white rounded-full px-6 py-3 font-semibold cursor-pointer transition-all duration-300 flex items-center"
              >
                <FaArrowLeft className="mr-2 dark:bg-blue-100" />  {translations.retourbutton}
              </button>
            ) : delayedContentVisible &&  (
               (
                <div className="space-y-4">
                  <button
                    onClick={() => setShowPopup('signIn')}
                    className="bg-blue-500 hover:bg-blue-700 text-white rounded-full px-6 py-3 font-semibold cursor-pointer transition-all duration-300 flex items-center"
                  >
                    <FaSignInAlt className="mr-2" />  {translations.SignIn}
                  </button>
                  <button
                    onClick={() => setShowPopup('createAccount')}
                    className="bg-green-500 hover:bg-green-700 text-white rounded-full px-6 py-3 font-semibold cursor-pointer transition-all duration-300 flex items-center"
                  >
                    <FaUserPlus className="mr-2" />  {translations.CreateAccount}
                  </button>
                </div>
              )
            )}  
            </div>
                
          {showPopup === 'signIn' && (
        <SigninPopup onClose={() => setShowPopup(null)} />
      )}

      {showPopup === 'createAccount' && (
        <CreateAnAccountPopup onClose={() => setShowPopup(null)} />
      )}
        </div>
      )}


    </div>

      {selectedTimeSlot && !isAppointmentSuccessful && (
  <button
    onClick={() => setConfirmationOpen(true)} // Open the confirmation modal
    className="w-full bg-blue-500 text-white rounded-lg px-4 py-2 mt-7 font-semibold hover-bg-blue-700 cursor-pointer flex items-center justify-center"
  >
    <span className="mr-2">
      <TbCalendarPlus className="text-xl" />
    </span>

    {translations.Réserver}
  </button>
)}

<div > 
{isConfirmationOpen && (
 <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 "> {/* Increase z-index for the confirmation popup backdrop */}
 <div className="bg-white p-4 rounded-lg w-80 text-center shadow-lg "> {/* Increase z-index for the confirmation popup */}

         <div className="text-gray-800 font-semibold text-lg mb-4 ">
         {translations.confirmeRéserver}
     <div className=" bg-blue-400 h-18 py-2 text-center rounded-lg text-white text-lg mb-4">{doctor.doctorname} </div>       

         </div>   
         <div className="text-center border border-sky-400 space-x-2 " >
  
         <p className="text-gray-700 text-md ">{selectedTimeSlot.day}
         </p>
         <p className="text-gray-600 text-md ">{selectedTimeSlot.id}
         </p>
         </div>

   <div className="flex iteme-center justify-end p-2">

     <button  className="text-blue-500 hover:text-blue-700 px-4 py-2 rounded-md border border-blue-500 hover:bg-red-100 focus:outline-none"
          onClick={handleReserveAppointment}
          >
   {translations.oui}     
     </button>
     <button
             className="text-gray-500 hover:text-gray-700 px-4 py-2 ml-4 rounded-md border border-gray-500 hover:bg-gray-100 focus:outline-none"
             onClick={() => setConfirmationOpen(false)}
     >
       {translations.non}     
   
     </button>
   </div>
 </div>
</div>
)}
</div>
      {isAppointmentSuccessful && (
         <button
         onClick={() => navigate('/mes-rdv')}
         className="w-full bg-blue-500 text-white rounded-full  px-6 py-3 mt-8 pb-4 font-semibold hover:bg-blue-700 hover:shadow-md cursor-pointer transition-all duration-300 focus:outline-none focus:ring focus:border-blue-300"
       > 
               {translations.appointementunderstood}

      
       </button>
        )}    
{!isAppointmentSuccessful && (
          // Render the "Close" button when isAppointmentSuccessful is falsy
          <button
            onClick={onClose}
            className="mt-7 w-full md:w-auto bg-gray-800  text-blue-100 dark:bg-blue-200 dark:text-gray-700 py-1 px-2  rounded hover:bg-gray-600 transition duration-300 text-sm"
          >
                      {translations.close}
       
          </button>
        )}
    </div>
    </div>
  );
};



















function Doctors() {
  const { id } = useParams(); // Get id from the URL params
  const [doctor, setDoctor] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rdvData, setRdvData] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [shareOptionsVisible, setShareOptionsVisible] = useState(false);
  const [mapPopupVisible, setMapPopupVisible] = useState(false);
  const [propertyCoordinates, setPropertyCoordinates] = useState({ latitude: 0, longitude: 0 });

  const { language, translations } = useLanguage();

  const [rdvButtonCount, setRdvButtonCount] = useState(() => {
    const storedCount = localStorage.getItem(`rdvButtonCount_${id}`);
    const lastResetTime = localStorage.getItem(`lastResetTime_${id}`);
    if (!lastResetTime || Date.now() - parseInt(lastResetTime) >= 600000) {
      // If it's the first time or elapsed time exceeds 10 minutes, reset count
      return 0;
    } else {
      return parseInt(storedCount) || 0;
    }
  });
  const [doctorLinkCount, setDoctorLinkCount] = useState(() => {
    const storedCount = localStorage.getItem(`doctorLinkCount_${id}`);
    const lastResetTime = localStorage.getItem(`lastResetTime_${id}`);
    if (!lastResetTime || Date.now() - parseInt(lastResetTime) >= 600000) {
      // If it's the first time or elapsed time exceeds 10 minutes, reset count
      return 0;
    } else {
      return parseInt(storedCount) || 0;
    }
  });

  useEffect(() => {
    const interval = setInterval(() => {
      // Update last reset time
      localStorage.setItem(`lastResetTime_${id}`, Date.now().toString());
      // Reset counts
      setRdvButtonCount(0);
    }, 300000); // Reset counts every 10 minutes (600,000 milliseconds)
    return () => clearInterval(interval);
  }, [id]); // Add id as a dependency to reset counts for each doctor

  useEffect(() => {
    localStorage.setItem(`rdvButtonCount_${id}`, rdvButtonCount);
  }, [rdvButtonCount, id]);

  useEffect(() => {
    const doctorRef = doc(db, "doctors", id);
    getDoc(doctorRef)
      .then((docSnap) => {
        if (docSnap.exists()) {
          const doctorData = docSnap.data();
          setDoctor(doctorData);
        } else {
          console.log("Doctor not found");
        }
      })
      .catch((error) => {
        console.error("Error fetching doctor data:", error);
      });
  }, [id]);

  const fetchRdvData = async (doctorId) => {
    try {
      setLoading(true); // Set loading to true when fetching data
      const rdvRef = doc(db, "RDV", doctorId);
      const rdvDoc = await getDoc(rdvRef);

      if (rdvDoc.exists()) {
        const rdvData = rdvDoc.data();
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        const filteredTimeSlots = rdvData.selectedTimeSlots.reduce((filteredSlots, dayData) => {
          const dayDate = new Date(dayData.day);

          if (dayDate >= currentDate) {
            const filteredDaySlots = dayData.slots.filter((timeSlot) => {
              return timeSlot.status === "selected";
            });

            if (filteredDaySlots.length > 0) {
              filteredSlots.push({
                day: dayData.day,
                slots: filteredDaySlots,
              });
            }
          }

          return filteredSlots;
        }, []);

        setRdvData(filteredTimeSlots);
        setLoading(false); // Set loading to false when data is fetched

      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching RDV data:", error);

      if (error.code === "permission-denied") {
        // Show a toast message for insufficient permissions
        toast.error("Vous devez d'abord ouvrir un compte pour réserver un rendez-vous.");
      }

      setLoading(false);
    }

    setIsPopupOpen(true);
  };

  const handleRdvButtonClick = async () => {
    if (rdvButtonCount >= 5) {
      toast.error("Réessayer plus-tard!");
      return;
    }
    setRdvButtonCount(rdvButtonCount + 1);
    fetchRdvData(id);
  };

  const handleMapPopupOpen = (markerCoordinates) => {
    const { latitude, longitude } = markerCoordinates;

    if (latitude === 0 && longitude === 0) {
      notify('warning', translations.locationnotprovided);
    } else if (!isNaN(latitude) && !isNaN(longitude)) {
      setPropertyCoordinates({ latitude, longitude });
      setMapPopupVisible(true);
    } else {
      console.log('Invalid markerCoordinates format');
    }

    // Function to show notifications
    function notify(type, message) {
      switch (type) {
        case 'success':
          toast.success(message);
          break;
        case 'error':
          toast.error(message);
          break;
        case 'warning':
          toast.warning(message);
          break;
        default:
          toast(message);
      }
    }

  };

  const handleMapPopupClose = () => {
    setMapPopupVisible(false);
  };

  if (!doctor) {
    console.log("Loading doctor data...");
    return         <Spinner />    ;
  }

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
  };

  const generateShareableLink = (doctorId) => {
    // Assuming your doctor page URL pattern is '/doctors/:id'
    const doctorPageUrl = `${window.location.origin}/doctors/${doctorId}`;
    return doctorPageUrl;
  };

  const shareableLink = generateShareableLink(id);

  return (

    <main className="bg-blue-100 dark:bg-gray-700 py-8">
    <div className="container dark:bg-gray-700 bg-blue-100 mx-auto flex flex-col md:flex-row items-center md:space-x-8">
  
      {mapPopupVisible && (
        <MapPopup
          latitude={propertyCoordinates.latitude}
          longitude={propertyCoordinates.longitude}
          onClose={() => setMapPopupVisible(false)}
        />
      )}
  
      <div className="w-32 h-32 object-cover rounded-md overflow-hidden mb-4 md:mb-0">
        <img
          src={doctor.imageUrl || 'https://cdn.pixabay.com/photo/2020/08/03/09/39/medical-5459630_1280.png'}
          alt="Doctor's Profile"
          className="w-full h-full object-cover rounded-md"
          onError={(e) => {
            e.target.src = 'https://cdn.pixabay.com/photo/2020/08/03/09/39/medical-5459630_1280.png';
          }}
        />
      </div>
  
      <div className="flex flex-col md:items-start">
        <h2 className="text-2xl md:text-3xl dark:text-blue-100 font-semibold text-primary mb-2">
          {truncateText(doctor.doctorname || "N/A", 133)}
        </h2>
  
        <div className="flex flex-wrap mb-4">
          {doctor.specialties
            ? doctor.specialties.map((specialty, index) => (
                <span
                  key={index}
                  className="border border-blue-500 px-2 py-1  dark:text-blue-100  rounded-full mr-2 mb-2 hover:bg-blue-100"
                >
                  {specialty}
                </span>
              ))
            : "N/A"}
        </div>
  
        <div className="flex space-x-4 ">
        <button
  onClick={handleRdvButtonClick}
  className="bg-gradient-to-r from-blue-400 to-blue-500 hover:from-sky-400 hover:to-blue-500 text-white font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center transition-all duration-300 ease-in-out transform hover:scale-105"
>
  <LuActivity size={30} className="mr-2 transition-all duration-300 ease-in-out transform hover:rotate-12" /> 
  {loading ? <Spinner /> :  translations.bookappointement}
</button>

          <button
            onClick={() => handleMapPopupOpen(doctor.markerCoordinates)}
            className="bg-gradient-to-r from-blue-400 to-green-500 hover:from-sky-400 hover:to-green-500 text-white  font-semibold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center transition-all duration-300 ease-in-out transform hover:scale-105"
            >
            <FaMapMarkerAlt size={30} className="mr-2 transition-all duration-300 ease-in-out transform hover:rotate-12" /> {translations.Viewonmap}
          </button>
        </div>
      </div>
  
           <div className=" py-2 md:w-1/3">
        <div className="flex items-center space-x-2 mb-2">
          <MdLocationOn className="h-6 w-6 text-green-600" />
          <p className="text-sm md:text-base overflow-wrap font-semibold overscroll-none break-words  dark:text-blue-100  text-gray-600 ">
            {truncateText(doctor.address || "N/A", 1133)}
          </p>
        </div>
        <div className="flex items-center space-x-2 mb-2">
          <FaRegHospital className="h-6 w-6  dark:text-blue-400  text-blue-500" />
          <p className="text-sm md:text-base  break-words text-gray-600">
          <strong className="text-gray-600  dark:text-blue-100 text-sm break-words font-semibold">{translations.cabinetcliniquehospitalname}: </strong>   
             </p>
          
        </div>
        <p className="text-sm text-center  items-center md:text-base mt-1 bg-blue-500 border border-blue-300 break-words text-white">

 {truncateText(doctor.nomCabinet || "N/A", 33)}
          </p> 
        <div className=" flex items-center space-x-2 mb-2">
          <MdLocationCity className="h-6 w-6  dark:text-blue-400  text-blue-500" />
          <p className="text-sm md:text-base  dark:text-blue-100  text-gray-600 truncate">
          <strong className="text-gray-600   dark:text-blue-100 font-semibold"> {translations.Wilaya}:</strong> {truncateText(doctor.selectedWilaya, 40)}
          </p>
        </div>
        <div className=" flex items-center space-x-2 mb-2">

        <BiRadioCircleMarked className="h-6 w-6  dark:text-blue-400  text-blue-500" />
          <p className="text-sm md:text-base  dark:text-blue-100  text-gray-600 truncate">
          <strong className="  dark:text-blue-100 text-gray-600 font-semibold">  {translations.Daira}:</strong> {truncateText(doctor.selectedDaira, 40)}
          </p>
          </div>

      </div>
      
      {/* Share Listing */}
    
      <div className="md:ml-auto relative">
     
       <FiShare2
       
            size={30}
            className="text-primary  dark:text-blue-100  hover:text-blue-500 mt-2 cursor-pointer"
            onClick={() => setShareOptionsVisible(!shareOptionsVisible)}
          />
     {/* Share buttons */}
     <div className="text-sm font-semibold dark:text-blue-100 text-gray-600">{translations.Share}          
</div>   
          {/* Share Listing */}
          {shareOptionsVisible && (
       <div className=" bg-white p-2 rounded-lg shadow-md flex space-x-3 space-y-2">
             
       
             
              <EmailShareButton url={shareableLink}>
                <EmailIcon size={23} round />
              </EmailShareButton>
              <FacebookShareButton url={shareableLink}>
                <FacebookIcon size={23} round />
              </FacebookShareButton>
              <FacebookMessengerShareButton url={shareableLink}>
                <FacebookMessengerIcon size={23} round />
              </FacebookMessengerShareButton>
              <WhatsappShareButton url={shareableLink}>
                <WhatsappIcon size={23} round />
              </WhatsappShareButton>
              <ViberShareButton url={shareableLink}>
                <ViberIcon size={23} round />
              </ViberShareButton>
              <TelegramShareButton url={shareableLink}>
                <TelegramIcon size={23} round />
              </TelegramShareButton>
              <TwitterShareButton url={shareableLink}>
                <TwitterIcon size={23} round />
              </TwitterShareButton>
            </div>
          )}
                      </div>

    </div>   
 

    
<div className="mt-4">
  <p className="flex items-center  dark:text-blue-100  text-primary-600 font-semibold text-xl">
    <BiIntersect size={20} className="mr-4  dark:text-blue-100 " />
{translations.introduction2}:

</p>

  
  <div className="text-primary-600  mt-2 h-140 py-4 px-6 border-gray-400 border rounded-lg shadow-md overflow-wrap overscroll-none overflow-y-scroll break-words items-center w-9/12">
    <p className="text-lg md:text-xl break-words leading-7 mb-4  dark:text-blue-100  text-gray-800">
      {doctor.description || "No description available."}
    </p>
  </div>
</div>



    

     {/* Conditionally render the popup when isPopupOpen is true */}
     {isPopupOpen && (
        <Popups rdvData={rdvData} onClose={() => setIsPopupOpen(false)} doctorId={doctor.id} doctor={doctor} />
      )}
  </main>
  
  );
}

export default Doctors;

import { getAuth } from "firebase/auth";
import { doc, getDoc, updateDoc, setDoc } from "firebase/firestore";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

import { db } from "../firebase";
import { 
  getStorage, 
  ref, 
  uploadBytesResumable, 
  getDownloadURL 
} from "firebase/storage";
import { useAuthStatus } from "../hooks/useAuthStatus";
import { BsTrash } from 'react-icons/bs';
import { FaMale, FaFemale, FaEdit, FaMapMarkerAlt  } from 'react-icons/fa';
import { useLanguage } from '../components/LanguageContext'; 
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




export default function DoctorProfile() {
  const authStatus = useAuthStatus();
  const auth = authStatus ? getAuth() : null;
  const navigate = useNavigate();
  const [changeDetail, setChangeDetail] = useState(false);
  const { language, translations } = useLanguage();

  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    doctorname: "",
    email: "",
    phoneNumber: "",
    address: "",
    description: "",
    imageUrl: "",
    description: "", 
    markerCoordinates: { latitude:"", longitude:"" },
    zipCode:"",
    nomCabinet:"",
    sex:"",
    selectedWilaya: null,
    selectedDaira: null,
    zipCode: "",
    id: "",

  });
  const { doctorname, email, phoneNumber, address,  imageUrl, 
    description,
    markerCoordinates,    nomCabinet,      selectedWilaya,
    selectedDaira,
    sex,  zipCode, id,
  } = formData;

  useEffect(() => {
    async function fetchData() {
      try {
        if (auth) {
          const docRef = doc(db, "doctors", auth.currentUser.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const data = docSnap.data();
            setFormData(data);
      
          }
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    }
    fetchData();
  }, [auth]);

  const [image, setImage] = useState(null);
  const fileInputRef = useRef();
  const storage = getStorage(); // Initialize Firebase Storage

  const selectImage = () => {
    fileInputRef.current.click();
  };
  const onFileChange = (e) => {
    const file = e.target.files[0];
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];
    
    // Check if the file exists and is of allowed type
    if (file && allowedTypes.includes(file.type) && file.size <= 2 * 1024 * 1024) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage({ file, preview: reader.result });
      };
      reader.readAsDataURL(file);
    } else {
      if (!file) {
        toast.error('Please select a file.');
      } else if (!allowedTypes.includes(file.type)) {
        toast.error('Please select an image of type JPEG, JPG, PNG, or GIF.');
      } else {
        toast.error(`${translations. lessthen2mb}`);
      }
    }
  };
  
  const clearImage = () => {
    if (image) {
      URL.revokeObjectURL(image.preview);
      setImage(null);
    }
  };

  async function updateData() {
    try {
      setLoading(true);
      const docRef = doc(db, "doctors", auth.currentUser.uid);
      const docSnapshot = await getDoc(docRef);
      if (docSnapshot.exists()) {
        const updatedData = { ...formData }; // Update all fields
        await updateDoc(docRef, updatedData);
        setLoading(false);
        toast.success("Profile details updated");

        // Update data in the "alldoctors" collection as well
        const alldoctorsRef = doc(db, "alldoctors", "all-doctors-document");
        await setDoc(alldoctorsRef, { [auth.currentUser.uid]: updatedData }, { merge: true });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating data:", error);
      toast.error("Could not update profile details");
    }
  }

  async function handleImageUpload() {
    try {
      setLoading(true);



     // Upload the image to Cloudflare R2
     let downloadURL = null;
     if (image) {
       // Generate a random number
const user = auth.currentUser;
function generateRandomName(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
}

// Usage example
const randomName = generateRandomName(13); 
// Sanitize the filename to remove invalid characters
const sanitizedFilename = `${user.uid}_${randomName}`;

// Upload the image to Cloudflare R2 using the sanitized filename
const formData = new FormData();
formData.append('file', image.file);

const response = await fetch('https://hello-world-shiny-art-9691.mokraniagld.workers.dev/' + sanitizedFilename, {
    method: 'PUT',
    body:  image.file,
    headers: {
        'x-api-key': 'AzertyBoghni'
    }
});
         if (response.ok) {
             // Construct the download URL
             downloadURL = `https://profilepicture.doktori.site/${sanitizedFilename}`;
         } else {
             throw new Error('Error uploading image.');
         }
     

       
        setFormData((prevState) => ({
          ...prevState,
          imageUrl: downloadURL,
        }));
        // Update imageUrl in Firestore immediately after image upload
        const docRef = doc(db, "doctors", auth.currentUser.uid);
        await updateDoc(docRef, { imageUrl: downloadURL });
        setLoading(false);
        toast.success("Profile picture updated");

        // Update data in the "alldoctors" collection as well
        const alldoctorsRef = doc(db, "alldoctors", "all-doctors-document");
        await setDoc(alldoctorsRef, { [auth.currentUser.uid]: { imageUrl: downloadURL } }, { merge: true });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error uploading image:", error);
      toast.error("Could not upload profile picture");
    }
  }





 


  
 // New onChange function to handle selected Wilaya change
 const handleWilayaChange = (selectedOption) => {
  if (selectedOption) {
    const selectedWilaya = selectedOption.label;
    setFormData((prevState) => ({
      ...prevState,
      selectedWilaya,
      selectedDaira: null,
    }));
  } else {
    setFormData((prevState) => ({
      ...prevState,
      selectedWilaya: null,
      selectedDaira: null,
    }));
  }
};

   // New onChange function to handle selected Daira change
   const handleDairaChange = (selectedOption) => {
    if (selectedOption) {
      const selectedDaira = selectedOption.label;
      setFormData((prevState) => ({
        ...prevState,
        selectedDaira,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        selectedDaira: null,
      }));
    }
  };


// Define function to get daira options based on selected wilaya
const getDairaOptions = () => {
  if (selectedWilaya && dairas[selectedWilaya.value]) {
    return dairas[selectedWilaya.value];
  } else {
    return [];
  }
};
  
     
const wilayas = [
  { label: 'Tizi Ouzou', value: 'Tizi Ouzou' },
  { label: 'Alger', value: 'Alger' },
  { label: 'Oran', value: 'Oran' },
  { label: 'Constantine', value: 'Constantine' },
  { label: 'Tipaza', value: 'Tipaza' },
  { label: 'Sétif', value: 'Sétif' },
  { label: 'Boumerdès', value: 'Boumerdès' },

  { label: 'Médéa', value: 'Médéa' },
  { label: 'Mostaganem', value: 'Mostaganem' },
    { label: 'Adrar', value: 'Adrar' },
    { label: 'Chlef', value: 'Chlef' },
    { label: 'Laghouat', value: 'Laghouat' },
    { label: 'Oum El Bouaghi', value: 'Oum El Bouaghi' },
    { label: 'Batna', value: 'Batna' },
    { label: 'Béjaïa', value: 'Béjaïa' },
  
  
  
    { label: 'Biskra', value: 'Biskra' },
    { label: 'Béchar', value: 'Béchar' },
    { label: 'Blida', value: 'Blida' },
    { label: 'Bouira', value: 'Bouira' },
    { label: 'Tamanrasset', value: 'Tamanrasset' },
    { label: 'Tebessa', value: 'Tebessa' },
    { label: 'Tlemcen', value: 'Tlemcen' },
    { label: 'Tiaret', value: 'Tiaret' },
   

    { label: 'Djelfa', value: 'Djelfa' },
    { label: 'Jijel', value: 'Jijel' },
    { label: 'Saida', value: 'Saida' },
    { label: 'Skikda', value: 'Skikda' },
    { label: 'Sidi-Bel-Abbès', value: 'Sidi-Bel-Abbès' },
    { label: 'Annaba', value: 'Annaba' },
    { label: 'Guelma', value: 'Guelma' },
{ label: 'Msila', value: 'Msila' },
    { label: 'Mascara', value: 'Mascara' },



    { label: 'El-Bayadh', value: 'El-Bayadh' },
    { label: 'Illizi', value: 'Illizi' },
    { label: 'Bordj-Bou-Arreridj', value: 'Bordj-Bou-Arreridj' },
    { label: 'El-Tarf', value: 'El-Tarf' },
    { label: 'Tindouf', value: 'Tindouf' },
    { label: 'Tissemsilt', value: 'Tissemsilt' },
    { label: 'El-Oued', value: 'El-Oued' },
    { label: 'Khenchela', value: 'Khenchela' },
    { label: 'Souk-Ahras', value: 'Souk-Ahras' },
    { label: 'Mila', value: 'Mila' },
    { label: 'Aïn-Defla', value: 'Aïn-Defla' },
    { label: 'Naâma', value: 'Naâma' },
    { label: 'Aïn-Témouchent', value: 'Aïn-Témouchent' },
    { label: 'Ghardaia', value: 'Ghardaia' },
   
   
    { label: 'Relizane', value: 'Relizane' },
    { label: 'Timimoun', value: 'Timimoun' },
    { label: 'Bordj Badji Mokhtar', value: 'Bordj Badji Mokhtar' },
    { label: 'Ouled Djellal', value: 'Ouled Djellal' },
    { label: 'Béni Abbès', value: 'Béni Abbès' },
    { label: 'In Salah', value: 'In Salah' },
    { label: 'In Guezzam', value: 'In Guezzam' },
    { label: 'Touggourt', value: 'Touggourt' },
    { label: 'Djanet', value: 'Djanet' },
    { label: 'El Mghair', value: 'El Mghair' },
    { label: 'El Meniaa', value: 'El Meniaa' }


];

const dairas = {
  'Tizi Ouzou': [
    { label: 'Boghni', value: 'Boghni' },
    { label: 'Ouadhias', value: 'Ouadhias' },
    { label: 'Tigzirt', value: 'Tigzirt' },
    { label: 'Tizi Ouzou', value: 'Tizi Ouzou' },
    { label: 'Draa El Mizan', value: 'Draa El Mizan' },

    { label: 'Ain El Hammam', value: 'Ain El Hammam' },
    { label: 'Azazga', value: 'Azazga' },
    { label: 'Azeffoun', value: 'Azeffoun' },
    { label: 'Beni Douala', value: 'Beni Douala' },
    { label: 'Ath Dwala', value: 'Ath Dwala' },
    { label: 'Beni-yenni', value: 'Beni-yenni' },
    { label: 'Ath Yenni', value: 'Ath Yenni' },
    { label: 'Bouzeguene', value: 'Bouzeguene' },
    { label: 'Draa Ben Khedda', value: 'Draa Ben Khedda' },
    { label: 'Iferhounène', value: 'Iferhounène' },
    { label: 'Larbaa Nath Irathen', value: 'Larbaa Nath Irathen' },
    { label: 'Maatkas', value: 'Maatkas' },
    { label: 'Makouda', value: 'Makouda' },
    { label: 'Mekla', value: 'Mekla' },
    { label: 'Ouacifs', value: 'Ouacifs' },
    { label: 'Ouaguenoun', value: 'Ouaguenoun' },
    { label: 'Tizi Ghenif', value: 'Tizi Ghenif' },
    { label: 'Tizi-Rached', value: 'Tizi-Rached' }
],

'Alger': [
  { label: 'Zéralda', value: 'Zéralda' },
  { label: 'Chéraga', value: 'Chéraga' },
  { label: 'Draria', value: 'Draria' },
  { label: 'Bir Mourad Raïs', value: 'Bir Mourad Raïs' },
  { label: 'Birtouta', value: 'Birtouta' },
  { label: 'Bouzareah', value: 'Bouzareah' },
  { label: 'Bab El Oued', value: 'Bab El Oued' },
  { label: 'Sidi Mhamed', value: 'Sidi Mhamed' },
  { label: 'Hussein Dey', value: 'Hussein Dey' },
  { label: 'El Harrach', value: 'El Harrach' },
  { label: 'Baraki', value: 'Baraki' },
  { label: 'Dar El Beïda', value: 'Dar El Beïda' },
  { label: 'Rouïba', value: 'Rouïba' }
],

'Oran': [
{ label: 'Oran', value: 'Oran' },

{ label: 'Aïn El Turk', value: 'Aïn El Turk' },
{ label: 'Arzew', value: 'Arzew' },
{ label: 'Bethioua', value: 'Bethioua' },
{ label: 'Es Sénia', value: 'Es Sénia' },
{ label: 'Bir El Djir', value: 'Bir El Djir' },
{ label: 'Boutlélis', value: 'Boutlélis' },
{ label: 'Oued Tlelat', value: 'Oued Tlelat' },
{ label: 'Gdyel', value: 'Gdyel' }
],

'Constantine': [
{ label: 'Constantine', value: 'Constantine' },

{ label: 'El Khroub', value: 'El Khroub' },
{ label: 'Aïn Abid', value: 'Aïn Abid' },
{ label: 'Zighoud Youcef', value: 'Zighoud Youcef' },
{ label: 'Hamma Bouziane', value: 'Hamma Bouziane' },
{ label: 'Ibn Ziad', value: 'Ibn Ziad' }
],


'Tipaza': [
{ label: 'Ahmar El Ain', value: 'Ahmar El Ain' },
{ label: 'Bou Ismail', value: 'Bou Ismail' },
{ label: 'Cherchell', value: 'Cherchell' },
{ label: 'Damous', value: 'Damous' },
{ label: 'Fouka', value: 'Fouka' },
{ label: 'Gouraya', value: 'Gouraya' },
{ label: 'Hadjout', value: 'Hadjout' },
{ label: 'Koléa', value: 'Koléa' },
{ label: 'Sidi Amar', value: 'Sidi Amar' },
{ label: 'Tipaza', value: 'Tipaza' }
],

'Sétif': [
{ label: 'Sétif', value: 'Sétif' },

{ label: 'Aïn Arnat', value: 'Aïn Arnat' },
{ label: 'Aïn Azel', value: 'Aïn Azel' },
{ label: 'Aïn El Kebira', value: 'Aïn El Kebira' },
{ label: 'Aïn Oulmene', value: 'Aïn Oulmene' },
{ label: 'Amoucha', value: 'Amoucha' },
{ label: 'Babor', value: 'Babor' },
{ label: 'Beni Aziz', value: 'Beni Aziz' },
{ label: 'Beni Ourtilane', value: 'Beni Ourtilane' },
{ label: 'Bir El Arch', value: 'Bir El Arch' },
{ label: 'Bouandas', value: 'Bouandas' },
{ label: 'Bougaa', value: 'Bougaa' },
{ label: 'Djemila', value: 'Djemila' },
{ label: 'El Eulma', value: 'El Eulma' },
{ label: 'Guidjel', value: 'Guidjel' },
{ label: 'Guenzet', value: 'Guenzet' },
{ label: 'Hammam Guergour', value: 'Hammam Guergour' },
{ label: 'Hammam Soukhna', value: 'Hammam Soukhna' },
{ label: 'Maoklane', value: 'Maoklane' },
{ label: 'Salah Bey', value: 'Salah Bey' }
],
'Boumerdès': [
{ label: 'Boumerdès', value: 'Boumerdès' },

{ label: 'Baghlia', value: 'Baghlia' },
{ label: 'Boudouaou', value: 'Boudouaou' },
{ label: 'Bordj Ménaïel', value: 'Bordj Ménaïel' },
{ label: 'Boumerdès', value: 'Boumerdès' },
{ label: 'Dellys', value: 'Dellys' },
{ label: 'Khemis El Kechna', value: 'Khemis El Kechna' },
{ label: 'Isser', value: 'Isser' },
{ label: 'Naciria', value: 'Naciria' },
{ label: 'Thenia', value: 'Thenia' }
],

'Médéa': [
{ label: 'Aïn Boucif', value: 'Aïn Boucif' },
{ label: 'Aziz', value: 'Aziz' },
{ label: 'Beni Slimane', value: 'Beni Slimane' },
{ label: 'Berrouaghia', value: 'Berrouaghia' },
{ label: 'Chahbounia', value: 'Chahbounia' },
{ label: 'Chellalet El Adhaoura', value: 'Chellalet El Adhaoura' },
{ label: 'El Azizia', value: 'El Azizia' },
{ label: 'El Guelb El Kebir', value: 'El Guelb El Kebir' },
{ label: 'El Omaria', value: 'El Omaria' },
{ label: 'Ksar Boukhari', value: 'Ksar Boukhari' },
{ label: 'Médéa', value: 'Médéa' },
{ label: 'Ouamri', value: 'Ouamri' },
{ label: 'Ouled Antar', value: 'Ouled Antar' },
{ label: 'Ouzera', value: 'Ouzera' },
{ label: 'Seghouane', value: 'Seghouane' },
{ label: 'Sidi Naâmane', value: 'Sidi Naâmane' },
{ label: 'Si Mahdjoub', value: 'Si Mahdjoub' },
{ label: 'Souagui', value: 'Souagui' },
{ label: 'Tablat', value: 'Tablat' }
],

'Mostaganem': [
{ label: 'Achaacha', value: 'Achaacha' },
{ label: 'Ain Nouissi', value: 'Ain Nouissi' },
{ label: 'Ain Tadles', value: 'Ain Tadles' },
{ label: 'Bouguirat', value: 'Bouguirat' },
{ label: 'Hassi Mameche', value: 'Hassi Mameche' },
{ label: 'Khiredine', value: 'Khiredine' },
{ label: 'Mesra', value: 'Mesra' },
{ label: 'Mostaganem', value: 'Mostaganem' },
{ label: 'Sidi Ali', value: 'Sidi Ali' },
{ label: 'Sidi Lakhdar', value: 'Sidi Lakhdar' }
],

'Adrar': [
{ label: 'Adrar', value: 'Adrar' },
{ label: 'Aoulef', value: 'Aoulef' },
{ label: 'Aougrout', value: 'Aougrout' },
{ label: 'Bordj Badji Mokhtar', value: 'Bordj Badji Mokhtar' },
{ label: 'Charouine', value: 'Charouine' },
{ label: 'Fenoughil', value: 'Fenoughil' },
{ label: 'Reggane', value: 'Reggane' },
{ label: 'Timimoun', value: 'Timimoun' },
{ label: 'Tinerkouk', value: 'Tinerkouk' },
{ label: 'Tsabit', value: 'Tsabit' },
{ label: 'Zaouiet Kounta', value: 'Zaouiet Kounta' }
],

'Chlef': [
{ label: 'Abou El Hassan', value: 'Abou El Hassan' },
{ label: 'Aïn Merane', value: 'Aïn Merane' },
{ label: 'Beni Haoua', value: 'Beni Haoua' },
{ label: 'Boukadir', value: 'Boukadir' },
{ label: 'Chlef', value: 'Chlef' },
{ label: 'El Karimia', value: 'El Karimia' },
{ label: 'El Marsa', value: 'El Marsa' },
{ label: 'Oued Fodda', value: 'Oued Fodda' },
{ label: 'Ouled Ben Abdelkader', value: 'Ouled Ben Abdelkader' },
{ label: 'Ouled Fares', value: 'Ouled Fares' },
{ label: 'Taougrit', value: 'Taougrit' },
{ label: 'Ténès', value: 'Ténès' },
{ label: 'Zeboudja', value: 'Zeboudja' }
],

'Laghouat': [
{ label: 'Aflou', value: 'Aflou' },
{ label: 'Aïn Mahdi', value: 'Aïn Mahdi' },
{ label: 'Brida', value: 'Brida' },
{ label: 'El Ghicha', value: 'El Ghicha' },
{ label: 'Gueltet Sidi Saâd', value: 'Gueltet Sidi Saâd' },
{ label: 'Hassi Rmel', value: 'Hassi Rmel' },
{ label: 'Ksar El Hirane', value: 'Ksar El Hirane' },
{ label: 'Laghouat', value: 'Laghouat' },
{ label: 'Oued Morra', value: 'Oued Morra' },
{ label: 'Sidi Makhlouf', value: 'Sidi Makhlouf' }
],


'Oum El Bouaghi': [
{ label: 'Aïn Babouche', value: 'Aïn Babouche' },
{ label: 'Ksar Sbahi', value: 'Ksar Sbahi' },
{ label: 'Aïn Beïda', value: 'Aïn Beïda' },
{ label: 'Fkirina', value: 'Fkirina' },
{ label: 'Aïn Mlila', value: 'Aïn Mlila' },
{ label: 'Souk Naamane', value: 'Souk Naamane' },
{ label: 'Aïn Fakroun', value: 'Aïn Fakroun' },
{ label: 'Sigus', value: 'Sigus' },
{ label: 'Aïn Kercha', value: 'Aïn Kercha' },
{ label: 'Meskiana', value: 'Meskiana' },
{ label: 'Dhalaa', value: 'Dhalaa' },
{ label: 'Oum El Bouaghi', value: 'Oum El Bouaghi' }
],

'Batna': [
{ label: 'Aïn Djasser', value: 'Aïn Djasser' },
{ label: 'Aïn Touta', value: 'Aïn Touta' },
{ label: 'Arris', value: 'Arris' },
{ label: 'Barika', value: 'Barika' },
{ label: 'Batna', value: 'Batna' },
{ label: 'Bouzina', value: 'Bouzina' },
{ label: 'Chemora', value: 'Chemora' },
{ label: 'Djezzar', value: 'Djezzar' },
{ label: 'El Madher', value: 'El Madher' },
{ label: 'Ichmoul', value: 'Ichmoul' },
{ label: 'Menaa', value: 'Menaa' },
{ label: 'Merouana', value: 'Merouana' },
{ label: 'N\'Gaous', value: 'N\'Gaous' },
{ label: 'Ouled Si Slimane', value: 'Ouled Si Slimane' },
{ label: 'Ras El Aioun', value: 'Ras El Aioun' },
{ label: 'Seggana', value: 'Seggana' },
{ label: 'Seriana', value: 'Seriana' },
{ label: 'Tazoult', value: 'Tazoult' },
{ label: 'Teniet El Abed', value: 'Teniet El Abed' },
{ label: 'Timgad', value: 'Timgad' }
],

'Béjaïa': [
{ label: 'Adekar', value: 'Adekar' },
{ label: 'Akbou', value: 'Akbou' },
{ label: 'Amizour', value: 'Amizour' },
{ label: 'Aokas', value: 'Aokas' },
{ label: 'Barbacha', value: 'Barbacha' },
{ label: 'Béjaïa', value: 'Béjaïa' },
{ label: 'Beni Maouche', value: 'Beni Maouche' },
{ label: 'Chemini', value: 'Chemini' },
{ label: 'Darguina', value: 'Darguina' },
{ label: 'El Kseur', value: 'El Kseur' },
{ label: 'Ighil Ali', value: 'Ighil Ali' },
{ label: 'Kherrata', value: 'Kherrata' },
{ label: 'Ouzellaguen', value: 'Ouzellaguen' },
{ label: 'Seddouk', value: 'Seddouk' },
{ label: 'Sidi-Aïch', value: 'Sidi-Aïch' },
{ label: 'Souk El Ténine', value: 'Souk El Ténine' },
{ label: 'Tazmalt', value: 'Tazmalt' },
{ label: 'Tichy', value: 'Tichy' },
{ label: 'Timezrit', value: 'Timezrit' }
],

'Jijel': [
{ label: 'Chekfa', value: 'Chekfa' },
{ label: 'Djimla', value: 'Djimla' },
{ label: 'El Ancer', value: 'El Ancer' },
{ label: 'El Aouana', value: 'El Aouana' },
{ label: 'El Milia', value: 'El Milia' },
{ label: 'Jijel', value: 'Jijel' },
{ label: 'Settara', value: 'Settara' },
{ label: 'Sidi Maarouf', value: 'Sidi Maarouf' },
{ label: 'Taher', value: 'Taher' },
{ label: 'Texenna', value: 'Texenna' },
{ label: 'Ziama Mansouriah', value: 'Ziama Mansouriah' }
],

'Djelfa': [
{ label: 'Aïn El Ibel', value: 'Aïn El Ibel' },
{ label: 'Aïn Oussara', value: 'Aïn Oussara' },
{ label: 'Birine', value: 'Birine' },
{ label: 'Charef', value: 'Charef' },
{ label: 'Dar Chioukh', value: 'Dar Chioukh' },
{ label: 'Djelfa', value: 'Djelfa' },
{ label: 'El Idrissia', value: 'El Idrissia' },
{ label: 'Faidh El Botma', value: 'Faidh El Botma' },
{ label: 'Had-Sahary', value: 'Had-Sahary' },
{ label: 'Hassi Bahbah', value: 'Hassi Bahbah' }
],

'Saida': [
{ label: 'Saida', value: 'Saida' },
{ label: 'Aïn El Hadjar', value: 'Aïn El Hadjar' },
{ label: 'Sidi Boubekeur', value: 'Sidi Boubekeur' },
{ label: 'El Hassasna', value: 'El Hassasna' },
{ label: 'Ouled Brahim', value: 'Ouled Brahim' },
{ label: 'Youb', value: 'Youb' }
],

'Skikda': [
{ label: 'Skikda', value: 'Skikda' },
{ label: 'Azzaba', value: 'Azzaba' },
{ label: 'Aïn Kechra', value: 'Aïn Kechra' },
{ label: 'Ben Azzouz', value: 'Ben Azzouz' },
{ label: 'Collo', value: 'Collo' },
{ label: 'El Hadaiek', value: 'El Hadaiek' },
{ label: 'El Harrouch', value: 'El Harrouch' },
{ label: 'Ouled Attia', value: 'Ouled Attia' },
{ label: 'Oum Toub', value: 'Oum Toub' },
{ label: 'Ramdane Djamel', value: 'Ramdane Djamel' },
{ label: 'Sidi Mezghiche', value: 'Sidi Mezghiche' },
{ label: 'Tamalous', value: 'Tamalous' },
{ label: 'Zitouna', value: 'Zitouna' }
],
'Sidi Bel Abbès': [
{ label: 'Sidi Bel Abbès', value: 'Sidi Bel Abbès' },
{ label: 'Aïn el Berd', value: 'Aïn el Berd' },
{ label: 'Ben Badis', value: 'Ben Badis' },
{ label: 'Marhoum', value: 'Marhoum' },
{ label: 'Merine', value: 'Merine' },
{ label: 'Mostefa Ben Brahim', value: 'Mostefa Ben Brahim' },
{ label: 'Moulay Slissen', value: 'Moulay Slissen' },
{ label: 'Ras El Ma', value: 'Ras El Ma' },
{ label: 'Sfisef', value: 'Sfisef' },
{ label: 'Sidi Ali Benyoub', value: 'Sidi Ali Benyoub' },
{ label: 'Sidi Ali Boussidi', value: 'Sidi Ali Boussidi' },
{ label: 'Sidi Lahcene', value: 'Sidi Lahcene' },
{ label: 'Telagh', value: 'Telagh' },
{ label: 'Tenira', value: 'Tenira' },
{ label: 'Tessala', value: 'Tessala' }
],

'Annaba': [
{ label: 'Annaba', value: 'Annaba' },
{ label: 'Aïn Berda', value: 'Aïn Berda' },
{ label: 'El Hadjar', value: 'El Hadjar' },
{ label: 'Berrahal', value: 'Berrahal' },
{ label: 'Chetaïbi', value: 'Chetaïbi' },
{ label: 'El Bouni', value: 'El Bouni' }
],
'Guelma': [       
{ label: 'Guelma', value: 'Guelma' },   
{ label: 'Khezarra', value: 'Khezarra' },
    { label: 'Guelaât Bou Sbaâ', value: 'Guelaât Bou Sbaâ' },
    { label: 'Héliopolis', value: 'Héliopolis' },
    { label: 'Oued Zenati', value: 'Oued Zenati' },
    { label: 'Ain Makhlouf', value: 'Ain Makhlouf' },
    { label: 'Hammam Debagh', value: 'Hammam Debagh' },
    { label: 'Bouchegouf', value: 'Bouchegouf' },
    { label: 'Hammam N’Bails', value: 'Hammam N’Bails' },
    { label: 'Houari Boumédiène', value: 'Houari Boumédiène' }
],

'Msila': [
{ label: 'Msila', value: 'Msila' },
    { label: 'Hammam Dalaa', value: 'Hammam Dalaa' },
    { label: 'Ouled Derradj', value: 'Ouled Derradj' },
    { label: 'Sidi Aissa', value: 'Sidi Aissa' },
    { label: 'Aïn El Melh', value: 'Aïn El Melh' },
    { label: 'Ben Srour', value: 'Ben Srour' },
    { label: 'Bou Saada', value: 'Bou Saada' },
    { label: 'Ouled sidi Brahim', value: 'Ouled sidi Brahim' },
    { label: 'Sidi Ameur', value: 'Sidi Ameur' },
    { label: 'Magra', value: 'Magra' },
    { label: 'Chellal', value: 'Chellal' },
    { label: 'Khoubana', value: 'Khoubana' },
    { label: 'Medjedel', value: 'Medjedel' },
    { label: 'Aïn El Hadjel', value: 'Aïn El Hadjel' },
    { label: 'Djebel Messaad', value: 'Djebel Messaad' }
],


'Mascara': [
    { label: 'Aïn Fares', value: 'Aïn Fares' },
    { label: 'Aïn Fekan', value: 'Aïn Fekan' },
    { label: 'Aouf', value: 'Aouf' },
    { label: 'Bou Hanifia', value: 'Bou Hanifia' },
    { label: 'El Bordj', value: 'El Bordj' },
    { label: 'Ghriss', value: 'Ghriss' },
    { label: 'Hachem', value: 'Hachem' },
    { label: 'Mascara', value: 'Mascara' },
    { label: 'Mohammadia', value: 'Mohammadia' },
    { label: 'Oggaz', value: 'Oggaz' },
    { label: 'Oued El Abtal', value: 'Oued El Abtal' },
    { label: 'Oued Taria', value: 'Oued Taria' },
    { label: 'Sig', value: 'Sig' },
    { label: 'Tighennif', value: 'Tighennif' },
    { label: 'Tizi', value: 'Tizi' },
{ label: 'Zahana', value: 'Zahana' }
],

'Biskra': [
{ label: 'Biskra', value: 'Biskra' },
  { label: 'Djemorah', value: 'Djemorah' },
{ label: 'Djemorah', value: 'Djemorah' },
    { label: 'El Kantara', value: 'El Kantara' },
    { label: 'M\'Chouneche', value: 'M\'Chouneche' },
    { label: 'Sidi Okba', value: 'Sidi Okba' },
    { label: 'Zeribet El Oued', value: 'Zeribet El Oued' },
    { label: 'Ourlal', value: 'Ourlal' },
    { label: 'Tolga', value: 'Tolga' },
    { label: 'Ouled Djellal', value: 'Ouled Djellal' },
    { label: 'Sidi Khaled', value: 'Sidi Khaled' },
    { label: 'Foughala', value: 'Foughala' },
    { label: 'El Outaya', value: 'El Outaya' }
],

'Béchar': [
{ label: 'Béchar', value: 'Béchar' },
 { label: 'Beni Ounif', value: 'Beni Ounif' },
    { label: 'Lahmar', value: 'Lahmar' },
    { label: 'Kenadsa', value: 'Kenadsa' },
    { label: 'Taghit', value: 'Taghit' },
    { label: 'Abadla', value: 'Abadla' },
    { label: 'Tabelbala', value: 'Tabelbala' },
    { label: 'Igli', value: 'Igli' },
    { label: 'Beni Abbes', value: 'Beni Abbes' },
    { label: 'El Ouata', value: 'El Ouata' },
    { label: 'Kerzaz', value: 'Kerzaz' },
    { label: 'Ouled Khoudir', value: 'Ouled Khoudir' }
],

'Blida': [
  { label: 'Blida', value: 'Blida' },
    { label: 'Boufarik', value: 'Boufarik' },
    { label: 'Bougara', value: 'Bougara' },
    { label: 'Bouinan', value: 'Bouinan' },
    { label: 'El Affroun', value: 'El Affroun' },
    { label: 'Larbaa', value: 'Larbaa' },
    { label: 'Meftah', value: 'Meftah' },
    { label: 'Mouzaïa', value: 'Mouzaïa' },
    { label: 'Oued Alleug', value: 'Oued Alleug' },
    { label: 'Ouled Yaich', value: 'Ouled Yaich' }
],

'Bouira': [     { label: 'Bouira', value: 'Bouira' },
    { label: 'Haizer', value: 'Haizer' },
    { label: 'Bechloul', value: 'Bechloul' },
    { label: 'Mchedallah', value: 'Mchedallah' },
    { label: 'Kadiria', value: 'Kadiria' },
    { label: 'Lakhdaria', value: 'Lakhdaria' },
    { label: 'Bir Ghbalou', value: 'Bir Ghbalou' },
    { label: 'Ain Bessam', value: 'Ain Bessam' },
    { label: 'Souk El Khemis', value: 'Souk El Khemis' },
    { label: 'El Hachimia', value: 'El Hachimia' },
    { label: 'Sour El Ghozlane', value: 'Sour El Ghozlane' },
    { label: 'Bordj Okhriss', value: 'Bordj Okhriss' }
],

'Tamanrasset': [
    { label: 'Tamanrasset', value: 'Tamanrasset' },
    { label: 'Abalessa', value: 'Abalessa' },
    { label: 'In Ghar', value: 'In Ghar' },
    { label: 'In Guezzam', value: 'In Guezzam' },
    { label: 'In Salah', value: 'In Salah' },
    { label: 'Tazrouk', value: 'Tazrouk' },
    { label: 'Tinzaouten', value: 'Tinzaouten' }
],

'Tebessa': [    { label: 'Tebessa', value: 'Tebessa' },
    { label: 'El Kouif', value: 'El Kouif' },
    { label: 'Morsott', value: 'Morsott' },
    { label: 'El Ma Labiodh', value: 'El Ma Labiodh' },
    { label: 'El Aouinet', value: 'El Aouinet' },
    { label: 'Ouenza', value: 'Ouenza' },
    { label: 'Bir Mokkadem', value: 'Bir Mokkadem' },
    { label: 'Bir El-Ater', value: 'Bir El-Ater' },
    { label: 'El Ogla', value: 'El Ogla' },
    { label: 'Oum Ali', value: 'Oum Ali' },
    { label: 'Negrine', value: 'Negrine' },
    { label: 'Cheria', value: 'Cheria' }
],

'Tlemcen': [ { label: 'Tlemcen', value: 'Tlemcen' },
    { label: 'Aïn Tallout', value: 'Aïn Tallout' },
    { label: 'Bab El Assa', value: 'Bab El Assa' },
    { label: 'Beni Boussaid', value: 'Beni Boussaid' },
    { label: 'Beni Snous', value: 'Beni Snous' },
    { label: 'Bensekrane', value: 'Bensekrane' },
    { label: 'Chetouane', value: 'Chetouane' },
    { label: 'Fellaoucene', value: 'Fellaoucene' },
    { label: 'Ghazaouet', value: 'Ghazaouet' },
    { label: 'Hennaya', value: 'Hennaya' },
    { label: 'Honaïne', value: 'Honaïne' },
    { label: 'Maghnia', value: 'Maghnia' },
    { label: 'Mansourah', value: 'Mansourah' },
    { label: 'Marsa Ben M\'Hidi', value: 'Marsa Ben M\'Hidi' },
    { label: 'Nedroma', value: 'Nedroma' },
    { label: 'Ouled Mimoun', value: 'Ouled Mimoun' },
    { label: 'Remchi', value: 'Remchi' },
    { label: 'Sabra', value: 'Sabra' },
    { label: 'Sebdou', value: 'Sebdou' },
    { label: 'Sidi Djillal', value: 'Sidi Djillal' }
],

'Tiaret': [
{ label: 'Tiaret', value: 'Tiaret' },
    { label: 'Sougueur', value: 'Sougueur' },
    { label: 'Aïn Deheb', value: 'Aïn Deheb' },
    { label: 'Aïn Kermes', value: 'Aïn Kermes' },
    { label: 'Frenda', value: 'Frenda' },
    { label: 'Dahmouni', value: 'Dahmouni' },
    { label: 'Mahdia', value: 'Mahdia' },
    { label: 'Hamadia', value: 'Hamadia' },
    { label: 'Ksar Chellala', value: 'Ksar Chellala' },
    { label: 'Medroussa', value: 'Medroussa' },
    { label: 'Mechraa Safa', value: 'Mechraa Safa' },
    { label: 'Rahouia', value: 'Rahouia' },
    { label: 'Oued Lilli', value: 'Oued Lilli' },
    { label: 'Meghila', value: 'Meghila' }
],

'El Bayadh': [
{ label: 'El Bayadh', value: 'El Bayadh' },
    { label: 'Rogassa', value: 'Rogassa' },
    { label: 'Brezina', value: 'Brezina' },
    { label: 'El Abiodh Sidi Cheikh', value: 'El Abiodh Sidi Cheikh' },
    { label: 'Bougtoub', value: 'Bougtoub' },
    { label: 'Chellala', value: 'Chellala' },
    { label: 'Boussemghoun', value: 'Boussemghoun' },
    { label: 'Boualem', value: 'Boualem' },
],

'Illizi': [  { label: 'Illizi', value: 'Illizi' },
    { label: 'Bordj Omar Driss', value: 'Bordj Omar Driss' },
    { label: 'Debdeb', value: 'Debdeb' },
    { label: 'In Amenas', value: 'In Amenas' }
],

'Bordj Bou Arreridj': [ 
{ label: 'Bordj-Bou-Arreridj', value: 'Bordj-Bou-Arreridj' },
    { label: 'Aïn Taghrout', value: 'Aïn Taghrout' },
    { label: 'Ras El Oued', value: 'Ras El Oued' },
    { label: 'Bordj Ghedir', value: 'Bordj Ghedir' },
    { label: 'Bir Kasdali', value: 'Bir Kasdali' },
    { label: 'El Hamadia', value: 'El Hamadia' },
    { label: 'Mansoura', value: 'Mansoura' },
    { label: 'Medjana', value: 'Medjana' },
    { label: 'Bordj Zemoura', value: 'Bordj Zemoura' },
    { label: 'Djaafra', value: 'Djaafra' }
],

'El Tarf': [
  { label: 'El-Tarf', value: 'El-Tarf' },
{ label: 'El Kala', value: 'El Kala' },
    { label: 'Ben Mehidi', value: 'Ben Mehidi' },
    { label: 'Besbes', value: 'Besbes' },
    { label: 'Dréan', value: 'Dréan' },
    { label: 'Bouhadjar', value: 'Bouhadjar' },
    { label: 'Bouteldja', value: 'Bouteldja' },
],

'Tindouf': [ { label: 'Tindouf', value: 'Tindouf' },

],

'Tissemsilt': [ 
{ label: 'Tissemsilt', value: 'Tissemsilt' },
    { label: 'Ammari', value: 'Ammari' },
    { label: 'Bordj Bou Naama', value: 'Bordj Bou Naama' },
    { label: 'Bordj El Emir Abdelkader', value: 'Bordj El Emir Abdelkader' },
    { label: 'Khemisti', value: 'Khemisti' },
    { label: 'Lardjem', value: 'Lardjem' },
    { label: 'Lazharia', value: 'Lazharia' },
    { label: 'Theniet El Had', value: 'Theniet El Had' }
],

'El Oued': [
{ label: 'El-Oued', value: 'El-Oued' },
    { label: 'Robbah', value: 'Robbah' },
    { label: 'Oued El Alenda', value: 'Oued El Alenda' },
    { label: 'Bayadha', value: 'Bayadha' },
    { label: 'Nakhla', value: 'Nakhla' },
    { label: 'Guemar', value: 'Guemar' },
    { label: 'Kouinine', value: 'Kouinine' },
    { label: 'Reguiba', value: 'Reguiba' },
    { label: 'Hamraia', value: 'Hamraia' },
    { label: 'Taghzout', value: 'Taghzout' },
    { label: 'Debila', value: 'Debila' },
    { label: 'Hassani Abdelkrim', value: 'Hassani Abdelkrim' },
    { label: 'Hassi Khalifa', value: 'Hassi Khalifa' },
    { label: 'Taleb Larbi', value: 'Taleb Larbi' },
    { label: 'Douar El Ma', value: 'Douar El Ma' },
    { label: 'Sidi Aoun', value: 'Sidi Aoun' },
    { label: 'Trifaoui', value: 'Trifaoui' },
    { label: 'Magrane', value: 'Magrane' },
    { label: 'Beni Guecha', value: 'Beni Guecha' },
    { label: 'Ourmas', value: 'Ourmas' }
],


'Khenchela': [          
{ label: 'Khenchela', value: 'Khenchela' },
    { label: 'Babar', value: 'Babar' },
    { label: 'Bouhmama', value: 'Bouhmama' },
    { label: 'Chechar', value: 'Chechar' },
    { label: 'El Hamma', value: 'El Hamma' },
    { label: 'Kais', value: 'Kais' },
    { label: 'Ouled Rechache', value: 'Ouled Rechache' },
    { label: 'Aïn Touila', value: 'Aïn Touila' }
],

'Souk Ahras': [        
{ label: 'Souk-Ahras', value: 'Souk-Ahras' },
    { label: 'Bir Bou Haouch', value: 'Bir Bou Haouch' },
    { label: 'Heddada', value: 'Heddada' },
    { label: "M'daourouch", value: "M'daourouch" },
    { label: 'Mechroha', value: 'Mechroha' },
    { label: 'Merahna', value: 'Merahna' },
    { label: 'Ouled Driss', value: 'Ouled Driss' },
    { label: 'Oum El Adhaim', value: 'Oum El Adhaim' },
    { label: 'Sedrata', value: 'Sedrata' },
    { label: 'Taoura', value: 'Taoura' }
],

'Mila': [
{ label: 'Mila', value: 'Mila' },
    { label: 'Chelghoum Laid', value: 'Chelghoum Laid' },
    { label: 'Ferdjioua', value: 'Ferdjioua' },
    { label: 'Grarem Gouga', value: 'Grarem Gouga' },
    { label: 'Oued Endja', value: 'Oued Endja' },
    { label: 'Rouached', value: 'Rouached' },
    { label: 'Terrai Bainen', value: 'Terrai Bainen' },
    { label: 'Tassadane Haddada', value: 'Tassadane Haddada' },
    { label: 'Aïn Beida Harriche', value: 'Aïn Beida Harriche' },
    { label: 'Sidi Merouane', value: 'Sidi Merouane' },
    { label: 'Teleghma', value: 'Teleghma' },
    { label: 'Bouhatem', value: 'Bouhatem' },
    { label: 'Tadjenanet', value: 'Tadjenanet' }
],

'Aïn Defla': [  
{ label: 'Aïn-Defla', value: 'Aïn-Defla' },
    { label: 'Aïn Lechiekh', value: 'Aïn Lechiekh' },
    { label: 'Bathia', value: 'Bathia' },
    { label: 'Bordj Emir Khaled', value: 'Bordj Emir Khaled' },
    { label: 'Boumedfaa', value: 'Boumedfaa' },
    { label: 'Djelida', value: 'Djelida' },
    { label: 'Djendel', value: 'Djendel' },
    { label: 'El Abadia', value: 'El Abadia' },
    { label: 'El Amra', value: 'El Amra' },
    { label: 'El Attaf', value: 'El Attaf' },
    { label: 'Hammam Righa', value: 'Hammam Righa' },
    { label: 'Khemis Miliana', value: 'Khemis Miliana' },
    { label: 'Miliana', value: 'Miliana' },
    { label: 'Rouina', value: 'Rouina' }
],
'Naama': [       
{ label: 'Naâma', value: 'Naâma' },
    { label: 'Ain Sefra', value: 'Ain Sefra' },
    { label: 'Assela', value: 'Assela' },
    { label: 'Makman Ben Amer', value: 'Makman Ben Amer' },
    { label: 'Mecheria', value: 'Mecheria' },
    { label: 'Moghrar', value: 'Moghrar' },
    { label: 'Sfissifa', value: 'Sfissifa' }
],

'Aïn-Témouchent': [
      { label: 'Aïn-Témouchent', value: 'Aïn-Témouchent' },   
{ label: 'Aïn El Arbaa', value: 'Aïn El Arbaa' },
    { label: 'Ain Kihal', value: 'Ain Kihal' },
    { label: 'Beni Saf', value: 'Beni Saf' },
    { label: 'El Amria', value: 'El Amria' },
    { label: 'El Malah', value: 'El Malah' },
    { label: 'Hammam Bou Hadjar', value: 'Hammam Bou Hadjar' },
    { label: 'Oulhaça El Gheraba', value: 'Oulhaça El Gheraba' }
],

'Ghardaïa': [ { label: 'Ghardaia', value: 'Ghardaia' },
    { label: 'El Meniaa', value: 'El Meniaa' },
    { label: 'Metlili', value: 'Metlili' },
    { label: 'Berriane', value: 'Berriane' },
    { label: 'Daïa Ben Dahoua', value: 'Daïa Ben Dahoua' },
    { label: 'Mansoura', value: 'Mansoura' },
    { label: 'Zelfana', value: 'Zelfana' },
    { label: 'Guerrara', value: 'Guerrara' },
    { label: 'Bounoura', value: 'Bounoura' }
],
'Relizane': [ { label: 'Relizane', value: 'Relizane' },
    { label: 'Aïn Tarek', value: 'Aïn Tarek' },
    { label: 'Ammi Moussa', value: 'Ammi Moussa' },
    { label: 'Djidioua', value: 'Djidioua' },
    { label: 'El Hamadna', value: 'El Hamadna' },
    { label: 'El Matmar', value: 'El Matmar' },
    { label: 'Mazouna', value: 'Mazouna' },
    { label: 'Mendes', value: 'Mendes' },
    { label: 'Oued Rhiou', value: 'Oued Rhiou' },
    { label: 'Ramka', value: 'Ramka' },
    { label: 'Sidi M\'Hamed Ben Ali', value: 'Sidi M\'Hamed Ben Ali' },
    { label: 'Yellel', value: 'Yellel' },
    { label: 'Zemmora', value: 'Zemmora' }
],
'Timimoun': [
    { label: 'Timimoun', value: 'Timimoun' },
    { label: 'Aougrout', value: 'Aougrout' },
    { label: 'Tinerkouk', value: 'Tinerkouk' },
    { label: 'Charouine', value: 'Charouine' }
],
'Bordj Badji Mokhtar': [
         { label: 'Bordj Badji Mokhtar', value: 'Bordj Badji Mokhtar' },

],

'Ouled Djellal': [
    { label: 'Ouled Djellal', value: 'Ouled Djellal' },
    { label: 'Sidi Khaled', value: 'Sidi Khaled' }
],

'Béni Abbès': [
    { label: 'Béni Abbès', value: 'Béni Abbès' },
    { label: 'Tamtert', value: 'Tamtert' }
],

'In Salah': [
    { label: 'In Salah', value: 'In Salah' },
    { label: 'In Ghar', value: 'In Ghar' }
],

'In Guezzam': [
    { label: 'In Guezzam', value: 'In Guezzam' },
    { label: 'Tin Zaouatine', value: 'Tin Zaouatine' }
],

'Touggourt': [
   { label: 'Touggourt', value: 'Touggourt' }, 
],
'Djanet': [
         { label: 'Djanet', value: 'Djanet' },

],

'El Mghair': [
    { label: 'Djamaa', value: 'Djamaa' },
    { label: 'El Mghair', value: 'El Mghair' }
],

'El Meniaa': [
                { label: 'El Meniaa', value: 'El Meniaa' }


],
  // Add more dairas for other wilayas
};


  const handleCheckboxChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const sexOptions = [
    { value: 'Homme', icon: <FaMale className="ml-2 text-blue-400" />, label: translations.Homme },
    { value: 'Femme', icon: <FaFemale className="ml-2 text-pink-400" />, label: translations.Femme },
  ];



  function onChange(e) {
    const { id, value } = e.target;
    if (id === "latitude" || id === "longitude") {
      setFormData((prevState) => ({
        ...prevState,
        markerCoordinates: {
          ...prevState.markerCoordinates,
          [id]: value
        }
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [id]: value
      }));
    }
  }
  
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <section className="max-w-6xl mx-auto flex bg dark:bg-gray-500 bg-blue-100 justify-center items-center flex-col">
        <h1 className="text-3xl text-center mt-6 font-bold">{translations.Myprofile}          
</h1> 
        <div className="w-full md:w-[50%] dark:bg-gray-500 bg-blue-100  mt-6 px-3">
          <form>
     {/* Profile Picture */}
     <div className="flex justify-center items-center flex-col mb-6">
          {imageUrl && (
            <img
              src={imageUrl}
              alt=""
              className="w-24 h-24 rounded-full  border border-gray-600   object-cover mb-4"
            />
          )}
          <label htmlFor="fileInput" className="bg-sky-600 text-gray-100 rounded-lg px-4 py-2 cursor-pointer hover:bg-blue-600">
          {translations.ChangeProfilePicture}          
            <input
              id="fileInput"
              type="file"
              className="hidden"
              ref={fileInputRef}
              onChange={onFileChange}
            />
          </label>
        </div>

        {/* Uploaded Image Preview */}
        {image && (
          <div className="flex justify-center items-center mb-6">
                  <div
                        style={{
                            width: '160px',
                            height: '160px',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                            border: '3px solid #0074D9',
                            borderRadius: '8px',
                        }}
                    >
              <img
                src={image.preview}
                alt="Uploaded"
                className="w-full h-full rounded-md object-cover"
              />
              <button
                onClick={clearImage}
                className="bg-red-500 text-white hover:bg-red-600 px-2 py-1 rounded-full absolute top-0 right-0"
              >
                <BsTrash />
              </button>
            </div>
          </div>
        )}

        {/* Update Profile Picture Button */}
        {image && (
          <button
            onClick={handleImageUpload}
            className="bg-sky-600 text-gray-100 rounded-lg px-6 py-3 mb-4 hover:bg-blue-600"
          >
 {translations.UpdateProfilePicture}            </button>
        )}

        {/* Edit Profile Information Button */}
        <div className="flex justify-end mb-4">
          <button
            type="button" 
            onClick={() => {
              changeDetail && updateData();
              setChangeDetail((prevState) => !prevState);
            }}
            className="flex items-center bg-sky-600 text-gray-100 rounded-lg px-4 py-2 hover:bg-blue-600 transition duration-200 ml-1 cursor-pointer"
          >
            <FaEdit className="mr-2" /> {/* Edit Icon */}
            {changeDetail ?  translations.applychanges : translations.EditProfile}
          </button>
        </div>
 
        <h1 className="text-1xl mt-2 font-bold word-break dark:text-blue-100">
 ID: </h1>

            {/* Email Input */}
            <input
              type="text"
              id="id"
              value={id}
              disabled
              required

              className="mb-6 w-full px-4 py-2 text-xl word-break text-gray-700 bg-white border border-gray-600 rounded transition ease-in-out"
            />

        <h1 className="text-1xl mt-2 dark:text-blue-100  font-bold">   {translations.nomprenom}   :</h1>

            {/* Name Input */}
            <input
              type="text"
              id="doctorname"
              value={doctorname}
              required

              disabled={!changeDetail}
              onChange={onChange}
              className={`mb-6 w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-600 rounded transition ease-in-out ${
                changeDetail && "bg-sky-200 focus:bg-sky-200"
              }`}
            />

<div className="flex flex-wrap border border-gray-700 text-gray-700 dark:text-blue-100 justify-center ">
  {sexOptions.map((option) => (
    <label key={option.value} className="flex dark:text-blue-100 items-center mx-2 mb-2">
      <input
        type="checkbox"
        name="sex"
        value={option.value}
        checked={ sex === option.value}
        onChange={handleCheckboxChange}
      
      />
      {option.icon} {option.label}
    </label>
  ))}
</div>


        <h1 className="text-1xl mt-2 dark:text-blue-100 font-semibold"> 
{translations.NumerodeTelephone} </h1>
            <input
              type="number"
              id="phoneNumber"
              value={phoneNumber}
              disabled={!changeDetail}
              onChange={onChange}
              required

              className={`mb-6 w-full px-4 py-2 text-xl text-gray-700 bg-white border border-gray-600 rounded transition ease-in-out ${
                changeDetail && "bg-sky-200 focus:bg-sky-200"
              }`}
            />
    
            <h1 className="text-1xl mt-2 font-bold word-break dark:text-blue-100 mb-2">
              {translations.cabinetcliniquehospitalname}  
            </h1>

            <input
              type="text"
              id="nomCabinet"
              value={nomCabinet}
              required
              disabled={!changeDetail}
              onChange={onChange}
              className={`mb-6 w-full px-4 py-2 text-xl text-gray-700 word-break bg-white border border-gray-600 rounded transition ease-in-out ${
                changeDetail && "bg-sky-200 focus:bg-sky-200"
              }`}
            />
      
        <h1 className="text-1xl mt-2 font-bold word-break dark:text-blue-100">
{translations.email}  </h1>

            {/* Email Input */}
            <input
              type="email"
              id="email"
              value={email}
              disabled
              required

              className="mb-6 w-full px-4 py-2 text-xl word-break text-gray-700 bg-white border border-gray-600 rounded transition ease-in-out"
            />


            <p className="text-lg dark:text-blue-100 font-semibold">
            {translations.introduction}  </p>
        <textarea
          type="text"
          id="description"
          value={description}
          onChange={onChange}
         disabled={!changeDetail}
          className={`w-full px-4 py-2 text-xl h-40 text-gray-700 word-break bg-white border border-sky-400 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6 ${
            changeDetail && "bg-sky-200 focus:bg-sky-200"
          }`}
        />

        <h1 className="text-1xl mt-2 font-bold word-break dark:text-blue-100 ">
{translations.email} </h1>

        <input
          type="text"
          id="address"
          value={address}
          required
          disabled={!changeDetail}
          onChange={onChange}
          className={`mb-6 w-full px-4 py-2  text-xl word-break text-gray-700 bg-white border border-gray-600 rounded transition ease-in-out ${
            changeDetail && "bg-sky-200 focus:bg-sky-200"
          }`}
        />

<p className="text-lg dark:text-blue-100 font-semibold">
            {translations.ZipCode} </p>

<input
  type="text"
  id="zipCode"
  value={zipCode}
  onChange={onChange}
  disabled={!changeDetail}
  placeholder="Zip Code"
  required
  className={`w-full px-4 py-2  text-xl  text-gray-700 word-break bg-white border border-gray-600 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6 ${
    changeDetail && "bg-sky-200 focus:bg-sky-200"
  }`}/>


<div className="grid grid-cols-1 md:grid-cols-2 gap-4">
  <div>
    <label className="block mb-2 dark:text-blue-100 text-sm font-medium text-gray-700">
      Wilaya:
    </label>
    <Select
      options={wilayas}
      value={{ label: formData.selectedWilaya, value: formData.selectedWilaya }}
      onChange={handleWilayaChange}
      isClearable
      placeholder="Sélectionner une wilaya"
      required
      noOptionsMessage={() => "Aucune option disponible"}
      menuPlacement="auto"
      className={`mb-6 w-full px-4 py-2 text-xl text-gray-700 word-break bg-white border border-gray-600 rounded transition ease-in-out ${
        changeDetail && "bg-sky-200 focus:bg-sky-200"
      }`}
    />
  </div>
  <div>
    <label className="block mb-2 dark:text-blue-100 text-sm font-medium text-gray-700">
      Daira:
    </label>
    <Select
      options={dairas[formData.selectedWilaya] || []}
      value={{ label: formData.selectedDaira, value: formData.selectedDaira }}
      onChange={handleDairaChange}
      isClearable
      placeholder="Sélectionner une daira"
      required
      isDisabled={!formData.selectedWilaya}
      noOptionsMessage={() => "Aucune option disponible"}
      menuPlacement="auto"
      className={`mb-6 w-full px-4 py-2 text-xl text-gray-700 word-break bg-white border border-gray-600 rounded transition ease-in-out ${
        changeDetail && "bg-sky-200 focus:bg-sky-200"
      }`}
    />
  </div>
</div>
        
       <div class="bg-sky-100 p-2 mt-2 rounded-lg shadow-lg">
        <FaMapMarkerAlt size={30} className="mr-2 transition-all duration-300 ease-in-out transform hover:rotate-12" /> 

        <h3 class="text-2xl  font-bold mb-4 ">  {translations.mapCoordinates} :</h3>
        <h3 class="  ">  {translations.usegooglemap} :</h3>
           
                <div class="flex flex-row gap-4 ">
          <div>
            <p class="text-gray-600 mb-2">Latitude:</p>
            
            <input
              type="number"
              id="latitude"
              value={markerCoordinates.latitude}
              disabled={!changeDetail}
              onChange={onChange}
              className={`mb-6 w-full px-4 py-2 text-xl word-break text-gray-700 bg-white border border-gray-600 rounded transition ease-in-out ${
                changeDetail && "bg-sky-200 focus:bg-sky-200"
              }`}
            />
          </div>
          <div>
            <p class="text-gray-600 mb-2">Longitude:</p>
             
            <input
              type="number"
              id="longitude"
              value={markerCoordinates.longitude}
              disabled={!changeDetail}
              onChange={onChange}
              className={`mb-6 w-full px-4 py-2 text-xl word-break text-gray-700 bg-white border border-gray-600 rounded transition ease-in-out ${
                changeDetail && "bg-sky-200 focus:bg-sky-200"
              }`}
            />
          </div>
        </div>
      </div>


            {/* Other Input fields go here */}

            <div className="flex justify-between whitespace-nowrap bg-white border border-gray-600 px-4 py-2  text-sm sm:text-lg mb-6">
            <p className="flex items-center">
              <span
                onClick={() => {
                  changeDetail && updateData();
                  setChangeDetail((prevState) => !prevState);
                }}
                className="text-sky-600 px-4 py-2  hover:text-blue-700 transition ease-in-out  duration-200 ml-1 cursor-pointer"
              >
            {changeDetail ?  translations.applychanges : translations.EditProfile}
              </span>
            </p>
     
          </div>
          </form>
        </div>
      </section>
    </>
  );
}

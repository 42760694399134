import React, { useState, useCallback, useRef } from 'react';
import { BsTrash, BsArrowRepeat, BsUpload } from 'react-icons/bs';

function Doctorpicture() {
    const [image, setImage] = useState(null);
    const fileInputRef = useRef();

    const selectImage = () => {
        fileInputRef.current.click();
    };

    const onFileChange = (e) => {
        const selectedImage = e.target.files[0];
        if (selectedImage) {
            setImage({
                file: selectedImage,
                preview: URL.createObjectURL(selectedImage),
            });
        }
    };

    const clearImage = () => {
        if (image) {
            URL.revokeObjectURL(image.preview);
            setImage(null);
        }
    };

    return (
        <div className="container mx-auto mt-10">
            <div className="w-64 mx-auto mt-4">
                <label className="w-full flex flex-col items-center px-4 py-6 bg-white text-blue-500 rounded-lg shadow-lg tracking-wide uppercase border border-blue-500 cursor-pointer hover:bg-blue-500 hover:text-white">
                    <BsUpload className="w-8 h-8" />
                    <span className="mt-2 text-base leading-normal">Select a file</span>
                    <input
                        type="file"
                        className="hidden"
                        ref={fileInputRef}
                        onChange={onFileChange}
                    />
                </label>
            </div>

            {image && (
                <div className="w-64 mx-auto mt-4">
                    <div
                        style={{
                            width: '160px',
                            height: '160px',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                            border: '1px solid #0074D9',
                            borderRadius: '8px',
                        }}
                    >
                        <img
                            src={image.preview}
                            alt="Uploaded"
                            className="w-full h-full object-cover rounded-md"
                        />
                        <div className="mt-2 flex justify-between">
                            <button
                                onClick={clearImage}
                                className="bg-red-500 text-white hover:bg-red-600 px-3 py-2 rounded-full absolute top-2 left-2"
                            >
                                <BsTrash />
                            </button>
                            <button
                                onClick={selectImage}
                                className="bg-blue-500 text-white hover:bg-blue-600 px-3 py-2 rounded-full absolute bottom-2 right-2"
                            >
                                <BsArrowRepeat />
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Doctorpicture;

import { useState, useEffect } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { useAuthStatus } from "../hooks/useAuthStatus";
import Spinner from "./Spinner";
import { db } from "../firebase"; 
import { getAuth } from "firebase/auth";

export default function PrivateRouteDoctor() {
  const auth = getAuth();
  const { loggedIn, loading } = useAuthStatus();

  const checkDoctorStatus = async () => {
      if (!loggedIn) {
          return false;
      }

      // Get the user's document from Firestore
      const userDocRef = doc(db, "doctors", auth.currentUser.uid);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          // Check if userType is "doctor" and active is true
          return { userType: userData.userType, active: userData.active };
      }

      return { userType: null, active: null }; // User document doesn't exist
  };

  if (loading) {
    return <Spinner />;
  }

  // Render the content based on the result of checkDoctorStatus()
  return <DoctorRouteLoader checkDoctorStatus={checkDoctorStatus} />;
}

function DoctorRouteLoader({ checkDoctorStatus }) {
  const [userStatus, setUserStatus] = useState({ userType: null, active: null });

  useEffect(() => {
    const loadDoctorStatus = async () => {
      const status = await checkDoctorStatus();
      setUserStatus(status);
    };

    loadDoctorStatus();
  }, [checkDoctorStatus]);

  // Show spinner while waiting for the result
  if (userStatus.userType === null || userStatus.active === null) {
    return     <Spinner />;
    
  }

  // Render content based on doctor status
  if (userStatus.userType === "doctor" && userStatus.active ===  false) {
    return <Navigate to="/under-review2" />;

  } else if (userStatus.userType === "doctor" && userStatus.active === true) {
    return <Outlet /> ;
  } else {
    // Redirect to appropriate page if userType is not "doctor"
    return <Navigate to="/doctor-sign-in" />;
  }
}

import { useState, useEffect,useRef } from "react";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import Spinner from "../components/Spinner";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import { db } from "../firebase";
import { doc, serverTimestamp, setDoc, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { icon } from 'leaflet'; 
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { FaMale, FaFemale } from 'react-icons/fa';

import { BsTrash, BsArrowRepeat, BsUpload } from 'react-icons/bs';
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage"; 
import { useLanguage } from '../components/LanguageContext'; 
import TermsAndConditions from '../components/TermsAndConditions'; 
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import signupDoctorImage1 from "../Assets/images/signupdoctor.png";
import signupDoctorImage2 from "../Assets/images/signupdoctor02.png";

export default function DoctorSignUp() {
  const [showPassword, setShowPassword] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const { language, translations } = useLanguage();
  const [istermserviceVisible, settermserviceVisible] = useState(false);


  const [geolocationEnabled, setGeolocationEnabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    doctorname: "",
    email: "",
    password: "",
    confirmPassword: "",

    phoneNumber: "", // Add phone number field
    userType: "doctor",
    specialties: [], // Add specialties field
    address: "",
    description: "", 
    markerCoordinates: { latitude: 0, longitude: 0 },
    selectedWilaya: null,
    selectedDaira: null,
    zipCode:"",
    nomCabinet:"",
    averageDuration:"",
    maxPatientsPerDay:"",
    sex:"",

  });

  const {
     doctorname, email, password, confirmPassword, phoneNumber, specialties,
    address,
    description,
    latitude,
    longitude,
    selectedWilaya,
    selectedDaira,
    zipCode,
    sex,
    nomCabinet,
    averageDuration,
    maxPatientsPerDay
  } =
    formData;

  const navigate = useNavigate();



  const [isSubmitting, setIsSubmitting] = useState(false);

  const [markerPosition, setMarkerPosition] = useState([36.737232, 3.086472]); 

const [mapCenter, setMapCenter] = useState([36.737232, 3.086472]);
const [markerCoordinates, setMarkerCoordinates] = useState({
  latitude: 0,
  longitude: 0, 
});


const [markerDragged, setMarkerDragged] = useState(false);

const [lat, setLat] = useState(markerPosition[0]);
const [lng, setLng] = useState(markerPosition[1]);

const [services, setServices] = useState([]);
const markerRef = useRef(null);
 




  function onChange(e) {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  }

  function handleSpecialtyChange(selectedOptions) {
    setFormData((prevState) => ({
      ...prevState,
      specialties: selectedOptions.map((option) => option.value),
    }));
  }


  const handleCheckboxChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


  const sexOptions = [
    { value: 'Homme', icon: <FaMale className="ml-2 text-blue-400" />, label:translations.Homme },
    { value: 'Femme', icon: <FaFemale className="ml-2 text-pink-400" />, label: translations.Femme },
  ];





  const availableSpecialties = [
    { value: "Médecin généraliste", label: translations.Médecingénéraliste },

      { value: "Chirurgien-dentiste", label: translations.Chirurgiendentiste },
      { value: "Ophtalmologue", label: translations.Ophtalmologue },
      { value: "Dermatologue et vénérologue", label: translations.Dermatologueetvénérologue },
      { value: "Allergologue", label: translations.Allergologue },
      { value: "Chirurgien urologue", label: translations.Chirurgienurologue },
      { value: "Rhumatologue", label: translations.Rhumatologue },
      { value: "Stomatologue", label: translations.Stomatologue },
      { value: "Endocrinologue", label: translations.Endocrinologue },
      { value: "Diététicien", label: translations.Diététicien },
      { value: "Psychologue", label: translations.Psychologue },
      { value: "Neurologue", label: translations.Neurologue },
      { value: "Psychiatre", label: translations.Psychiatre },
      { value: "Radiologue", label: translations.Radiologue },
      { value: "Cardiologue", label: translations.Cardiologue },
      { value: "Gastro-entérologue et hépatologue", label: translations.Gastroentérologueethépatologue },
      { value: "Dermatopathologue", label: translations.Dermatopathologue },
      { value: "Échographiste", label: translations.Échographiste },
      { value: "Endocrinologue diabétologue", label: translations.Endocrinologuediabétologue },
      { value: "Épithésiste", label: translations.Épithésiste },
      { value: "Ergothérapeute", label: translations.Ergothérapeute },
      { value: "Généticien", label: translations.Généticien },
      { value: "Gériatre", label: translations.Gériatre },
      { value: "Pédiatre", label: translations.Pédiatre },
      { value: "ORL", label: translations.ORL },

      { value: "Gynécologue médical", label: translations.Gynécologuemédical },
      { value: "Gynécologue médical et obstétrique", label: translations.Gynécologuemédicaletobstétrique },
      { value: "Gynécologue obstétricien", label: translations.Gynécologueobstétricien },
      { value: "Hématologue", label: translations.Hématologue },
      { value: "Hématologue biologiste", label: translations.Hématologuebiologiste },
      { value: "Homéopathe", label: translations.Homéopathe },
      { value: "Immunologue", label: translations.Immunologue },
      { value: "Infectiologue", label: translations.Infectiologue },
      { value: "Cancérologue", label: translations.Cancérologue },
      { value: "Cancérologue biologiste", label: translations.Cancérologuebiologiste },
      { value: "Cancérologue médical", label: translations.Cancérologuemédical },
      { value: "Cancérologue radiothérapeute", label: translations.Cancérologueradiothérapeute },

      { value: "Anatomo-cyto-pathologiste", label: translations.Anatomocytopathologiste },
      { value: "Andrologue", label: translations.Andrologue },
      { value: "Anesthésiste", label: translations.Anesthésiste },
      { value: "Angiologue", label: translations.Angiologue },
      { value: "Masseur-kinésithérapeute", label: translations.Masseurkinésithérapeute },
      { value: "Pédicure-podologue", label: translations.Pédicurepodologue },

      { value: "Audioprothésiste", label: translations.Audioprothésiste },
      { value: "Biologiste des agents infectieux", label: translations.Biologistedesagentsinfectieux },{ value: "Chercheur médical", label: translations.Chercheurmédical },
      { value: "Chiropracteur", label: translations.Chiropracteur },
      { value: "Chirurgien cancérologue", label: translations.Chirurgiencancérologue },
      { value: "Chirurgien de la face et du cou", label: translations.Chirurgiendelafaceetducou },
      { value: "Chirurgien de la main", label: translations.Chirurgiendelamain },
      { value: "Chirurgien endocrinien", label: translations.Chirurgienendocrinien },
      { value: "Chirurgien général", label: translations.Chirurgiengénéral },
      { value: "Chirurgien infantile", label: translations.Chirurgieninfantile },
      { value: "Chirurgien orthopédiste et traumatologue", label: translations.Chirurgienorthopédisteettraumatologue },
      { value: "Chirurgien maxillo-facial", label: translations.Chirurgienmaxillofacial },
      { value: "Chirurgien maxillo-facial et stomatologue", label: translations.Chirurgienmaxillofacialetstomatologue },
      { value: "Chirurgien oral", label: translations.Chirurgienoral },
      { value: "Chirurgien thoracique et cardio-vasculaire", label: translations.Chirurgienthoraciqueetcardiovasculaire },
      { value: "Chirurgien vasculaire", label: translations.Chirurgienvasculaire },
      { value: "Chirurgien viscéral et digestif", label: translations.Chirurgienviscéraletdigestif },
      { value: "Conseiller médico-social", label: translations.Conseillermédicosocial },
      { value: "Manipulateur en électroradiologie médicale", label: translations.Manipulateurenélectroradiologiemédicale },
      { value: "Médecin biologiste", label: translations.Médecinbiologiste },
      { value: "Médecin bucco-dentaire", label: translations.Médecinbuccodentaire },
      { value: "Médecin de la douleur", label: translations.Médecindeladouleur },
      { value: "Médecin de la reproduction", label: translations.Médecindelareproduction },
      { value: "Médecin de la reproduction et gynécologue médical", label: translations.Médecindelareproductionetgynécologuemédical },
      { value: "Médecin de soins palliatifs", label: translations.Médecindesoinspalliatifs },
      { value: "Médecin du sport", label: translations.Médecindusport },
      { value: "Médecin du travail", label: translations.Médecindutravail },
      { value: "Médecin morphologue et anti-âge", label: translations.Médecinmorphologueetantiâge },
      { value: "Médecin nucléaire", label: translations.Médecinnucléaire },
      { value: "Médecin nutritionniste", label: translations.Médecinnutritionniste },
      { value: "Médecin réanimateur", label: translations.Médecinréanimateur },
      { value: "Médecin spécialiste de santé publique", label: translations.Médecinspécialistedesantépublique },
      { value: "Médecin urgentiste", label: translations.Médecinurgentiste },
      { value: "Médecin vasculaire", label: translations.Médecinvasculaire },
      { value: "Néphrologue", label: translations.Néphrologue },
      { value: "Neurochirurgien", label: translations.Neurochirurgien },
      { value: "Neuro-pathologiste", label: translations.Neuropathologiste },
      { value: "Neuro-psychiatre", label: translations.Neuropsychiatre },
      { value: "Oculariste", label: translations.Oculariste },
      { value: "Onco-hématologue", label: translations.Oncohématologue },
      { value: "Oncologue médical", label: translations.Oncologuemédical },
      { value: "Onco-radiothérapeute", label: translations.Oncoradiothérapeute },
      { value: "ORL - Chirurgien de la face et du cou", label: translations.ORLChirurgiendelafaceetducou },
      { value: "Orthodontiste", label: translations.Orthodontiste },
      { value: "Orthopédiste-orthésiste", label: translations.Orthopédisteorthésiste },
      { value: "Orthophoniste", label: translations.Orthophoniste },
      { value: "Orthoprothésiste", label: translations.Orthoprothésiste },
      { value: "Orthoptiste", label: translations.Orthoptiste },
      { value: "Pharmacie", label: translations.Pharmacien },
      { value: "Phlébologue", label: translations.Phlébologue },
      { value: "Phoniatre", label: translations.Phoniatre },
      { value: "Pneumologue", label: translations.Pneumologue },
      { value: "Podo-orthésiste", label: translations.Podoorthésiste },
      { value: "Psychiatre enfant/adolescent", label: translations.Psychiatreenfant },
      { value: "Psychomotricien", label: translations.Psychomotricien },
      { value: "Psychothérapeute", label: translations.Psychothérapeute },
      { value: "Radiothérapeute", label: translations.Radiothérapeute },
      { value: "Spécialiste chirurgie plastique reconstructrice et esthétique", label: translations.Spécialisteenchirurgieplastiquereconstructriceetesthétique },
      { value: "Spécialiste hémobiologie-transfusion", label: translations.Spécialisteenhémobiologietransfusion },
      { value: "Spécialiste médecine aérospatiale", label: translations.Spécialisteenmédecineaérospatiale },
      { value: "Spécialiste médecine de catastrophe", label: translations.Spécialisteenmédecinedecatastrophe },
      { value: "Spécialiste médecine générale", label: translations.Spécialisteenmédecinegénérale },
      { value: "Spécialiste médecine interne", label: translations.Spécialisteenmédecineinterne },
      { value: "Spécialiste médecine légale et expertises médicales", label: translations.Spécialisteenmédecinelégaleetexpertisesmédicales },
      { value: "Spécialiste médecine physique réadaptation", label: translations.Spécialisteenmédecinephysiqueetderéadaptation },
      { value: "Spécialiste en réanimation", label: translations.Spécialisteenréanimation },
      { value: "Assistant de service social", label: translations.Assistantdeservicesocial },
      { value: "Acupuncteur", label: translations.Acupuncteur },
      { value: "Addictologue", label: translations.Addictologue },
      { value: "Auxiliaire de puériculture", label: translations.Auxiliairedepuériculture },
      { value: "Adjoint chirurgien-dentiste", label: translations.Adjointchirurgienentiste },
      { value: "Adjoint médecin", label: translations.Adjointmédecin },

 
      { value: "Technicien de biologie médicale", label: translations.Techniciendebiologiemédicale },
   { value: "Toxicologie", label: translations.Toxicologie },
   { value: "laboratoire d'analyse", label: translations.laboratoireanalyse },
   { value: "Opticien-lunetier", label: translations.Opticienlunetier },
   { value: "Infirmier en pratique avancée", label: translations.Infirmierenpratiqueavancée },
   { value: "Infirmière puéricultrice", label: translations.Infirmièrepuéricultrice },
 
   { value: "Infirmier", label: translations.Infirmier },
   { value: "Sage-femme", label: translations.Sagefemme },
   { value: "Sage-femme échographiste", label: translations.Sagefemmeéchographiste },

   { value: "veterinaire", label: translations.veterinaire },


    // Add more specialties as needed
  ];

  


  const [currentImage, setCurrentImage] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage === 1 ? 2 : 1));
    }, 5000);

    return () => clearInterval(interval);
  }, []);



  function isAllowedEmailDomain(email) {
    const allowedEmailDomains = [
      "gmail.com",
      "yahoo.com",
      "hotmail.com",
      "outlook.com",
      "aol.com",
      "icloud.com",
      "protonmail.com",
      "zoho.com",
      "mail.com",
      "gmx.com",
      "live.com",
      "msn.com",
      "yandex.com",
      "inbox.com",
      "me.com",
      "rocketmail.com",
      "att.net",
      "cox.net",
      "verizon.net",
      // Add more domains here
      "gmail.fr",
      "yahoo.fr",
      "hotmail.fr",
      "outlook.fr",
      "aol.fr",
      "icloud.fr",
      "protonmail.fr",
      "zoho.fr",
      "mail.fr",
      "gmx.fr",
      "live.fr",
      "msn.fr",
      "yandex.fr",
      "inbox.fr",
      "me.fr",
      "rocketmail.fr",
      "att.fr",
      "cox.fr",
      "verizon.fr",
      // Add .dz versions
      "gmail.dz",
      "yahoo.dz",
      "hotmail.dz",
      "outlook.dz",
      "aol.dz",
      "icloud.dz",
      "protonmail.dz",
      "zoho.dz",
      "mail.dz",
      "gmx.dz",
      "live.dz",
      "msn.dz",
      "yandex.dz",
      "inbox.dz",
      "me.dz",
      "rocketmail.dz",
      "att.dz",
      "cox.dz",
      "verizon.dz",
    ];
    const emailDomain = email.split("@")[1];
    return allowedEmailDomains.includes(emailDomain);
  }

  function toggleAcceptedTerms() {
    setAcceptedTerms((prevAccepted) => !prevAccepted);
  }



// Update lat and lng when markerPosition changes
useEffect(() => {
  setLat(markerPosition[0]);
  setLng(markerPosition[1]);
  setMapCenter(markerPosition);
  
  }, [markerPosition]);
  
       // Function to handle manual marker movement
       const handleMarkerDragEnd = (e) => {
        const marker = e.target;
        if (marker) {
          const { lat, lng } = marker.getLatLng();
      
          // Update the marker coordinates state
          setMarkerCoordinates({ latitude: lat, longitude: lng });
      
          
        }
      }
        

  
  
     
      const wilayas = [
        { label: 'Tizi Ouzou', value: 'Tizi Ouzou' },
        { label: 'Alger', value: 'Alger' },
        { label: 'Oran', value: 'Oran' },
        { label: 'Constantine', value: 'Constantine' },
        { label: 'Tipaza', value: 'Tipaza' },
        { label: 'Sétif', value: 'Sétif' },
        { label: 'Boumerdès', value: 'Boumerdès' },

        { label: 'Médéa', value: 'Médéa' },
        { label: 'Mostaganem', value: 'Mostaganem' },
          { label: 'Adrar', value: 'Adrar' },
          { label: 'Chlef', value: 'Chlef' },
          { label: 'Laghouat', value: 'Laghouat' },
          { label: 'Oum El Bouaghi', value: 'Oum El Bouaghi' },
          { label: 'Batna', value: 'Batna' },
          { label: 'Béjaïa', value: 'Béjaïa' },
        
        
        
          { label: 'Biskra', value: 'Biskra' },
          { label: 'Béchar', value: 'Béchar' },
          { label: 'Blida', value: 'Blida' },
          { label: 'Bouira', value: 'Bouira' },
          { label: 'Tamanrasset', value: 'Tamanrasset' },
          { label: 'Tebessa', value: 'Tebessa' },
          { label: 'Tlemcen', value: 'Tlemcen' },
          { label: 'Tiaret', value: 'Tiaret' },
         

          { label: 'Djelfa', value: 'Djelfa' },
          { label: 'Jijel', value: 'Jijel' },
          { label: 'Saida', value: 'Saida' },
          { label: 'Skikda', value: 'Skikda' },
          { label: 'Sidi-Bel-Abbès', value: 'Sidi-Bel-Abbès' },
          { label: 'Annaba', value: 'Annaba' },
          { label: 'Guelma', value: 'Guelma' },
     { label: 'Msila', value: 'Msila' },
          { label: 'Mascara', value: 'Mascara' },



          { label: 'El-Bayadh', value: 'El-Bayadh' },
          { label: 'Illizi', value: 'Illizi' },
          { label: 'Bordj-Bou-Arreridj', value: 'Bordj-Bou-Arreridj' },
          { label: 'El-Tarf', value: 'El-Tarf' },
          { label: 'Tindouf', value: 'Tindouf' },
          { label: 'Tissemsilt', value: 'Tissemsilt' },
          { label: 'El-Oued', value: 'El-Oued' },
          { label: 'Khenchela', value: 'Khenchela' },
          { label: 'Souk-Ahras', value: 'Souk-Ahras' },
          { label: 'Mila', value: 'Mila' },
          { label: 'Aïn-Defla', value: 'Aïn-Defla' },
          { label: 'Naâma', value: 'Naâma' },
          { label: 'Aïn-Témouchent', value: 'Aïn-Témouchent' },
          { label: 'Ghardaia', value: 'Ghardaia' },
         
         
          { label: 'Relizane', value: 'Relizane' },
          { label: 'Timimoun', value: 'Timimoun' },
          { label: 'Bordj Badji Mokhtar', value: 'Bordj Badji Mokhtar' },
          { label: 'Ouled Djellal', value: 'Ouled Djellal' },
          { label: 'Béni Abbès', value: 'Béni Abbès' },
          { label: 'In Salah', value: 'In Salah' },
          { label: 'In Guezzam', value: 'In Guezzam' },
          { label: 'Touggourt', value: 'Touggourt' },
          { label: 'Djanet', value: 'Djanet' },
          { label: 'El Mghair', value: 'El Mghair' },
          { label: 'El Meniaa', value: 'El Meniaa' }
      
      
      ];
    
      const dairas = {
        'Tizi Ouzou': [
          { label: 'Boghni', value: 'Boghni' },
          { label: 'Ouadhias', value: 'Ouadhias' },
          { label: 'Tigzirt', value: 'Tigzirt' },
          { label: 'Tizi Ouzou', value: 'Tizi Ouzou' },
          { label: 'Draa El Mizan', value: 'Draa El Mizan' },

          { label: 'Ain El Hammam', value: 'Ain El Hammam' },
          { label: 'Azazga', value: 'Azazga' },
          { label: 'Azeffoun', value: 'Azeffoun' },
          { label: 'Beni Douala', value: 'Beni Douala' },
          { label: 'Ath Dwala', value: 'Ath Dwala' },
          { label: 'Beni-yenni', value: 'Beni-yenni' },
          { label: 'Ath Yenni', value: 'Ath Yenni' },
          { label: 'Bouzeguene', value: 'Bouzeguene' },
          { label: 'Draa Ben Khedda', value: 'Draa Ben Khedda' },
          { label: 'Iferhounène', value: 'Iferhounène' },
          { label: 'Larbaa Nath Irathen', value: 'Larbaa Nath Irathen' },
          { label: 'Maatkas', value: 'Maatkas' },
          { label: 'Makouda', value: 'Makouda' },
          { label: 'Mekla', value: 'Mekla' },
          { label: 'Ouacifs', value: 'Ouacifs' },
          { label: 'Ouaguenoun', value: 'Ouaguenoun' },
          { label: 'Tizi Ghenif', value: 'Tizi Ghenif' },
          { label: 'Tizi-Rached', value: 'Tizi-Rached' }
      ],

      'Alger': [
        { label: 'Zéralda', value: 'Zéralda' },
        { label: 'Chéraga', value: 'Chéraga' },
        { label: 'Draria', value: 'Draria' },
        { label: 'Bir Mourad Raïs', value: 'Bir Mourad Raïs' },
        { label: 'Birtouta', value: 'Birtouta' },
        { label: 'Bouzareah', value: 'Bouzareah' },
        { label: 'Bab El Oued', value: 'Bab El Oued' },
        { label: 'Sidi Mhamed', value: 'Sidi Mhamed' },
        { label: 'Hussein Dey', value: 'Hussein Dey' },
        { label: 'El Harrach', value: 'El Harrach' },
        { label: 'Baraki', value: 'Baraki' },
        { label: 'Dar El Beïda', value: 'Dar El Beïda' },
        { label: 'Rouïba', value: 'Rouïba' }
    ],

    'Oran': [
      { label: 'Oran', value: 'Oran' },

      { label: 'Aïn El Turk', value: 'Aïn El Turk' },
      { label: 'Arzew', value: 'Arzew' },
      { label: 'Bethioua', value: 'Bethioua' },
      { label: 'Es Sénia', value: 'Es Sénia' },
      { label: 'Bir El Djir', value: 'Bir El Djir' },
      { label: 'Boutlélis', value: 'Boutlélis' },
      { label: 'Oued Tlelat', value: 'Oued Tlelat' },
      { label: 'Gdyel', value: 'Gdyel' }
  ],

  'Constantine': [
    { label: 'Constantine', value: 'Constantine' },

    { label: 'El Khroub', value: 'El Khroub' },
    { label: 'Aïn Abid', value: 'Aïn Abid' },
    { label: 'Zighoud Youcef', value: 'Zighoud Youcef' },
    { label: 'Hamma Bouziane', value: 'Hamma Bouziane' },
    { label: 'Ibn Ziad', value: 'Ibn Ziad' }
],


'Tipaza': [
  { label: 'Ahmar El Ain', value: 'Ahmar El Ain' },
  { label: 'Bou Ismail', value: 'Bou Ismail' },
  { label: 'Cherchell', value: 'Cherchell' },
  { label: 'Damous', value: 'Damous' },
  { label: 'Fouka', value: 'Fouka' },
  { label: 'Gouraya', value: 'Gouraya' },
  { label: 'Hadjout', value: 'Hadjout' },
  { label: 'Koléa', value: 'Koléa' },
  { label: 'Sidi Amar', value: 'Sidi Amar' },
  { label: 'Tipaza', value: 'Tipaza' }
],

  'Sétif': [
    { label: 'Sétif', value: 'Sétif' },

    { label: 'Aïn Arnat', value: 'Aïn Arnat' },
      { label: 'Aïn Azel', value: 'Aïn Azel' },
      { label: 'Aïn El Kebira', value: 'Aïn El Kebira' },
      { label: 'Aïn Oulmene', value: 'Aïn Oulmene' },
      { label: 'Amoucha', value: 'Amoucha' },
      { label: 'Babor', value: 'Babor' },
      { label: 'Beni Aziz', value: 'Beni Aziz' },
      { label: 'Beni Ourtilane', value: 'Beni Ourtilane' },
      { label: 'Bir El Arch', value: 'Bir El Arch' },
      { label: 'Bouandas', value: 'Bouandas' },
      { label: 'Bougaa', value: 'Bougaa' },
      { label: 'Djemila', value: 'Djemila' },
      { label: 'El Eulma', value: 'El Eulma' },
      { label: 'Guidjel', value: 'Guidjel' },
      { label: 'Guenzet', value: 'Guenzet' },
      { label: 'Hammam Guergour', value: 'Hammam Guergour' },
      { label: 'Hammam Soukhna', value: 'Hammam Soukhna' },
      { label: 'Maoklane', value: 'Maoklane' },
      { label: 'Salah Bey', value: 'Salah Bey' }
  ],
  'Boumerdès': [
    { label: 'Boumerdès', value: 'Boumerdès' },

    { label: 'Baghlia', value: 'Baghlia' },
    { label: 'Boudouaou', value: 'Boudouaou' },
    { label: 'Bordj Ménaïel', value: 'Bordj Ménaïel' },
    { label: 'Boumerdès', value: 'Boumerdès' },
    { label: 'Dellys', value: 'Dellys' },
    { label: 'Khemis El Kechna', value: 'Khemis El Kechna' },
    { label: 'Isser', value: 'Isser' },
    { label: 'Naciria', value: 'Naciria' },
    { label: 'Thenia', value: 'Thenia' }
],

'Médéa': [
  { label: 'Aïn Boucif', value: 'Aïn Boucif' },
  { label: 'Aziz', value: 'Aziz' },
  { label: 'Beni Slimane', value: 'Beni Slimane' },
  { label: 'Berrouaghia', value: 'Berrouaghia' },
  { label: 'Chahbounia', value: 'Chahbounia' },
  { label: 'Chellalet El Adhaoura', value: 'Chellalet El Adhaoura' },
  { label: 'El Azizia', value: 'El Azizia' },
  { label: 'El Guelb El Kebir', value: 'El Guelb El Kebir' },
  { label: 'El Omaria', value: 'El Omaria' },
  { label: 'Ksar Boukhari', value: 'Ksar Boukhari' },
  { label: 'Médéa', value: 'Médéa' },
  { label: 'Ouamri', value: 'Ouamri' },
  { label: 'Ouled Antar', value: 'Ouled Antar' },
  { label: 'Ouzera', value: 'Ouzera' },
  { label: 'Seghouane', value: 'Seghouane' },
  { label: 'Sidi Naâmane', value: 'Sidi Naâmane' },
  { label: 'Si Mahdjoub', value: 'Si Mahdjoub' },
  { label: 'Souagui', value: 'Souagui' },
  { label: 'Tablat', value: 'Tablat' }
],

'Mostaganem': [
  { label: 'Achaacha', value: 'Achaacha' },
  { label: 'Ain Nouissi', value: 'Ain Nouissi' },
  { label: 'Ain Tadles', value: 'Ain Tadles' },
  { label: 'Bouguirat', value: 'Bouguirat' },
  { label: 'Hassi Mameche', value: 'Hassi Mameche' },
  { label: 'Khiredine', value: 'Khiredine' },
  { label: 'Mesra', value: 'Mesra' },
  { label: 'Mostaganem', value: 'Mostaganem' },
  { label: 'Sidi Ali', value: 'Sidi Ali' },
  { label: 'Sidi Lakhdar', value: 'Sidi Lakhdar' }
],

'Adrar': [
 { label: 'Adrar', value: 'Adrar' },
  { label: 'Aoulef', value: 'Aoulef' },
  { label: 'Aougrout', value: 'Aougrout' },
  { label: 'Bordj Badji Mokhtar', value: 'Bordj Badji Mokhtar' },
  { label: 'Charouine', value: 'Charouine' },
  { label: 'Fenoughil', value: 'Fenoughil' },
  { label: 'Reggane', value: 'Reggane' },
  { label: 'Timimoun', value: 'Timimoun' },
  { label: 'Tinerkouk', value: 'Tinerkouk' },
  { label: 'Tsabit', value: 'Tsabit' },
  { label: 'Zaouiet Kounta', value: 'Zaouiet Kounta' }
],

'Chlef': [
  { label: 'Abou El Hassan', value: 'Abou El Hassan' },
  { label: 'Aïn Merane', value: 'Aïn Merane' },
  { label: 'Beni Haoua', value: 'Beni Haoua' },
  { label: 'Boukadir', value: 'Boukadir' },
  { label: 'Chlef', value: 'Chlef' },
  { label: 'El Karimia', value: 'El Karimia' },
  { label: 'El Marsa', value: 'El Marsa' },
  { label: 'Oued Fodda', value: 'Oued Fodda' },
  { label: 'Ouled Ben Abdelkader', value: 'Ouled Ben Abdelkader' },
  { label: 'Ouled Fares', value: 'Ouled Fares' },
  { label: 'Taougrit', value: 'Taougrit' },
  { label: 'Ténès', value: 'Ténès' },
  { label: 'Zeboudja', value: 'Zeboudja' }
],

'Laghouat': [
  { label: 'Aflou', value: 'Aflou' },
  { label: 'Aïn Mahdi', value: 'Aïn Mahdi' },
  { label: 'Brida', value: 'Brida' },
  { label: 'El Ghicha', value: 'El Ghicha' },
  { label: 'Gueltet Sidi Saâd', value: 'Gueltet Sidi Saâd' },
  { label: 'Hassi Rmel', value: 'Hassi Rmel' },
  { label: 'Ksar El Hirane', value: 'Ksar El Hirane' },
  { label: 'Laghouat', value: 'Laghouat' },
  { label: 'Oued Morra', value: 'Oued Morra' },
  { label: 'Sidi Makhlouf', value: 'Sidi Makhlouf' }
],


'Oum El Bouaghi': [
  { label: 'Aïn Babouche', value: 'Aïn Babouche' },
  { label: 'Ksar Sbahi', value: 'Ksar Sbahi' },
  { label: 'Aïn Beïda', value: 'Aïn Beïda' },
  { label: 'Fkirina', value: 'Fkirina' },
  { label: 'Aïn Mlila', value: 'Aïn Mlila' },
  { label: 'Souk Naamane', value: 'Souk Naamane' },
  { label: 'Aïn Fakroun', value: 'Aïn Fakroun' },
  { label: 'Sigus', value: 'Sigus' },
  { label: 'Aïn Kercha', value: 'Aïn Kercha' },
  { label: 'Meskiana', value: 'Meskiana' },
  { label: 'Dhalaa', value: 'Dhalaa' },
  { label: 'Oum El Bouaghi', value: 'Oum El Bouaghi' }
],

'Batna': [
  { label: 'Aïn Djasser', value: 'Aïn Djasser' },
  { label: 'Aïn Touta', value: 'Aïn Touta' },
  { label: 'Arris', value: 'Arris' },
  { label: 'Barika', value: 'Barika' },
  { label: 'Batna', value: 'Batna' },
  { label: 'Bouzina', value: 'Bouzina' },
  { label: 'Chemora', value: 'Chemora' },
  { label: 'Djezzar', value: 'Djezzar' },
  { label: 'El Madher', value: 'El Madher' },
  { label: 'Ichmoul', value: 'Ichmoul' },
  { label: 'Menaa', value: 'Menaa' },
  { label: 'Merouana', value: 'Merouana' },
  { label: 'N\'Gaous', value: 'N\'Gaous' },
  { label: 'Ouled Si Slimane', value: 'Ouled Si Slimane' },
  { label: 'Ras El Aioun', value: 'Ras El Aioun' },
  { label: 'Seggana', value: 'Seggana' },
  { label: 'Seriana', value: 'Seriana' },
  { label: 'Tazoult', value: 'Tazoult' },
  { label: 'Teniet El Abed', value: 'Teniet El Abed' },
  { label: 'Timgad', value: 'Timgad' }
],

'Béjaïa': [
  { label: 'Adekar', value: 'Adekar' },
  { label: 'Akbou', value: 'Akbou' },
  { label: 'Amizour', value: 'Amizour' },
  { label: 'Aokas', value: 'Aokas' },
  { label: 'Barbacha', value: 'Barbacha' },
  { label: 'Béjaïa', value: 'Béjaïa' },
  { label: 'Beni Maouche', value: 'Beni Maouche' },
  { label: 'Chemini', value: 'Chemini' },
  { label: 'Darguina', value: 'Darguina' },
  { label: 'El Kseur', value: 'El Kseur' },
  { label: 'Ighil Ali', value: 'Ighil Ali' },
  { label: 'Kherrata', value: 'Kherrata' },
  { label: 'Ouzellaguen', value: 'Ouzellaguen' },
  { label: 'Seddouk', value: 'Seddouk' },
  { label: 'Sidi-Aïch', value: 'Sidi-Aïch' },
  { label: 'Souk El Ténine', value: 'Souk El Ténine' },
  { label: 'Tazmalt', value: 'Tazmalt' },
  { label: 'Tichy', value: 'Tichy' },
  { label: 'Timezrit', value: 'Timezrit' }
],

'Jijel': [
  { label: 'Chekfa', value: 'Chekfa' },
  { label: 'Djimla', value: 'Djimla' },
  { label: 'El Ancer', value: 'El Ancer' },
  { label: 'El Aouana', value: 'El Aouana' },
  { label: 'El Milia', value: 'El Milia' },
  { label: 'Jijel', value: 'Jijel' },
  { label: 'Settara', value: 'Settara' },
  { label: 'Sidi Maarouf', value: 'Sidi Maarouf' },
  { label: 'Taher', value: 'Taher' },
  { label: 'Texenna', value: 'Texenna' },
  { label: 'Ziama Mansouriah', value: 'Ziama Mansouriah' }
],

'Djelfa': [
  { label: 'Aïn El Ibel', value: 'Aïn El Ibel' },
  { label: 'Aïn Oussara', value: 'Aïn Oussara' },
  { label: 'Birine', value: 'Birine' },
  { label: 'Charef', value: 'Charef' },
  { label: 'Dar Chioukh', value: 'Dar Chioukh' },
  { label: 'Djelfa', value: 'Djelfa' },
  { label: 'El Idrissia', value: 'El Idrissia' },
  { label: 'Faidh El Botma', value: 'Faidh El Botma' },
  { label: 'Had-Sahary', value: 'Had-Sahary' },
  { label: 'Hassi Bahbah', value: 'Hassi Bahbah' }
],

'Saida': [
  { label: 'Saida', value: 'Saida' },
  { label: 'Aïn El Hadjar', value: 'Aïn El Hadjar' },
  { label: 'Sidi Boubekeur', value: 'Sidi Boubekeur' },
  { label: 'El Hassasna', value: 'El Hassasna' },
  { label: 'Ouled Brahim', value: 'Ouled Brahim' },
  { label: 'Youb', value: 'Youb' }
],

'Skikda': [
{ label: 'Skikda', value: 'Skikda' },
{ label: 'Azzaba', value: 'Azzaba' },
  { label: 'Aïn Kechra', value: 'Aïn Kechra' },
  { label: 'Ben Azzouz', value: 'Ben Azzouz' },
  { label: 'Collo', value: 'Collo' },
  { label: 'El Hadaiek', value: 'El Hadaiek' },
  { label: 'El Harrouch', value: 'El Harrouch' },
  { label: 'Ouled Attia', value: 'Ouled Attia' },
  { label: 'Oum Toub', value: 'Oum Toub' },
  { label: 'Ramdane Djamel', value: 'Ramdane Djamel' },
  { label: 'Sidi Mezghiche', value: 'Sidi Mezghiche' },
  { label: 'Tamalous', value: 'Tamalous' },
  { label: 'Zitouna', value: 'Zitouna' }
],
'Sidi Bel Abbès': [
{ label: 'Sidi Bel Abbès', value: 'Sidi Bel Abbès' },
  { label: 'Aïn el Berd', value: 'Aïn el Berd' },
  { label: 'Ben Badis', value: 'Ben Badis' },
  { label: 'Marhoum', value: 'Marhoum' },
  { label: 'Merine', value: 'Merine' },
  { label: 'Mostefa Ben Brahim', value: 'Mostefa Ben Brahim' },
  { label: 'Moulay Slissen', value: 'Moulay Slissen' },
  { label: 'Ras El Ma', value: 'Ras El Ma' },
  { label: 'Sfisef', value: 'Sfisef' },
  { label: 'Sidi Ali Benyoub', value: 'Sidi Ali Benyoub' },
  { label: 'Sidi Ali Boussidi', value: 'Sidi Ali Boussidi' },
  { label: 'Sidi Lahcene', value: 'Sidi Lahcene' },
  { label: 'Telagh', value: 'Telagh' },
  { label: 'Tenira', value: 'Tenira' },
  { label: 'Tessala', value: 'Tessala' }
],

'Annaba': [
  { label: 'Annaba', value: 'Annaba' },
{ label: 'Aïn Berda', value: 'Aïn Berda' },
  { label: 'El Hadjar', value: 'El Hadjar' },
  { label: 'Berrahal', value: 'Berrahal' },
  { label: 'Chetaïbi', value: 'Chetaïbi' },
  { label: 'El Bouni', value: 'El Bouni' }
],
'Guelma': [       
  { label: 'Guelma', value: 'Guelma' },   
  { label: 'Khezarra', value: 'Khezarra' },
          { label: 'Guelaât Bou Sbaâ', value: 'Guelaât Bou Sbaâ' },
          { label: 'Héliopolis', value: 'Héliopolis' },
          { label: 'Oued Zenati', value: 'Oued Zenati' },
          { label: 'Ain Makhlouf', value: 'Ain Makhlouf' },
          { label: 'Hammam Debagh', value: 'Hammam Debagh' },
          { label: 'Bouchegouf', value: 'Bouchegouf' },
          { label: 'Hammam N’Bails', value: 'Hammam N’Bails' },
          { label: 'Houari Boumédiène', value: 'Houari Boumédiène' }
      ],
  
   'Msila': [
    { label: 'Msila', value: 'Msila' },
          { label: 'Hammam Dalaa', value: 'Hammam Dalaa' },
          { label: 'Ouled Derradj', value: 'Ouled Derradj' },
          { label: 'Sidi Aissa', value: 'Sidi Aissa' },
          { label: 'Aïn El Melh', value: 'Aïn El Melh' },
          { label: 'Ben Srour', value: 'Ben Srour' },
          { label: 'Bou Saada', value: 'Bou Saada' },
          { label: 'Ouled sidi Brahim', value: 'Ouled sidi Brahim' },
          { label: 'Sidi Ameur', value: 'Sidi Ameur' },
          { label: 'Magra', value: 'Magra' },
          { label: 'Chellal', value: 'Chellal' },
          { label: 'Khoubana', value: 'Khoubana' },
          { label: 'Medjedel', value: 'Medjedel' },
          { label: 'Aïn El Hadjel', value: 'Aïn El Hadjel' },
          { label: 'Djebel Messaad', value: 'Djebel Messaad' }
      ],
  
  
    'Mascara': [
          { label: 'Aïn Fares', value: 'Aïn Fares' },
          { label: 'Aïn Fekan', value: 'Aïn Fekan' },
          { label: 'Aouf', value: 'Aouf' },
          { label: 'Bou Hanifia', value: 'Bou Hanifia' },
          { label: 'El Bordj', value: 'El Bordj' },
          { label: 'Ghriss', value: 'Ghriss' },
          { label: 'Hachem', value: 'Hachem' },
          { label: 'Mascara', value: 'Mascara' },
          { label: 'Mohammadia', value: 'Mohammadia' },
          { label: 'Oggaz', value: 'Oggaz' },
          { label: 'Oued El Abtal', value: 'Oued El Abtal' },
          { label: 'Oued Taria', value: 'Oued Taria' },
          { label: 'Sig', value: 'Sig' },
          { label: 'Tighennif', value: 'Tighennif' },
          { label: 'Tizi', value: 'Tizi' },
   { label: 'Zahana', value: 'Zahana' }
      ],
  
    'Biskra': [
    { label: 'Biskra', value: 'Biskra' },
        { label: 'Djemorah', value: 'Djemorah' },
     { label: 'Djemorah', value: 'Djemorah' },
          { label: 'El Kantara', value: 'El Kantara' },
          { label: 'M\'Chouneche', value: 'M\'Chouneche' },
          { label: 'Sidi Okba', value: 'Sidi Okba' },
          { label: 'Zeribet El Oued', value: 'Zeribet El Oued' },
          { label: 'Ourlal', value: 'Ourlal' },
          { label: 'Tolga', value: 'Tolga' },
          { label: 'Ouled Djellal', value: 'Ouled Djellal' },
          { label: 'Sidi Khaled', value: 'Sidi Khaled' },
          { label: 'Foughala', value: 'Foughala' },
          { label: 'El Outaya', value: 'El Outaya' }
      ],
   
    'Béchar': [
      { label: 'Béchar', value: 'Béchar' },
       { label: 'Beni Ounif', value: 'Beni Ounif' },
          { label: 'Lahmar', value: 'Lahmar' },
          { label: 'Kenadsa', value: 'Kenadsa' },
          { label: 'Taghit', value: 'Taghit' },
          { label: 'Abadla', value: 'Abadla' },
          { label: 'Tabelbala', value: 'Tabelbala' },
          { label: 'Igli', value: 'Igli' },
          { label: 'Beni Abbes', value: 'Beni Abbes' },
          { label: 'El Ouata', value: 'El Ouata' },
          { label: 'Kerzaz', value: 'Kerzaz' },
          { label: 'Ouled Khoudir', value: 'Ouled Khoudir' }
      ],
  
  'Blida': [
        { label: 'Blida', value: 'Blida' },
          { label: 'Boufarik', value: 'Boufarik' },
          { label: 'Bougara', value: 'Bougara' },
          { label: 'Bouinan', value: 'Bouinan' },
          { label: 'El Affroun', value: 'El Affroun' },
          { label: 'Larbaa', value: 'Larbaa' },
          { label: 'Meftah', value: 'Meftah' },
          { label: 'Mouzaïa', value: 'Mouzaïa' },
          { label: 'Oued Alleug', value: 'Oued Alleug' },
          { label: 'Ouled Yaich', value: 'Ouled Yaich' }
      ],
  
     'Bouira': [     { label: 'Bouira', value: 'Bouira' },
          { label: 'Haizer', value: 'Haizer' },
          { label: 'Bechloul', value: 'Bechloul' },
          { label: 'Mchedallah', value: 'Mchedallah' },
          { label: 'Kadiria', value: 'Kadiria' },
          { label: 'Lakhdaria', value: 'Lakhdaria' },
          { label: 'Bir Ghbalou', value: 'Bir Ghbalou' },
          { label: 'Ain Bessam', value: 'Ain Bessam' },
          { label: 'Souk El Khemis', value: 'Souk El Khemis' },
          { label: 'El Hachimia', value: 'El Hachimia' },
          { label: 'Sour El Ghozlane', value: 'Sour El Ghozlane' },
          { label: 'Bordj Okhriss', value: 'Bordj Okhriss' }
      ],
  
      'Tamanrasset': [
          { label: 'Tamanrasset', value: 'Tamanrasset' },
          { label: 'Abalessa', value: 'Abalessa' },
          { label: 'In Ghar', value: 'In Ghar' },
          { label: 'In Guezzam', value: 'In Guezzam' },
          { label: 'In Salah', value: 'In Salah' },
          { label: 'Tazrouk', value: 'Tazrouk' },
          { label: 'Tinzaouten', value: 'Tinzaouten' }
      ],
  
     'Tebessa': [    { label: 'Tebessa', value: 'Tebessa' },
          { label: 'El Kouif', value: 'El Kouif' },
          { label: 'Morsott', value: 'Morsott' },
          { label: 'El Ma Labiodh', value: 'El Ma Labiodh' },
          { label: 'El Aouinet', value: 'El Aouinet' },
          { label: 'Ouenza', value: 'Ouenza' },
          { label: 'Bir Mokkadem', value: 'Bir Mokkadem' },
          { label: 'Bir El-Ater', value: 'Bir El-Ater' },
          { label: 'El Ogla', value: 'El Ogla' },
          { label: 'Oum Ali', value: 'Oum Ali' },
          { label: 'Negrine', value: 'Negrine' },
          { label: 'Cheria', value: 'Cheria' }
      ],
  
   'Tlemcen': [ { label: 'Tlemcen', value: 'Tlemcen' },
          { label: 'Aïn Tallout', value: 'Aïn Tallout' },
          { label: 'Bab El Assa', value: 'Bab El Assa' },
          { label: 'Beni Boussaid', value: 'Beni Boussaid' },
          { label: 'Beni Snous', value: 'Beni Snous' },
          { label: 'Bensekrane', value: 'Bensekrane' },
          { label: 'Chetouane', value: 'Chetouane' },
          { label: 'Fellaoucene', value: 'Fellaoucene' },
          { label: 'Ghazaouet', value: 'Ghazaouet' },
          { label: 'Hennaya', value: 'Hennaya' },
          { label: 'Honaïne', value: 'Honaïne' },
          { label: 'Maghnia', value: 'Maghnia' },
          { label: 'Mansourah', value: 'Mansourah' },
          { label: 'Marsa Ben M\'Hidi', value: 'Marsa Ben M\'Hidi' },
          { label: 'Nedroma', value: 'Nedroma' },
          { label: 'Ouled Mimoun', value: 'Ouled Mimoun' },
          { label: 'Remchi', value: 'Remchi' },
          { label: 'Sabra', value: 'Sabra' },
          { label: 'Sebdou', value: 'Sebdou' },
          { label: 'Sidi Djillal', value: 'Sidi Djillal' }
      ],
  
    'Tiaret': [
      { label: 'Tiaret', value: 'Tiaret' },
          { label: 'Sougueur', value: 'Sougueur' },
          { label: 'Aïn Deheb', value: 'Aïn Deheb' },
          { label: 'Aïn Kermes', value: 'Aïn Kermes' },
          { label: 'Frenda', value: 'Frenda' },
          { label: 'Dahmouni', value: 'Dahmouni' },
          { label: 'Mahdia', value: 'Mahdia' },
          { label: 'Hamadia', value: 'Hamadia' },
          { label: 'Ksar Chellala', value: 'Ksar Chellala' },
          { label: 'Medroussa', value: 'Medroussa' },
          { label: 'Mechraa Safa', value: 'Mechraa Safa' },
          { label: 'Rahouia', value: 'Rahouia' },
          { label: 'Oued Lilli', value: 'Oued Lilli' },
          { label: 'Meghila', value: 'Meghila' }
      ],
     
   'El Bayadh': [
  { label: 'El Bayadh', value: 'El Bayadh' },
          { label: 'Rogassa', value: 'Rogassa' },
          { label: 'Brezina', value: 'Brezina' },
          { label: 'El Abiodh Sidi Cheikh', value: 'El Abiodh Sidi Cheikh' },
          { label: 'Bougtoub', value: 'Bougtoub' },
          { label: 'Chellala', value: 'Chellala' },
          { label: 'Boussemghoun', value: 'Boussemghoun' },
          { label: 'Boualem', value: 'Boualem' },
      ],
  
     'Illizi': [  { label: 'Illizi', value: 'Illizi' },
          { label: 'Bordj Omar Driss', value: 'Bordj Omar Driss' },
          { label: 'Debdeb', value: 'Debdeb' },
          { label: 'In Amenas', value: 'In Amenas' }
      ],
  
     'Bordj Bou Arreridj': [ 
   { label: 'Bordj-Bou-Arreridj', value: 'Bordj-Bou-Arreridj' },
          { label: 'Aïn Taghrout', value: 'Aïn Taghrout' },
          { label: 'Ras El Oued', value: 'Ras El Oued' },
          { label: 'Bordj Ghedir', value: 'Bordj Ghedir' },
          { label: 'Bir Kasdali', value: 'Bir Kasdali' },
          { label: 'El Hamadia', value: 'El Hamadia' },
          { label: 'Mansoura', value: 'Mansoura' },
          { label: 'Medjana', value: 'Medjana' },
          { label: 'Bordj Zemoura', value: 'Bordj Zemoura' },
          { label: 'Djaafra', value: 'Djaafra' }
      ],
  
      'El Tarf': [
        { label: 'El-Tarf', value: 'El-Tarf' },
     { label: 'El Kala', value: 'El Kala' },
          { label: 'Ben Mehidi', value: 'Ben Mehidi' },
          { label: 'Besbes', value: 'Besbes' },
          { label: 'Dréan', value: 'Dréan' },
          { label: 'Bouhadjar', value: 'Bouhadjar' },
          { label: 'Bouteldja', value: 'Bouteldja' },
      ],
  
  'Tindouf': [ { label: 'Tindouf', value: 'Tindouf' },
  
  ],
  
    'Tissemsilt': [ 
  { label: 'Tissemsilt', value: 'Tissemsilt' },
          { label: 'Ammari', value: 'Ammari' },
          { label: 'Bordj Bou Naama', value: 'Bordj Bou Naama' },
          { label: 'Bordj El Emir Abdelkader', value: 'Bordj El Emir Abdelkader' },
          { label: 'Khemisti', value: 'Khemisti' },
          { label: 'Lardjem', value: 'Lardjem' },
          { label: 'Lazharia', value: 'Lazharia' },
          { label: 'Theniet El Had', value: 'Theniet El Had' }
      ],
  
  'El Oued': [
    { label: 'El-Oued', value: 'El-Oued' },
          { label: 'Robbah', value: 'Robbah' },
          { label: 'Oued El Alenda', value: 'Oued El Alenda' },
          { label: 'Bayadha', value: 'Bayadha' },
          { label: 'Nakhla', value: 'Nakhla' },
          { label: 'Guemar', value: 'Guemar' },
          { label: 'Kouinine', value: 'Kouinine' },
          { label: 'Reguiba', value: 'Reguiba' },
          { label: 'Hamraia', value: 'Hamraia' },
          { label: 'Taghzout', value: 'Taghzout' },
          { label: 'Debila', value: 'Debila' },
          { label: 'Hassani Abdelkrim', value: 'Hassani Abdelkrim' },
          { label: 'Hassi Khalifa', value: 'Hassi Khalifa' },
          { label: 'Taleb Larbi', value: 'Taleb Larbi' },
          { label: 'Douar El Ma', value: 'Douar El Ma' },
          { label: 'Sidi Aoun', value: 'Sidi Aoun' },
          { label: 'Trifaoui', value: 'Trifaoui' },
          { label: 'Magrane', value: 'Magrane' },
          { label: 'Beni Guecha', value: 'Beni Guecha' },
          { label: 'Ourmas', value: 'Ourmas' }
      ],
  
  
     'Khenchela': [          
  { label: 'Khenchela', value: 'Khenchela' },
          { label: 'Babar', value: 'Babar' },
          { label: 'Bouhmama', value: 'Bouhmama' },
          { label: 'Chechar', value: 'Chechar' },
          { label: 'El Hamma', value: 'El Hamma' },
          { label: 'Kais', value: 'Kais' },
          { label: 'Ouled Rechache', value: 'Ouled Rechache' },
          { label: 'Aïn Touila', value: 'Aïn Touila' }
      ],
  
      'Souk Ahras': [        
    { label: 'Souk-Ahras', value: 'Souk-Ahras' },
          { label: 'Bir Bou Haouch', value: 'Bir Bou Haouch' },
          { label: 'Heddada', value: 'Heddada' },
          { label: "M'daourouch", value: "M'daourouch" },
          { label: 'Mechroha', value: 'Mechroha' },
          { label: 'Merahna', value: 'Merahna' },
          { label: 'Ouled Driss', value: 'Ouled Driss' },
          { label: 'Oum El Adhaim', value: 'Oum El Adhaim' },
          { label: 'Sedrata', value: 'Sedrata' },
          { label: 'Taoura', value: 'Taoura' }
      ],
  
     'Mila': [
   { label: 'Mila', value: 'Mila' },
          { label: 'Chelghoum Laid', value: 'Chelghoum Laid' },
          { label: 'Ferdjioua', value: 'Ferdjioua' },
          { label: 'Grarem Gouga', value: 'Grarem Gouga' },
          { label: 'Oued Endja', value: 'Oued Endja' },
          { label: 'Rouached', value: 'Rouached' },
          { label: 'Terrai Bainen', value: 'Terrai Bainen' },
          { label: 'Tassadane Haddada', value: 'Tassadane Haddada' },
          { label: 'Aïn Beida Harriche', value: 'Aïn Beida Harriche' },
          { label: 'Sidi Merouane', value: 'Sidi Merouane' },
          { label: 'Teleghma', value: 'Teleghma' },
          { label: 'Bouhatem', value: 'Bouhatem' },
          { label: 'Tadjenanet', value: 'Tadjenanet' }
      ],
  
    'Aïn Defla': [  
    { label: 'Aïn-Defla', value: 'Aïn-Defla' },
          { label: 'Aïn Lechiekh', value: 'Aïn Lechiekh' },
          { label: 'Bathia', value: 'Bathia' },
          { label: 'Bordj Emir Khaled', value: 'Bordj Emir Khaled' },
          { label: 'Boumedfaa', value: 'Boumedfaa' },
          { label: 'Djelida', value: 'Djelida' },
          { label: 'Djendel', value: 'Djendel' },
          { label: 'El Abadia', value: 'El Abadia' },
          { label: 'El Amra', value: 'El Amra' },
          { label: 'El Attaf', value: 'El Attaf' },
          { label: 'Hammam Righa', value: 'Hammam Righa' },
          { label: 'Khemis Miliana', value: 'Khemis Miliana' },
          { label: 'Miliana', value: 'Miliana' },
          { label: 'Rouina', value: 'Rouina' }
      ],
   'Naama': [       
     { label: 'Naâma', value: 'Naâma' },
          { label: 'Ain Sefra', value: 'Ain Sefra' },
          { label: 'Assela', value: 'Assela' },
          { label: 'Makman Ben Amer', value: 'Makman Ben Amer' },
          { label: 'Mecheria', value: 'Mecheria' },
          { label: 'Moghrar', value: 'Moghrar' },
          { label: 'Sfissifa', value: 'Sfissifa' }
      ],
  
    'Aïn-Témouchent': [
            { label: 'Aïn-Témouchent', value: 'Aïn-Témouchent' },   
  { label: 'Aïn El Arbaa', value: 'Aïn El Arbaa' },
          { label: 'Ain Kihal', value: 'Ain Kihal' },
          { label: 'Beni Saf', value: 'Beni Saf' },
          { label: 'El Amria', value: 'El Amria' },
          { label: 'El Malah', value: 'El Malah' },
          { label: 'Hammam Bou Hadjar', value: 'Hammam Bou Hadjar' },
          { label: 'Oulhaça El Gheraba', value: 'Oulhaça El Gheraba' }
      ],
  
     'Ghardaïa': [ { label: 'Ghardaia', value: 'Ghardaia' },
          { label: 'El Meniaa', value: 'El Meniaa' },
          { label: 'Metlili', value: 'Metlili' },
          { label: 'Berriane', value: 'Berriane' },
          { label: 'Daïa Ben Dahoua', value: 'Daïa Ben Dahoua' },
          { label: 'Mansoura', value: 'Mansoura' },
          { label: 'Zelfana', value: 'Zelfana' },
          { label: 'Guerrara', value: 'Guerrara' },
          { label: 'Bounoura', value: 'Bounoura' }
      ],
   'Relizane': [ { label: 'Relizane', value: 'Relizane' },
          { label: 'Aïn Tarek', value: 'Aïn Tarek' },
          { label: 'Ammi Moussa', value: 'Ammi Moussa' },
          { label: 'Djidioua', value: 'Djidioua' },
          { label: 'El Hamadna', value: 'El Hamadna' },
          { label: 'El Matmar', value: 'El Matmar' },
          { label: 'Mazouna', value: 'Mazouna' },
          { label: 'Mendes', value: 'Mendes' },
          { label: 'Oued Rhiou', value: 'Oued Rhiou' },
          { label: 'Ramka', value: 'Ramka' },
          { label: 'Sidi M\'Hamed Ben Ali', value: 'Sidi M\'Hamed Ben Ali' },
          { label: 'Yellel', value: 'Yellel' },
          { label: 'Zemmora', value: 'Zemmora' }
      ],
    'Timimoun': [
          { label: 'Timimoun', value: 'Timimoun' },
          { label: 'Aougrout', value: 'Aougrout' },
          { label: 'Tinerkouk', value: 'Tinerkouk' },
          { label: 'Charouine', value: 'Charouine' }
      ],
   'Bordj Badji Mokhtar': [
               { label: 'Bordj Badji Mokhtar', value: 'Bordj Badji Mokhtar' },
  
      ],
  
    'Ouled Djellal': [
          { label: 'Ouled Djellal', value: 'Ouled Djellal' },
          { label: 'Sidi Khaled', value: 'Sidi Khaled' }
      ],
  
      'Béni Abbès': [
          { label: 'Béni Abbès', value: 'Béni Abbès' },
          { label: 'Tamtert', value: 'Tamtert' }
      ],
  
   'In Salah': [
          { label: 'In Salah', value: 'In Salah' },
          { label: 'In Ghar', value: 'In Ghar' }
      ],
  
   'In Guezzam': [
          { label: 'In Guezzam', value: 'In Guezzam' },
          { label: 'Tin Zaouatine', value: 'Tin Zaouatine' }
      ],
  
  'Touggourt': [
         { label: 'Touggourt', value: 'Touggourt' }, 
     ],
  'Djanet': [
               { label: 'Djanet', value: 'Djanet' },
  
     ],
  
  'El Mghair': [
          { label: 'Djamaa', value: 'Djamaa' },
          { label: 'El Mghair', value: 'El Mghair' }
      ],
  
  'El Meniaa': [
                      { label: 'El Meniaa', value: 'El Meniaa' }
  
  
     ],
        // Add more dairas for other wilayas
      };
     







      
      // Function to check if a string is non-empty and non-whitespace
  const isNonEmptyOrWhitespace = (input) => {
    return input.trim().length > 0;
  };
  
  const handleWilayaChange = async (selectedOption) => {
    if (selectedOption) {
      const selectedWilaya = selectedOption.label;
      setFormData((prevState) => ({
        ...prevState,
        selectedWilaya,
        selectedDaira: null,
      }));
  
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
            selectedWilaya
          )}&format=json`
        );
  
        const data = await response.json();
        if (data.length > 0) {
          const { lat, lon } = data[0];
          setMapCenter([parseFloat(lat), parseFloat(lon)]);
          setMarkerPosition([parseFloat(lat), parseFloat(lon)]);
          setLat(lat);
          setLng(lon);
        }
      } catch (error) {
        console.error('Geocoding error:', error);
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        selectedWilaya: null,
        selectedDaira: null,
      }));
    }
  };
  
  const handleDairaChange = async (selectedOption) => {
    if (selectedOption) {
      const selectedDaira = selectedOption.label;
      setFormData((prevState) => ({
        ...prevState,
        selectedDaira,
      }));
  
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
            selectedDaira
          )}&format=json`
        );
  
        const data = await response.json();
        if (data.length > 0) {
          const { lat, lon } = data[0];
          setMapCenter([parseFloat(lat), parseFloat(lon)]);
          setMarkerPosition([parseFloat(lat), parseFloat(lon)]);
          setLat(lat);
          setLng(lon);
        }
      } catch (error) {
        console.error('Geocoding error:', error);
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        selectedDaira: null,
      }));
    }
  };







  const [image, setImage] = useState(null);
  const fileInputRef = useRef();
  const storage = getStorage(); // Initialize Firebase Storage

  const selectImage = () => {
      fileInputRef.current.click();
  };
 
  const onFileChange = (e) => {
    const file = e.target.files[0];
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];
  
    // Check if the file exists and is of allowed type
    if (file && allowedTypes.includes(file.type) && file.size <= 2 * 1024 * 1024) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage({ file, preview: reader.result });
      };
      reader.readAsDataURL(file);
    } else {
      if (!file) {
        notify('error', 'Please select a file.');
      } else if (!allowedTypes.includes(file.type)) {
        notify('error', 'Please select an image of type JPEG, JPG, PNG, or GIF.');
      } else {
        notify('error', translations.lessthen2mb);
      }
    }
  };
  
  const clearImage = () => {
    if (image) {
      URL.revokeObjectURL(image.preview);
      setImage(null);
    }
  };
  
  // Function to show notifications
  function notify(type, message) {
    switch (type) {
      case 'success':
        toast.success(message);
        break;
      case 'error':
        toast.error(message);
        break;
      case 'warning':
        toast.warning(message);
        break;
      default:
        toast(message);
    }
  }






  async function onSubmit(e) {
    e.preventDefault();
  
    if (isSubmitting) {
      return;
    }
  
    // Disable the submit button to prevent multiple submissions
    setIsSubmitting(true);
    
  
  
    
  // Validate the address and description fields before submission
  if (!isNonEmptyOrWhitespace(address)) {
    setIsSubmitting(false);
    toast.error("Veuillez entrer une adresse.");
    return;
    }
    
    if (!isNonEmptyOrWhitespace(description)) {
    setIsSubmitting(false);
    
    toast.error("Veuillez entrer une description.");
    return;
    }
    
    

    if (password !== confirmPassword) {
      setIsSubmitting(false);

      toast.error("Les mots de passe ne correspondent pas.");
      return;
    }


    if (!acceptedTerms) {
      setIsSubmitting(false);

      toast.error("Veuillez accepter les conditions d'utilisation pour ouvrir votre compte Doktori pro .");
      return;
    }

    if (doctorname.trim() === "") {
      setIsSubmitting(false);

      toast.error("Le nom complet est requis.");
      return;
    }

    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^_\-¤'"&(=€)])[A-Za-z\d@$!%*?&^_\-¤'"&(=€)]{8,}$/;
    if (!passwordPattern.test(password)) {
      setIsSubmitting(false);

      toast.error(
        "Le mot de passe doit contenir au moins 8 caractères, une combinaison de lettres et de chiffres, et au moins un caractère spécial."
      );
      return;
    }

    if (!isAllowedEmailDomain(email)) {
      setIsSubmitting(false);

      toast.error("L'adresse e-mail n'est pas valide.");
      return;
    }



    try {
      const auth = getAuth();

      setIsCreatingAccount(true);

      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      updateProfile(auth.currentUser, {
        displayName: doctorname,
      });

      const user = userCredential.user;
      // Upload the image to Cloudflare R2
      let downloadURL = null;
      if (image) {
   
        // Generate a random number
 function generateRandomName(length) {
   const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
   let result = '';
 
   for (let i = 0; i < length; i++) {
     const randomIndex = Math.floor(Math.random() * characters.length);
     result += characters.charAt(randomIndex);
   }
 
   return result;
 }
 
 // Usage example
 const randomName = generateRandomName(13); 
 // Sanitize the filename to remove invalid characters
 const sanitizedFilename = `${user.uid}_${randomName}`;
 
 // Upload the image to Cloudflare R2 using the sanitized filename
 const formData = new FormData();
 formData.append('file', image.file);
 
 const response = await fetch('https://hello-world-shiny-art-9691.mokraniagld.workers.dev/' + sanitizedFilename, {
     method: 'PUT',
     body:  image.file,
     headers: {
         'x-api-key': 'AzertyBoghni'
     }
 });
          if (response.ok) {
              // Construct the download URL
              downloadURL = `https://profilepicture.doktori.site/${sanitizedFilename}`;
          } else {
              throw new Error('Error uploading image.');
          }
      
    }
    
      const formDataCopy = { ...formData,
        imageUrl: downloadURL,
        sex: formData.sex,
    markerCoordinates,
    id: user.uid,
      };
      delete formDataCopy.password;
      delete formDataCopy.confirmPassword;
      formDataCopy.timestamp = serverTimestamp();

      const doctorData = { ...formDataCopy, active: false }; // Add an "active" field, set to false initially


      // Modify the Firestore document path to "doctors" for the main doctor collection
      await setDoc(doc(db, "doctors", user.uid), doctorData);
  // Define a reference to the "alldoctors" document
  const alldoctorsRef = doc(db, "alldoctors", "all-doctors-document");

  // Define the update object with the user's ID as a key and the doctor data as the value
  const updateObject = { [user.uid]: doctorData };

  // Update the "alldoctors" document without overwriting existing data
  await updateDoc(alldoctorsRef, updateObject);

      setIsCreatingAccount(false);

      navigate("/under-review");

      toast.success("Compte créé avec succès !");
    } catch (error) {
      setIsSubmitting(false);

      let errorMessage = "Une erreur s'est produite lors de l'inscription : ";

      if (error.message.includes("Votre Nom et Prenom")) {
        setIsSubmitting(false);

        errorMessage += "Le nom complet est requis.";
      } else if (error.code === "auth/invalid-email") {
        setIsSubmitting(false);

        errorMessage += "L'adresse e-mail n'est pas valide.";
      } else if (error.code === "auth/email-already-in-use") {
        setIsSubmitting(false);

        errorMessage += "L'adresse e-mail existe déjà.";
      } else if (error.message.includes("Votre Adress e-mail")) {
        setIsSubmitting(false);

        errorMessage += "L'adresse e-mail est requise.";
      } else if (error.message.includes("Votre Mots de Pass")) {
        setIsSubmitting(false);

        errorMessage += "Le mot de passe est requis.";
      } else if (error.message.includes("Password is not valid")) {
        setIsSubmitting(false);

        errorMessage +=
          "Le mot de passe doit contenir au moins 8 caractères, une combinaison de lettres et de chiffres, et au moins un caractère spécial.";
      } else {
        setIsSubmitting(false);

        errorMessage +=
          "Quelque chose s'est mal passé lors de l'inscription.";
      }

      toast.error(errorMessage);
      setIsCreatingAccount(false);
    }
    
    let geolocation = {};
    let location;
    if (geolocationEnabled) {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${process.env.REACT_APP_GEOCODE_API_KEY}`
      );
   const data = await response.json();
      geolocation.lat = data.results[0]?.geometry.location.lat ?? 0;
      geolocation.lng = data.results[0]?.geometry.location.lng ?? 0;
  
      location = data.status === "ZERO_RESULTS" && undefined;
  
      if (location === undefined) {
        setLoading(false);
        setIsSubmitting(false);
  
        toast.error("Veuillez entrer une adresse correcte");
        return;
      }
    } else {
      geolocation.lat = latitude;
      geolocation.lng = longitude;
    }
  }
      
      // Create a custom marker icon
      const markerIcon = icon({
        iconUrl: require('leaflet/dist/images/marker-icon.png'),
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        tooltipAnchor: [16, -28],
        shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
        shadowSize: [41, 41],
      });
    
  

  

  if (loading) {
    return <Spinner />;
  }
 
  
  

  return (
    <section>
      <h1 className="text-2xl text-center dark:text-blue-100 mt-2 font-bold">DoKtori Pro</h1>
    
      <div className="flex justify-center flex-wrap items-top px-3 py-7 max-w-6xl mx-auto">
    

    

    <div className="w-full md:w-[60%] lg:w-[40%] lg:ml-20">

    
      <form onSubmit={onSubmit}>
          <label htmlFor="doctorname" className="text-sm dark:text-blue-100 font-semibold">
       {translations.nomprenom}:
          </label>

            <input
              type="text"
              id="doctorname"
              value={doctorname}
              onChange={onChange}
              placeholder="Votre Nom et Prénom"
              className="mb-6 w-full px-4 py-2 text-xl text-gray-800 bg-white border border-sky-400 rounded transition ease-in-out"
            />





<div className="flex flex-wrap border border-sky-400 text-gray-700 dark:text-blue-100  justify-center ">
  {sexOptions.map((option) => (
    <label key={option.value} className="flex text-gray-700 dark:text-blue-100  items-center mx-2 mb-2">
      <input
        type="checkbox"
        name="sex"
        value={option.value}
        checked={formData.sex === option.value}
        onChange={handleCheckboxChange}
      />
      {option.icon} {option.label}
    </label>
  ))}
</div>

<label htmlFor="email" className="text-sm dark:text-blue-100 font-semibold">
{translations.email}   :
          </label>

            <input
              type="email"
              id="email"
              value={email}
              onChange={onChange}
              placeholder="Votre Adresse e-mail"
              className="mb-6 w-full px-4 py-2 text-xl text-black bg-white border border-sky-400 rounded transition ease-in-out"
            />
            <div className="relative mb-2 my-0">

            <label htmlFor="password" className="text-sm dark:text-blue-100 font-semibold">
            {translations.password}     :
          </label>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                onChange={onChange}
                placeholder="Votre Mot de Passe"
                className="w-full px-4 py-2 text-xl text-gray-800 bg-white  border border-sky-400 rounded transition ease-in-out"
              />
              {showPassword ? (
                <AiFillEye
                  className="absolute right-3 top-9 text-blue-400 text-xl cursor-pointer"
                  onClick={() => setShowPassword((prevState) => !prevState)}
                />
              ) : (
                <AiFillEyeInvisible
                  className="absolute right-3 top-9 text-blue-400 text-xl cursor-pointer"
                  onClick={() => setShowPassword((prevState) => !prevState)}
                />
              )}
            </div>
        
            <p className="text-sm dark:text-blue-100 text-gray-600 ">
            {translations.passwordformat}     :
            </p>
            <ul className="list-disc list-inside dark:text-blue-100 text-1xl font-bold text-gray-600 mb-3 my-1">
            <div className={`text-sm ${password.length >= 8 ? 'text-green-700 dark:text-green-500' : 'text-red-700 dark:text-red-300'}`}>{translations.passwordformat2} </div>
            <div className={`text-sm ${/(?=.*[a-z])(?=.*[A-Z])/.test(password) ? 'text-green-700 dark:text-green-500' : 'text-red-700 dark:text-red-300'}`}>{translations.passwordformat5}</div>
            <div className={`text-sm ${/(?=.*\d)/.test(password) ? 'text-green-700 dark:text-green-500' : 'text-red-700 dark:text-red-300'}`}>{translations.passwordformat3}</div>
            <div className={`text-sm ${/(?=.*[@$!%*?&^_\-¤'"&(=€)])/.test(password) ? 'text-green-700 dark:text-green-500' : 'text-red-700 dark:text-red-300'}`}>{translations.passwordformat4} Eg: @$!%*?&...</div>
                  
            </ul>


            <label htmlFor="confirmPassword" className="text-sm dark:text-blue-100 font-semibold">
            {translations.confirmpassword}  </label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={onChange}
              placeholder="Confirmez votre Mot de Passe"
              className="w-full px-4 py-2 text-xl text-gray-800 bg-white  border border-sky-400 rounded transition ease-in-out"
              />
        

    




            <div className="mb-6">
  <label htmlFor="specialties" className="block dark:text-blue-100 font-medium mb-2">

  {translations.yourmedicalfield}  
 :
  </label>
  <Select
    options={availableSpecialties} // Replace with your list of available specialties
    isMulti
    value={specialties.map((s) => ({ value: s, label: s }))}
    onChange={handleSpecialtyChange}
    className="w-full"
    placeholder="Sélectionnez votre spécialités médicales"
  />
</div>


            <div className="mb-6">
              <label htmlFor="nomCabinet" className="block dark:text-blue-100 font-medium mb-2">
              {translations.cabinetcliniquehospitalname}  
              </label>
              <input
                type="text"
                id="nomCabinet"
                maxLength="200"

                value={nomCabinet}
                onChange={onChange}
                placeholder="Nom du cabinet/clinique/hopital"
                required
                className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-sky-400 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600"
              />
            </div>


            <p className="text-lg dark:text-blue-100 font-semibold">
            {translations.introduction}  

              </p>
        <textarea
          type="text"
          id="description"
          value={description}
          maxLength="1600"
          onChange={onChange}
          placeholder=" Exmple :  [ votre Nom] est un médecin expérimenté en [domaine]. 
          Diplômé de [Nom de l'établissement] et certifié en [certifications], 
          il/Elle offre [nombre d'années] d'expertise. parlant [langues], il/elle pratique à [lieu]. 
           Son engagement envers des soins personnalisés et sa passion pour la médecine en font un/une professionnel respecté.
           "
          required
          className="w-full px-4 py-2 text-xl h-40 text-gray-700 bg-white border border-sky-400 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
        />









<div className="mb-6">
  <label htmlFor="averageDuration" className="block dark:text-blue-100 font-medium mb-2">
  {translations.Dureemoyenne}  {translations.minutes} 
  </label>
  <input
    type="number"
    id="averageDuration"
    value={averageDuration}
    onChange={onChange}
    placeholder= "Duree visite moyenne (minutes)"
    required
    className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-sky-400 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600"
  />
</div>

<div className="mb-6">
  <label htmlFor="maxPatientsPerDay" className="block dark:text-blue-100 font-medium mb-2">
  {translations.MaximumPatients}   
  </label>
  <input
    type="number"
    id="maxPatientsPerDay"
    value={maxPatientsPerDay}
    onChange={onChange}
    placeholder="Nombre maximum de patients par jour"
    required
    className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-sky-400 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600"
  />
</div>

<p className="text-lg mt-6 dark:text-blue-100 font-semibold"> 
{translations.NumerodeTelephone} 
 </p>

<input
  type="number"
  id="phoneNumber"
  value={phoneNumber}
  onChange={onChange}
  placeholder="Numéro de téléphone"
  required
  className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-sky-400 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
/>

      

         

<div className="grid grid-cols-1  md:grid-cols-2 gap-4">
          <div>
            <label className="block mb-2 text-sm font-medium dark:text-blue-100 text-gray-700">
              Wilaya :
            </label>
            <Select
              options={wilayas}
              onChange={handleWilayaChange}
              className="w-full"
              isClearable
              placeholder="Sélectionner une wilaya"
              required
              noOptionsMessage={() => "Aucune option disponible"}
              menuPlacement="auto"
            />
          </div>
          <div>
            <label className="block mb-2 text-sm font-medium dark:text-blue-100 text-gray-700">
              Daira :
            </label>
            <Select
              options={dairas[selectedWilaya] || []}
              onChange={handleDairaChange}
              className="w-full"
              isClearable
              placeholder="Sélectionner une daira"
              required
              isDisabled={!selectedWilaya}
              
              noOptionsMessage={() => "Aucune option disponible"}
              menuPlacement="auto"
            />
          </div>
        </div>
  
        
        <p className="text-lg mt-6 dark:text-blue-100 font-semibold">
        {translations.Address} 
</p>
        <textarea
          type="text"
          id="address"
          value={address}
          onChange={onChange}
          placeholder="Address"
          maxLength="200"

          required
          className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-sky-400 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
        />
            <p className="text-lg dark:text-blue-100 font-semibold">
            {translations.ZipCode} 

           </p>

<input
  type="text"
  id="zipCode"
  value={zipCode}
  onChange={onChange}
  placeholder="Zip Code"
  maxLength="150"

  required
  className="w-full px-4 py-2 text-xl text-gray-700 bg-white border border-sky-400 rounded transition duration-150 ease-in-out focus:text-gray-700 focus:bg-white focus:border-slate-600 mb-6"
/>
<p className="text-sm mt-1 dark:text-blue-100 text-center text-gray-700 ">

{translations.youcandoitlater} 

</p>
<p className="text-sm mt-2  dark:text-blue-100 text-center text-gray-700 ">

{translations.mapexplanation} 

</p>

<div className="relative z-10 overflow-x-hidden mt-6 md:mt-0 md:ml-2">
  <div className="w-full h-[320px] md:h-[310px]">
    <MapContainer
      center={mapCenter} 
      zoom={4}
      scrollWheelZoom={false}
      style={{ height: "90%", width: "90%" }}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker
        position={markerPosition}
        draggable={true}
        ref={markerRef}
        eventHandlers={{
          dragend: handleMarkerDragEnd,
        }}
        icon={markerIcon}
      >
        <Popup> Your Location</Popup>
      </Marker>
    </MapContainer>
  </div>


  <div className="bg-white dark:bg-blue-200 p-2 rounded-lg shadow-lg">
  <h3 className="text-2xl font-bold mb-4">
  {translations.mapCoordinates} :</h3>
  <div className="flex flex-row gap-4">
    <div>
      <p className="text-gray-600  mb-2">Latitude:</p>
      <p className="text-1xl font-semibold text-blue-500">{markerCoordinates.latitude}</p>
    </div>
    <div>
      <p className="text-gray-600 mb-2">Longitude:</p>
      <p className="text-1xl font-semibold truncate text-blue-500">{markerCoordinates.longitude}</p>
    </div>
  </div>
</div>

</div>






<div className="container mx-auto mt-10">
            <div className="w-64 mx-auto mt-4">
                <label className="w-full flex flex-col items-center  px-4 py-6 bg-white text-blue-500 rounded-lg shadow-lg tracking-wide uppercase border border-blue-500 cursor-pointer hover:bg-blue-500 hover:text-white">
                    <BsUpload className="w-8 h-8" />
                    <span className="mt-2 text-base  leading-normal">
                    {translations.selectprofilepicture}
                  </span>
                    <input
                        type="file"
                        className="hidden"
                        ref={fileInputRef}
                        onChange={onFileChange}
                    />
                </label>
                <div className="dark:text-blue-100">
    {translations.lessthen2mb} 
            </div>
            </div>

            {image && (
                <div className="w-64 mx-auto mt-4">
                    <div
                        style={{
                            width: '160px',
                            height: '160px',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                            border: '3px solid #0074D9',
                            borderRadius: '8px',
                        }}
                    >
                        <img
                            src={image.preview}
                            alt="Uploaded"
                            className="w-full h-full object-cover rounded-md"
                        />
                          <div className="mt-2 flex justify-between">
                            <button
                                onClick={clearImage}
                                className="bg-red-500 text-white hover:bg-red-600 px-3 py-2 rounded-full absolute top-2 left-2"
                            >
                                <BsTrash />
                            </button>
                       
                        </div>
                    </div>
                </div>
            )}

        </div>
   












        <div className="mb-5 py-4">
  <label className="flex items-center">
    <input
      type="checkbox"
      className="form-checkbox border  border-sky-400 rounded focus:outline-none focus:ring-blue-500"
      checked={acceptedTerms}
      onChange={toggleAcceptedTerms}
    />
    <span className="ml-2 dark:text-blue-100">
  {translations.Conditionsutilisation1} {" "}
  <button
    className="text-blue-500 dark:text-blue-300 hover:underline"
    onClick={() => settermserviceVisible(true)}
  >
    {translations.Conditionsutilisation2}
  </button>
</span>

  </label>
</div>

<div className="flex flex-col sm:flex-row justify-between whitespace-nowrap text-sm">
  <p className="mb-2 sm:mb-6">
    <Link
      to="/sign-in"
      className="text-red-600 hover:text-red-700 transition duration-200 ease-in-out ml-1"
 >
  {translations.SigneIn}    
    </Link>
  </p>

</div>

            <button
className="bg-gradient-to-r from-blue-400 to-purple-500 hover:from-sky-400 hover:to-purple-500 text-white  dark:text-gray-100 font-semibold py-3 px-6 rounded-full shadow-md transition duration-300"
type="submit"  
              disabled={loading || isSubmitting }
              >
                {isSubmitting ? "Submitting..." && <Spinner /> : translations.ApplyforProfessionalAccount}
            </button>

            <div className="flex items-center  before:border-t before:flex-1 before:border-gray-300 after:border-t after:flex-1 after:border-gray-300">
            </div>
          </form>
          </div>

          </div>

          {istermserviceVisible && (
          <main className="fixed inset-0 flex flex-col items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-700 mb-10 z-50">
    <div  className="w-full max-w-screen-lg p-4 bg-gray-100 dark:bg-gray-700 rounded-lg shadow-lg overflow-auto z-50 ">
          <TermsAndConditions />
      
        
        
        </div>
        <div className="flex justify-end bg-blue-100 dark:bg-gray-700 mb-10">
        
        <button  className="text-sky-600 hover:text-sky-700 px-4 py-2 rounded-md border border-sky-500 hover:bg-blue-100 dark:bg-blue-100 focus:outline-none" 
        onClick={() => {
          toggleAcceptedTerms();
          settermserviceVisible(false);
        }}      >
      {translations.iaccept}  
       
        </button>
        <button
                className="text-gray-500 hover:text-gray-700 px-4 py-2 ml-4 rounded-md border border-gray-500 hover:bg-gray-100 dark:bg-gray-100 focus:outline-none"
                onClick={() => settermserviceVisible(false)}
        >
         {translations.close}  
        </button>
      </div>
 
        </main>  
        )}  
    </section>
  );
}

import React, { createContext, useContext, useState } from 'react';


const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('fr'); 
  const translations = {
   
   
   
    en: {
      Share: 'share',
      certificatofsecurity:" Security Certifications ",
      PreviousMonth: 'Previous Month',

      NextMonth:'Next Month',
// liste of specialities en:
Pharmacie: 'Pharmacy',

veterinaire: 'veterinarian',


Toxicologie: 'Toxicology',


laboratoireanalyse: 'Medical laboratory',
Chirurgiendentiste: 'Dentist',
Infirmier: 'Nurse',
Médecingénéraliste: 'General Practitioner',
Pédiatre: 'Pediatrician',
Ophtalmologue: 'Ophthalmologist',
Dermatologueetvénérologue: 'Dermatologist and Venereologist',
Masseurkinésithérapeute: 'Physiotherapist',
Opticienlunetier: 'Optician',
Pédicurepodologue: 'Chiropodist',
Sagefemme: 'Midwife',
ORL: 'ENT Specialist',
Allergologue: 'Allergist',
Chirurgienurologue: 'Urological Surgeon',
Rhumatologue: 'Rheumatologist',
Stomatologue: 'Stomatologist',
Endocrinologue: 'Endocrinologist',
Chirurgienorthopédisteettraumatologue: 'Orthopedic and Trauma Surgeon',
Diététicien: 'Dietitian',
Psychologue: 'Psychologist',
Neurologue: 'Neurologist',
Psychiatre: 'Psychiatrist',
Radiologue: 'Radiologist',
Cardiologue: 'Cardiologist',
Gastroentérologueethépatologue: 'Gastroenterologist and Hepatologist',
Acupuncteur: 'Acupuncturist',
Addictologue: 'Addictologist',
Adjointchirurgienentiste: 'Assistant Dentist',
Adjointmédecin: 'Assistant Physician',
Anatomocytopathologiste: 'Anatomic Cytopathologist',
Andrologue: 'Andrologist',
Anesthésiste: 'Anesthetist',
Angiologue: 'Angiologist',
Assistantdeservicesocial: 'Social Worker',
Audioprothésiste: 'Audiologist',
Auxiliairedepuériculture: 'Childcare Assistant',
Biologistedesagentsinfectieux: 'Infectious Disease Biologist',
Cancérologue: 'Oncologist',
Cancérologuebiologiste: 'Oncobiologist',
Cancérologuemédical: 'Medical Oncologist',
Cancérologueradiothérapeute: 'Radiation Oncologist',
Chercheurmédical: 'Medical Researcher',
Chiropracteur: 'Chiropractor',
Chirurgiencancérologue: 'Cancer Surgeon',
Chirurgiendelafaceetducou: 'Facial and Neck Surgeon',
Chirurgiendelamain: 'Hand Surgeon',
Chirurgienendocrinien: 'Endocrine Surgeon',
Chirurgiengénéral: 'General Surgeon',
Chirurgieninfantile: 'Pediatric Surgeon',
Chirurgienmaxillofacial: 'Maxillofacial Surgeon',
Chirurgienmaxillofacialetstomatologue: 'Maxillofacial Surgeon and Stomatologist',
Chirurgienoral: 'Oral Surgeon',
Chirurgienthoraciqueetcardiovasculaire: 'Thoracic and Cardiovascular Surgeon',
Chirurgienvasculaire: 'Vascular Surgeon',
Chirurgienviscéraletdigestif: 'Visceral and Digestive Surgeon',
Conseillermédicosocial: 'Medico-social Counselor',
Dermatopathologue: 'Dermatopathologist',
Échographiste: 'Sonographer',
Endocrinologuediabétologue: 'Endocrinologist and Diabetologist',
Épithésiste: 'Epithesist',
Ergothérapeute: 'Occupational Therapist',
Généticien: 'Geneticist',
Gériatre: 'Geriatrician',
Gynécologuemédical: 'Medical Gynecologist',
Gynécologuemédicaletobstétrique: 'Medical and Obstetric Gynecologist',
Gynécologueobstétricien: 'Obstetric Gynecologist',
Hématologue: 'Hematologist',
Hématologuebiologiste: 'Hematobiologist',
Homéopathe: 'Homeopath',
Immunologue: 'Immunologist',
Infectiologue: 'Infectiologist',
Infirmierenpratiqueavancée: 'Advanced Practice Nurse',
Infirmièrepuéricultrice: 'Pediatric Nurse',
Manipulateurenélectroradiologiemédicale: 'Medical Electroradiology Technician',
Médecinbiologiste: 'Biological Physician',
Médecinbuccodentaire: 'Oral Physician',
Médecindeladouleur: 'Pain Specialist',
Médecindelareproduction: 'Reproductive Physician',
Médecindelareproductionetgynécologuemédical: 'Reproductive Physician and Medical Gynecologist',
Médecindesoinspalliatifs: 'Palliative Care Physician',
Médecindusport: 'Sports Physician',
Médecindutravail: 'Occupational Physician',
Médecinmorphologueetantiâge: 'Morphologist and Anti-Aging Physician',
Médecinnucléaire: 'Nuclear Medicine Physician',
Médecinnutritionniste: 'Nutritionist Physician',
Médecinréanimateur: 'Intensive Care Physician',
Médecinspécialistedesantépublique: 'Public Health Specialist',
Médecinurgentiste: 'Emergency Physician',
Médecinvasculaire: 'Vascular Physician',
Néphrologue: 'Nephrologist',
Neurochirurgien: 'Neurosurgeon',
Neuropathologiste: 'Neuropathologist',
Neuropsychiatre: 'Neuropsychiatrist',
Oculariste: 'Ocularist',
Oncohématologue: 'Onco-hematologist',
Oncologuemédical: 'Medical Oncologist',
Oncoradiothérapeute: 'Onco-radiotherapist',
ORLChirurgiendelafaceetducou: 'ENT Specialist - Facial and Neck Surgeon',
Orthodontiste: 'Orthodontist',
Orthopédisteorthésiste: 'Orthopedist and Orthotist',
Orthophoniste: 'Speech Therapist',
Orthoprothésiste: 'Orthotist Prosthetist',
Orthoptiste: 'Orthoptist',
Pharmacien: 'Pharmacist',
Phlébologue: 'Phlebologist',
Phoniatre: 'Phoniatrist',
Pneumologue: 'Pulmonologist',
Podoorthésiste: 'Podorthotist',
Psychiatreenfant: 'Child and Adolescent Psychiatrist',
Psychomotricien: 'Psychomotor Therapist',
Psychothérapeute: 'Psychotherapist',
Radiothérapeute: 'Radiotherapist',
Sagefemmeéchographiste: 'Ultrasound Midwife',
Spécialisteenchirurgieplastiquereconstructriceetesthétique: 'Specialist in Reconstructive / Aesthetic Plastic Surgery',
Spécialisteenhémobiologietransfusion: 'Specialist in Hemobiology and Transfusion',
Spécialisteenmédecineaérospatiale: 'Specialist in Aerospace Medicine',
Spécialisteenmédecinedecatastrophe: 'Specialist in Disaster Medicine',
Spécialisteenmédecinegénérale: 'Specialist in General Medicine',
Spécialisteenmédecineinterne: 'Specialist in Internal Medicine',
Spécialisteenmédecinelégaleetexpertisesmédicales: 'Specialist in Forensic Medicine and Medical Expertise',
Spécialisteenmédecinephysiqueetderéadaptation: 'Specialist in Physical Medicine and Rehabilitation',
Spécialisteenréanimation: 'Specialist in Resuscitation',
Techniciendebiologiemédicale: 'Medical Biology Technician',























      CreateAccount: (
        <span>  Create Account </span>
        
          ),    
          youcandoitlater: (
            <span>   you can choose to skip this step! </span>
            
              ),   
          lessthen2mb: (
            <span>  profile picture need to be less then 2mb </span>
            
              ), 
          
              locationnotprovided: (
<span> The location of the office/clinique/hospital was not provided</span>                
                  ),    


         
Contact: 'Contact',
BecomingPartner2: 'You are a healthcare professional and you would like to become a Doktori partner? click here..',


Wilaya: ' Willaya',
Daira: ' Daira',

      SelectWilaya: 'Select a Willaya',
      SelectDaira: 'Select a Daira',
      Selectfield: 'Select a Medical Field',
      searchdoctor: 'Search Practitioner',
      
      Informationscomplémentaires: 'Additional information (not required)',
      checkbox: '  Check a box :',
      BecomingPartner: 'Become a partner practitioner',
      bookappointementheader:  'Book an Appointment',   
      Dashboard:  'Dashboard',
      Profilesettings:  'Profile Settings', 
      
      myappointements: 'My Appointments',
      Déconnexion: 'Disconnect',
 
      Masculin: (
        <span >      
    Male
             </span>
        
          ), 
  
  
          Feminin: (
            <span >      
  female                       </span>
            
              ), 

              
              autre: (
                <span >      
other                           </span>
                
                  ), 

        // mes RDV

      ConfirmthenewAppointment: (
        <span >      
      
    Click here to Confirm the new Appointment   </span>
        
          ),   

          RefuseandcancelAppointment: (
            <span >      
          
        Click here to Refuse/Cancel the Appointment        </span>
            
              ),  
              
              ConfirmthenewAppointmentpopup: (
                <span >  
             Confirm the new Appointment?
           </span>
                
                  ),  
                  
             
                  cancelplageshoraires: (
                    <span >  
          Refuse/Cancel the Appointment?   </span>
                    
                      ),          
      Newappointmenttime: (
        <span className="text-1xl text-center font-semibold mb-1" >      
      
    Your new appointment time is:
        </span>
        
          ),   
  

          NewappointmentDate: (
            <span className="text-1xl text-center font-semibold mb-1" >      
          
        Your new appointment date is:
            </span>
            
              ),    
              previousAppointements: (
                <span  >      
              
              Hide Previous Appointments          </span>
                
                  ),   
      
              ShowPreviousAppointments: (
                <span  >      
              
              Show Previous Appointments                      </span>
                
                  ),    
  
                  UpcomingAppointments: (
                    <span>      Upcoming Appointments
                  
                  
                    </span>
                    ),    
  
        
 Appointmentday:  ' Appointement day ',
 Age:  'Age',
      Appointment:  ' Appointment ',
      warning:  ' warning',
      Reportedtonewdate:  ' One of your appointments has been rescheduled, click here to open it ',
      appointementCanceled:  ' one of your appointement has been canceled click here to open it',
 yourappointmentwasrescheduled:  ' your appointment was rescheduled to:',
   yourappointmentwasrescheduled2: ( 
    <div
    className="bg-orange-200 hover:bg-orange-400 text-gray-700 items-center font-semibold py-2 px-4 " 
  >
  you must now <strong className="text-sky-600 dark:text-sky-500 text-md "> confirm</strong> <p>or <strong className="text-red-500 ">cancel </strong> this appointement  </p> 
  
  click here then choose an option 
  </div>
  
    ),        
  



      //prodile
     applychanges:  ' Apply Change',
     EditProfile:  ' Edit Profile Information ',
     UpdateProfilePicture:  '  Update Profile Picture  ',
     ChangeProfilePicture:  '  Change Profile Picture  ',
     MyProfile:  '  My Profile  ',

      usegooglemap:  ' use google maps and copy past your locations coordinates here ',

      introduction: (
        <span>
introduction</span>
          ), 
    
          introduction2: (
            <span>
    introduction</span>
              ), 
      Ou: (
        <span>
Or</span>
          ), 
    
      pasdecompte: (
        <span>
You don't have an account?</span>
          ), 
      
      forgotpassword: (
        <span>
                      Forgot your password
 </span>
          ), 
      
      
      
      continuegoogle: (
        <span>
    Continue with Google </span>
        ), 

      report: (
        <span>
reschudle
        </span>
        
          ), 
      
      time: (
        <span>
      
choose time      
  </span>
        
        ),
//   home :



homeDoktori: (
  <span> Welcome to <strong className="text-blue-400 dark:text-sky-400 ">DoKtori</strong>
  </span>
  
    ),      
         
   homeDoktorintro: (
      <span>  Your Trusted Platform for Online Medical Appointments
      </span>
     ),      
         
  homeReserveButton: (
    <span>     Click here to search and Book an Appointment...
  
  
       </span>
   ),   
         
   homewithdoktori: (
    <span>With <strong className="text-sky-600 dark:text-sky-500 ">DoKtori</strong>  </span>
   ),      
       
  homeadventagetitle1: (
  <span>  Book Appointments <strong className="text-sky-600 dark:text-sky-500 ">24/7</strong>  </span>
  ),   
     
   homeadventage1: (
    <span><strong className="text-sky-600 dark:text-sky-500 ">Effortlessly </strong> book appointments from the comfort of your home <strong className="text-sky-600 dark:text-sky-500 ">home</strong>, <strong className="text-sky-600 dark:text-sky-500 "> anytime</strong>,<strong className="text-sky-600 dark:text-sky-500 "> anywhere</strong> .  </span>
   ),      
  homeadventagetitle2: (
  <span>  No More Waiting  </span>
  ),   
     
   homeadventage2: (
    <span>
               <strong className="text-sky-600 dark:text-sky-500 "> Skip long queues  </strong>  and  <strong className="text-sky-600 dark:text-sky-500 "> get quick access </strong>  to the services you need. </span>
   ),      
  
  
  homeadventagetitle3: (
  <span>Save Valuable Time</span>
  ),   
     
   homeadventage3: (          <span><strong className="text-sky-600 dark:text-sky-500 ">Seamless </strong>boooking that will <strong className="text-sky-600"> save </strong>   you <strong className="text-sky-600 dark:text-sky-500 "> valuable time </strong>, so you can focus on other things.</span>
  
   ),      
  
  
  homeadventagetitle4: (
  <span>  Effortless Booking </span>
  ),   
     
   homeadventage4: (
    <span><span>Quick and <strong className="text-sky-600 dark:text-sky-500 ">hassle-free</strong> appointment booking for your convenience.</span>
  </span>
   ),      
  
homeadventagetitle5: (
  <span>   Connect with Professionals</span>
  ),   
     
   homeadventage5: (
    <span>
              <span>Access a <strong className="text-sky-600 dark:text-sky-500 ">wide range of specialized medical services</strong> and Find <strong className="text-sky-600 dark:text-sky-500 ">reputable medical professionals</strong> online.</span>
  </span>
   ),      
  
  
  homeadventagetitle6: (
  <span>   Enhance Your Health Journey </span>
  ),   
     
   homeadventage6: (          <span><strong className="text-sky-600 dark:text-sky-500 ">Experience</strong> unparalleled convenience and <strong className="text-sky-600 dark:text-sky-500 ">take control</strong> of your <strong className="text-sky-600 dark:text-sky-500 ">health journey</strong>.</span>
  
  
   ),      







  ProfessionalAccount: ' Professional Account',

      //  doctor home :

      ApplyforProfessionalAccount: 'Apply for Professional Account',

      logintomyAccount: 'login to my Account',

      BenefitsofOnlineBookingforMedicalProfessionals: 'Benefits of Online Booking for Medical Professionals',
    
    
      Homme: 'Man',
      Femme: 'Women',
    

      TimeEfficiency: 'Time Efficiency',
Timepoint1: (
            <span >Studies indicate  <strong className="text-sky-600"> 25% increase </strong> in overall  <strong className="text-sky-600">productivity</strong> with online scheduling because of a  <strong className="text-sky-600">better</strong> time management.</span>
),
Timepoint2: (
  <span >Studies show a  <strong className="text-sky-600">30% decrease</strong>   in patients   <strong className="text-sky-600"> wait times </strong>   with online booking systems.</span>
  ),  
 
  Timepoint3: (
<span>Save <strong className="text-sky-600">time</strong> for <strong className="text-sky-600">yourself</strong>  and for your  <strong className="text-sky-600">patients</strong>  with efficient scheduling.</span>
                ),  
    
 Timepoint4: (
<span><strong className="text-sky-600">Focus more on patient care</strong> and less on administrative tasks.</span>
  ),  

  Availability:'24/7 Availability',

  Availabilitypoint1: (
<span>Patient<strong className="text-sky-600">s can schedule appointments at any time</strong>, leading to a <strong className="text-sky-600">40% increase</strong> in bookings <strong className="text-sky-600">during non-office hours</strong>.</span>
                     ),   

                     Availabilitypoint2: (
<span>Ensure <strong className="text-sky-600">quick</strong> and <strong className="text-sky-600">hassle-free</strong> appointment booking at any given time</span>
                                           ), 
 Availabilitypoint3: (
<span>Approximately <strong className="text-sky-600">78%</strong> patients want an online booking options for their medical appointments.</span>
                                                                                       ), 

       
    EffectivePatientManagement:'Effective Patient Management',

    
    PatientManagement1: (
<span>   Manage schedules <strong className="text-sky-600"> effortlessly.</strong></span>
             ),        
           
          
         PatientManagement2: (
<span><strong className="text-sky-600">Simplify patient queues</strong> and <strong className="text-sky-600">enhance access to services</strong> through organized appointment scheduling.</span>
           ),        
               
      
          PatientManagement3: (
<span>Online appointment systems include features for <strong className="text-sky-600">tracking your patient appointments</strong> with you.</span>
              ),        
                


    
    CostReductionandAdministrativeEfficiency:'Cost Reduction and Administrative Efficiency',


    costreduction1: (
      <span>Practitioners using online appointment services report a <strong className="text-sky-600">reduction</strong> in <strong className="text-sky-600">administrative costs</strong> of up to <strong className="text-sky-600">30%</strong>.</span>

     ),        
           
          
             costreduction2: (
<span>Studies suggest adopting digital solutions can lead to a <strong className="text-sky-600">20% reduction in administrative workload</strong>.</span>
           ),        
               
      
           costreduction3: (
<span>Decrease <strong className="text-sky-600">phone call volumes and inquiries</strong>, allowing staff to focus on <strong className="text-sky-600">critical tasks</strong>.</span>
              ),        
         
              
           costreduction4: (
<span><strong className="text-sky-600">Optimize workflow</strong> and <strong className="text-sky-600">overall efficiency</strong>.</span>
                          ),        
                            

    PromotionofPreventiveCare: 'Promotion of Preventive Care ',
    PreventiveCare: (
<span><strong className="text-sky-600">Online appointment services</strong> can facilitate the scheduling of <strong className="text-sky-600">preventive care appointments</strong>, such as <strong className="text-sky-600">regular check-ups and screenings</strong>.</span>
                                                ),      
  PreventiveCare2: (
<span>By making <strong className="text-sky-600">preventive care</strong> more <strong className="text-sky-600">accessible</strong> and <strong className="text-sky-600">convenient</strong>, <strong className="text-sky-600">online booking platforms</strong> encourage patients to <strong className="text-sky-600">prioritize</strong> their <strong className="text-sky-600">health</strong> and <strong className="text-sky-600">well-being</strong>.</span>
                                                                                            ),  



    ExpandedPatientBasewithMobileFriendlyServices:'Expanded Patient Base with Mobile-Friendly Services',
    ExpandedPatientBase: (
<span><strong className="text-sky-600">Online booking services</strong> can attract <strong className="text-sky-600">patients</strong> from a <strong className="text-sky-600">wider geographic area</strong>, allowing for <strong className="text-sky-600">increased reach</strong> and <strong className="text-sky-600">accessibility</strong> beyond <strong className="text-sky-600">local demographics</strong>.</span>
                                                                                                  ),      
      
        ExpandedPatientBase2: (
<span><strong className="text-sky-600">Patients</strong> who may have difficulty visiting the clinic physically, such as those with <strong className="text-sky-600">mobility issues</strong> or <strong className="text-sky-600">transportation limitations</strong>, can benefit from <strong className="text-sky-600">remote appointment scheduling</strong>.</span>
 ),      
 ExpandedPatientBase3: (
<span><strong className="text-sky-600">Mobile-friendly online booking services</strong> are crucial, as <strong className="text-sky-600">45%</strong> of all appointments are made through <strong className="text-sky-600">smartnes</strong>.</span>
                                                                                                    ),      
     
                                                                                                                                                                                                
                                                                                                                                                                                                
    IncreasedPatientEngagement:'Increased Patient Engagement  ',   
    IncreasedEngagement: (
<span><strong className="text-sky-600">Online appointment services</strong> offer <strong className="text-sky-600">patients</strong> convenient access to their <strong className="text-sky-600">healthcare providers</strong>, potentially boosting <strong className="text-sky-600">patient loyalty</strong>.</span>
                                                                                                          ),      
        
     IncreasedEngagement2: (
<span>Studies suggest adopting <strong className="text-sky-600">technology</strong> can lead to a <strong className="text-sky-600">20%</strong> increase in <strong className="text-sky-600">patient satisfaction</strong>.</span>
                                                                                                          ),      
       


ApplyingSteps: (
   <span> How it  <strong className="text-sky-600">Works?</strong> </span>
  ),      
       
  ApplyingStepsheader1: (
    <span> Submission of the request
    </span>
   ),      
       
ApplyingSteps1: (
  <span>Submit your request for a DoKtori professional account.
 </span>
 ),   


underreview1: (
  <span>  under review...
 </span>
 ),   
 underreview2: (
  <span> You have submitted your request for a Doktori professional account.
  We are currently processing it.
 </span>
 ),     



 ApplyingStepsheader2: (
  <span>One of our team members will contact you.
  </span>
 ),      
 viewonmap: (
  <span>view on map
  </span>
 ),
 
ApplyingSteps2: (
<span>You will be contacted by a member of our team via the email address and/or phone number you provided.
 </span>
),   
   
ApplyingStepsheader3: (
  <span>Account activation
  </span>
 ),      
     
ApplyingSteps3: (
<span>Once your identity is verified, your professional account will be activated and you will be granted access to it.
 </span>
),   






  //  doctor display page :
      allDoctors: 'All Doctors',
      loadingDoctors: 'Loading doctors...',
      seeMoreDoctors: 'See more doctors',
      noDoctorsFoundMessage: "We apologize for this inconvenience, but it seems that the doctor(s) you are looking for is not using the DoKtori platform yet. If your doctor(s) are not on the platform yet, feel free to suggest and share it with them. The entire DoKtori team thanks you and wishes you a quick recovery.",
    

bookappointement: (
  <span> Book an Appointement
  </span>
  
    ),    
  
  
  
  // booking sucessfull :
  

  booksuccess1: (
    <span>   Your appointment for time slot 
    </span>
    
      ),      
           booksuccess2: (
    <span>  with
    </span>
    
      ),      
            booksuccess3: (
    <span>   has been .<span className="text-green-600  text-2xl font-semibold">successfully booked</span>
    </span>
    
      ),   
    
        booksuccess4: [
    <span>  <div key="booking-instructions" className="text-lg text-gray-800 mt-6 mb-2 space-y-4">
        <p>Try to  arrive <span className="font-semibold">10 to 15 minutes early, Thank you </span>.</p>
        <div className="text-lg text-gray-800 font-semibold mb-2">
          Before attending your appointment:
        </div>   
        <p>Ensure it hasn't been <span className="text-orange-500 font-semibold">rescheduled</span> or <span className="text-red-500 font-semibold">canceled</span>.</p>
        <p>To view your <span className="font-semibold">appointments</span>:</p>
        <p>Click on <span className="font-semibold">profile</span>, then <span className="font-semibold">My Appointments</span>.</p>
      </div>
    
    </span>
    
      ],   
    



// doctordisplaypage
 
retourbutton: (
  <span>          Go back
  </span>
  
  
    ), 
  
  NometPrenomduPatient: (
  <span>           Patient Name and First Name:
  
  </span>
  
    ),      
    Âgedupatient: (
      <span>  Age of patient:
      </span>
      
        ),          
    
    GroupeSanguin: (
      <span>  Blood type:
      </span>
      
        ),          
  
  Symptômes: (
  <span>symptoms experienced:
  </span>
  
    ),      
        
    notimeslot: [
      <span >  No time slots are  <span  className="text-sky-600 dark:text-sky-500 "> available. </span>
   You can   <span  className="text-sky-600 dark:text-sky-500 ">  come back later </span>, <span  > or </span>, 
   make an appointment with another   <strong  className="text-sky-600 dark:text-sky-500 ">  practitioner.</strong>
      </span>  ]
    ,      
          
  Allergies: (
  <span> Allergies:
  </span>
  
    ),      
        
  
             NumerodeTelephone: (
  <span>        phone Number:
  </span>
  
    ),      
        
  
   TypedeConsultation: (
  <span>  Type of Consultation: 
  </span>
  
    ),      
    errorappointementbooking: (
      <span className="text-red-600 font-bold">The time slot is no longer available.</span>
    ),
    
    errorappointementbooking2: (
      <span className="text-red-600 font-bold">Please choose another time slot.</span>
    ),
    
 
  
  LoadMore: (
  <span> load more
  </span>
  
    ),      
        
  
 SignIn: (
  <span>   Sign In
  </span>
  
    ),      
    
 MyAccount:  ' My Account',

    




  securityraisons: (
   <div className=" dark:text-blue-100 " >
    For <span className="text-sky-600 dark:text-sky-400 ">safety reasons</span>, in order to <span className="text-sky-600 dark:text-sky-400 ">book an appointment</span>,
    you are <span className="text-sky-600 dark:text-sky-400 ">required to sign in</span> or <span className="text-green-600">create an account</span>
    <div  >
 if you do not already have one.  </div>

  </div>

  
    ),      
        

       
  
  Réserver: (
  <span>Book this appointment
  </span>
  
    ),      
        
  confirmeRéserver: (
  <span>  Confirm the appointment with
  </span>
  
    ),      
        
  oui: (
  <span> Yes
  </span>
  
    ),      
        
  non: ( 
  <span> No
  </span>
  
    ),      
        
  
close: (
  <span>  close
  </span>
  
    ),      

  
                  
    chooseatimefirst: (
      <span className="text-1xl text-center font-semibold mb-1" >     choose a time first before choosing a date:

  </span>
  
    ),    
        
  appointementunderstood: (
  <span>  OK, I understand
  </span>
  
    ),      
        
  
        
  
      
    nodoctor: (
      <span> <p className="mb-3">
      It seems that the doctor(s) you are looking for has not yet opened a professional account on <strong className="text-sky-600 dark:text-sky-500 ">DoKtori</strong>.
    </p>
    <hr className="border-t-2 border-gray-300 my-3" />
    <p className="mb-3">
      If your <strong className="text-sky-600 dark:text-sky-500 ">doctor</strong> is not on the platform, we invite you to <strong className="text-sky-600 dark:text-sky-500 ">share</strong> it with them and <strong className="text-sky-600 dark:text-sky-500 ">suggest</strong> its <strong className="text-sky-600 dark:text-sky-500 ">use.</strong>
    </p>
    <hr className="border-t-2 border-gray-300 my-3" />
    <p className="border border-blue-400 py-2 dark:text-blue-100 text-gray-700 text-center">
      The <strong className="text-sky-600 dark:text-sky-500 ">DoKtori</strong> team <strong className="text-sky-600 dark:text-sky-500 ">thanks</strong> you and wishes you a speedy <strong className="text-sky-600 dark:text-sky-500 ">recovery</strong>.
    </p>
    
      </span>
      
        ),      
  
  
  
         
   
    
       
  
  
        // signe up page:
  


        nomprenom: (
          <span>  First and last name </span>
          ),   
             
          password: (
          <span> Password </span>
          ),   
             
          email: (
          <span>  E-mail </span>
          ),   
             
          confirmpassword : (
          <span>  Confirm Password  </span>
          ),   
             
          passwordformat: (
          <span> Your password must contain </span>
          ),
             
         passwordformat2: (
           <li>At least 8 characters</li>
                
        
          ),   
          passwordformat3: (
                        <li>At least 1 digit</li>
                    
          
            ),   
            passwordformat4: (
        
                          <li>At least 1 special character</li>
                       
            
              ),   
              passwordformat5: (
              
                            <li>At least 1 lowercase letter and 1 uppercase letter</li>
              
                ),   
              
        
         yourmedicalfield: (
          <span> Your medical field(s)
         </span>
          ),   
            
          
          cabinetcliniquehospitalname2: (
            <span>   office/clinic/hospital
          
           </span>
            ),   
          
          cabinetcliniquehospitalname: (
          <span>    Name of office/clinic/hospital
        
         </span>
          ),   
             
         MaximumPatients: (
          <span> Maximum Patients per Day </span>
          ),   
        
        
        
        Address: (
          <span> Address </span>
          ),   
             
        
        
        ZipCode: (
          <span> ZipCode </span>
          ),   
             
        
        
        mapexplanation: (
        <span>
          <strong className="text-sky-600 dark:text-sky-400">The map marker</strong> is <strong className="text-sky-600 dark:text-sky-500  ">automatically positioned</strong> at the location of the selected "<strong className="text-sky-600 dark:text-sky-500   ">willaya</strong>" and "<strong className="text-sky-600 dark:text-sky-400">daira</strong>." <strong className="text-sky-600 dark:text-sky-500  ">Simply zoom in</strong> and <strong className="text-sky-600 dark:text-sky-400">adjust</strong> its position.
        
        Be as precise as possible
        </span>
        
          ),   
             
        
        
        mapCoordinates: (
          <span>  Location Coordinates </span>
          ),   
             
        
        
        selectprofilepicture: (
          <span>   Select a profile picture </span>
          ),   
             
        
        Conditionsutilisation1: (
          <span>
        I accept the  </span>
          ),   
             
          iaccept: (
            <span>
          I accept </span>
            ),   
               
            Conditionsutilisationgoogle: (
              <span>by continuing you accept </span>
              ),   
              PolitiquedeConfidentialité: (
                <span>Policy of Confidentiality</span>
                ),     
        Conditionsutilisation2: (
          <span>conditions of use </span>
          ),   
        
          Conditionsutilisation3: (
            <span>By accessing the Doktori application or website, you "users" fully and unreservedly accept the conditions of use.</span>
            ),  
        
        alreadyhaveaccount: (
          <span>     
        Already have an account?
        </span>
          ),   
        
        


 // Dashboard :

 ChooseTime: ( 
  <span>
   Choose Time
  </span>
  
    ),  
  
  Confirm: ( 
  <span>
  Confirm
  </span>
  
    ),     
  
  SelectDate: ( 
  <span>
  Select Date
  </span>
  
    ),
    Apply: ( 
      <span>
Apply      </span>
      
        ),
   

  cancel: ( 
  <span>
  cancel
  </span>
  
    ),  
  
  
  Annullezplagehoraire: ( 
  <span>
          Are you sure you want to cancel this appointment?
  
  </span>
  
    ),  
  
  Reporteplagehoraire: ( 
  <span>
                Are you sure you want to reschedule this appointment?
  
  </span>
  
    ),  
  
  Status: ( 
  <span>
  Status
  </span>
  
    ),  
  
  GestionRDV: ( 
  <span>
  Manage medical appointments
  
  </span>
  
    ),         
  
  
  
  TimeSettings: ( 
  <span>
  Time Settings
  </span>
  
    ),  
  
  
  tempsFin: ( 
  <span>
  End
  </span>
  
    ),  
  
  
  tempsDebut: ( 
  <span>
  Start
  </span>
  
    ),  
  
  
  Dureemoyenne: ( 
  <span>
  Average visit duration
  </span>
  
    ),  
  
  
  minutes: ( 
  <span>
  (minutes)
  </span>
  
    ),  
  
  
  Patients: ( 
  <span>
  My Patients
  </span>
  
    ),  
  
  
  Submit: ( 
  <span>
  Submit
  </span>
  
    ),  
  
  confirmezplageshoraires: ( 
  <span>
  confirm the time slots?
  </span>
  
    ),  
  
week: 'Week',
day:'Day',

booked: ( 
  <span>
  Booked
  </span>
  
    ),  
  
  reported: ( 
  <span>
Rescheduled  </span>
  
    ),     
  
  canceled: ( 
  <span>
  Canceled
  </span>
  
    ),           // Add more translations for English
    },



    fr: {
  
      Share: 'partager',



// liste of specialities fr:

veterinaire: 'veterinaire',
Toxicologie: 'Toxicologie',
laboratoireanalyse: 'laboratoire d\'analyse',
Chirurgiendentiste: 'Chirurgien-dentiste',
Infirmier: 'Infirmier',
Médecingénéraliste: 'Médecin généraliste',
Pédiatre: 'Pédiatre',
Ophtalmologue: 'Ophtalmologue',
Dermatologueetvénérologue: 'Dermatologue et vénérologue',
Masseurkinésithérapeute: 'Masseur-kinésithérapeute',
Opticienlunetier: 'Opticien-lunetier',
Pédicurepodologue: 'Pédicure-podologue',
Sagefemme: 'Sage-femme',
ORL: 'ORL',
Allergologue: 'Allergologue',
Chirurgienurologue: 'Chirurgien urologue',
Rhumatologue: 'Rhumatologue',
Stomatologue: 'Stomatologue',
Endocrinologue: 'Endocrinologue',
Chirurgienorthopédisteettraumatologue: 'Chirurgien orthopédiste et traumatologue',
Diététicien: 'Diététicien',
Psychologue: 'Psychologue',
Neurologue: 'Neurologue',
Psychiatre: 'Psychiatre',
Radiologue: 'Radiologue',
Cardiologue: 'Cardiologue',
Gastroentérologueethépatologue: 'Gastro-entérologue et hépatologue',
Acupuncteur: 'Acupuncteur',
Addictologue: 'Addictologue',
Adjointchirurgienentiste: 'Adjoint chirurgien-dentiste',
Adjointmédecin: 'Adjoint médecin',
Anatomocytopathologiste: 'Anatomo-cyto-pathologiste',
Andrologue: 'Andrologue',
Anesthésiste: 'Anesthésiste',
Angiologue: 'Angiologue',
Assistantdeservicesocial: 'Assistant de service social',
Audioprothésiste: 'Audioprothésiste',
Auxiliairedepuériculture: 'Auxiliaire de puériculture',
Biologistedesagentsinfectieux: 'Biologiste des agents infectieux',
Cancérologue: 'Cancérologue',
Cancérologuebiologiste: 'Cancérologue biologiste',
Cancérologuemédical: 'Cancérologue médical',
Cancérologueradiothérapeute: 'Cancérologue radiothérapeute',
Chercheurmédical: 'Chercheur médical',
Chiropracteur: 'Chiropracteur',
Chirurgiencancérologue: 'Chirurgien cancérologue',
Chirurgiendelafaceetducou: 'Chirurgien de la face et du cou',
Chirurgiendelamain: 'Chirurgien de la main',
Chirurgienendocrinien: 'Chirurgien endocrinien',
Chirurgiengénéral: 'Chirurgien général',
Chirurgieninfantile: 'Chirurgien infantile',
Chirurgienmaxillofacial: 'Chirurgien maxillo-facial',
Chirurgienmaxillofacialetstomatologue: 'Chirurgien maxillo-facial et stomatologue',
Chirurgienoral: 'Chirurgien oral',
Chirurgienthoraciqueetcardiovasculaire: 'Chirurgien thoracique et cardio-vasculaire',
Chirurgienvasculaire: 'Chirurgien vasculaire',
Chirurgienviscéraletdigestif: 'Chirurgien viscéral et digestif',
Conseillermédicosocial: 'Conseiller médico-social',
Dermatopathologue: 'Dermatopathologue',
Échographiste: 'Échographiste',
Endocrinologuediabétologue: 'Endocrinologue diabétologue',
Épithésiste: 'Épithésiste',
Ergothérapeute: 'Ergothérapeute',
Généticien: 'Généticien',
Gériatre: 'Gériatre',
Gynécologuemédical: 'Gynécologue médical',
Gynécologuemédicaletobstétrique: 'Gynécologue médical et obstétrique',
Gynécologueobstétricien: 'Gynécologue obstétricien',
Hématologue: 'Hématologue',
Hématologuebiologiste: 'Hématologue biologiste',
Homéopathe: 'Homéopathe',
Immunologue: 'Immunologue',
Infectiologue: 'Infectiologue',
Infirmierenpratiqueavancée: 'Infirmier en pratique avancée',
Infirmièrepuéricultrice: 'Infirmière puéricultrice',
Manipulateurenélectroradiologiemédicale: 'Manipulateur en électroradiologie médicale',
Médecinbiologiste: 'Médecin biologiste',
Médecinbuccodentaire: 'Médecin bucco-dentaire',
Médecindeladouleur: 'Médecin de la douleur',
Médecindelareproduction: 'Médecin de la reproduction',
Médecindelareproductionetgynécologuemédical: 'Médecin de la reproduction et gynécologue médical',
Médecindesoinspalliatifs: 'Médecin de soins palliatifs',
Médecindusport: 'Médecin du sport',
Médecindutravail: 'Médecin du travail',
Médecinmorphologueetantiâge: 'Médecin morphologue et anti-âge',
Médecinnucléaire: 'Médecin nucléaire',
Médecinnutritionniste: 'Médecin nutritionniste',
Médecinréanimateur: 'Médecin réanimateur',
Médecinspécialistedesantépublique: 'Médecin spécialiste de santé publique',
Médecinurgentiste: 'Médecin urgentiste',
Médecinvasculaire: 'Médecin vasculaire',
Néphrologue: 'Néphrologue',
Neurochirurgien: 'Neurochirurgien',
Neuropathologiste: 'Neuro-pathologiste',
Neuropsychiatre: 'Neuro-psychiatre',
Oculariste: 'Oculariste',
Oncohématologue: 'Onco-hématologue',
Oncologuemédical: 'Oncologue médical',
Oncoradiothérapeute: 'Onco-radiothérapeute',
ORLChirurgiendelafaceetducou: 'ORL - Chirurgien de la face et du cou',
Orthodontiste: 'Orthodontiste',
Orthopédisteorthésiste: 'Orthopédiste-orthésiste',
Orthophoniste: 'Orthophoniste',
Orthoprothésiste: 'Orthoprothésiste',
Orthoptiste: 'Orthoptiste',
Pharmacien: 'Pharmacien',
Phlébologue: 'Phlébologue',
Phoniatre: 'Phoniatre',
Pneumologue: 'Pneumologue',
Podoorthésiste: 'Podo-orthésiste',
Psychiatreenfant: 'Psychiatre enfant et adolescent',
Psychomotricien: 'Psychomotricien',
Psychothérapeute: 'Psychothérapeute',
Radiothérapeute: 'Radiothérapeute',
Sagefemmeéchographiste: 'Sage-femme échographiste',
Spécialisteenchirurgieplastiquereconstructriceetesthétique: 'Spécialiste en chirurgie plastique reconstructrice et esthétique',
Spécialisteenhémobiologietransfusion: 'Spécialiste hémobiologie-transfusion',
Spécialisteenmédecineaérospatiale: 'Spécialiste médecine aérospatiale',
Spécialisteenmédecinedecatastrophe: 'Spécialiste médecine de catastrophe',
Spécialisteenmédecinegénérale: 'Spécialiste médecine générale',
Spécialisteenmédecineinterne: 'Spécialiste médecine interne',
Spécialisteenmédecinelégaleetexpertisesmédicales: 'Spécialiste médecine légale et expertises médicales',
Spécialisteenmédecinephysiqueetderéadaptation: 'Spécialiste médecine physique et réadaptation',
Spécialisteenréanimation: 'Spécialiste en réanimation',
Techniciendebiologiemédicale: 'Technicien de biologie médicale',












       viewonmap: (
        <span>voir sur map
        </span>
       ),
      youcandoitlater: (
        <span>   vous pouvez choisire d'ignorer cette étape!  </span>
        
          ), 

          lessthen2mb: (
            <span> la photo de profil doit faire moins de 2 Mo </span>
            
              ), 
              locationnotprovided: (
                <span> l'emplacement du cabinet/clinique/hôpital n'a pas été fourni </span>
                
                  ),  
           
              
      Wilaya: ' Willaya',
   Daira: ' Daira',

      SelectWilaya: 'Sélectionner une Willaya',
      SelectDaira: 'Sélectionner une Daira',
      Selectfield: 'Sélectionner un Domaine Medical ',
     searchdoctor: 'Rechercher un praticien',

      Homme: 'Homme',
      Femme: 'Femme',
    
      checkbox: 'Cochez une case :',
      Informationscomplémentaires: 'Informations supplémentaires (non obligatoires)   ',

      Apply: ( 
        <span>
Appliquer          </span>
        
          ),

         


      BecomingPartner: 'Devenir un praticien partenaire',
      bookappointementheader:  'Prendre un rendez-vous',    
      Dashboard:  'Tableau de bord',
     
// mes RDV
Profilesettings:  'Reglages Profil', 
myappointements: 'Mes Rendez-vous',
Déconnexion: 'Déconnexion',

MyAccount:  ' Mon Compte',

Masculin: (
  <span >      
Masculin
       </span>
  
    ),           
                    Feminin: (
                      <span >      
                 Feminin
                           </span>
                      
                        ), 


                        autre: (
          <span >      
autre               </span>
          
            ), 


      ConfirmthenewAppointment: (
        <span >      
     cliquez ici pour Confirmer le nouveau rendez-vous
             </span>
        
          ),   

          RefuseandcancelAppointment: (
            <span >     
          cliquez ici pour refuser/annuler le rendez-vous       </span>
            
              ),
              ConfirmthenewAppointmentpopup: (
                <span >  
              Confirmer le nouveau rendez-vous?
           </span>
                
                  ),  
                  
             
                  cancelplageshoraires: (
                    <span >  
          Refuser/Annuler le rendez-vous?   </span>
                    
                      ),
          UpcomingAppointments: (
            <span>     Rendez-vous à venir
          
          
            </span>
                ), 
  
  
  
                previousAppointements: (
                  <span >      
                
                Masquer les rendez-vous précédents              </span>
                  
                    ),  
                    ShowPreviousAppointments: (
                      <span >      
                    
                    Afficher les rendez-vous précédents
                              </span>
                      
                        ),  

              Newappointmenttime: (
                <span className="text-1xl text-center font-semibold mb-1" >      
              
             Votre Nouvelle heure de rendez-vous est :
                        </span>
                
                  ),    
               
                  NewappointmentDate: (
                    <span className="text-1xl text-center font-semibold mb-1" >      
                  
                 Votre Nouvelle date de rendez-vous est :
                            </span>
                    
                      ),  
                        

                      errorappointementbooking: (
                        <span className="text-red-600 font-bold"> Le créneau horaire n'est plus disponible.</span>
           
                 
                   ), 
            
                   errorappointementbooking2: (
                     <span className="text-red-600 font-bold"> Veuillez choisir un autre créneau</span>
              
                ), 

Appointmentday:  ' Jour d\'rendez-vous ',
Age:  'Age',
Appointment:  ' rendez-vous ',
warning:  'avertissement',
Reportedtonewdate:  '  Un de vos rendez-vous a été Reporté, cliquez ici pour l\'ouvrir ',
appointementCanceled:  'un de vos rendez-vous a été annulé cliquez ici pour l\'ouvrir',
yourappointmentwasrescheduled:  ' votre rendez-vous a été reporté au:',

yourappointmentwasrescheduled2: ( 
<div
className="bg-orange-200 survol :bg-orange-400 text-gray-700 items-center font-semibold py-2 px-4 "
>
vous devez maintenant <strong className="text-sky-600 dark:text-sky-500  text-md "> confirmer</strong> <p>ou <strong className="text-red-500 ">annuler </strong> ce rendez-vous </p>

cliquez ici, puis choisissez une option
</div>

),        



    chooseatimefirst: (
      <span className="text-1xl text-center font-semibold mb-1" >     Choisissez l'horaire en premier puis choisir la date:
    
      </span>
      
        ),    

      // profile
      applychanges:  ' Appliquer le changement ',
      EditProfile:  ' Modifier informations profil',
      UpdateProfilePicture:  ' Mettre à jour photo de profil  ',
      ChangeProfilePicture:  ' Modifier  photo de profil    ',
      MyProfile:  '  Mon profil  ',
      usegooglemap:  '  utilisez Google Maps et copiez collez vos coordonnées  ici',

      introduction: (
        <span>
introduction</span>
          ), 
          introduction2: (
            <span>
    introduction</span>
              ), 
      Ou: (
        <span>
Ou</span>
          ), 

      pasdecompte: (
        <span>
           Vous n'ave pas de compte ?
</span>
          ), 
      
      forgotpassword: (
        <span>
                      Mot de passe oublié 
 </span>
          ), 
      
      
      
      continuegoogle: (
        <span>
     Continuer avec Google </span>
        ), 
      report: (
        <span>
reporté
        </span>
        
          ), 
      
      time: (
        <span>
      
      choisir l'horaire
        </span>
        
          ), 
  // signe up page:



  nomprenom: (
    <span>  Nom et Prenom  </span>
    ),   
       
    password: (
    <span>  Mots de Pass </span>
    ),   
       
    email: (
    <span>  E-mail </span>
    ),   
       
    confirmpassword : (
    <span>  Confirmez le mot de passe</span>
    ),   
       
    passwordformat: (
    <span>  Votre mot de passe doit contenir </span>
    ),   
    passwordformat2: (
    <li>Au moins 8 caractères</li>
      ),   
     
   passwordformat3: (
             <li>Au moins 1 chiffre</li>
    ),   
    passwordformat4: (
          
         <li>Au moins 1 caractère spécial</li>
      ),   
     
   passwordformat5: (
    <span>      
                <li>Au moins 1 lettre majuscule et 1 lettre minuscule  </li>
   </span>
    ),   
  
   yourmedicalfield: (
    <span> Votre/vos domaine médicales
   </span>
    ),   
       
   
       
    cabinetcliniquehospitalname2: (
      <span>   cabinet/clinique/hopital
     </span>
      ),   
       
    cabinetcliniquehospitalname: (
    <span>     Nom du cabinet/clinique/hopital
   </span>
    ),   
       
   MaximumPatients: (
    <span> Nombre maximum de patients par jour
   </span>
    ),   
  
  
  
  Address: (
    <span> Adresse
  </span>
    ),   
       
  
  
  ZipCode: (
    <span> Code postal
  </span>
    ),   
       
  
    mapexplanation: (
      <span>   
      <strong className="text-sky-600 dark:text-sky-500 ">Le marqueur cartographique</strong> est <strong className="text-sky-600 dark:text-sky-500 ">positionné automatiquement</strong> à l'emplacement de la "<strong className="  text-sky-600 dark:text-sky-500 ">Willaya</strong>" et "<strong className="text-sky-600 dark:text-sky-500 ">Daira</strong>"choisi. <strong className="text-sky-600 dark:text-sky-500 ">Zoomez </strong> et <strong className="text-sky-600 dark:text-sky-500 ">ajustez</strong> sa position. Soyez aussi precis que possible
     </span>
      ),   
         
       
  
  
  mapCoordinates: (
    <span>  Coordonnées de localisation</span>
    ),   
       
  
  
  selectprofilepicture: (
    <span>  Sélectionnez une photo de profil </span>
    ),   
    Conditionsutilisationgoogle: (
      <span>en continuant vous acceptez les  </span>
      ),     
  
    Conditionsutilisation1: (
    <span>J'accepte les </span>
    ),   
       
    iaccept: (
      <span>
  J'accepte </span>
      ), 
      PolitiquedeConfidentialité: (
        <span>Politique de Confidentialité</span>
        ),     
  Conditionsutilisation2: (
    <span>Conditions d'utilisation </span>
    ),   
  
    Conditionsutilisation3: (
      <span>En accédant à l'application ou au site web Doktori, vous "utilisateurs" acceptez pleinement et sans réserve les présentes conditions d'utilisation.</span>
      ),  
  
  alreadyhaveaccount: (
    <span>     Vous avez déjà un compte? </span>
    ),   
  
  



  //   home :



  homeDoktori: (
    <span> Bienvenue sur <strong className="text-blue-400 dark:text-sky-400 ">DoKtori</strong>
    
    </span>
    
      ),      
           
     homeDoktorintro: (
        <span>  Votre plateforme de confiance pour les rendez-vous médicaux en ligne
    
        </span>
       ),      
           
    homeReserveButton: (
      <span> Cliquez ici pour rechercher et prendre un rendez-vous...
         </span>
     ),   
           
     homewithdoktori: (
      <span>Avec <strong className="text-sky-600 dark:text-sky-500  ">DoKtori</strong> </span>
     ),      
         
    homeadventagetitle1: (
    <span>  Prendre des rendez-vous <strong className="text-sky-600 dark:text-sky-500 ">24/7</strong></span>
    ),   
       
     homeadventage1: (
      <span>Prenez rendez-vous <strong className="text-sky-600 dark:text-sky-500 ">à tout moment</strong>   
    , que ce soit depuis le confort de votre <strong class="text-sky-600 dark:text-sky-500">domicile </strong> ou <strong class="text-sky-600 dark:text-sky-500">n'importe où ailleurs</strong> <strong class="text-sky-600 dark:text-sky-500">et cela avec facilité</strong>.
</span>
     ),      
    homeadventagetitle2: (
    <span> Plus besoin d'attendre
      </span>
    ),   
       
     homeadventage2: (
      <span> <strong className="text-sky-600 dark:text-sky-500 "> Évitez les longues files d'attente</strong> et <strong className="text-sky-600 dark:text-sky-500 "> accédez rapidement</strong> aux services dont vous avez besoin.</span>
     ),      
    
    
    homeadventagetitle3: (
    <span> Économisez un temps précieux
     </span>
    ),   
       
     homeadventage3: (
      <span><span><strong className="text-sky-600 dark:text-sky-500 ">Réservation rapide </strong> qui vous fera  <strong className="text-sky-600 dark:text-sky-500">économiser</strong> votre <strong className="text-sky-600 dark:text-sky-500 "> temps</strong>, afin que vous puissiez vous concentrer sur autre chose..</span>
    </span>
     ),      
    
    
    homeadventagetitle4: (
    <span> Réservation sans effort </span>
    ),   
       
     homeadventage4: (
      <span><span>Réservation fluide et <strong className="text-sky-600 dark:text-sky-500 ">sans tracas</strong> pour votre commodité.</span>
    </span>
     ),      
    

     homeadventagetitle5: (
      <span> Connectez-vous avec des professionnels
       </span>
      ),   
         
       homeadventage5: (
        <span><span>Accédez à une <strong className="text-sky-600 dark:text-sky-500 ">large gamme de services médicaux </strong> et trouvez des <strong className="text-sky-600 dark:text-sky-500 ">professionnels médicaux réputés</strong> en ligne.</span>
      
      </span>
       ),      
      
      
      homeadventagetitle6: (
      <span> Améliorez votre parcours santé </span>
      ),   
         
       homeadventage6: (
        <span><span><strong className="text-sky-600 dark:text-sky-500 ">Expérimentez</strong> une commodité sans égal et <strong className="text-sky-600 dark:text-sky-500 ">prenez le contrôle</strong> de votre parcours 
      santé.</span>
      
      </span>
       ),      
      



ProfessionalAccount:  '  Compte Pro',

  //  doctor home :

  ApplyforProfessionalAccount:  ' Ouvrire un compte pro',

  logintomyAccount: 'Se connecter à mon compte',

  BenefitsofOnlineBookingforMedicalProfessionals: 'Bénéfices des réservations en ligne pour les professionnels de la santé',
 
  TimeEfficiency: 'Meilleure gestion de votre temps',
  Timepoint1: (
    <span>Les études indiquent une <strong className="text-sky-600"> augmentation de 25 %</strong> de la <strong className="text-sky-600">productivité</strong> grace au  <strong className="text-sky-600">  RDV</strong> en ligne en raison d'une <strong className="text-sky-600">meilleure gestion</strong> du temps.</span>
    ),
    Timepoint2: (
<span>Les études montrent une  <strong className="text-sky-600"> réduction de 30%</strong> des  <strong className="text-sky-600"> temps d'attente</strong>  des patients avec les systèmes de réservation en ligne.</span>
      ),  

      Timepoint3: (
<span>Économisez <strong className="text-sky-600">votre temps</strong> et <strong className="text-sky-600">celui de vos patients</strong> avec une planification efficace de vos RDV.</span>
            ),  

  
            Timepoint4: (
<span><strong className="text-sky-600">Concentrez-vous davantage </strong> sur les soins de vos patients et moins sur les tâches administratives.</span>
                ),  
                        
                
               Availability:'Disponibilité 24/7',

               Availabilitypoint1: (
<span>Les patients peuvent prendre RDV à tout moment, ce qui entraîne une <strong className="text-sky-600">augmentation de 40%</strong> des réservations <strong className="text-sky-600">en dehors des heures de bureau</strong>.</span>
                 ),        
                 Availabilitypoint2: (
<span>Assurez une prise de rendez-vous <strong className="text-sky-600">rapide</strong> et <strong className="text-sky-600">sans tracas</strong> à tout moment</span>
                                   ),   
              Availabilitypoint3: (
<span>Environ <strong className="text-sky-600">78%</strong> des patients souhaitent avoir des options de réservation en ligne pour leurs rendez-vous médicaux.</span>
     
     ),                      
            
     
    EffectivePatientManagement:'Gestion efficace des patients    ',
    
    PatientManagement1: (
<span>Gérez les RDV avec <strong className="text-sky-600">facilité</strong>  et <strong className="text-sky-600">sans effort.</strong></span>
       ),        
     
    
   PatientManagement2: (
<span><strong className="text-sky-600">Simplifiez les files d'attente des patients</strong> et <strong className="text-sky-600">améliorez l'accès aux services</strong> grâce à une planification des rendez-vous organisée.</span>
     ),        
         

    PatientManagement3: (
<span> fonctionnalités pour <strong className="text-sky-600">suivre les rendez-vous de vos patients avec vous </strong>.</span>
        ),        
          

      
    CostReductionandAdministrativeEfficiency:'efficacité administrative et réduction des coûts ',


   costreduction1: (
    <span>

    Les praticiens utilisant des services de prise de rendez-vous en ligne rapportent une <strong className="text-sky-600">réduction des coûts administratifs</strong> pouvant atteindre <strong className="text-sky-600">30%</strong>.
</span>
                     ),        
           
          
             costreduction2: (
<span>Les études suggèrent qu'adopter des solutions numériques peut entraîner une réduction de <strong className="text-sky-600">20%</strong> de la charge de travail administrative.</span>
           ),        
               
      
           costreduction3: (
<span>Réduisez les <strong className="text-sky-600">volumes d'appels téléphoniques </strong>, permettant au personnel de se concentrer sur les <strong className="text-sky-600">tâches critiques</strong>.</span>
              ),        
                
              costreduction4: (
                <span><strong className="text-sky-600">Optimisez le flux de travail</strong> et <strong className="text-sky-600">l'efficacité globale</strong>.</span>
                                          ),        
                            
                                          
    PromotionofPreventiveCare:'Promotion des soins préventifs    ',
    PreventiveCare: (
<span>Les services de rendez-vous en ligne peuvent faciliter la planification des <strong className="text-sky-600">rendez-vous de soins préventifs</strong>, tels que les <strong className="text-sky-600">examens médicaux réguliers et les dépistages</strong>.</span>
                                                ),      
  PreventiveCare2: (
<span>En rendant les soins préventifs plus <strong className="text-sky-600">accessibles</strong> et <strong className="text-sky-600">pratiques</strong>, les plateformes de réservation en ligne encouragent les patients à <strong className="text-sky-600">prioriser</strong> leur <strong className="text-sky-600">santé</strong> et leur <strong className="text-sky-600">bien-être</strong>.</span>
                                                                                            ),  


    ExpandedPatientBasewithMobileFriendlyServices:'Expansion de la clientèle avec des services adaptés aux mobiles ',
    ExpandedPatientBase: (
<span>Les services de réservation en ligne peuvent attirer les <strong className="text-sky-600">patients</strong> d'une <strong className="text-sky-600">zone géographique plus étendue</strong>, permettant une <strong className="text-sky-600">portée accrue</strong> et une <strong className="text-sky-600">accessibilité</strong> au-delà des <strong className="text-sky-600">démographies locales</strong>.</span>
                                                                                                  ),      
      
        ExpandedPatientBase2: (
<span>Les <strong className="text-sky-600">patients</strong> qui pourraient avoir des difficultés à se rendreà la clinique, tels que ceux ayant des <strong className="text-sky-600">problèmes de mobilité</strong> ou des <strong className="text-sky-600">limitations de transport</strong>, peuvent bénéficier de la <strong className="text-sky-600"> prise des rendez-vous à distance</strong>.</span>
 ),      
 ExpandedPatientBase3: (
<span>Les services de réservation en ligne adaptés aux mobiles sont essentiels, car <strong className="text-sky-600">45%</strong> de tous les rendez-vous sont pris via des <strong className="text-sky-600">smartphones</strong>.</span>
       ), 




    IncreasedPatientEngagement:'Augmentation de l\'engagement et satisfaction des patients  ',
    IncreasedEngagement: (
<span>Les <strong className="text-sky-600">services de prise de rendez-vous en ligne</strong> offrent aux <strong className="text-sky-600">patients</strong> un accès pratique à leurs <strong className="text-sky-600">prestataires de soins </strong>, ce qui peut renforcer <strong className="text-sky-600">leur fidélité</strong>.</span>
                                                                                                                ),      
              
           IncreasedEngagement2: (
<span>Des études suggèrent que l'adoption de ce genre de <strong className="text-sky-600">technologie</strong> peut entraîner une augmentation de  <strong className="text-sky-600">20 %</strong> de la <strong className="text-sky-600">satisfaction des patients</strong>.</span>
                                                                                                                ),      
 
ApplyingSteps: (
  <span>Comment cela <span className="text-sky-600">fonctionne</span>?</span>
  
    ),      
         
    ApplyingStepsheader1: (
      <span> Soumission de la demande
      </span>
     ),      
         
  ApplyingSteps1: (
    <span>  soumetre votre demande pour un compte professionnel DoKtori.
       </span>
   ),   
   underreview1: (
    <span>  en cours de vérification...
   </span>
   ),   
   underreview2: (
    <span> Vous avez soumis votre demande pour un compte professionnel Doktori.
    Nous la traitons actuellement.
   </span>
   ),     
   ApplyingStepsheader2: (
    <span>Attente de contact par un membre de notre équipe </span>
   ),      
       
  ApplyingSteps2: (
  <span>   Vous serez contacté(e) par un membre de notre équipe via l'adresse
            email ou le numéro de téléphone que vous avez fourni. </span>
  ),   
     
  ApplyingStepsheader3: (
    <span> Activation du compte  </span>
   ),      
       
  ApplyingSteps3: (
  <span>  Une fois votre identite vérifié, votre compte pro  sera activé et vous y aurez accès.   
   </span>
  ),               
      
      
                
      allDoctors: 'Tous les médecins',
      loadingDoctors: 'Chargement des médecins...',
      seeMoreDoctors: 'Voir plus de médecins',
      noDoctorsFoundMessage: "Nous nous excusons pour cet inconvénient, mais il semble que le ou les médecins que vous recherchez n'utilisent pas encore la plateforme DoKtori. Si votre médecin n'est pas encore sur la plateforme, n'hésitez pas à lui suggérer notre application. Nous serions ravis de les accueillir. Toute l'équipe DoKtori vous remercie et vous souhaite un bon rétablissement.",

    // Add more translations for French
    certificatofsecurity:" Certifications de sécurité ",


// doctordisplaypage

bookappointement: (
  <span> Prendre rendez-vous
  </span>
  
    ),    
  
  
  
  booksuccess1: (
  <span>  Votre rendez-vous pour la plage horaire 
  </span>
  
    ),      
         booksuccess2: (
  <span>avec
  </span>
  
    ),      
          booksuccess3: (
  <span> a été <span className="text-green-600 text-2xl font-semibold"> réservé avec succès</span>
  </span>
    ),   
  
      booksuccess4: [
  <span>         <div className="text-lg text-gray-800 mt-6 mb-2 space-y-4">
              <p>Merci d'arriver <span className="font-semibold">10 à 15 minutes en avance</span>.</p>
              <div className="text-lg text-gray-800 font-semibold mb-2">
                Avant d'aller à votre rendez-vous:
              </div>   
              <p>Assurez-vous qu'il n'a pas été <span className="text-orange-500 font-semibold">reporté</span> ou <span className="text-red-500 font-semibold">annulé.</span></p>
              <p>Pour voir vos <span className="font-semibold">rendez-vous:</span>,</p>
              <p>cliquez sur <span className="font-semibold">profil</span>, puis sur <span className="font-semibold">      Mes Rendez-vous
</span>.</p>
            </div>
  
  </span>
  
    ],   
   
  retourbutton: (
  <span>            retour
  
  </span>
  
  
    ), 
  
  NometPrenomduPatient: (
  <span>             Nom et Prenom du Patient:
  
  </span>
  
    ),      
  
  Symptômes: (
  <span> 
  Symptômes ressentis :
  </span>
  
    ),      
        
  
  Allergies: (
  <span> Allergies: 
  </span>
  
    ),      
        
  
             NumerodeTelephone: (
  <span>            Numero de Telephone :
  </span>
  
    ),      
        
  
   TypedeConsultation: (
  <span>   Type de Consultation:  
  </span>
  
    ),      
    Âgedupatient: (
      <span>   Âge du patient:
      </span>
      
        ),          
    
    GroupeSanguin: (
      <span>  Groupe Sanguin:
      </span>
      
        ),          
  
  
  notimeslot: (
  <span>
 Aucun créneau horaire   <span className="text-sky-600 dark:text-sky-400 "> disponible.</span> Vous pouvez <span className="text-sky-600 dark:text-sky-400 ">revenir plus tard</span>
 <span>  ou prendre rendez-vous avec un  </span> <span className="text-sky-600 dark:text-sky-400 "> autre praticien.</span>
  </span>
  
  
    ),      
        
  
  
  LoadMore: (
  <span>  voir plus d'option
  </span>
  
    ),      
        


    SignIn: (
      <span>  Se connecter
      </span>
      
        ),      
            
      
      
      CreateAccount: (
      <span>  Créer un compte
      </span>
      
        ),      
            
      
      
      
      securityraisons: (
          <div className=" dark:text-blue-100 " >
       Pour des <span className="text-sky-600 dark:text-sky-400 ">raisons de sécurité</span>, afin de <span className="text-sky-600 dark:text-sky-400 ">prendre rendez-vous</span>,
        vous devez <span className="text-sky-600 dark:text-sky-400 ">vous connecter</span><span  > ou </span>,  <span className="text-green-600">créer un compte</span>
        <div >
   si vous n'en avez pas déjà un. </div>

      </div>
      
      
        ),      
      
    
      Réserver: (
      <span>      Réserver ce rendez-vous
      
      </span>
      
        ),      
            
      
      
      confirmeRéserver: (
      <span>    Confirmez le Rendez-vous avec
      </span>
      
        ),      
            
      oui: (
      <span>  Oui
      </span>
      
        ),      
            
      non: (
      <span>  Non
      </span>
      
        ),      
            

        close: (
          <span>  fermer
          </span>
          
            ),      
                
          appointementunderstood: (
          <span>     OK, J'ai compris
          </span>
          
            ),      
                
      
            nodoctor: (
              <span>    <p className="mb-3 ">

              Il semble que le ou les médecins que vous recherchez n'ont pour l'instant pas ouvert de compte professionnelle  <strong className="text-sky-600 dark:text-sky-500 ">DoKtori</strong>.
             </p>
             <hr className="border-t-2 border-gray-300 my-3" />
             <p className="mb-3">
             Si votre  <strong className="text-sky-600 dark:text-sky-500 ">médecin</strong>  n'est pas sur la plateforme, nous vous invitons à la <strong className="text-sky-600 dark:text-sky-500 ">partager</strong>  avec eux et à leur <strong className="text-sky-600 dark:text-sky-500 "> suggérer </strong>  son  <strong className="text-sky-600 dark:text-sky-500 "> utilisation. </strong> 
             </p>
             <hr className="border-t-2 border-gray-300 my-3" />
             <p  className="border dark:text-blue-100 border-blue-400 py-2 text-gray-700 text-center "   >
               L'équipe <strong className="text-sky-600 dark:text-sky-500 ">DoKtori</strong> vous   <strong className="text-sky-600 dark:text-sky-500 ">remercie</strong> et vous souhaite un bon <strong className="text-sky-600 dark:text-sky-500 ">rétablissement</strong> 
             </p>
              </span>
              
                ),      


                Contact: 'Contact',
BecomingPartner2: 'Vous êtes un professionnel de la santé et vous souhaitez devenir partenaire Doktori ? cliquer ici...',


    // Dashboard :
week: 'Semain',
day: 'Jour',
booked: ( 
  <span>
  Réservé
  </span>
  
    ),  
 
    
    ChooseTime: ( 
      <span>
      Choisissez l'heure
      </span>
      
        ),  
      
      Confirm: ( 
      <span>
      Confirmer
      </span>
      
        ),     
      
      SelectDate: ( 
      <span>
      Sélectionner une date
      
      </span>
      
        ),
      
      
      cancel: ( 
      <span>
      Annulé
      </span>
      
        ),  
      
      
      Annullezplagehoraire: ( 
      <span>
                Etes-vous sûr de vouloir annuler ce rendez-vous ?
      </span>
      
        ),  
      
      Reporteplagehoraire: ( 
      <span>
                  Êtes-vous sûr de vouloir reporter ce rendez-vous ?
      
      
      </span>
      
        ),  
      
      Status: ( 
      <span>
      Statu
      </span>
      
        ),  
      
      GestionRDV: ( 
      <span>
      Gestion des rendez-vous médicaux
      
      </span>
      
        ),         
      
      
      
      TimeSettings: ( 
      <span>
      Reglages Temps
      </span>
      
        ),  
      
      
      tempsFin: ( 
      <span>
      Fin
      </span>
      
        ),  
      
      
      tempsDebut: ( 
      <span>
      Debut
      </span>
      
        ),  
      
      
      Dureemoyenne: ( 
      <span>
      Duree visite moyenne
      </span>
      
        ),  
      
      
      minutes: ( 
      <span>
      (minutes)
      </span>
      
        ),  
      
      
      Patients: ( 
      <span>
      Mes Patients
      </span>
      
        ),  
      
      
      Submit: ( 
      <span>
      Soumettre
      </span>
      
        ),  
      
      confirmezplageshoraires: ( 
      <span>
      confirmez les plages horaires ?
      </span>
      
        ),  
      

        NextMonth: 'Mois prochain',
        PreviousMonth: 'Mois précédent',
  reported: ( 
  <span>
Reprogrammer  </span>
  
    ),     
  
  canceled: ( 
  <span>
  Annulé
  </span>
  
    ),        
    },
  
   

    ar: {
      NextMonth: 'الشهر المقبل',
      PreviousMonth: 'الشهر الماضي',

      Share: 'مشاركة',
errorappointementbooking: (
  <span className="text-red-600 font-bold">الفترة الزمنية غير متوفرة.</span>
),

errorappointementbooking2: (
  <span className="text-red-600 font-bold">يرجى اختيار فترة زمنية أخرى.</span>
),

      usegooglemap: ' google maps استخدم وانسخ إحداثيات مواقعك هنا',
      
// liste of specialities ar:
veterinaire: 'طبيب بيطري',
Toxicologie: 'علم السموم',
laboratoireanalyse: 'مختبر التحليل',
Chirurgiendentiste: 'طبيب الأسنان',
Infirmier: 'ممرض',
Médecingénéraliste: 'طبيب عام',
Pédiatre: 'طبيب الأطفال',
Ophtalmologue: 'طبيب العيون',
Dermatologueetvénérologue: 'طبيب الجلد والتناسلية',
Masseurkinésithérapeute: 'معالج طبيعي',
Opticienlunetier: 'اختصاصي بصريات',
Pédicurepodologue: 'اختصاصي العناية بالأقدام',
Sagefemme: 'قابلة',
ORL: 'أخصائي الأنف والأذن والحنجرة',
Allergologue: 'أخصائي حساسية',
Chirurgienurologue: 'جراح المسالك البولية',
Rhumatologue: 'أخصائي أمراض الروماتيزم',
Stomatologue: 'طبيب الفم',
Endocrinologue: 'أخصائي الغدد الصماء',
Chirurgienorthopédisteettraumatologue: 'جراح العظام والصدمات',
Diététicien: 'اختصاصي تغذية',
Psychologue: 'عالم نفسي',
Neurologue: 'أخصائي أمراض الأعصاب',
Psychiatre: 'طبيب نفسي',
Radiologue: 'أخصائي أشعة',
Cardiologue: 'أخصائي أمراض القلب',
Gastroentérologueethépatologue: 'أخصائي أمراض الجهاز الهضمي والكبد',
Acupuncteur: 'اختصاصي الوخز بالإبر',
Addictologue: 'أخصائي علاج الإدمان',
Adjointchirurgienentiste: 'مساعد طبيب الأسنان',
Adjointmédecin: 'مساعد طبيب',
Anatomocytopathologiste: 'أخصائي علم أمراض الأنسجة والخلايا',
Andrologue: 'أخصائي أمراض الذكورة',
Anesthésiste: 'طبيب تخدير',
Angiologue: 'أخصائي علم الأوعية',
Assistantdeservicesocial: 'أخصائي اجتماعي',
Audioprothésiste: 'أخصائي السمعيات',
Auxiliairedepuériculture: 'مساعد رعاية الأطفال',
Biologistedesagentsinfectieux: 'أخصائي علم الأحياء المعدية',
Cancérologue: 'أخصائي أورام',
Cancérologuebiologiste: 'أخصائي بيولوجيا الأورام',
Cancérologuemédical: 'أخصائي أورام طبية',
Cancérologueradiothérapeute: 'أخصائي علاج الأورام بالإشعاع',
Chercheurmédical: 'باحث طبي',
Chiropracteur: 'معالج تقويم العمود الفقري',
Chirurgiencancérologue: 'جراح الأورام',
Chirurgiendelafaceetducou: 'جراح الوجه والرقبة',
Chirurgiendelamain: 'جراح اليد',
Chirurgienendocrinien: 'جراح الغدد الصماء',
Chirurgiengénéral: 'جراح عام',
Chirurgieninfantile: 'جراح الأطفال',
Chirurgienmaxillofacial: 'جراح الفم والفكين',
Chirurgienmaxillofacialetstomatologue: 'جراح الفم والفكين وطبيب الفم',
Chirurgienoral: 'جراح الفم',
Chirurgienthoraciqueetcardiovasculaire: 'جراح الصدر والقلب والأوعية الدموية',
Chirurgienvasculaire: 'جراح الأوعية الدموية',
Chirurgienviscéraletdigestif: 'جراح الأحشاء والجهاز الهضمي',
Conseillermédicosocial: 'مستشار طبي اجتماعي',
Dermatopathologue: 'أخصائي علم الأمراض الجلدية',
Échographiste: 'أخصائي الموجات فوق الصوتية',
Endocrinologuediabétologue: 'أخصائي الغدد الصماء والسكري',
Épithésiste: 'اختصاصي الأطراف الصناعية',
Ergothérapeute: 'معالج مهني',
Généticien: 'اختصاصي علم الوراثة',
Gériatre: 'أخصائي أمراض الشيخوخة',
Gynécologuemédical: 'أخصائي أمراض النساء',
Gynécologuemédicaletobstétrique: 'أخصائي أمراض النساء والتوليد',
Gynécologueobstétricien: 'طبيب النساء والتوليد',
Hématologue: 'أخصائي أمراض الدم',
Hématologuebiologiste: 'أخصائي بيولوجيا الدم',
Homéopathe: 'اختصاصي الطب التجانسي',
Immunologue: 'أخصائي علم المناعة',
Infectiologue: 'أخصائي الأمراض المعدية',
Infirmierenpratiqueavancée: 'ممرض ممارس متقدم',
Infirmièrepuéricultrice: 'ممرضة رعاية الأطفال',
Manipulateurenélectroradiologiemédicale: 'فني الأشعة الطبية',
Médecinbiologiste: 'طبيب بيولوجي',
Médecinbuccodentaire: 'طبيب الفم والأسنان',
Médecindeladouleur: 'أخصائي علاج الألم',
Médecindelareproduction: 'طبيب التناسلية',
Médecindelareproductionetgynécologuemédical: 'طبيب التناسلية وأخصائي أمراض النساء',
Médecindesoinspalliatifs: 'طبيب رعاية تلطيفية',
Médecindusport: 'طبيب رياضي',
Médecindutravail: 'طبيب الشغل',
Médecinmorphologueetantiâge: 'طبيب المورفولوجيا ومكافحة الشيخوخة',
Médecinnucléaire: 'طبيب الطب النووي',
Médecinnutritionniste: 'اختصاصي التغذية',
Médecinréanimateur: 'طبيب إنعاش',
Médecinspécialistedesantépublique: 'اختصاصي الصحة العامة',
Médecinurgentiste: 'طبيب طوارئ',
Médecinvasculaire: 'طبيب الأوعية الدموية',
Néphrologue: 'أخصائي أمراض الكلى',
Neurochirurgien: 'جراح الأعصاب',
Neuropathologiste: 'أخصائي علم الأمراض العصبية',
Neuropsychiatre: 'طبيب الأمراض النفسية العصبية',
Oculariste: 'اختصاصي العيون',
Oncohématologue: 'أخصائي الأورام الدموية',
Oncologuemédical: 'أخصائي الأورام الطبية',
Oncoradiothérapeute: 'أخصائي علاج الأورام بالإشعاع',
ORLChirurgiendelafaceetducou: 'أخصائي الأنف والأذن والحنجرة - جراح الوجه والرقبة',
Orthodontiste: 'اختصاصي تقويم الأسنان',
Orthopédisteorthésiste: 'اختصاصي تقويم العظام والأطراف',
Orthophoniste: 'اختصاصي النطق',
Orthoprothésiste: 'اختصاصي الأطراف الصناعية',
Orthoptiste: 'اختصاصي تقويم النظر',
Pharmacien: 'صيدلي',
Phlébologue: 'أخصائي علم الأوردة',
Phoniatre: 'أخصائي علاج النطق',
Pneumologue: 'أخصائي الأمراض الرئوية',
Podoorthésiste: 'اختصاصي تقويم الأقدام',
Psychiatreenfant: 'طبيب نفسي للأطفال والمراهقين',
Psychomotricien: 'اختصاصي العلاج الحركي النفسي',
Psychothérapeute: 'اختصاصي العلاج النفسي',
Radiothérapeute: 'أخصائي العلاج بالإشعاع',
Sagefemmeéchographiste: 'قابلة متخصصة في الموجات فوق الصوتية',
Spécialisteenchirurgieplastiquereconstructriceetesthétique: 'أخصائي في الجراحة التجميلية والترميمية',
Spécialisteenhémobiologietransfusion: 'أخصائي في علم الأحياء الدموية ونقل الدم',
Spécialisteenmédecineaérospatiale: 'أخصائي في طب الطيران',
Spécialisteenmédecinedecatastrophe: 'أخصائي في طب الكوارث',
Spécialisteenmédecinegénérale: 'أخصائي في الطب العام',
Spécialisteenmédecineinterne: 'أخصائي في الطب الباطني',
Spécialisteenmédecinelégaleetexpertisesmédicales: 'أخصائي في الطب الشرعي والخبرات الطبية',
Spécialisteenmédecinephysiqueetderéadaptation: 'أخصائي في الطب الطبيعي وإعادة التأهيل',
Spécialisteenréanimation: 'أخصائي الإنعاش',
Techniciendebiologiemédicale: 'فني بيولوجيا طبية',







      viewonmap: (
        <span>الرؤية على الخريطة
        </span>
       ), 
      locationnotprovided: (
        <span> لم يتم توفير موقع المكتب/العيادة/المستشفى</span>
                            
                              ),    
                  youcandoitlater: (
                    <span>    !يمكنك اختيار تخطي هذه الخطوة
                    </span>
                    
                      ),      
                      lessthen2mb: (
                        <span> يجب أن يكون حجم الصورة الشخصية أقل من 2 ميجابايت </span>
                        
                          ), 
Contact: 'للإتصال                ',
BecomingPartner2: 'أنت ممارس صحي وترغب في أن تصبح شريكاً في دكتوري؟ انقر هنا...',



      Homme: 'رجل',
      Femme: 'امرأة',
      checkbox: 'ضع علامة :',
      Informationscomplémentaires: 'معلومات إضافية (ليست إلزامية)',
  
     Wilaya: ' ولاية  ',
      Daira: ' دائرة',

      SelectWilaya: 'اختر ولاية  ',
      SelectDaira: 'اختر دائرة',
      Selectfield: 'اختر المجال الطبي',
      searchdoctor: 'بحث عن ممارس',
    
    

      Apply: ( 
        <span>
تطبيق             
</span>
        
          ),
      MyAccount:  ' فتح حسابي',
     
      BecomingPartner:  'أصبح ممارسًا شريكًا',
      bookappointementheader:  'حجز موعد',    
      Dashboard:  'لوحة الإدارة',
  
  
          Masculin: (
            <span >      
  ذكر               </span>
            
              ), 
  
              
              Feminin: (
                <span >      
  أنثى                   </span>
                
                  ), 
              
  
              
                  autre: (
                    <span >      
      خيار آخر                   </span>
                    
                      ), 
                  
                  
                  


// mes RDV

ConfirmthenewAppointment: (
  <span >  
    انقر هنا لتأكيد الموعد الجديد    
       </span>
  
    ),   

    ConfirmthenewAppointmentpopup: (
      <span >  
تأكيد الموعد الجديد؟          
 </span>
      
        ),  
        
   
        cancelplageshoraires: (
          <span >  
رفض/ إلغاء الموعد؟     </span>
          
            ),        

    RefuseandcancelAppointment: (
      <span >     
  انقر هنا لرفض/إلغاء الموعد     </span>
      
        ),
        
        
chooseatimefirst: (
  <span className="text-1xl text-center font-semibold mb-1" >      :اختر الوقت أولاً قبل اختيار التاريخ


  </span>
  
    ),    

                    ShowPreviousAppointments: (
                      <span >      
                    
                    إظهار المواعيد السابقة                              </span>
                      
                        ),   
                        UpcomingAppointments: (
                          <span>     المواعيد القادمة

                        
                        
                          </span>
                          ),              

                previousAppointements: (
                  <span  >      
                
                إخفاء المواعيد السابقة                  </span>
                  
                    ),   

                    NewappointmentDate: (
                  <span className="text-1xl text-center font-semibold mb-1" >  التاريخ جديد للموعد هو:
                  </span>
                  
                    ),    

                    Newappointmenttime: (
  <span className="text-1xl text-center font-semibold mb-1" >     الوقت الجديد للموعد هو:
  </span>
  
    ),    


 Appointmentday:  ' يوم موعدك ',
 Age:  'عمر',
Appointment:  ' موعدك ',
      warning:  ' تحذير',
      Reportedtonewdate:  '  تمت إعادة جدولة أحد مواعيدك، انقر هنا لفتحه ',
      appointementCanceled:  ' تم إلغاء أحد مواعيدك انقر هنا لفتحه',
      yourappointmentwasrescheduled:  ' تمت إعادة جدولة موعدك إلى:',

      yourappointmentwasrescheduled2: ( 
       <div
         className="bg-orange-200 survol :bg-orange-400 text-gray-700 items-center font-semibold py-2 px-4 "
       >  
       يجب عليك الآن <strong className="text-sky-600 dark:text-sky-500  text-md "> تأكيد</strong> <p>أو <strong className="text-red-500 ">إلغاء </strong> هذا الموعد </ p>
       
       انقر هنا، ثم اختر أحد الخيارين
     
       </div>
       
         ),        
      
      applychanges:  ' تطبيق التغيير ',
      EditProfile:  ' تغيير المعلومات',
      UpdateProfilePicture:  ' تحديث الصورة   ',
      ChangeProfilePicture:  ' تغيير الصورة    ',
      MyProfile:  ' حسابي  ',
      Profilesettings:  'إعدادت الحساب ', 


myappointements: 'مواعيدي',
Déconnexion: 'خروج',

      introduction: (
        <span>
قدم نفسك</span>
          ), 
          introduction2: (
            <span>
    مقدمة</span>
              ), 
    
//  doctor home :

Ou: (
  <span>
أو
</span>
    ), 


pasdecompte: (
  <span>
ليس لديك حساب؟ </span>
    ), 


forgotpassword: (
  <span>
هل نسيت كلمة السر ؟ </span>
    ), 



continuegoogle: (
  <span>
    
    Google
الاستمرار ب
 </span>
  ), 


report: (
  <span>
تاجيل  
  </span>
  
    ), 

time: (
  <span>

اختر الوقت
  </span>
  
    ), 

homeDoktori: (
  <span> مرحبًا بكم في <strong className=" text-blue-400 dark:text-sky-400">دكتوري</strong>
  
  </span>
  
    ),      
         
   homeDoktorintro: (
      <span>  منصتك الموثوقة للمواعيد الطبية عبر الإنترنت
      </span>
     ),      
         
  homeReserveButton: (
    <span>  اضغط هنا للبحث وحجز موعد...
  
       </span>
   ),   
         
   homewithdoktori: (
    <span>
  مع <strong className="text-sky-600 dark:text-sky-500 ">DoKtori</strong>  </span>
   ),      
       
  homeadventagetitle1: (
  <span> حجز المواعيد <strong className="text-sky-600 dark:text-sky-500 ">24/7</strong> </span>
  ),   
     
   homeadventage1: (
    <span><strong className="text-sky-600 dark:text-sky-500 ">احجز موعدًا بسهولة</strong> وأنت مرتاح في <strong className="text-sky-600 dark:text-sky-500 ">منزلك</strong>،
   <strong className="text-sky-600 dark:text-sky-500 ">في أي وقت</strong>،<strong className="text-sky-600 dark:text-sky-500 ">في أي مكان</strong>.
   </span>
   ),      
  
  homeadventagetitle2: (
  <span> لا مزيد من الانتظار  </span>
  ),   
     
   homeadventage2: (
    <span><strong className="text-sky-600 dark:text-sky-500 "> تجنب قوائم الانتظار الطويلة</strong> و<strong className="text-sky-600 dark:text-sky-500 "> اوصلو بسرعة</strong> إلى الخدمات التي تحتاجها.</span>
   ),      
  
  
  homeadventagetitle3: (
  <span> اربح وقتك الثمين  </span>
  ),   
     
   homeadventage3: (
    <span><strong className="text-sky-600 dark:text-sky-500 ">احجز بسرعة </strong> و<strong className="text-sky-600 dark:text-sky-500"> اربح</strong> <strong className="text-sky-600 dark:text-sky-500 "> الوقت </strong>،   و تمكن من التركيز على أشياء أخرى</span>
   ),      
  
  
  homeadventagetitle4: (
  <span> لحجز بدون عناء  </span>
  ),   
     
   homeadventage4: (
  <span>حجز سريع و<strong className="text-sky-600 dark:text-sky-500 "> بدون عناء</strong> لراحتك.</span>
   ),      
  
  
homeadventagetitle5: (
  <span> تواصل مع المحترفين  </span>
  ),   
     
   homeadventage5: (
    <span><span>يمكنك الوصول إلى <strong className="text-sky-600 dark:text-sky-500 ">مجموعة واسعة من الخدمات الطبية المتخصصة</strong> والعثور على <strong className="ttext-sky-600 dark:text-sky-500 ">متخصصين طبيين ذوي سمعة طيبة</strong> عبر الإنترنت. </span></span>
   ),      
  
  
  homeadventagetitle6: (
  <span> تحسين رحلتك الصحية  </span>
  ),   
     
   homeadventage6: (
   <span><strong className="text-sky-600 dark:text-sky-500 ">استمتع</strong> براحة لا مثيل لها و<strong className="text-sky-600 dark:text-sky-500 ">تحكم</strong> في رحلتك
  الصحة الصحية</span>
   ),      
  
  
  
  
      certificatofsecurity:" شهادات الأمان",



  ProfessionalAccount: ' حساب احترافي',

//  doctor home :

ApplyforProfessionalAccount: 'قدّم طلب لحساب احترافي',

logintomyAccount: 'الدخول إلى حسابي',

BenefitsofOnlineBookingforMedicalProfessionals: 'فوائد الحجز عبر الإنترنت للمتخصصين',
TimeEfficiency: 'ربح الوقت',

Timepoint1: (
<span>تشير الدراسات إلى زيادة <strong className="text-sky-600">بنسبة 25%</strong> في ال<strong className="text-sky-600">إنتاجية الإجمالية</strong> مع ال<strong className="text-sky-600">جدولة عبر الإنترنت</strong> بسبب <strong className="text-sky-600">إدارة الوقت بشكل أفضل</strong>.</span>
  ),
  
  Timepoint2: (
<span>تظهر الدراسات انخفاضًا بنسبة <strong className="text-sky-600">30%</strong> في أوقات انتظار المرضى مع أنظمة الحجز عبر الإنترنت.</span>
    ),  
        
    Timepoint3: (
<span>وفّر <strong className="text-sky-600">وقتك</strong> ووقت <strong className="text-sky-600">مرضاك</strong> مع جدولة فعّالة.</span>
          ),  
              
Timepoint4: (
<span><strong className="text-sky-600">ركّز أكثر على رعاية المرضى</strong> وأقل على المهام الإدارية.</span>
                            ),        

                            Availability:'التوفر على مدار الساعة 24/7',


                          

Availabilitypoint1: (
<span>يمكن للمرضى حجز المواعيد في أي وقت، مما يؤدي إلى <strong className="text-sky-600">زيادة بنسبة 40%</strong> في الحجوزات <strong className="text-sky-600 ">خارج ساعات العمل </strong>.</span>     
 ),        
 
 Availabilitypoint2: (
  <span>ي<strong className="text-sky-600">سريع</strong> و<strong className="text-sky-600">حجز موعد بدون متاعب في أي وقت</strong></span >   
   ),        

   Availabilitypoint3: (
    <span>يرغب ما يقرب من <strong className="text-sky-600">78%</strong> من المرضى في الحصول على إمكانية حجز مواعيدهم الطبية عبر الإنترنت.</span>   ),        
         


    EffectivePatientManagement:'إدارة المرضى بفعالية  ',

    PatientManagement1: (
<span>إدارة المواعيد <strong className="text-sky-600">بسهولة</strong> و<strong className="text-sky-600">بدون جهد.</strong></span>       ),        
     
    
   PatientManagement2: (
<span><strong className="text-sky-600">تبسيط قوائم انتظار المرضى</strong> و<strong className="text-sky-600">تحسين الخدمات</strong> من خلال جدولة المواعيد المنظمة.</span>
    ),        
         

    PatientManagement3: (
<span>يحتوي <strong className="text-sky-600">على ميزات لتتبع مواعيد مرضاك معك</strong>.</span>
       ),        
           



    CostReductionandAdministrativeEfficiency:'خفض التكاليف وتعظيم الكفاءة الإدارية  ',
    costreduction1: (
      <span>أبلغ الممارسون الذين يستخدمون خدمات حجز المواعيد عبر الإنترنت عن <strong className="text-sky-600">انخفاض في التكاليف الإدارية</strong> يصل إلى <strong className="text-sky-600">30%</strong>.</span>
  
                       ),        
             
            
               costreduction2: (
<span>تشير الدراسات إلى أن اعتماد الحلول الرقمية يمكن أن يؤدي إلى انخفاض <strong className="text-sky-600">بنسبة 20%</strong> في عبء العمل الإداري.</span>             ),        
                 
        
             costreduction3: (
<span>تقليل <strong className="text-sky-600">حجم المكالمات الهاتفية</strong>، مما يسمح للموظفين بالتركيز على <strong className="text-sky-600">المهام الحاسمة</strong>.</span>
                ),        
                  
                costreduction4: (
                  <span>قم بتحسين <strong className="text-sky-600">سير العمل</strong> و<strong className="text-sky-600"> الكفاءة العامة</strong>.</span>
                                                            ),      


    PromotionofPreventiveCare:'تعزيز الرعاية الوقائية ',
    PreventiveCare: (
<span>يمكن لخدمات المواعيد عبر الإنترنت أن تسهل جدولة <strong className="text-sky-600">مواعيد الرعاية الوقائية</strong>، مثل <strong className="text-sky-600">الفحوصات الطبية المنتظمة والفحوصات الطبية</strong>.</span>
                                                ),      
  PreventiveCare2: (
<span>من خلال جعل الرعاية الوقائية أكثر <strong className="text-sky-600">يسراً</strong> و <strong className="text-sky-600">راحة</strong>، تشجع منصات الحجز عبر الإنترنت المرضى على <strong className="text-sky-600">إعطاء الأولوية</strong> <strong className="text-sky-600">لصحتهم</strong>.</span>
                                                                                            ),      

    ExpandedPatientBasewithMobileFriendlyServices:'توسيع نطاق المرضى من خلال الخدمات الملائمة للهاتف المحمول',
    ExpandedPatientBase: (
<span>يمكن لخدمات الحجز عبر الإنترنت أن تجتذب المرضى من <strong className="text-sky-600">منطقة جغرافية أوسع</strong> مما يسمح بزيادة <strong className="text-sky-600">إمكانية الوصول</strong> إلى خدمتك، <strong className="text-sky-600">خارج التركيبة السكانية المحلية</strong>.</span>
                                                                                                  ),      
      
        ExpandedPatientBase2: (
<span><strong className="text-sky-600">المرضى</strong> الذين قد يواجهون صعوبة جسديًا في الوصول إلى العيادة، مثل أولئك الذين يعانون من <strong className="text-sky-600">مشكلة في الحركة</strong> أو <strong className="text-sky-600">مع وسائل النقل</strong>، يمكنهم الاستفادة من <strong className="text-sky-600">أخذ موعد عن بعد</strong>.</span>
   
 
 ),      
     
 ExpandedPatientBase3: (
<span>تُعد خدمات الحجز عبر الإنترنت <strong className="text-sky-600">المتوافقة مع الأجهزة المحمولة</strong> أمرًا ضروريًا لأن <strong className="text-sky-600">45%</strong> من جميع المواعيد يتم إجراؤها عبر <strong className="text-sky-600">الهواتف الذكية</strong>.</span>
    
  
  ),             
  
  
    IncreasedPatientEngagement:'زيادة مشاركة المريض ورضاه',

    IncreasedEngagement: (
<span><strong className="text-sky-600">خدمات جدولة المواعيد عبر الإنترنت</strong> توفر <strong className="text-sky-600">للمرضى</strong> الفرصة لحجز المواعيد مع <strong className="text-sky-600">مقدمي الرعاية الصحية</strong> في أي وقت، مما يعزز <strong className="text-sky-600">ولائهم</strong>.</span>
                                                                                                                      ),      
                    
                 IncreasedEngagement2: (
<span>تشير الدراسات إلى أن استخدام <strong className="text-sky-600">التكنولوجيا</strong> يمكن أن يؤدي إلى زيادة بنسبة <strong className="text-sky-600">20%</strong> في <strong className="text-sky-600">رضا المرضى</strong>.</span>
                                                                                                                      ),      
                   
 

ApplyingSteps: (<span>الخطوات وكيفية <strong className="text-sky-600">عملها</strong></span>


),      
     
ApplyingStepsheader1: (
  <span> تقديم الطلب
  </span>
 ),      
   
 underreview1: (
  <span> قيد المراجعة...
 </span>
 ),   
 underreview2: (
  <span> لقد قدمت طلبك للحصول على حساب Doktori الاحترافي.
  نحن نقوم بمعالجتها حاليا
 </span>
 ),     
ApplyingSteps1: (
<span>قدم طلبك للحصول على حساب مهني  دكتوري.</span>
),   
     
ApplyingStepsheader2: (
<span> سيقوم أحد أعضاء فريقنا بالاتصال بكم.

</span>
),      
   
ApplyingSteps2: (
<span>سيتم التواصل معكم من قبل أحد أعضاء فريقنا عبر 
        البريد الإلكتروني و، أو رقم الهاتف الذي قدمتموه. </span>
),   
 
ApplyingStepsheader3: (
<span>تفعيل الحساب</span>
),      
   
ApplyingSteps3: (
<span> بمجرد التحقق من هويتك، سيتم تفعيل حسابكم الاحترافي وسيكون بإمكانكم استخدامه. </span>
),            


      noDoctorsFoundMessage: '   نعتذر عن هذا الإزعاج، ولكن يبدو أن الطبيب (الأطباء) الذين تبحث عنهم لا يستخدمون منصة DoKtori بعد. إذا لم يكن طبيبك موجودًا على المنصة، فلا تتردد في اقتراحه ومشاركة تطبيقنا معهم. فريق DoKtori بأكمله يشكرك ويتمنى لك الشفاء العاجل  ',

      allDoctors: 'جميع الأطباء',
      loadingDoctors: 'جار التحميل...',
      seeMoreDoctors: 'رؤية المزيد من الأطباء',

// doctordisplaypage

bookappointement: (
  <span> حجز موعد
  </span>
  
    ),  
  
         
  booksuccess3: (
  <span> موعدك مع </span>
  
    ),      
         booksuccess2: (
  <span> 
للفترة الزمنية
 

  </span>
  
    ),      
          booksuccess1: (
  <span>   <span className="text-green-600 text-2xl font-semibold"> تم حجزه بنجاح.</span>
  </span>
  
    ),   
  
      booksuccess4: [
  <span> <div className="text-lg text-gray-800 mt-6 mb-2 space-y-4">
    <p>شكراً لوصولك <span className="font-semibold">10 إلى 15 دقيقة مبكراً</span>.</p>
    <div className="text-lg text-gray-800 font-semibold mb-2">
      قبل الذهاب إلى موعدك:
    </div>   
    <p>تأكد من أنه لم يتم <span className="text-orange-500 font-semibold">تأجيله</span> <span>أو </span> <span className="text-red-500 font-semibold">إلغاؤه</span>.</p>
    <p>لعرض <span className="font-semibold">مواعيدك</span>:</p>
    <p>انقر على <span className="font-semibold">حسابي</span>، ثم <span className="font-semibold">مواعيدي</span>.</p>
  </div>
  </span>
  
    ],   
  
  // doctordisplaypage
   
  retourbutton: (
  <span>           عودة إلى الوراء
  
  </span>
  
  
    ), 
  
  NometPrenomduPatient: (
  <span>  إسم و للقب المريض (باللاتينية)
  </span>
  
    ),      
  
  Symptômes: (
  <span> الأعراض التي تعاني منها
  </span>
  
    ),      
    Âgedupatient: (
      <span>  عمر المريض:
      </span>
      
        ),          
    
    GroupeSanguin: (
      <span>  فصيلة الدم: 
      </span>
      
        ),          
  
  Allergies: (
  <span> هل لديك الحساسية؟
  </span>
  
    ),      
        
  
             NumerodeTelephone: (
  <span>           رقم الهاتف :
  </span>
  
    ),      
        
  
   TypedeConsultation: (
  <span>   نوع الاستشارة: 
  </span>
  
    ),      
  
  
  notimeslot: (
  <span>  <span className="text-sky-600 dark:text-sky-500 ">لا يوجد موعد للحجز مع هذا الممارس</span> يمكنك <span>العودة لاحقًا</span>
   <span> أو </span> <span >يمكنك أيضًا حجز موعد مع ممارس آخر</span>
  </span>
  
  
    ),      
        
  
  
  LoadMore: (
  <span>  رؤية المزيد من الخيارات
  </span>
  
    ),      

 
 

    SignIn: (
      <span> الدخول إلى حسابي
      </span>
      
        ),      
            
      
      CreateAccount: (
      <span>  إنشاء حساب
      </span>
      
        ),      
            
      
      securityraisons: (
        <span className=" dark:text-blue-100 " >
 <span>
       لأسباب أمنية</span>، <span className="text-sky-600 dark:text-sky-400 ">لتحديد موعد</span>،
      <span className="text-sky-600 dark:text-sky-400 "> ادخل إلى حسابك </span> أو <span className="text-green-600">أنشئ حسابًا</span>
      <span>    إذا لم يكن لديك واحد     </span>

      </span>
            
        ),      
      
      
      Réserver: (
      <span> حجز هذا الموعد
      </span>
      
        ),      
            
      
      
      confirmeRéserver: (
      <span>  تأكيد الموعد مع
      
      </span>
      
        ),      
      oui: (
      <span> نعم
      </span>
      
        ),      
            
      non: ( 
      <span>لا
      
      </span>
      
        ),      
                  


        appointementunderstood: (
          <span> طيب فهمت
          
          </span>
          
            ),      
               
          close: (
          <span>  غلق
          </span>
          
            ),      
                 
      
            nodoctor: (
              <span>  <p className="mb-3">
           <strong className="text-sky-600 dark:text-sky-500 ">DoKtori</strong>   يبدو أن الطبيب (الأطباء) الذي تبحث عنه لم يفتح بعد حسابًا مهنيًا على 
            </p>
            <hr className="border-t-2 border-gray-300 my-3" />
            <p className="mb-3">
              إذا لم يكن <strong className="text-sky-600 dark:text-sky-500 ">طبيبك</strong> متواجدًا على المنصة، فنحن ندعوك لـ <strong className="text-sky-600 dark:text-sky-500 ">مشاركتها</strong> معهم و<strong className="text-sky-600 dark:text-sky-500 "> اقتراح </strong> استخدامها <strong className="text-sky-600 dark:text-sky-500 ">.</strong>
            </p>
            <hr className="border-t-2 border-gray-300 my-3" />
            <p className="border border-blue-400 py-2 dark:text-blue-100 text-gray-700 text-center"> 
             يشكركم ويتمنى لكم <strong className="text-sky-600 dark:text-sky-500 ">  الشفاء العاجل </strong> <strong className="text-sky-600 dark:text-sky-500 ">DoKtori</strong> فريق  
            </p>
            
              </span>
              
                ),      






      // Dashboard :
week: 'أسبوع',
 day:'يوم',



           
  
  

  
       
  


        
  booked: ( 
  <span>
  محجوز
  
  </span>
  
    ),  
  
  reported: ( 
  <span>
أعادة الجدولة  </span>
  
    ),     
  
  canceled: ( 
  <span>
  الغي
  </span>
  
    ),     


    ChooseTime: ( 
      <span>
      اختر الوقت
      </span>
      
        ),  
      
      Confirm: ( 
      <span>
      تأكيد
      </span>
      
        ),     
      
      SelectDate: ( 
      <span>
      حدد تاريخا
      </span>
      
        ),
      
      
      cancel: ( 
      <span>
      الإلغاء
      </span>
      
        ),  
      
      
      Annullezplagehoraire: ( 
      <span>
               هل أنت متأكد أنك تريد إلغاء هذا الموعد؟
      
      
      </span>
      
        ),  
      
      Reporteplagehoraire: ( 
      <span>
                  هل أنت متأكد أنك تريد إعادة جدولة هذا الموعد؟
      
      
      </span>
      
        ),  
      
      Status: ( 
      <span>
      الحالة
      </span>
      
        ),  
      
      GestionRDV: ( 
      <span>
      إدارة المواعيد الطبية
      
      </span>
      
        ),         
      
      
      
      TimeSettings: ( 
      <span>
      اعدادات الوقت
      </span>
      
        ),  
      
      
      tempsFin: ( 
      <span>
      النهاية
      </span>
      
        ),  
      
      
      tempsDebut: ( 
      <span>
      البداية
      </span>
      
        ),  
      
      
      Dureemoyenne: ( 
      <span>
متوسط ​​مدة الزيارة      </span>
      
        ),  
      
      
      minutes: ( 
      <span>
      (دقائق)
      </span>
      
        ),  
      
      
      Patients: ( 
      <span>
      مرضاي
      </span>
      
        ),  
      
      
      Submit: ( 
      <span>
إضافة الفترات     </span>
      
        ),  
      
      confirmezplageshoraires: ( 
      <span>
      تأكيد الفترات الزمنية؟
      </span>
      
        ),  




      
  // signe up page:



  nomprenom: (
    <span> 
  الإسم واللقب
  (بلاتينية)
    </span>
    ),   
       
    password: (
    <span>  الكلمة السرية </span>
    ),   
       
    email: (
    <span>  بريد إلكتروني </span>
    ),   
       
    confirmpassword : (
    <span> تأكيد كلمة السر
    </span>
    ),   
       
    passwordformat: (
    <span>  يجب أن تحتوي كلمة  السر </span>
    ),   
       
   passwordformat2: (
     <li> 
8 حروف على الأقل  </li>
           
    ),   

    passwordformat3: (
                 <li>رقم واحد على الأقل</li>

     ),   

     passwordformat4: (
    
                 <li>حرف خاص واحد على الأقل</li>
             
     ),   
 
     passwordformat5: (
   
                  <li>حرف صغير واحد وحرف كبير واحد على الأقل</li>
      ),   
  
   yourmedicalfield: (
    <span>  مجالك الطبي </span>
    ),   
       
    cabinetcliniquehospitalname: (
    <span>    اسم المكتب/العيادة/المستشفى
   </span>
    ),   
    cabinetcliniquehospitalname2: (
      <span>    المكتب/العيادة/المستشفى
     </span>
      ),   
   MaximumPatients: (
    <span> الحد الأقصى للمرضى في اليوم الواحد </span>
    ),   
  
  
       
  
  Address: (
    <span> عنوان </span>
    ),   
       
  
  
  ZipCode: (
    <span> الرقم البريدي </span>
    ),   
       
  
  
  mapexplanation: (
    <span>   
    <strong className="text-sky-600 dark:text-sky-400">العلامة على الخريطة</strong> موضوعة <strong className="text-sky-600 dark:text-sky-500">تلقائيًا</strong> في موقع "<strong className="text-sky-600 dark:text-sky-500">الولاية</strong>" و "<strong className="text-sky-600 dark:text-sky-400">الدائرة</strong>" المحددة. <strong className="text-sky-600 dark:text-sky-500">قم بالتكبير</strong> و <strong className="text-sky-600 dark:text-sky-400">ضبط</strong> موقعها.
   </span>
    ),   
       
  
  
  mapCoordinates: (
    <span> إحداثيات الموقع </span>
    ),   
       
  
  
  selectprofilepicture: (
    <span>  إضافة صورة </span>
    ),   
       
    Conditionsutilisationgoogle: (
      <span>من خلال الاستمرار فإنك تقبل </span>

      ),

  Conditionsutilisation1: (
    <span>أوافق عليها </span>
    ),   
   

    iaccept: (
      <span>أوافق  </span>
      ),   
         
      PolitiquedeConfidentialité: (
        <span>سياسة السرية</span>
        ),   

  Conditionsutilisation2: (
    <span>شروط الاستخدام </span>
    ),   
  
    Conditionsutilisation3: (
      <span>من خلال دخولك إلى تطبيق أو موقع Doktori، فإنك "المستخدمين" تقبل شروط الاستخدام بشكل كامل ودون تحفظ.</span>
      ),  
  
  
  alreadyhaveaccount: (
    <span>     لديك حساب؟</span>
    ),   
  
  
       


 
},



  };






  
  const value = {
    language,
    setLanguage,
    translations: translations[language],
  };

  return (
    <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  return useContext(LanguageContext);
};

import { useState } from "react";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import OAuth from "../components/OAuth";
import Spinner from "../components/Spinner";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import { db } from "../firebase";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import patientsignin from "../Assets/images/patientsignin.gif";
import { useLanguage } from '../components/LanguageContext'; 
import TermsAndConditions from '../components/TermsAndConditions'; 

export default function SignUp() {
  const [showPassword, setShowPassword] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const { language, translations } = useLanguage();
  const [istermserviceVisible, settermserviceVisible] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    userType: "user",
  });
  const { name, email, password, confirmPassword, userType } = formData;

  const navigate = useNavigate();

  function onChange(e) {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  }

  async function onSubmit(e) {
    e.preventDefault();

    if (!acceptedTerms) {
      toast.error("Veuillez accepter les conditions d'utilisation.");
      return;
    }

    if (name.trim() === "") {
      toast.error("Le nom complet est requis.");
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Les mots de passe ne correspondent pas.");
      return;
    }

    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&^_\-¤'"&(=€)])[A-Za-z\d@$!%*?&^_\-¤'"&(=€)]{8,}$/;
    if (!passwordPattern.test(password)) {
      toast.error(
        "Le mot de passe doit contenir au moins 8 caractères, une combinaison de lettres et de chiffres, et au moins un caractère spécial."
      );
      return;
    }

    if (!isAllowedEmailDomain(email)) {
      toast.error("L'adresse e-mail n'est pas valide.");
      return;
    }

    try {
      const auth = getAuth();

      setIsCreatingAccount(true);

      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      updateProfile(auth.currentUser, {
        displayName: name,
      });

      const user = userCredential.user;
      const formDataCopy = { ...formData };
      delete formDataCopy.password;
      delete formDataCopy.confirmPassword;
      formDataCopy.timestamp = serverTimestamp();

      await setDoc(doc(db, "users", user.uid), formDataCopy);

      setIsCreatingAccount(false);

      navigate("/");
      toast.success("Compte créé avec succès !");
    } catch (error) {
      let errorMessage = "Une erreur s'est produite lors de l'inscription : ";

      if (error.message.includes("Votre Nom et Prenom")) {
        errorMessage += "Le nom complet est requis.";
      } else if (error.code === "auth/invalid-email") {
        errorMessage += "L'adresse e-mail n'est pas valide.";
      } else if (error.code === "auth/email-already-in-use") {
        errorMessage += "L'adresse e-mail existe déjà.";
      } else if (error.message.includes("Votre Adress e-mail")) {
        errorMessage += "L'adresse e-mail est requise.";
      } else if (error.message.includes("Votre Mots de Pass")) {
        errorMessage += "Le mot de passe est requis.";
      } else if (error.message.includes("Password is not valid")) {
        errorMessage +=
          "Le mot de passe doit contenir au moins 8 caractères, une combinaison de lettres et de chiffres, et au moins un caractère spécial.";
      } else {
        errorMessage +=
          "Quelque chose s'est mal passé lors de l'inscription.";
      }

      toast.error(errorMessage);
      setIsCreatingAccount(false);
    }
  }

  function isAllowedEmailDomain(email) {
    const allowedEmailDomains = [
      "gmail.com",
      "yahoo.com",
      "hotmail.com",
      "outlook.com",
      "aol.com",
      "icloud.com",
      "protonmail.com",
      "zoho.com",
      "mail.com",
      "gmx.com",
      "live.com",
      "msn.com",
      "yandex.com",
      "inbox.com",
      "me.com",
      "rocketmail.com",
      "att.net",
      "cox.net",
      "verizon.net",
      // Add more domains here
      "gmail.fr",
      "yahoo.fr",
      "hotmail.fr",
      "outlook.fr",
      "aol.fr",
      "icloud.fr",
      "protonmail.fr",
      "zoho.fr",
      "mail.fr",
      "gmx.fr",
      "live.fr",
      "msn.fr",
      "yandex.fr",
      "inbox.fr",
      "me.fr",
      "rocketmail.fr",
      "att.fr",
      "cox.fr",
      "verizon.fr",
      // Add .dz versions
      "gmail.dz",
      "yahoo.dz",
      "hotmail.dz",
      "outlook.dz",
      "aol.dz",
      "icloud.dz",
      "protonmail.dz",
      "zoho.dz",
      "mail.dz",
      "gmx.dz",
      "live.dz",
      "msn.dz",
      "yandex.dz",
      "inbox.dz",
      "me.dz",
      "rocketmail.dz",
      "att.dz",
      "cox.dz",
      "verizon.dz",
    ];
    const emailDomain = email.split("@")[1];
    return allowedEmailDomains.includes(emailDomain);
  }

  function toggleAcceptedTerms() {
    setAcceptedTerms((prevAccepted) => !prevAccepted);
  }


  return (
    <section>
    <h1 className="text-3xl text-center dark:text-blue-100 mt-2 font-bold">{translations.CreateAccount}</h1>
    <div className="flex justify-center flex-wrap dark:bg-gray-700 bg-blue-100 items-top px-3 py-7 max-w-6xl mx-auto">
    
      <div className="w-full md:w-[40%] lg:w-[40%] lg:ml-20">
        <OAuth acceptedTerms={acceptedTerms} />
        <span className="ml-2 dark:text-blue-100">
              {translations.Conditionsutilisationgoogle} {" "}
              <button
                className="text-blue-500 dark:text-blue-300 hover:underline"
                onClick={() => settermserviceVisible(true)}
              >
                {translations.Conditionsutilisation2}
              </button>
            </span>
        <div className="flex items-center dark:text-blue-100 before:border-t before:flex-1 before:border-gray-300 after:border-t after:flex-1 after:border-gray-300">
          <p className="text-center font-semibold mx-3 my-5"></p>
        </div>

      </div>
    </div>
    {istermserviceVisible && (
          <main className="fixed inset-0 flex flex-col items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-700 mb-10 z-50">
    <div  className="w-full max-w-screen-lg p-4 bg-gray-100 dark:bg-gray-700 rounded-lg shadow-lg overflow-auto z-50 ">
          <TermsAndConditions />
      
        
        
        </div>
        <div className="flex justify-end bg-blue-100 dark:bg-gray-700 mb-10">
        
        <button  className="text-sky-600 hover:text-sky-700 px-4 py-2 rounded-md border border-sky-500 hover:bg-blue-100 dark:bg-blue-100 focus:outline-none" 
        onClick={() => {
          toggleAcceptedTerms();
          settermserviceVisible(false);
        }}      >
      {translations.iaccept}  
       
        </button>
        <button
                className="text-gray-500 hover:text-gray-700 px-4 py-2 ml-4 rounded-md border border-gray-500 hover:bg-gray-100 dark:bg-gray-100 focus:outline-none"
                onClick={() => settermserviceVisible(false)}
        >
         {translations.close}  
        </button>
      </div>
 
        </main>  
        )}  


      {isCreatingAccount && <Spinner />}


    </section>
  );
}
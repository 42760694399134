import { getAuth, GoogleAuthProvider, signInWithRedirect, getRedirectResult } from "firebase/auth";
import { doc, getDoc, serverTimestamp, setDoc } from "firebase/firestore";
import { FcGoogle } from "react-icons/fc";
import { toast } from "react-toastify";
import { db } from "../firebase";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react"; // Import useEffect for handling redirect results
import Spinner from "../components/Spinner";
import { useLanguage } from '../components/LanguageContext';

export default function OAuth({ userType }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // State to manage loading
  const { language, translations } = useLanguage();

  useEffect(() => {
    const handleRedirectResult = async () => {
      const auth = getAuth();
      try {
        const result = await getRedirectResult(auth);
        if (result) {
          const user = result.user;
          console.log("Google Sign-In Successful with redirect");
          console.log("User Info:", user);

          // Check if the user exists in Firestore
          const docRef = doc(db, "users", user.uid);
          const docSnap = await getDoc(docRef);

          if (!docSnap.exists()) {
            console.log("User not found in 'users' collection. Adding user...");
            await setDoc(docRef, {
              name: user.displayName,
              email: user.email,
              userType: "user",
              timestamp: serverTimestamp(),
            });
            console.log("User added to 'users' collection.");
          } else {
            console.log("User already exists in 'users' collection.");
          }

          setLoading(false); // Stop loading spinner
          navigate("/");
          window.location.reload();
        }
      } catch (error) {
        console.error("Error handling Google sign-in with redirect:", error);
        toast.error("Could not authorize with Google");
        setLoading(false); // Stop loading spinner in case of error
      }
    };

    handleRedirectResult();
  }, [navigate]); // Run only once when the component mounts

  const onGoogleClick = () => {
    setLoading(true); // Start loading spinner
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    signInWithRedirect(auth, provider); // Trigger redirect sign-in
  };

  return (
    <>
      <button
        type="button"
        onClick={onGoogleClick}
        disabled={loading} // Disable button during loading
        className="flex items-center justify-center w-full bg-white text-black py-2 px-4 rounded-lg shadow-md hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 border border-black"
      >
        {loading ? (
          <Spinner /> // Show spinner when loading
        ) : (
          <>
            <FcGoogle className="mr-2 text-xl" />
            {translations.continuegoogle}   
          </>
        )}
      </button>
    </>
  );
}

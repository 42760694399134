
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';

import Layout from './components/Layout';
import DoctorProfile from "./pages/DoctorProfile";
import Profile from "./pages/Profile";
import MesRdv from "./pages/MesRdv";
import SignUp from "./pages/SignUp";
import DoctorSignIn from "./pages/DoctorSignIn";
import DoctorSignUp2 from "./pages/DoctorSignUp2";
import Doctorpicture from "./pages/Doctorpicture";



import DoctorHome from './pages/DoctorHome';
import MonCompte from "./pages/MonCompte";
import PrendreRdv from './pages/PrendreRdv';
import ForgotPassword from "./pages/ForgotPassword";
import MesFavoris from './pages/MesFavoris';

import DossierMedical from './pages/DossierMedical';
import DoctorDashboard from './pages/DoctorDashboard';
import UnderReview from "./components/UnderReview";

import UnderReview2 from "./components/underReview2";

import Doctorspage from './pages/Doctorspage';
import TermsAndConditions from './components/TermsAndConditions';
import DoctorsDisplayPage from './pages/DoctorsDisplayPage';
import PolitiquedeConfidentialité from './components/PolitiquedeConfidentialité';
import Certification from './components/certification';


import Deletingaccountsteps from './components/Deletingaccountsteps';

import Deletingaccountdata from './components/Deletingaccountdata';

import PrivateRoute from "./components/PrivateRoute";
import PrivateRouteDoctor from "./components/PrivateRouteDoctor";


import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import { LanguageProvider } from './components/LanguageContext';
import { DarkModeProvider } from './components/DarkModeContext';


function App() {
 
  return (

    <LanguageProvider>

     <> 
     <DarkModeProvider>

    <Router>
      <Layout> 
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/profile" element={<PrivateRoute />}>

      <Route path="/profile"  element={<Profile />} />
      </Route>

      <Route path="/picture"  element={<Doctorpicture />} />


      <Route path="/profile-doctor" element={<PrivateRouteDoctor />}>

<Route path="/profile-doctor"  element={<DoctorProfile />} />
    
</Route>

      <Route path="/mon-compte"  element={<MonCompte />} />
      <Route path="/sign-up"  element={<SignUp />} />
      <Route path="/doctor-sign-Up"  element={<DoctorSignUp2 />} />
      
      <Route path="/term-service"  element={      <TermsAndConditions />
} />
  <Route path="/Politique-de-Confidentialité"  element={      <PolitiquedeConfidentialité />
} />

 <Route path="/Delete-account"  element={      <Deletingaccountsteps />
} />
<Route path="/Delete-data"  element={      <Deletingaccountdata />
} />
<Route path="/security"  element={      <Certification />
} />
      <Route path="/doctor-sign-in" element={<DoctorSignIn />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />


      <Route path="/doctor-dashboard" element={<PrivateRouteDoctor />} > 
      <Route  path="/doctor-dashboard" element={<DoctorDashboard/>} />
      </Route>
          <Route path="/under-review" element={<UnderReview />} />

          <Route path="/under-review2" element={<UnderReview2 />} />


          <Route path="/doctors-display-page" element={<DoctorsDisplayPage />} />

          <Route path="/doctors/:id" element={<Doctorspage />} />




          

          <Route path="/compte-Pro" element={<DoctorHome/>} />
          <Route path="/sign-up"  element={<SignUp />} />
        
          <Route path="mes-favoris" element={<PrivateRoute />} > 
      <Route path="/mes-favoris" element={<MesFavoris/>} />
      </Route>


      <Route path="/mes-rdv" element={<PrivateRoute />} >
      <Route path="/mes-rdv" element={<MesRdv/>} />
      </Route>

          <Route path="/prendre-rdv" element={<PrendreRdv/>} />
          <Route path="/dossier-medical" element={<DossierMedical/>} />
    
        </Routes>
      </Layout>
    </Router>

       <ToastContainer
       position="top-right"
       autoClose={5000}
       hideProgressBar={false}
       newestOnTop={false}
       closeOnClick
       rtl={false}
       pauseOnFocusLoss
       draggable
       pauseOnHover
       theme="light"
       />
       {/* Same as */}
       <ToastContainer />

       </DarkModeProvider>

       </>     

</LanguageProvider>

           
  );
}

export default App;

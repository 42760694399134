import React, { useState, useEffect } from 'react';
import { useLanguage } from './LanguageContext';
import englandIcon from '../Assets/images/englandicon.png';
import algeriaIcon from '../Assets/images/algeriaicon.png';
import franceIcon from '../Assets/images/franceicon.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LanguageSwitcher = () => {
  const { language, setLanguage } = useLanguage();
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    // Load language preference from localStorage on component mount
    const savedLanguage = localStorage.getItem('preferredLanguage');
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, [setLanguage]);

  const handleLanguageSwitch = (newLanguage) => {
    setLanguage(newLanguage);
    setDropdownOpen(false);
    // Save the selected language to localStorage
    localStorage.setItem('preferredLanguage', newLanguage);
    // Display toast message
    toast.success('Language changed successfully!', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
    });
  };

  const getFlagIcon = (lang) => {
    switch (lang) {
      case 'en':
        return englandIcon;
      case 'fr':
        return franceIcon;
      case 'ar':
        return algeriaIcon;
      default:
        return null;
    }
  };

  return (
    <div className="relative inline-block">
      <button
        onClick={() => setDropdownOpen(!isDropdownOpen)}
        className="flex items-center px-2 py-1 border border-gray-300 rounded-md hover:border-blue-500 focus:outline-none"
      >
        <img src={getFlagIcon(language)} alt={`${language} Flag`} className="w-4 h-4 mr-1" />
        <span className="text-sm font-semibold">{language}</span>
        <svg
          className="w-3 h-3 ml-1 fill-current text-gray-600"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path fillRule="evenodd" clipRule="evenodd" d="M6 8l4 4 4-4H6z" />
        </svg>
      </button>
      {isDropdownOpen && (
        <div className="absolute right-0 mt-1 w-24 bg-white border rounded-md shadow-lg overflow-hidden">
          <button
            onClick={() => handleLanguageSwitch('en')}
            className="flex items-center w-full px-2 py-1 hover:bg-blue-500 hover:text-white focus:outline-none"
          >
            <img src={englandIcon} alt="English Flag" className="w-4 h-4 mr-1" />
            <span className="text-sm font-semibold">English</span>
          </button>
          <button
            onClick={() => handleLanguageSwitch('fr')}
            className="flex items-center w-full px-2 py-1 hover:bg-blue-500 hover:text-white focus:outline-none"
          >
            <img src={franceIcon} alt="French Flag" className="w-4 h-4 mr-1" />
            <span className="text-sm font-semibold">Français</span>
          </button>
          <button
            onClick={() => handleLanguageSwitch('ar')}
            className="flex items-center w-full px-2 py-1 hover:bg-blue-500 hover:text-white focus:outline-none"
          >
            <img src={algeriaIcon} alt="Arabic Flag" className="w-4 h-4 mr-1" />
            <span className="text-sm font-semibold">العربية</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { FaCheckCircle } from "react-icons/fa"; // Import icons for steps
import { useLanguage } from '../components/LanguageContext'; 


export default function ProfessionalReview2() {
  const navigate = useNavigate();
  const auth = getAuth();
  const { language, translations } = useLanguage();

  const [step, setStep] = useState(1);

  useEffect(() => {
    // Simulate the verification process by changing steps after a delay
    const timer = setTimeout(() => {
      setStep(2); // Move to step 2 after a delay
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const onconfirme = () => {
    navigate("/");
};

  return (
    <div className="max-w-3xl mx-auto p-6">
      <h1 className="text-3xl dark:text-gray-100 font-bold mb-4">
      {translations.underreview1}
      </h1>

      <div className="flex items-center mb-4">
        <FaCheckCircle
          className={`text-xl ${
            step >= 1 ? "text-green-500" : "text-gray-300"
          } mr-2`}
        />
        <span className={`text-lg ${step >= 1 ? "font-bold dark:text-gray-100 " : ""}`}>
      1 :  {translations.ApplyingStepsheader1}
        </span>
      </div>
      <p className="text-gray-600 dark:text-gray-100 mb-4">
      {translations.underreview2}
  
      </p>

      <div className="flex items-center mb-4">
        <FaCheckCircle
          className={`text-xl ${
            step >= 3 ? "text-green-500" : "text-gray-300"  } mr-2`}
        />
        <span className={`text-lg dark:text-gray-100 ${step >= 3 ? "font-bold dark:text-gray-100 " : ""}`}>
        2 :{translations.ApplyingStepsheader2}
        </span>
      </div>
      <p className="text-gray-600 dark:text-gray-100 mb-4">
      {translations.ApplyingSteps2}

      </p>


      <div className="flex items-center mb-4">
        <FaCheckCircle
          className={`text-xl ${
            step >= 3 ? "text-green-500" : "text-gray-300"
          } mr-2`}
        />
        <span className={`text-lg dark:text-gray-100  ${step >= 3 ? "font-bold dark:text-gray-100 " : ""}`}>
        3 :{translations.ApplyingStepsheader3}
        </span>
      </div>
      <p className="text-gray-600 dark:text-gray-100 mb-4">
      {translations.ApplyingSteps3}

      </p>

      <button
        className="bg-blue-500 hover.bg-blue-700 text-white font-bold py-2 px-4 mt-4 rounded"
        onClick={onconfirme}
      >+
      {translations.appointementunderstood}
      </button>
    </div>
  );
}
